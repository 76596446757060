import React from 'react';

import { useState, useEffect } from 'react';

import { Tag, Row, Button, Popconfirm } from 'antd';

import './guest-info.scss';

import { Location, ApiUtils } from 'soxo-bootstrap-core';

import moment from 'moment';

let paidValues = {
    Y: 'Paid',
    N: 'Pending',
    P: 'Partially Paid',
};

export default function GuestInfo({
    guest = {},
    // data of process status
    data = {},
    guestreg = {},
    guestdr = {},
    guestopreg = {},
    guestprereg = {},
    guestitm = {},
    guestitem = {},
    guestevent = {},
    params = {},
    urlParams = {},
    disableQuestionnaire = false,
}) {

    // Guest
    let { opbill = {} } = guest;

    if (!opbill) {
        opbill = { opb_paidflg: 'N' };
    }

    const [key, setKey] = useState(null);

    useEffect(() => {
        // getToken(guest.da_id)
        if (guest && guest.da_id) getQuestionnaireKey(guest.da_id);
    }, [guest]);

    /**
     * Get the questionnaire key
     *
     * @param {*} id
     */
    async function getQuestionnaireKey(id) {
        const result = await ApiUtils.get({ url: `doctor-appointment/questionnaire-key/${id}` });

        setKey(result.key);
    }

    //function to Reschedule Appointment
    function rescheduleAppointment(record) {
        let url = `/new-registrations/slots?da_doctorptr=${record.da_doctorptr}&da_packagetypevalue=${record.da_packagetypevalue}&appointId=${record.da_id}`;

        Location.navigate({ url: url });
    }
    function proceedBilling() {
        // &prereg_id=10553&mode=Online

        let url = `/new-registration/bill?op_no=${guest.da_newopno}&opreg_id=${guest.da_referenceid}&da_id=${guest.da_id}&da_packagetypevalue=${guest.da_packagetypevalue}`;

        Location.navigate({ url: url });
    }

    // //function to Start Questionnaire
    // function goQues(id, edit) {
    //     if (edit) {
    //         window.open(`${process.env.REACT_APP_QUES_KEY}/user/questionnaire?id=${id}&key=${key}&edit=true`, '_blank');
    //     } else {
    //         window.open(`${process.env.REACT_APP_QUES_KEY}/user/questionnaire?id=${id}&key=${key}`, '_blank');
    //     }
    // }

    function goQues(id, edit) {
        if (edit) {
            window.open(`${process.env.REACT_APP_QUES_KEY}/user/questionnaire?id=${id}&key=${key}&edit=true&index=${guest.da_branchptr}`, '_blank');

        } else {
            window.open(`${process.env.REACT_APP_QUES_KEY}/user/questionnaire?id=${id}&key=${key}&index=${guest.da_branchptr}`, '_blank');

        }
    }
    //function to Edit Appointmnet
    var date = moment(guest.da_date).format('DD/MM/YYYY');
    // Add time to da_date, to pass selected_time in the url for edit
    date = date + ' ' + guest.da_aptime

    function editAppointment() {
        Location.navigate({
            url: `/new-registrations/edit-guest?da_id=${guest.da_id}&da_packagetypevalue=${guest.da_packagetypevalue}&id=${guest.da_referenceid}&prereg_opno=${guest.da_newopno}&selected_time=${date}&edit=true&redirect=appointment`,
        });
    }

    //to check if Date of Birth is available
    var age = null;

    if (guestprereg && !guestprereg.prereg_dob) {
        age = guestprereg.prereg_ageyear;
    }

    //Apointmnet Guest Deatil

    if (guest.da_id) {
        return (
            <>
                {/*Edit Appointment */}
                {guest.da_billid ? null : (
                    <div className="edit-appointment">
                        <Button
                            size="middle"
                            type="primary"
                            // shape="round"
                            // style={{ background: "#FAC141", justifyContent: ' space-between"' }}
                            onClick={editAppointment}
                        >
                            Edit Appointment
                        </Button>
                    </div>
                )}

                {/*Guest Information */}
                <div className="guest-info">
                    {/* <div>
                        <h6>{guest && guest.da_fname ? (guest.da_fname.toUpperCase()) : null} {guest && guest.da_lname ? (guest.da_lname.toUpperCase()) : null} booked an appointment for {moment(guest.da_time).format('dddd, MMM Do')}</h6>
                    </div> */}

                    {/*Guest Detail */}
                    <div gutter={0} className="detail-wrapper">
                        <div className="detail-element">
                            <Row>
                                <span>Name</span>
                            </Row>

                            <Row>
                                <span>
                                    <strong>
                                        {guest && guest.da_title ? guest.da_title : null}{' '}
                                        {guest && guest.da_fname ? guest.da_fname.toUpperCase() : null}{' '}
                                        {guest && guest.da_lname ? guest.da_lname.toUpperCase() : null} (
                                        {guest && guest.da_gender
                                            ? guest.da_gender
                                            : guestprereg && guestprereg.prereg_gender
                                                ? guestprereg.prereg_gender
                                                : null}
                                        ) - {guestprereg ? guestprereg.prereg_ageyear : guestopreg && guestopreg.op_age ? guestopreg.op_age : null}{' '}
                                    </strong>
                                </span>
                            </Row>
                            {/*Reminder to Provide Date of Birth if not Given */}
                            {age ? (
                                <Row>
                                    <small style={{ color: 'orange' }}>Remarks: Date of Birth is Required</small>
                                </Row>
                            ) : null}
                        </div>
                        {/* <div className="detail-element">
                        <Row span={12}>
                                <span className='details'>Credited At</span>
                            </Row>
                            <Row span={12}>
                                <span>
                                    <strong>{moment(guest.da_time).format('dddd, MMM Do')}</strong>
                                </span>
                            </Row>
                        </div> */}
                        <div className="detail-element">
                            <Row>
                                <span>Address</span>
                            </Row>

                            {guestprereg ? (
                                (guestprereg && guestprereg.prereg_address1) || (guestprereg && guestprereg.prereg_address2) ? (
                                    <Row>
                                        <span>
                                            <strong>
                                                {guestprereg && guestprereg.prereg_address1 ? guestprereg.prereg_address1 : null}
                                                <br />
                                                {guestprereg && guestprereg.prereg_address2 ? guestprereg.prereg_address2 : null}
                                                <br />
                                                {guestprereg && guestprereg.prereg_zip ? guestprereg.prereg_zip : null}
                                            </strong>
                                        </span>
                                    </Row>
                                ) : guest.da_opno ? null : (
                                    <Row>
                                        <small style={{ color: 'orange' }}>Remarks: Address is Required</small>
                                    </Row>
                                )
                            ) : guest ? (
                                <Row>
                                    <span>
                                        <strong>
                                            {guest.da_add1}
                                            <br />
                                            {guest.da_add2}
                                            <br />
                                            {guest.da_zip1}
                                            {/* {guestprereg && guestprereg.da_place ? guestprereg.da_place : null} */}
                                        </strong>
                                    </span>
                                </Row>
                            ) : null}
                        </div>

                        <div className="detail-element">
                            <Row>
                                <span>Contact</span>
                            </Row>
                            <Row>
                                <span>
                                    {' '}
                                    <strong>{guest && guest.da_mobile ? guest.da_mobile : null} </strong>
                                </span>
                            </Row>
                        </div>

                        <div className="detail-element-card">
                            <Row span={12}>
                                <span>Email</span>
                            </Row>
                            <Row span={12}>
                                <span>
                                    {' '}
                                    <strong>
                                        {guest && guest.da_email
                                            ? guest.da_email
                                            : guestprereg && guestprereg.prereg_email
                                                ? guestprereg.prereg_email
                                                : null}{' '}
                                    </strong>
                                </span>
                            </Row>
                        </div>

                        <div className="detail-element-card">
                            <Row span={12}>
                                <span>UserName</span>
                            </Row>
                            <Row span={12}>
                                <span>
                                    {' '}
                                    <strong>{guestprereg && guestprereg.prereg_username ? guestprereg.prereg_username : null} </strong>
                                </span>
                            </Row>
                        </div>

                        {/* Package Name */}
                        <div className="detail-element-card">
                            <Row span={12}>
                                <span>Package</span>
                            </Row>
                            <Row span={12}>
                                <span>
                                    {' '}
                                    <strong>{guestitem && guestitem.itm_desc ? guestitem.itm_desc : null} </strong>
                                </span>
                            </Row>
                        </div>

                        {/*Token Number */}

                        <div className="detail-element-card">
                            <Row span={12}>
                                <span>Token Number</span>
                            </Row>
                            <Row span={12}>
                                <span>
                                    {' '}
                                    <strong>{guest && guest.da_tokenno ? guest.da_tokenno : null} </strong>
                                </span>
                            </Row>
                        </div>

                        {/*Registration Number */}

                        <div className="detail-element-card">
                            <Row span={12}>
                                <span>Registered Status</span>
                            </Row>
                            <Row span={12}>
                                <span>{guestopreg && guestopreg.op_no ? <Tag color="green">Booked</Tag> : <Tag color="orange">Pending</Tag>}</span>
                            </Row>
                        </div>

                        {/*Appointment Time */}

                        <div className="detail-element-card">
                            <Row span={12}>
                                <span>Appointment At</span>
                            </Row>
                            <Row span={12}>
                                <span>
                                    <strong>
                                        {moment(guest.da_date).format('DD/MM/YYYY')} {guest.da_aptime}
                                    </strong>
                                </span>
                            </Row>

                            {/*Rechedule of Appointment */}
                            <Row>
                                {guest.da_billid ? null : (
                                    <Popconfirm
                                        title="Are you sure you want to Reschedule this appointment ? "
                                        onConfirm={() => rescheduleAppointment(guest)}
                                        onCancel={() => { }}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button
                                            size="middle"
                                            type="primary"
                                        // shape="round"
                                        // style={{ marginTop: '10px', background: "#FAC141", justifyContent: ' space-between' }}
                                        >
                                            Reschedule
                                        </Button>
                                    </Popconfirm>
                                )}
                            </Row>
                        </div>

                        {/*Consent Status */}
                        {/* <div className="detail-element-card">
                            <Row span={12}>
                                <span>Consent Status</span>
                            </Row>
                            <Row span={12}>
                                <span>{guest.da_consentfilled ? <Tag color="green">Submitted</Tag> : <Tag color="orange">Pending</Tag>}</span>
                            </Row>
                        </div> */}

                        {/*Payment Status */}
                        <div className="detail-element-card">
                            <Row span={12}>
                                <span>Appointment Paid</span>
                            </Row>
                            <Row span={12}>
                                <span>{guest.da_paystatus === 'F' ? <Tag color="green">Paid</Tag> : <Tag color="orange">Pending</Tag>}</span>
                            </Row>
                        </div>

                        {/*Payment Status */}
                        {/* <div className="detail-element-card">
                            <Row span={12}>
                                <span>Bill Paid</span>
                            </Row>
                            <Row span={12}>
                                <Tag color={opbill.opb_paidflg === 'Y' ? 'green' : 'orange'}>{paidValues[opbill.opb_paidflg]}</Tag>

                            </Row>
                        </div> */}

                        {/*Payment Status */}
                        <div className="detail-element-card">
                            <Row span={12}>
                                <span>Remarks</span>
                            </Row>
                            <Row span={12}>
                                <strong>{guest.da_remarks}</strong>

                                {/* <span>{guest.da_paystatus === 'F' ? <Tag color="green">Paid</Tag> : <Tag color="orange">Pending</Tag>}</span> */}
                            </Row>
                        </div>

                        {/*Questionnaire Status */}
                        <div className="detail-element-card">
                            <Row span={12}>
                                <span>Questionnaire Status</span>
                            </Row>
                            <Row span={12}>
                                <span> {guest.da_questfilled === 'F' ? <Tag color="green">Filled</Tag> : <Tag color="orange">Pending</Tag>}</span>
                            </Row>
                            <Row>
                                {/* Show the questionnaire link only once the key is loaded */}

                                <>
                                    {key ? (
                                        <>
                                            {guest.da_questfilled === 'F' ? (
                                                <Button
                                                    size="middle"
                                                    type="primary"
                                                    // shape="round"
                                                    // style={{ marginTop: '10px', background: "#FAC141", justifyContent: ' space-between' }}
                                                    onClick={() => {
                                                        goQues(guest.da_id, true);
                                                    }}
                                                >
                                                    Edit Questionnaire
                                                </Button>
                                            ) : (
                                                <Button
                                                    size="middle"
                                                    type="primary"
                                                    // shape="round"
                                                    // style={{ marginTop: '10px', background: "#FAC141", justifyContent: ' space-between' }}
                                                    onClick={() => {
                                                        goQues(guest.da_id);
                                                    }}
                                                >
                                                    Start Questionnaire
                                                </Button>
                                            )}
                                        </>
                                    ) : null}
                                </>
                            </Row>
                        </div>

                        {/* Process Status */}
                        <div className='card'>
                            <>
                                {
                                    data ?
                                        (
                                            <div>
                                                <h1>Process Status</h1>
                                                <p>Fitkit Status: {data.fitkit_status ? <strong>{data.fitkit_status} </strong> : <Tag color="orange">Pending</Tag>}</p>
                                                <p>Bill Paid: {data.billed_status === 'Billed' ? <Tag color="green">{data.billed_status}</Tag> : <Tag color="orange">{data.billed_status}</Tag>}</p>
                                                <p>Sample Status: {data.sample_status ? <strong>{data.sample_status}</strong> : <Tag color="orange">Pending</Tag>}</p>
                                                <p>Physician Verified : {data.physician_verified === 'Verified' ? <Tag color="green">{data.physician_verified}</Tag> : <Tag color="orange">{data.physician_verified}</Tag>} </p>
                                                <p>Report Published  : {data.report_published && data.report_published === true ? <Tag color="green">Published</Tag> : <Tag color="orange">Pending</Tag>}</p>
                                                <p>Report Status: {data.report_status ? <strong>{data.report_status}</strong> : <Tag color="orange">Pending</Tag>}</p>
                                                <p>Report Ready Mail Send : {data.reportmail_sendstatus && data.reportmail_sendstatus === 'Send' ? <Tag color="green">{data.reportmail_sendstatus}</Tag> : <Tag color="orange">{data.reportmail_sendstatus}</Tag>} </p>

                                            </div>
                                        )
                                        :
                                        (null)
                                }

                            </>

                        </div>

                        {/* Process Status */}

                    </div>
                </div>

                {/* <Avatar shape="square" size={64} icon={<UserOutlined />} /> */}
            </>
        );
    } else if (guestreg) {
        // /Registration Details

        return (
            <>
                {guestdr.da_billid ? null : (
                    <div className="edit-appointment">
                        {/* <Button
                            onClick={() => {
                                Location.navigate({
                                    url: `/new-registration/guest?da_id=${guest.da_id}&da_packagetypevalue=${guest.da_packagetypevalue}&prereg_id=${guest.da_referenceid}&prereg_opno=${guest.da_newopno}&edit=true&redirect=registration`,
                                });
                            }}
                            type="ghost"
                            size={'small'}
                            shape="round"
                            style={{ background: '#FAC141', justifyContent: ' space-between"' }}
                        >
                            Edit Registration
                        </Button> */}
                    </div>
                )}

                {/*Guest Details */}
                <div className="guest-info card ">
                    <div gutter={0} className="detail-wrapper">
                        <div className="detail-element">
                            <Row span={12}>
                                <span className="details">Name</span>
                            </Row>
                            <Row span={12}>
                                <span>
                                    <strong>
                                        {guestreg.op_fname ? guestreg.op_fname.toUpperCase() : null}{' '}
                                        {guestreg.op_lname ? guestreg.op_lname.toUpperCase() : null} ({guestreg.op_gender ? guestreg.op_gender : null}
                                        ) - {guestreg.op_age ? guestreg.op_age : null}
                                    </strong>
                                </span>
                            </Row>
                        </div>
                        <div className="detail-element">
                            <Row span={12}>
                                <span className="details">Address</span>
                            </Row>
                            <Row span={12}>
                                <span>
                                    <strong>
                                        {guestreg.op_add1 ? guestreg.op_add1 : null}
                                        <br />
                                        {guestreg.op_add2 ? guestreg.op_add2 : null}
                                        <br />
                                        {guestreg.op_place ? guestreg.op_place : null}
                                    </strong>
                                </span>
                            </Row>
                        </div>
                        <div className="detail-element">
                            <Row span={12}>
                                <span className="details">Contact</span>
                            </Row>
                            <Row span={12}>
                                <span><strong>{guestreg.op_mobile ? guestreg.op_mobile : guest && guest.da_mobile ? guest.da_mobile : null}</strong></span>
                            </Row >
                        </div >
                        {/* <div className="detail-element-card">
                            <Row span={12}>
                                <span className="details">OP No</span>
                            </Row>
                            <Row span={12}>
                                <span>
                                    <strong>{params.id}</strong>
                                </span>
                            </Row>
                        </div> */}
                        < div className="detail-element-card" >
                            <Row span={12}>
                                <span className="details">Email</span>
                            </Row>
                            <Row span={12}>
                                <span>
                                    <strong>{guestreg.op_email ? guestreg.op_email : null}</strong>
                                </span>
                            </Row>
                        </div >
                        <div className="detail-element-card">
                            <Row span={12}>
                                <span className="details">UserName</span>
                            </Row>
                            <Row span={12}>
                                <span>
                                    <strong>{guestprereg ? guestprereg.prereg_username : null}</strong>
                                </span>
                            </Row>
                        </div>
                        {/*Appointmnet Time */}
                        <div className="detail-element-card">
                            <Row span={12}>
                                <span className="details">Appointment Time</span>
                            </Row>
                            <Row span={12}>
                                <span>
                                    <strong>{moment(guest.da_date).format('dddd, MMM Do')} {guest.da_aptime}</strong>
                                </span >
                            </Row >
                        </div >
                        {/*Package Name */}
                        < div className="detail-element-card" >
                            <Row span={12}>
                                <span className="details">Package</span>
                            </Row>
                            <Row span={12}>
                                <span>
                                    <strong>{guestitm.itm_desc ? guestitm.itm_desc : null}</strong>
                                </span>
                            </Row>
                        </div >
                        {/*Questionnaire Status */}
                        < div className="detail-element-card" >
                            <Row span={12}>
                                <span className="details">Questionnaire Status</span>
                            </Row>
                            {
                                guest.da_questfilled === 'F' ? (
                                    <Row span={12}>
                                        <Tag color="green">Filled</Tag>
                                    </Row>
                                ) : (
                                    <Row>
                                        {/* {guest.da_questfilled === 'F' ?
                                        <Button
                                            size="middle"
                                            type="ghost"
                                            shape="round"
                                            style={{ marginTop: '10px', background: '#FAC141', justifyContent: ' space-between' }}
                                            onClick={() => {
                                                goQues(guest.da_id, true);
                                            }}
                                        >
                                            Edit Questionnaire
                                        </Button>
                                    ) : (
                                        <Button
                                            size="middle"
                                            type="ghost"
                                            shape="round"
                                            style={{ marginTop: '10px', background: '#FAC141', justifyContent: ' space-between' }}
                                            onClick={() => {
                                                goQues(guest.da_id);
                                            }}
                                        >
                                            Start Questionnaire


                                        </Button>} */}
                                    </Row>
                                )
                            }
                        </div >
                        {/*Consent Form Status */}
                        < div className="detail-element-card" >
                            <Row span={12}>
                                <span className="details">Consent Status</span>
                            </Row>
                            {
                                guest.da_consentfilled ? (
                                    <Row span={12}>
                                        <Tag color="green">Submitted</Tag>
                                    </Row>
                                ) : (
                                    <Row span={12}>
                                        <Tag color="orange">Pending</Tag>
                                    </Row>
                                )
                            }
                        </div >

                        {/*Payment Status */}

                        < div className="detail-element-card" >
                            <Row span={12}>
                                <span className="details">Payment Status</span>
                            </Row>
                            {
                                guestdr.da_billid ? (
                                    <Row span={12}>
                                        <Tag color="green">Paid</Tag>
                                    </Row>
                                ) : (
                                    <Row span={12}>
                                        <Tag color="orange">Pending</Tag>
                                    </Row>
                                )
                            }
                        </div >

                        {/*Current Status */}
                        {
                            guestevent.Subevtm_desc ? (
                                <>
                                    <div className="detail-element-card">
                                        <Row span={12}>
                                            <span className="details">Current Status</span>
                                        </Row>
                                        <Row span={12}>
                                            <span>
                                                <strong>{guestevent.Subevtm_desc}</strong>
                                            </span>
                                        </Row>
                                    </div>

                                    <div className="detail-element-card">
                                        <Row span={12}>
                                            <span>Current Status Remarks</span>
                                        </Row>
                                        <Row span={12}>
                                            <span>
                                                <strong>{guestevent.Subevtm_remarks}</strong>
                                            </span>
                                        </Row>
                                    </div>
                                </>
                            ) : null
                        }
                    </div >
                </div >
                <div>
                    {/* {guest.da_newopno && !guest.da_billid ? (

                        <>
                            <div>
                                <Row>
                                    <div>
                                        <Button
                                            className="billing"
                                            onClick={() => {
                                                proceedBilling();
                                            }}
                                            type="secondary"
                                            shape="round"
                                            style={{ background: '#FAC141' }}
                                            // size="small"
                                            color="green"
                                        >
                                            Proceed for Billing
                                        </Button>
                                    </div>
                                </Row>
                            </div>
                        </>
                    ) : null} */}
                </div>
            </>
        );
    }
}
