import React, { useState, useEffect, useContext, Fragment } from 'react';

import { Form, Radio, InputNumber, Input, DatePicker, Button, Typography, message, Divider, Space, Popconfirm } from 'antd';

import AsyncSelect from 'react-select/async';

import { withRouter } from 'react-router-dom';

import { GlobalContext } from 'soxo-bootstrap-core';

import { Location, ApiUtils } from 'soxo-bootstrap-core';

import { Bills, Collections } from './../../../../models/';

import './collections-add.scss';

const { Title, Text } = Typography;

const { TextArea } = Input;

const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 16 },
        sm: { span: 16 },
        lg: { span: 16 },
    },
};

let defaultCollection = {};

/**
 * Collection add
 *
 * @param {*} param0
 * @returns
 */
function CollectionAdd({ model, history, match }) {
    const { user } = useContext(GlobalContext);

    // Bills
    const [bill, setBill] = useState({});

    const [collection, setCollection] = useState({
        collected_amount: 0,
    });

    const [records, setRecords] = useState([defaultCollection]);

    const [loader, setLoader] = useState(false);

    const [openForm, setOpenForm] = useState(0);

    const [errMessage, setError] = useState('');

    const [form] = Form.useForm();

    const [type, setType] = useState('Cash');

    const [mounted, setMount] = useState(0);

    let urlParams = Location.search();

    useEffect(() => {
        if (urlParams.opbd_hdrid) {
            loadBill(urlParams.opbd_hdrid);
        }
    }, []);

    function loadBill(opbd_hdrid) {
        Bills.getRecord(opbd_hdrid).then((result) => {
            // setBill(result);

            setBill({
                ...result,
                value: result.opb_id,
                label: result.opb_name,
            });

            console.log(result);
        });

        console.log(opbd_hdrid);

        setBill({});
    }

    /**
     *
     * @param {*} values
     */
    const onSubmit = () => {
        setError('');
        setLoader(true);
        // Records
        const saved = records.filter((record) => record.status === 'saved');
        if (saved.length !== records.length) {
            setLoader(false);
            return setError('Please save all!');
        } else
            Collections.addEntry(records, bill, user)
                .then((result) => {
                    console.log('addentry', result);

                    // Location.back();

                    setLoader(false);
                    setError('');
                    message.success('New record added');
                    // if ()

                    //  if (urlParams.opbd_hdrid){
                    // Location.navigate({ url: '/' })
                    // Location.navigate({ url:`/receipts/${urlParams.opbd_hdrid}`});

                    //  }
                    //  else{
                    // urlParams.opbd_hdrid =  result[0].cold_rctno
                    Location.navigate({ url: `/receipts/${result[0].cold_rctno}` });
                    //  }
                    //   Location.back();

                    //  Location.navigate({ url: '/' });
                })
                .catch(() => {
                    setLoader(false);
                });
    };

    /**
     * Add New record
     */
    function addNew() {
        //
        console.log(records);

        var values = form.getFieldsValue();

        console.log(values);

        // Save the previous record
        records[records.length - 1] = {
            ...records[records.length - 1],
            ...values,

            status: 'saved',
        };
        setOpenForm(records.length);

        let defaultCollection = {};

        records.push(defaultCollection);

        setRecords([...records]);

        form.setFieldsValue({ type: 'Cash', amount: undefined, remarks: '' });
        setType('Cash');
    }

    /**
     * Edit record
     */

    function editRecord(index) {
        records[index].status = 'editing';

        setOpenForm(index);

        setRecords([...records]);

        form.setFieldsValue(records[index]);

        setType(records[index].type);
    }

    /**
     * Update record
     */

    function updateRecord(index) {
        var values = form.getFieldsValue();

        records[index] = {
            ...records[index],
            ...values,

            status: 'saved',
        };

        setOpenForm(records.length);

        setRecords([...records]);

        form.setFieldsValue(records[records.length - 1]);

        setType(records[records.length - 1].type);
    }

    /**
     * Delete record
     */

    function deleteRecord(index) {
        records.splice(index, 1);
        message.success('Record deleted');
        setMount((prev) => {
            return prev + 1;
        });
    }

    function cancel() {
        message.success('Cancelled');
    }

    /**
     * Load the matching heads
     */
    function loadHeads(query) {
        if (query) {
            return Bills.getOplist(query).then((result) => {
                return result.map((item) => {
                    return {
                        value: item.opb_id,
                        label: item.opb_name,
                        ...item,
                    };
                });
            });
        }
    }
    const handleChange = (value) => {
        /**
         * Load Previous Collection
         */
        var bill_id = +value.opb_id;

        Bills.getCollections(bill_id).then((collections) => {
            var collected_amount = 0;

            var coll = [];
            // console.log('collections',collections)

            coll = collections.map((element) => {
                collected_amount = collected_amount + element.cold_amt;
                return element;
            });

            var coll_object = { collected_amount, coll };

            //  console.log("collections", coll_object)

            setCollection(coll_object);
        });

        // Bill
        setBill(value);
    };

    return (
        <section className="collections-add">
            <Title level={4}>New Collection</Title>

            <p>Record the collection details below.</p>

            <div className="page-content">
                <div className="left">
                    <p>
                        <small>Bill Number</small>
                    </p>

                    <AsyncSelect value={bill} onChange={handleChange} cacheOptions defaultOptions loadOptions={loadHeads} />
                    {bill.opb_bno ? (
                        bill.opb_netamt - (collection.collected_amount + bill.opb_paidamt) > 0 ? (
                            <div>
                                {records.map((record, index) => {
                                    return (
                                        <div>
                                            {record.status === 'saved' ? (
                                                <>
                                                    <div className={'card card-shadow'}>
                                                        <h1 className="">{record.amount}</h1>

                                                        <h1>{record.type}</h1>
                                                        <Space>
                                                            <Button onClick={() => editRecord(index)} type="secondary">
                                                                Edit
                                                            </Button>
                                                            <Popconfirm
                                                                title="Are you sure to delete this task?"
                                                                onConfirm={() => deleteRecord(index)}
                                                                onCancel={cancel}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <Button danger>Delete</Button>
                                                            </Popconfirm>
                                                        </Space>
                                                    </div>
                                                </>
                                            ) : openForm === index ? (
                                                <Form
                                                    {...layout}
                                                    form={form}
                                                    name="new-record"
                                                    onFinish={onSubmit}
                                                    layout="vertical"
                                                    // validateMessages={validateMessages}
                                                    initialValues={{}}
                                                    onFieldsChange={(fields, value) => {
                                                        let field = fields[0];

                                                        if (field && field.value) {
                                                            if (field.name[0] === 'type') {
                                                                setType(field.value);
                                                            }
                                                        } else {
                                                        }
                                                    }}
                                                >
                                                    <div className="card card-shadow">
                                                        {/* OP Bill */}
                                                        {/* <Form.Item
                                        name={'op_bill'}
                                        label="OP Number"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please choose guest',
                                            },
                                        ]}
                                    >
                                   
                                    </Form.Item> */}
                                                        {/* OP Bill Ends */}

                                                        {/* Payment Type */}

                                                        <Form.Item
                                                            name={'type'}
                                                            label="Payment Type"
                                                            initialValue="Cash"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please choose the payment type.',
                                                                },
                                                            ]}
                                                        >
                                                            <Radio.Group>
                                                                <Radio value="Cash">Cash</Radio>
                                                                <Radio value="Net Banking">Net Banking</Radio>
                                                                <Radio value="Credit Card">Credit Card</Radio>
                                                                <Radio value="Debit Card">Debit Card</Radio>
                                                                <Radio value="Cheque">Cheque</Radio>
                                                            </Radio.Group>
                                                        </Form.Item>

                                                        {/* Payment Type */}

                                                        <Form.Item
                                                            name={'amount'}
                                                            label="Amount"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter Amount',
                                                                },
                                                            ]}
                                                        >
                                                            <InputNumber />
                                                        </Form.Item>

                                                        {type === 'Net Banking' ? (
                                                            <Form.Item
                                                                name={'instrumentNo'}
                                                                label="UTR Number"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please enter UTR Number',
                                                                    },
                                                                ]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                        ) : null}

                                                        {type === 'Cheque' ? (
                                                            <>
                                                                <Form.Item
                                                                    name={'instrumentNo'}
                                                                    label="Cheque Number"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please enter Cheque Number',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input />
                                                                </Form.Item>

                                                                <Form.Item
                                                                    name={'chequeDate'}
                                                                    label="Cheque Date"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please enter Cheque Date',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <DatePicker inputReadOnly />
                                                                </Form.Item>
                                                            </>
                                                        ) : null}

                                                        {type === 'Credit Card' || type === 'Debit Card' ? (
                                                            <>
                                                                <Form.Item
                                                                    name={'instrumentNo'}
                                                                    label="Card Number(Last 4 digits)"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please enter Card Number',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input />
                                                                </Form.Item>

                                                                <Form.Item
                                                                    name={'holderName'}
                                                                    label="Card Holder Name"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please enter Card Holder Name',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input />
                                                                </Form.Item>

                                                                <Form.Item
                                                                    name={'bankName'}
                                                                    label="Bank Name"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please enter Bank Name',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input />
                                                                </Form.Item>

                                                                <Form.Item
                                                                    name={'cardName'}
                                                                    label="Card Name"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Please enter Card Name',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input />
                                                                </Form.Item>

                                                                <Form.Item
                                                                    name={'issuedBy'}
                                                                    label="Card Issued By"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Card Issued By',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input />
                                                                </Form.Item>

                                                                <Form.Item
                                                                    name={'expiryDate'}
                                                                    label="Card Expiry Date"
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'Expiry Date',
                                                                        },
                                                                    ]}
                                                                >
                                                                    <Input />
                                                                </Form.Item>
                                                            </>
                                                        ) : null}
                                                        {/* 
                {type !== 'Cash' &&
                    type !== 'Credit Card' &&
                    type !== 'Debit Card' ? (
                    <>
                        <Form.Item
                            name="attachments"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Share attachments of the receipt'
                                }
                            ]}
                            label="Attach Payment Screenshot"
                            valuePropName="fileList"
                            extra="File size should not exceed 1 mb"
                        >
                            <FileUpload
                                callback={onUpload}
                                onProgress={() => {
                                    setDisabled(
                                        true
                                    );
                                }}
                            />
                        </Form.Item>
                    </>
                ) : null} */}

                                                        {/* Remarks */}
                                                        <Form.Item
                                                            name={'remarks'}
                                                            label="Remarks"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter remarks',
                                                                },
                                                            ]}
                                                        >
                                                            <TextArea rows={4} />
                                                        </Form.Item>

                                                        <Space>
                                                            <Button onClick={() => updateRecord(index)} type="secondary">
                                                                Save
                                                            </Button>
                                                            <Popconfirm
                                                                title="Are you sure to delete this task?"
                                                                onConfirm={() => deleteRecord(index)}
                                                                onCancel={cancel}
                                                                okText="Yes"
                                                                cancelText="No"
                                                            >
                                                                <Button danger>Delete</Button>
                                                            </Popconfirm>
                                                        </Space>
                                                    </div>
                                                </Form>
                                            ) : (
                                                <div className={'card card-shadow'}>
                                                    <h1 className="">{record.amount}</h1>

                                                    <h1>{record.type}</h1>

                                                    <Button onClick={() => editRecord(index)} type="secondary">
                                                        Edit
                                                    </Button>
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                                <p>
                                    {records.length} {records.length === 1 ? <small>entry</small> : <small>entries</small>}
                                    <Text type="danger">
                                        <small> {errMessage}</small>
                                    </Text>
                                </p>
                                <br />
                                <Space>
                                    <Button onClick={addNew} type="secondary">
                                        Add New
                                    </Button>
                                    <Button
                                        loading={loader}
                                        // disabled={disabled}
                                        type="primary"
                                        className="submit-button"
                                        onClick={onSubmit}
                                    >
                                        Save
                                    </Button>
                                </Space>
                            </div>
                        ) : (
                            <div>
                                <h1 style={{ marginTop: '50px', marginLeft: '50px' }}>
                                    <strong>Nothing to Pay</strong>
                                </h1>
                            </div>
                        )
                    ) : (
                        <div>
                            {records.map((record, index) => {
                                return (
                                    <div>
                                        {record.status === 'saved' ? (
                                            <>
                                                <div className={'card card-shadow'}>
                                                    <h1 className="">{record.amount}</h1>

                                                    <h1>{record.type}</h1>
                                                    <Space>
                                                        <Button onClick={() => editRecord(index)} type="secondary">
                                                            Edit
                                                        </Button>
                                                        <Popconfirm
                                                            title="Are you sure to delete this task?"
                                                            onConfirm={() => deleteRecord(index)}
                                                            onCancel={cancel}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <Button danger>Delete</Button>
                                                        </Popconfirm>
                                                    </Space>
                                                </div>
                                            </>
                                        ) : openForm === index ? (
                                            <Form
                                                {...layout}
                                                form={form}
                                                name="new-record"
                                                onFinish={onSubmit}
                                                layout="vertical"
                                                // validateMessages={validateMessages}
                                                initialValues={{}}
                                                onFieldsChange={(fields, value) => {
                                                    let field = fields[0];

                                                    if (field && field.value) {
                                                        if (field.name[0] === 'type') {
                                                            setType(field.value);
                                                        }
                                                    } else {
                                                    }
                                                }}
                                            >
                                                <div className="card card-shadow">
                                                    {/* OP Bill */}
                                                    {/* <Form.Item
                                        name={'op_bill'}
                                        label="OP Number"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please choose guest',
                                            },
                                        ]}
                                    >
                                   
                                    </Form.Item> */}
                                                    {/* OP Bill Ends */}

                                                    {/* Payment Type */}

                                                    <Form.Item
                                                        name={'type'}
                                                        label="Payment Type"
                                                        initialValue="Cash"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please choose the payment type.',
                                                            },
                                                        ]}
                                                    >
                                                        <Radio.Group>
                                                            <Radio value="Cash">Cash</Radio>
                                                            <Radio value="Net Banking">Net Banking</Radio>
                                                            <Radio value="Credit Card">Credit Card</Radio>
                                                            <Radio value="Debit Card">Debit Card</Radio>
                                                            <Radio value="Cheque">Cheque</Radio>
                                                        </Radio.Group>
                                                    </Form.Item>

                                                    {/* Payment Type */}

                                                    <Form.Item
                                                        name={'amount'}
                                                        label="Amount"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter Amount',
                                                            },
                                                        ]}
                                                    >
                                                        <InputNumber />
                                                    </Form.Item>

                                                    {type === 'Net Banking' ? (
                                                        <Form.Item
                                                            name={'instrumentNo'}
                                                            label="UTR Number"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please enter UTR Number',
                                                                },
                                                            ]}
                                                        >
                                                            <Input />
                                                        </Form.Item>
                                                    ) : null}

                                                    {type === 'Cheque' ? (
                                                        <>
                                                            <Form.Item
                                                                name={'instrumentNo'}
                                                                label="Cheque Number"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please enter Cheque Number',
                                                                    },
                                                                ]}
                                                            >
                                                                <Input />
                                                            </Form.Item>

                                                            <Form.Item
                                                                name={'chequeDate'}
                                                                label="Cheque Date"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please enter Cheque Date',
                                                                    },
                                                                ]}
                                                            >
                                                                <DatePicker inputReadOnly />
                                                            </Form.Item>
                                                        </>
                                                    ) : null}

                                                    {type === 'Credit Card' || type === 'Debit Card' ? (
                                                        <>
                                                            <Form.Item
                                                                name={'instrumentNo'}
                                                                label="Card Number(Last 4 digits)"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please enter Card Number',
                                                                    },
                                                                ]}
                                                            >
                                                                <Input />
                                                            </Form.Item>

                                                            <Form.Item
                                                                name={'holderName'}
                                                                label="Card Holder Name"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please enter Card Holder Name',
                                                                    },
                                                                ]}
                                                            >
                                                                <Input />
                                                            </Form.Item>

                                                            <Form.Item
                                                                name={'bankName'}
                                                                label="Bank Name"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please enter Bank Name',
                                                                    },
                                                                ]}
                                                            >
                                                                <Input />
                                                            </Form.Item>

                                                            <Form.Item
                                                                name={'cardName'}
                                                                label="Card Name"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please enter Card Name',
                                                                    },
                                                                ]}
                                                            >
                                                                <Input />
                                                            </Form.Item>

                                                            <Form.Item
                                                                name={'issuedBy'}
                                                                label="Card Issued By"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Card Issued By',
                                                                    },
                                                                ]}
                                                            >
                                                                <Input />
                                                            </Form.Item>

                                                            <Form.Item
                                                                name={'expiryDate'}
                                                                label="Card Expiry Date"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Expiry Date',
                                                                    },
                                                                ]}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                        </>
                                                    ) : null}
                                                    {/* 
                {type !== 'Cash' &&
                    type !== 'Credit Card' &&
                    type !== 'Debit Card' ? (
                    <>
                        <Form.Item
                            name="attachments"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Share attachments of the receipt'
                                }
                            ]}
                            label="Attach Payment Screenshot"
                            valuePropName="fileList"
                            extra="File size should not exceed 1 mb"
                        >
                            <FileUpload
                                callback={onUpload}
                                onProgress={() => {
                                    setDisabled(
                                        true
                                    );
                                }}
                            />
                        </Form.Item>
                    </>
                ) : null} */}

                                                    {/* Remarks */}
                                                    <Form.Item
                                                        name={'remarks'}
                                                        label="Remarks"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'Please enter remarks',
                                                            },
                                                        ]}
                                                    >
                                                        <TextArea rows={4} />
                                                    </Form.Item>

                                                    <Space>
                                                        <Button onClick={() => updateRecord(index)} type="secondary">
                                                            Save
                                                        </Button>
                                                        <Popconfirm
                                                            title="Are you sure to delete this task?"
                                                            onConfirm={() => deleteRecord(index)}
                                                            onCancel={cancel}
                                                            okText="Yes"
                                                            cancelText="No"
                                                        >
                                                            <Button danger>Delete</Button>
                                                        </Popconfirm>
                                                    </Space>
                                                </div>
                                            </Form>
                                        ) : (
                                            <div className={'card card-shadow'}>
                                                <h1 className="">{record.amount}</h1>

                                                <h1>{record.type}</h1>

                                                <Button onClick={() => editRecord(index)} type="secondary">
                                                    Edit
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                            <p>
                                {records.length} {records.length === 1 ? <small>entry</small> : <small>entries</small>}
                                <Text type="danger">
                                    <small> {errMessage}</small>
                                </Text>
                            </p>
                            <br />
                            <Space>
                                <Button onClick={addNew} type="secondary">
                                    Add New
                                </Button>
                                <Button
                                    loading={loader}
                                    // disabled={disabled}
                                    type="primary"
                                    className="submit-button"
                                    onClick={onSubmit}
                                >
                                    Save
                                </Button>
                            </Space>
                        </div>
                    )}
                </div>

                <div className="right">
                    {bill.opb_bno ? (
                        <>
                            <div className="card card-shadow bill-details">
                                <Title level={5}>Bill #{bill.opb_bno}</Title>

                                <div className="section-content">
                                    <div className="section-row">
                                        <div className="section-label">
                                            <Text type="secondary">Bill Value</Text>
                                        </div>
                                        <div className="section-value">
                                            <strong>{bill.opb_netamt}</strong>
                                        </div>
                                    </div>

                                    <div className="section-row">
                                        <div className="section-label">
                                            <Text type="secondary">Paid Amount</Text>
                                        </div>
                                        <div className="section-value">
                                            <strong>{bill.opb_paidamt}</strong>
                                        </div>
                                    </div>

                                    <div className="section-row">
                                        <div className="section-label">
                                            <Text type="secondary">Collected Amount</Text>
                                        </div>
                                        <div className="section-value">
                                            <strong>{collection.collected_amount}</strong>
                                        </div>
                                    </div>

                                    <Divider />

                                    <div className="section-row">
                                        <div className="section-label">
                                            <Text type="secondary">Balance Payable</Text>
                                        </div>
                                        <div className="section-value">
                                            <strong>{bill.opb_netamt - (collection.collected_amount + bill.opb_paidamt)}</strong>
                                        </div>
                                    </div>
                                </div>
                                {/* Package Details  */}

                                {/* Package Details */}
                            </div>
                        </>
                    ) : null}
                </div>
            </div>
        </section>
    );
}

export default withRouter(CollectionAdd);
