/**
 *@description
 * @author Sameenaammad
 * This compnent is used to display the summary 
 * The time date of appoinment, package and the total value
 * Apply discount is also managed in this component
 * On click of Apply discount, discount value is enterd and submitted
 * On Submission of the form applied discount value is deducted from da_billvalue
 */

import React,{ useState, useEffect } from 'react';

import { Button, Input, Typography, Modal, message, Form, Popconfirm, Skeleton, InputNumber, Radio, Card } from 'antd';

import { Location, InputComponent } from 'soxo-bootstrap-core';

import { Appointments, Items } from './../../../../models';

const { Title, Text } = Typography;

const { TextArea } = Input;

export function Summary({ loading, setGender, appliedDiscount, appliedCoupon, totalValue, callback }) {


   var urlParams = Location.search();

   const [selected, setSelected] = useState({});

   const [visible, setVisible] = useState(false);

   const [couponVisible, setCouponVisible] = useState(false);

   // Variable used to determine if disocunt or coupon chosen
   const [selectedMode, setSelectedMode] = useState(appliedDiscount ? 'discount' : 'coupon')

   const [coupon, setCoupon] = useState(appliedCoupon);

   const [discount, setDiscount] = useState(appliedDiscount);

   const [total, setTotal] = useState(totalValue ? totalValue : null);

   useEffect(async () => {
      if (urlParams.da_packagetypevalue)
         await getSelectedPackage(urlParams.da_packagetypevalue)
   }, [urlParams.da_packagetypevalue])

   /**
      *Get Selected package
      */
   function getSelectedPackage(da_packagetypevalue) {

      Items.getItems(da_packagetypevalue).then(async (result) => {

         setSelected(result);
         setGender(result.itm_gender)
         // If total has not been set 
         if (total === null)
            setTotal(result.ir_rate)
      });
   }

   function discountVisibility() {
      setVisible(true)
   }


   const onChange = async (e) => {
      setSelectedMode(e.target.value)
   }


   function couponvisibility() {
      setCouponVisible(true);
   }


   return (
      <div className="right-content">
         <div className="card card-shadow">
            <div className="section-header">
               <Title level={5}>Package</Title>
            </div>

            {loading ? (
               <Skeleton />
            ) : (

               <>
                  {/*  */}
                  <div className="section-content">
                     <div className="section-row">
                        <div className="section-label">
                           <Text>
                              <strong>{selected.itm_desc}</strong>
                           </Text>
                        </div>
                        <div className="section-value">
                           <strong>{selected.ir_rate}</strong>
                        </div>
                     </div>
                     <div className="section-row">
                        <div className="section-label">
                           <Text type="secondary"> Apply Discount/Coupon</Text>
                        </div>


                        <div className="section-value">
                           <Radio.Group defaultValue={selectedMode}>
                              <Radio value='coupon' id='1' onChange={onChange}>Coupon</Radio>
                              <Radio value='discount' id='2' onChange={onChange}>Discount</Radio>
                           </Radio.Group>
                        </div>
                     </div>
                     {/** For mode discount, show discount modal */}
                     {selectedMode && selectedMode === 'discount' ?
                        <div className="section-row">
                           <div className="section-label">
                              <Text type="secondary"> Discount</Text>
                           </div>
                           <div className="section-value">
                              {discount && discount.discount ? (
                                 <>
                                    <strong>{discount.discount}</strong>

                                    <Popconfirm
                                       title="Are you sure you want to Cancel the Applied Discount ? "
                                       onConfirm={() => {
                                          setTotal(total + discount.discount)
                                          setDiscount(null);
                                          callback(null, 'discount', total + discount.discount)

                                       }}
                                       className="cancel-coupon-button"
                                       onCancel={() => { }}
                                       okText="Yes"
                                       cancelText="No"
                                    >
                                       <Button size="small" className="cancel-coupon-button">
                                          Cancel
                                       </Button>
                                    </Popconfirm>
                                 </>
                              ) : (
                                 <Button
                                    size="small"
                                    // type="ghost"
                                    // shape="round"
                                    onClick={discountVisibility}
                                    disabled={urlParams.mode === 'Walk In' || urlParams.edit}
                                 >
                                    {' '}
                                    Apply Discount
                                 </Button>
                              )}
                           </div>
                        </div> :

                        <div className="section-row">
                           {/** for mode coupon, show coupon modal*/}
                           <div className="section-label">
                              <Text type="secondary"> Coupon</Text>
                           </div>
                           <div className="section-value">
                              {coupon ?
                                 <>
                                    <strong>{coupon && coupon.coupon_amount ? coupon.coupon_amount : null}</strong>

                                    <Popconfirm
                                       title="Are you sure you want to Cancel the Coupon ? "
                                       onConfirm={() => {
                                          setTotal(total + coupon.coupon_amount)
                                          setCoupon(null);
                                          callback(null, 'coupon', total + coupon.coupon_amount)
                                       }}
                                       className="cancel-coupon-button"
                                       onCancel={() => { }}
                                       okText="Yes"
                                       cancelText="No"
                                    >
                                       <Button size="small" className="cancel-coupon-button" >
                                          Cancel
                                       </Button>
                                    </Popconfirm>
                                 </> :
                                 <Button
                                    loading={loading}
                                    size="small"
                                    type="secondary"
                                    // shape="round"
                                    onClick={couponvisibility}
                                    disabled={urlParams.mode === 'Walk In' || urlParams.edit}
                                 >
                                    {' '}
                                    Apply Coupon
                                 </Button>
                              }
                           </div>
                        </div>
                     }

                     <div className="section-row">
                        <div className="section-label">
                           <Text>Bill Value</Text>
                        </div>
                        <div className="section-value">

                           <strong>{total}</strong>

                        </div>
                     </div>
                  </div>
               </>
            )}

            {/* Mode and Time */}

            <div className="section-content">
               <div className="section-row">
                  <div className="section-label">
                     <Text type="secondary">Time</Text>
                  </div>


                  <div className="section-value">
                     <strong>
                        {urlParams.selected_date} {urlParams.selected_time}
                     </strong>
                  </div>
               </div>

            </div>
         </div>
         <Modal
            destroyOnClose={true}
            footer={null}
            title="Apply Discount"
            visible={visible}
            okText="Okay"
            onOk={() => {
               setVisible(false);
            }}
            onCancel={() => {
               setVisible(false);
            }}
         >
            <DiscountModal setVisible={setVisible} total={total}
               callback={(values) => {
                  // When discount is set, remove the applied coupon if  any
                  setDiscount(values)
                  setCoupon(null)

                  //Total value is set
                  setTotal(selected.ir_rate - values.discount)
                  callback(values, 'discount', selected.ir_rate - values.discount)
               }} />
         </Modal>
         {/**Coupon Modal starts */}
         <Modal
            destroyOnClose={true}
            footer={null}
            title="Apply Coupon"
            visible={couponVisible}
            okText="Okay"
            onOk={() => {
               setCouponVisible(false);
            }}
            onCancel={() => {
               setCouponVisible(false);
            }}
         >
            <CouponModal

               setCoupon={setCoupon}
               setTotal={setTotal}
               total={total}
               itemCode={urlParams.da_packagetypevalue}
               callback={(values, result) => {
                  //If coupon is applied, remove any discount if applied
                  setDiscount(null)
                  setTotal(selected.ir_rate - result.data.coupon_amount)
                  callback(values, 'coupon', selected.ir_rate - result.data.coupon_amount, result)
                  setCouponVisible(false)

               }} />
         </Modal>
         {/**Coupon Modal Ends */}
      </div>



   );
}


function DiscountModal({ setVisible, callback, total }) {

   const [loading, setLoading] = useState(false);

   const onFinish = (values) => {
      setVisible(false);
      callback(values)

   };

   return (
      <>
         <p>Mention the Discount</p>

         <Form name="basic" initialValues={{ remember: true }} onFinish={onFinish}>
            <Form.Item label="Discount (in Rs)" name="discount" rules={[{ required: true, message: 'Please Enter Discount Amount' },

            ({ getFieldValue }) => ({
               validator(_, value) {
                  if (value > total) {
                     return Promise.reject(new Error('Discount should not exceed net amount.'));
                  } else if (total - value < 0) {
                     return Promise.reject(new Error('Total amount cannot be less than zero'));
                  } else if (value < 0)
                     return Promise.reject(new Error('Discount Amount cannot be less than zero'))
                  else return Promise.resolve();
               },
            }),]}>
               <InputNumber />
            </Form.Item>

            <Form.Item>
               <Button loading={loading} type="primary" htmlType="submit">
                  Save
               </Button>
            </Form.Item>
         </Form>
      </>
   );

}


/**
 * Modal to apply coupon
 * @param {*} param0
         * @returns
         */
function CouponModal({ setCoupon, setTotal, total, itemCode, callback, selected }) {

   const [btnLoading, setBtnLoading] = useState(false)

   const onFinish = (values) => {

      setBtnLoading(true)

      Appointments.applyCoupon(values.coupon, itemCode)
         .then((result) => {
            if (result.success) {

               let couponAmount
               // Check if coupon type is percetage or amount
               if (result.data.value_type === 'P')
                  couponAmount = total * (result.data.coupon_value / 100)
               else if (result.data.value_type === 'A')
                  couponAmount = result.data.coupon_value
               result.data = {
                  ...result.data,
                  coupon_amount: couponAmount
               }
               // If after applying coupon, the total becomes less tha zero, it should send an error message
               if (total - result.data.coupon_value < 0) {
                  message.error('Coupon Amount exceeds total Amount. Please check your coupon')
                  return false
               } else {
                  message.success(result.message)

                  setCoupon(result.data);

                  setBtnLoading(false)

                  callback(values, result)
               }
            }
            else {
               message.error(result.message)

            }
         })
         .catch((error) => {


            message.error('Invalid Coupon');
         });
   };

   return (
      <>
         <p>Mention the Coupon</p>

         <Form name="basic" initialValues={{ remember: true }} onFinish={onFinish}>
            <Form.Item label="Coupon" name="coupon" rules={[{ required: true, message: 'Please Enter Coupon' }]}>
               <InputComponent />
            </Form.Item>
            <Form.Item name="Remarks" label="Remarks" rules={[{ required: true, message: 'Please Enter Remarks' }]}>
               <TextArea rules={[{ required: true, message: 'Please enter your remarks' }]} />
            </Form.Item>

            <Form.Item>
               <Button loading={btnLoading} type="primary" htmlType="submit">
                  Save
               </Button>
            </Form.Item>
         </Form>
      </>
   );
}


