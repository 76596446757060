import React, { useState, useEffect, useContext } from 'react';

import { Button, Typography, Skeleton, Popconfirm, message, Modal, Form, Input, Tabs, Tag, Row } from 'antd';

import { GlobalContext, Location, ConfirmModal, ApiUtils, Camera, AdvancedCamera } from 'soxo-bootstrap-core';

import DocumentPreview from './../../components/document-preview/document-preview';

import { Appointments, DocDetails } from './../../models';

// export default function DocumentViewer({ id, value, callback }) {


//     return (<>


//     </>)
// }


export default function ConsentCamera({ id, value, callback,...props }) {

    const [openMessage, setOpenMessage] = useState(false);

    var id = props.match.params.id;

    /**
     * Function to upload consent form
     * 
     * @param {*} dataUri 
     */
    const onConsentUpload = (selectedImages, baseIndex = 0) => {

        message.loading('Uploading Consent Document');

        // var formData = new FormData();
        Promise.all(
            selectedImages.map((ele, index) => {

                var formData = new FormData();

                // setting body
                formData.append('files', ele);
                formData.append('appointment_id', id);
                formData.append('index', baseIndex + (index + 1));

                return DocDetails.uploadConsentForm(formData).then((result) => {
                    if (result.message === 'success') {
                        // return;
                    }
                });
            })
        ).then(() => {
            // refreshing page after consent upload
            // setTimeout(() => {
            message.success('File Uploaded SuccessFully');

            callback();
            setOpenMessage(true);
            // }, 3000);
        });
    }


    return (<>

        {/* Camera Component with Advanced View */}
        {/* {
            openMessage ?
                <div>Please close the modal</div>
                :
                <AdvancedCamera
                    multiple={true}
                    onUpload={onConsentUpload}
                    mode={'rear'}
                />
        } */}
        {/* Camera Component with Advanced View Ends */}

    </>)
}




// export default function AppointmentConsent({ id, value, callback }) {

//     const [openPreview, setOpenPreview] = useState(false);

//     const [openIframe, setOpenIframe] = useState(false);

//     /**
//      * Function to upload consent form
//      * 
//      * @param {*} dataUri 
//      */
//     const onConsentUpload = (selectedImages, baseIndex = 0) => {

//         message.loading('Uploading Consent Document');

//         // var formData = new FormData();
//         Promise.all(
//             selectedImages.map((ele, index) => {

//                 var formData = new FormData();

//                 // setting body
//                 formData.append('files', ele);
//                 formData.append('appointment_id', id);
//                 formData.append('index', baseIndex + (index + 1));

//                 return DocDetails.uploadConsentForm(formData).then((result) => {
//                     if (result.message === 'success') {
//                         // return;
//                     }
//                 });
//             })
//         ).then(() => {
//             // refreshing page after consent upload
//             // setTimeout(() => {
//             message.success('File Uploaded SuccessFully');

//             callback();
//             // }, 3000);
//         });
//     }

//     return (
//         <div className="detail-element-card appointment-consent">
//             <Button
//                 size="middle"
//                 type="primary"
//                 onClick={() => {
//                     setOpenIframe(true)
//                 }}
//             >
//                 Open
//             </Button>
//             <Row span={12}>
//                 <span>Consent Document Status</span>
//             </Row>
//             <Row span={12}>
//                 <span>{
//                     value ?
//                         <div>
//                             <Tag color="green">Submitted</Tag>

//                             <Button
//                                 size="middle"
//                                 type="primary"
//                                 onClick={() => {
//                                     setOpenPreview(true)
//                                 }}
//                             >
//                                 Preview
//                             </Button>
//                             {/* <DocumentPreview id={id} /> */}

//                         </div>
//                         :
//                         <>
//                             <Tag color="orange">Pending</Tag>

//                             {/* Camera Component with Advanced View */}
//                             <Camera
//                                 multiple={true}
//                                 onUpload={onConsentUpload}
//                                 mode={'rear'}
//                             />
//                             {/* Camera Component with Advanced View Ends */}

//                         </>
//                 }
//                 </span>
//             </Row>
//             <Modal
//                 width={'80%'}
//                 destroyOnClose={true}
//                 title="Consent File"
//                 visible={openPreview}
//                 okText="Okay"
//                 onOk={() => {
//                     setOpenPreview(false);
//                 }}
//                 onCancel={() => {
//                     setOpenPreview(false);
//                 }}
//             >
//                 <DocumentPreview id={id} onDocumentUpload={onConsentUpload} />
//             </Modal>
//             <Modal
//                 width={'80%'}
//                 destroyOnClose={true}
//                 title="Nura desk"
//                 visible={openIframe}
//                 okText="Okay"
//                 onOk={() => {
//                     setOpenIframe(false);
//                 }}
//                 onCancel={() => {
//                     setOpenIframe(false);
//                 }}
//             >
//                 <iframe align="center" src={`http://localhost:3001/nura-desk/appointment/60271?token=${localStorage.access_token}`} width="100%" height="400px"></iframe>
//             </Modal>
//         </div>
//     )
// }