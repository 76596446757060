/**
 *
 */

import React, { useState, useEffect, useContext } from 'react';

import { Button, Select, Form, Row, message, Skeleton, Typography, Input, Alert, Tag, Modal, Progress, Checkbox } from 'antd';

import { ReloadOutlined } from '@ant-design/icons';

import './patient-details.scss';

import { Location, GlobalContext, PdfViewer } from 'soxo-bootstrap-core';

import { ModalityGroups, Bills, Items, ServiceResultMaster } from './../../../../models/';

const { Title } = Typography;

const { TextArea } = Input;
const { Option } = Select;

let timeoutinterval = null;

let myTimeout = null;

export default function RadiologistEntry({
    mode = 'RAD',
    match,
    history,
    dashboard,
    onSubmit,
    allowCloseEdit,
    enableClose,
    enableLabTrigger,
    mode_status,
    category
}) {
    const [initial, setInitial] = useState({});

    const [options, setOptions] = useState([]);

    const { user } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    const [btnloading, setBtnloading] = useState(false);

    const [tech, setTech] = useState({});

    const [rad, setRad] = useState({});

    const [digitalreport, setDigitalReport] = useState({});

    const [visible, setVisible] = useState(false);

    const [modalVisible, setModalVisible] = useState(false);

    const [count, setCount] = useState(0);

    const [billed, setBilled] = useState(false);

    const [patient, setPatient] = useState({
        opbill: {},
        opreg: {},
        modality: { item: {} },
        patientStudies: {
            study_details: []
        }
    }); //Patiants list array

    const [form] = Form.useForm();

    const { opno } = match.params;

    // State for result.Initially result is N.It will become Y when the checkbox is checked
    const [resultClosed, setResultClosed] = useState('N');

    // State for checkbox.Initially checkbox is not checked
    const [checkbox, setCheckbox] = useState(false);

    // State for submit button.Initially button is enabled
    const [disableSubmit, setDisableSubmit] = useState(false);

    // State for the fields.Initially fields are enabled
    const [disableFields, setFieldsDisable] = useState(false);

    const [triggerBill, setTriggerBill] = useState(false);

    var urlParams = Location.search();

    useEffect(() => {

        initializeComponent();

    }, [match.params]);

    /**
     * Load the data and initialize the component
     */
    function initializeComponent() {

        // Url Params
        var urlParams = Location.search();

        getModalityDetails(urlParams);
        // Dropdown options
        getDropdown(category, mode_status)

    }

    /**
     * Setting Dropdown options based on category and mode of serviceresultmaster
     */

    function getDropdown() {

        ServiceResultMaster.getServiceResultMaster(category, mode_status).then((result) => {
            // filtering based on active status 'Y'
            var dropdown = result.data.filter((record) => record.active === 'Y');
            // setting options to state

            setOptions(dropdown)
        });
    }

    async function getModalityDetails(urlParams) {
        setLoading(true);
        // Load the details
        await ModalityGroups.getDetails({ op_no: opno || urlParams.opno, accessionno: urlParams.accessionno, mode: `'TECH','RAD'` }).then(
            async (result) => {
                setPatient(result);

                setRad({});

                setTech({});

                setInitial({});

                var rad_entry = result.serviceResultTran.filter((record) => record.srvrs_mode === 'RAD');

                var tech_entry = result.serviceResultTran.filter((record) => record.srvrs_mode === 'TECH');

                if (rad_entry && rad_entry[0]) {
                    setRad(rad_entry[0]);

                    if (mode === 'RAD') {
                        setInitial({
                            remarks: rad_entry[0].srvrs_resultdedesc,
                            status: rad_entry[0].srvrs_resultptr,
                        });

                        // Checking result closed or not.
                        // If srvrs_resultclosed='Y' - Closed
                        // If srvrs_resultclosed='N' - Not Closed
                        if (rad_entry[0].srvrs_resultclosed == 'Y') {
                            setDisableSubmit(true);
                            setCheckbox(true);
                            setFieldsDisable(true);
                        }
                    }
                }

                if (tech_entry && tech_entry[0]) {
                    setTech(tech_entry[0]);

                    if (mode === 'TECH') {
                        setInitial({
                            remarks: tech_entry[0].srvrs_resultdedesc,
                            status: tech_entry[0].srvrs_resultptr,
                        });

                        // Checking result closed or not.
                        // If srvrs_resultclosed='Y' - Closed
                        // If srvrs_resultclosed='N' - Not Closed
                        if (tech_entry[0].srvrs_resultclosed == 'Y') {
                            setDisableSubmit(true);

                            setCheckbox(true);

                            if (allowCloseEdit) {
                                setFieldsDisable(false);
                            } else {
                                setFieldsDisable(true);
                            }
                        }
                    }
                }


                setLoading(false);
            }
        );
    }

    /**
     *Update Remarks and teh status
     * @param {*} values
     */
    const onFinish = () => {
        var values = form.getFieldValue();
        setBtnloading(true);
        values = {
            ...values,
            resultptr: values.status,
            resultdedesc: values.remarks,
            user: user.email,
            doctorptr: user.staff_id,
            mode: mode,
            op_no: opno || urlParams.opno,
            accessionno: urlParams.accessionno,
            slno: mode === 'TECH' ? 1 : 5,
            // visit_id: patient.opbill.opb_opid,
            // resultstatus: resultClosed,
        };

        //This will trigger bill for QTBG if lab test is positive
        ModalityGroups.update(values).then(async () => {

            message.success('Your update has been captured');

            setBtnloading(false);

            setModalVisible(false);

            if (dashboard) {
                onSubmit();
            } else {
                history.goBack();
            }

        });
    };

    //to make progress circle animated

    function modalcalling() {
        if (count == 0) {
            timeoutinterval = setInterval(() => setCount((oldCount) => oldCount + 1), 30);
        }
    }
    //for clearing time out and time interval
    function stop() {
        setCount(0);
        clearInterval(timeoutinterval);
        clearTimeout(myTimeout);
    }
    function refresh() {
        var urlParams = Location.search();

        getModalityDetails(urlParams);

        // getPatientDetails(urlParams);
    }

    let disabled = false;

    if (mode === 'RAD') {
        if (!tech.srvrs_resultptr) {
            disabled = true;
        }
    }

    if (mode === 'TECH') {
        if (rad.srvrs_resultptr) {
            disabled = true;
        }
    }

    // From the report , get the image and link

    var image_link = {};

    var report_link = {};

    if (patient.scrfiles) {
        var images = patient.scrfiles.filter((record) => record.sflink_type === 'IMG');

        if (images.length) {
            image_link = images[0];
        }

        var reports = patient.scrfiles.filter((record) => record.sflink_type === 'RPT');

        if (reports.length) {
            report_link = reports[0];
        }
    }

    /**
     * Replace the filename with BLR for any other branch
     * This is a custom implementation for Nura
     * 
     * @param {*} fileName 
     * @returns 
     * 
     * @author Ashique Mohammed
     */
    // function replaceFileName(fileName) {

    //     const wordsToReplace = ["DEL", "BOM"];

    //     // The path has to be changed to bangalore
    //     const replacementString = "BLR";

    //     // To replace if its either of the strings and case insensitive
    //     const regex = new RegExp(wordsToReplace.join("|"), "i");


    //     return fileName.replace(regex, replacementString);
    // }


    /**
     * Replace the filename with BLR for any other branch
     * This is a custom implementation for Nura
     *
     * @param {*} fileName
     * @returns
     *
     * @author Ashique Mohammed
     */
    function replaceFileName(fileName) {
        // const wordsToReplace = ['DEL', 'BOM'];

        // // The path has to be changed to bangalore
        // const replacementString = 'BLR';

        // // To replace if its either of the strings and case insensitive
        // const regex = new RegExp(wordsToReplace.join('|'), 'i');

        // Below is temporary and will be moved to backend
        return fileName.replaceAll('\\\\', '////')
            .replaceAll('\\', '//')
            .replaceAll('tbn-his-appfs', 'TBN-HIS-APPFS')
            .trim().toUpperCase();
        // return fileName.replace('\\', '/');

        // const wordsToReplace = ["DEL", "BOM"];

        // // The path has to be changed to bangalore
        // const replacementString = "BLR";

        // // To replace if its either of the strings and case insensitive
        // const regex = new RegExp(wordsToReplace.join("|"), "i");


        // return fileName.replace(regex, replacementString);

        // return fileName;
    }

    // /**
    //  * Load the filename of the path
    //  *
    //  * @param {*} report_link
    //  */
    // async function loadPDF(report_link) {
    //     var hide = message.loading('Loading digital report');

    //     const options = {
    //         method: 'GET',
    //         headers: {
    //             'Content-Type': 'application/pdf',
    //             Authorization: 'Bearer' + ' ' + localStorage.access_token,
    //             db_ptr: process.env.REACT_APP_DB_PTR,
    //         },
    //     };

    //     let pdfArrayBuffer;
    //     let pdfUrl;

    //     // # Note Currently Medicant requires the filename to be set as local path as the file is present on the local folder
    //     // For avaialability for nura desk , we are syncing the files to the bangalore server
    //     // So , we need to change the prefix to bangalore and use for whichever is not

    //     let fileName = report_link.sflink_filename;

    //     // Replace the filename
    //     let updatedFileName = replaceFileName(fileName);

    //     var apiURL = process.env.REACT_APP_ON_PREMISE_URL + `doc-details/load-file?location=${updatedFileName}&&type=pdf`


    /**
     * Load the filename of the path
     * 
     * @param {*} report_link 
     */
    async function loadPDF(report_link) {
        var hide = message.loading('Loading digital report');

        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/pdf',
                Authorization: 'Bearer' + " " + localStorage.access_token,
                db_ptr: process.env.REACT_APP_DB_PTR
            },
        }

        let pdfArrayBuffer;
        let pdfUrl;

        // # Note Currently Medicant requires the filename to be set as local path as the file is present on the local folder
        // For avaialability for nura desk , we are syncing the files to the bangalore server 
        // So , we need to change the prefix to bangalore and use for whichever is not 

        let fileName = report_link.sflink_filename;

        // Replace the filename 
        // Replace the filename
        let updatedFileName = replaceFileName(fileName);

        var apiURL = process.env.REACT_APP_ON_PREMISE_URL + `doc-details/load-file?location=${updatedFileName}&&type=pdf`

        await fetch(`${apiURL}`, options)
            .then(response => {
                pdfUrl = response.url;
                return response.arrayBuffer();
            })
            .then(arrayBuffer => {
                pdfArrayBuffer = arrayBuffer;
            })
            .catch(error => {
                console.error('Error fetching PDF:', error);
            });

        if (pdfArrayBuffer && pdfUrl) {
            var pdf = {
                path: pdfUrl,
                data: pdfArrayBuffer
            }

            setDigitalReport(pdf);
        }

        setVisible(true);


        hide();
    }

    // Used to update checkbox status
    const onCheckboxCheck = (e) => {
        if (e.target.checked) {
            setCheckbox(true);

            setResultClosed('Y');
        } else if (!e.target.checked && allowCloseEdit) {
            setCheckbox(false);

            setDisableSubmit(false);

            setFieldsDisable(false);

            setResultClosed('N');
        } else {
            setResultClosed('N');

            setCheckbox(false);
        }
    };

    /**
     * Trigger Bill check box
     * @param {} e
     */

    function onCheckBillTrigger(e) {
        if (e.target.checked) {
            setTriggerBill(true);
        }
    }

    return (
        <section className="patient-details">
            {/* Loading Indicator */}
            {loading ? (
                <div className="card-shadow card">
                    <Skeleton active />
                </div>
            ) : (
                <div className="patient-main">
                    <>
                        <div className="card-shadow card">
                            <div className="page-header">
                                <div>
                                    <Title level={3}>{mode === 'TECH' ? 'Technician Entry' : 'Radiologist Entry'}</Title>

                                    <p className="size-hint">{loading ? 'Loading data' : 'Refer below information and update feedback'}</p>
                                </div>

                                <Button
                                    onClick={() => {
                                        refresh();
                                    }}
                                    type="secondary"
                                    size={'small'}
                                >
                                    <ReloadOutlined />
                                </Button>
                            </div>

                            <div gutter={0} className="detail-wrapper">
                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>Name</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{patient.opbill.opb_name}</h3>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>Gender</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{patient.opbill.opb_gender}</h3>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>Age</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{patient.opbill.opb_age}</h3>
                                    </Row>
                                </div>

                                {/* <div className="detail-element">
                                 <Row span={12}>
                                     <span>Bill Time</span>
                                 </Row>
                                 <Row span={12}>
                                     <h3>{moment(patient.opbill.opb_tm).format('DD/MM/YYYY HH:mm')}</h3>
                                 </Row>
                             </div> */}

                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>Modality</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>
                                            <Tag color={'green'}>{patient.modality.item.itm_desc}</Tag>
                                        </h3>
                                    </Row>
                                </div>

                                {/* <div className="detail-element">
                                 <Row span={12}>
                                     <span>Modality Time</span>
                                 </Row>
                                 <Row span={12}>
                                     <h3>{moment(patient.modality.mpst_processstarttime).format('DD/MM/YYYY HH:mm')}</h3>
                                 </Row>
                             </div> */}

                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>Mobile</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{patient.opbill.opb_mobile}</h3>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>Technician Remarks</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{tech.srvrs_resultptr ? tech.srvrs_resultdedesc : <Tag color="orange">Pending</Tag>}</h3>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>Radiologist Remarks</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{rad.srvrs_resultptr ? rad.srvrs_resultdedesc : <Tag color="orange">Pending</Tag>}</h3>
                                    </Row>
                                </div>
                            </div>
                        </div>

                        <div className="card-shadow rightCard card">

                            {/* The Studies Viewer only in use for StopTB right now but will be extended for Nura */}
                            {patient.patientStudies && patient.patientStudies.study_details.length && <PatientStudies patient={patient.patientStudies} />}


                            <div className="report-links">
                                {/* <a
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={() => {
                                        loadXray(report_link);
                                    }}
                                    // href={report_link.sflink_filename}
                                    className={`report-link vehicle-card card ${report_link.sflink_filename ? 'available' : ''}`}
                                >
                                    <h1>X-RAY</h1>
                                    View X-Ray
                                </a> */}

                                {report_link.sflink_filename ? (
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        onClick={() => {
                                            loadPDF(report_link);
                                        }}
                                        // href={report_link.sflink_filename}
                                        className={`report-link vehicle-card card ${report_link.sflink_filename ? 'available' : ''}`}
                                    >
                                        <h1>AI</h1>
                                        View AI Report
                                    </a>
                                ) : null}

                                {image_link.sflink_filename ? (
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={image_link.sflink_filename}
                                        className={`report-link vehicle-card card`}
                                    >
                                        <h1>PACS</h1>
                                        View PACS Image
                                    </a>
                                ) : null}
                                {/* <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={'http://localhost:8030/patient/1'}
                                    className={`report-link vehicle-card card`}
                                >
                                    <h1>PACS</h1>
                                    View PACS Image
                                </a>

                                <div></div>
                            */}
                            </div>

                            {/* {mode === 'RAD' && tech.srvrs_resultptr ? (
                                <Alert
                                    message="Radiologist Entry"
                                    description={`You are about to ${rad.srvrs_resultptr ? 'update' : 'leave'} the radiologist entry for this case`}
                                    type="info"
                                />
                            ) : null} */}

                            {mode === 'RAD' && !tech.srvrs_resultptr ? (
                                <Alert
                                    message="Radiologist Entry"
                                    description={`Radiology remarks can be updated only after technician entry is completed`}
                                    type="warning"
                                />
                            ) : null}

                            {/* {mode === 'TECH' && !rad.srvrs_resultptr ? (
                                <Alert
                                    message="Technician Entry"
                                    description={`You are about to ${tech.srvrs_resultptr ? 'update' : 'leave'}  the technician entry for this case`}
                                    type="info"
                                />
                            ) : null} */}

                            {mode === 'TECH' && rad.srvrs_resultptr ? (
                                <Alert
                                    message="Technician Entry"
                                    description={`Technician remarks cannot be updated after radiologist has updated remarks`}
                                    type="warning"
                                />
                            ) : null}

                            {mode === 'ALL' ? (
                                <>
                                    <Alert message="Notes" description={`Please choose a tab for which you want to mark entry`} type="info" />
                                </>
                            ) : (
                                <div className="ptnt_dtls_form ">
                                    <Form
                                        // {...layout}
                                        form={form}
                                        name="new-record"
                                        onFinish={onFinish}
                                        layout="vertical"
                                        initialValues={initial}
                                        onValuesChange={(fields) => {
                                            var fieldname = Object.keys(fields);

                                            // if(AutomateLabBill){
                                            //     if (fieldname[0] === 'status') {
                                            //         var fieldValue = Object.values(fields);

                                            //         if (fieldValue[0] === '1' && mode === 'TECH') {
                                            //             message.warning(
                                            //                 'As the Results of XRAY is positive, the pateint must proceed for Laboratory Test'
                                            //             );
                                            //         }
                                            //     }
                                            // }
                                            if (fieldname[0] === 'status') {
                                                var fieldValue = Object.values(fields);

                                                if (triggerBill && mode === 'TECH') {
                                                    message.warning('The pateint must proceed for Laboratory Test');
                                                }

                                            }

                                        }}
                                    >
                                        {/* <Title level={5}>Remarks</Title> */}

                                        <Form.Item
                                            name={'status'}
                                            label="Status"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter status',
                                                },
                                            ]}
                                        >
                                            <Select
                                                // defaultValue="positive"
                                                style={{ width: 220 }}
                                                disabled={disableFields}
                                            >
                                                {options.map((option) => (
                                                    <Option value={option.code}>{option.description}</Option>
                                                ))}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            name={'remarks'}
                                            label="Notes"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please enter notes',
                                                },
                                                {
                                                    max: 255,
                                                    message: 'Length cannot exceed 255 characters!',
                                                },
                                            ]}
                                        >
                                            <TextArea rows={4} disabled={disableFields} />
                                        </Form.Item>

                                        <Row gutter={2}>
                                            <Button
                                                loading={btnloading}
                                                disabled={disabled || billed || disableSubmit}
                                                type="primary"
                                                htmlType="submit"
                                                className="submit-button"
                                            >
                                                Save
                                            </Button>
                                            {!enableClose ?

                                                <Form.Item name={'resultstatus'}>
                                                    <Checkbox onChange={onCheckboxCheck} checked={checkbox} disabled={disableFields}>
                                                        Result Close
                                                    </Checkbox>
                                                </Form.Item> : null}
                                            {enableLabTrigger ? (
                                                <Form.Item name={'triggerbill'}>
                                                    <Checkbox onChange={onCheckBillTrigger} disabled={disableFields}>
                                                        Trigger Bill
                                                    </Checkbox>
                                                </Form.Item>
                                            ) : null}
                                        </Row>
                                    </Form>

                                    {/* Modal for Billing Lab if xray is positive */}

                                    <Modal
                                        width={'45%'}
                                        destroyOnClose={true}
                                        title="Alert"
                                        visible={modalVisible}
                                        okText="Okay"
                                        cancelButtonProps={{ style: { display: 'none' } }}
                                        onOk={() => {
                                            onFinish();

                                            setModalVisible(false);
                                            // Stop the counter
                                            stop();
                                            setCount(0);
                                        }}
                                    >
                                        <img src={require(`../../../../assets/icons/external-danger-security-flaticons-flat-flat-icons.png`)} />{' '}
                                        <Progress
                                            type="circle"
                                            strokeColor={{
                                                '0%': '#108ee9',
                                                '100%': '#87d068',
                                            }}
                                            percent={count}
                                        />{' '}
                                        <p>As the Results of XRAY is positive, we are proceeding for Laboratory Test Billing. Please Wait </p>
                                        <div className="anime">
                                            <div className="images">
                                                {/* <img className="card-icon" src="https://img.icons8.com/color/48/undefined/ct-scanner.png" /> */}
                                                <img className="specimen-icon" src={require(`../../../../assets/icons/radiology1.png`)} />
                                                <div className="arrow">
                                                    <div class="arrow-container">
                                                        <div class="arrow-down"></div>
                                                    </div>
                                                </div>

                                                <img
                                                    className="card-icon"
                                                    src={require(`../../../../assets/icons/external-lab-back-to-school-flat-02-chattapat-.png`)}
                                                />
                                            </div>
                                        </div>
                                    </Modal>

                                    {/* Modal for Billing Lab if xray is positive */}

                                </div>
                            )}
                        </div>
                    </>
                </div>
            )}

            {/* Modal for Viewing AI Report */}
            <Modal
                width={'50%'}
                destroyOnClose={true}
                title="Digital Report"
                visible={visible}
                okText="Okay"
                cancelButtonProps={{ style: { display: 'none' } }}
                onOk={() => {
                    setVisible(false);
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <PdfViewer url={digitalreport} />
                {/* <ImageWrapper type="array" content={digitalreport.data} /> */}
            </Modal>
            {/* Modal for Viewing AI Report */}
        </section>
    );
}


/**
 * Component used to render the studies as received 
 * from the dicom API 
 * 
 * The received studies are iterated to show a link that will open the dicom viewer
 * 
 * 
 */
function PatientStudies({ patient }) {

    return (<div className='patient-studies report-links'>

        {
            patient.study_details.map((study) => {

                return (<>

                    <LinkFrame url={`http://localhost:8030/patient/${study.id}/view`} />

                </>)

            })
        }

    </div>)

}

/**
 * Component would accept the url and onClick will open the url in an iframe in a modal 
 * 
 * The behaviour on how to display the content would be configurable
 */
function LinkFrame({ url }) {

    const [visible, setVisible] = useState(false);

    const openViewer = () => {

        setVisible(true);
    }


    return (<>

        <a
            target="_blank"
            rel="noreferrer"
            onClick={() => {
                openViewer();
            }}
            // href={report_link.sflink_filename}
            className={`report-link vehicle-card card`}
        >
            <h1>X-RAY</h1>
            View Image
        </a>


        <Modal
            className='image-viewer-modal'
            width={'100%'}
            destroyOnClose={true}
            title="Alert"
            header={null}
            footer={null}
            visible={visible}
            okText="Okay"
            onCancel={() => {

                setVisible(false);
            }}
            cancelButtonProps={{ style: { display: 'none' } }}
            onOk={() => {

                setVisible(false);

            }}
        >
            <iframe src={url} />
        </Modal>


    </>)

}