/**
 * Appointments Model
 *
 *
 * Appointments is a record created on
 *
 */
import React, { useState, useEffect, useContext } from 'react';

import Model from './models';

import { Link } from 'react-router-dom';

import { Tag, Card, Typography, Button } from 'antd';

import { ApiUtils, GetData, BaseAPI } from 'soxo-bootstrap-core';

import moment from 'moment';

const { Text } = Typography;

class Doctor extends BaseAPI {

    constructor() {
        super();


        this.fields = [
            {
                field: 'name',
                caption: 'Name',
            },
            {
                field: 'amount',
                caption: 'Amount',
            },
        ];

        // this.columns = ;
    }

    get id() {
        return 'comt_code';
    }

    get getEndpoint() {
        return 'doctor';
    }

    get path() {
        return `doctor`;
    }

    get getName() {
        return this.modelName;
    }

    get columns() {
        return [
            //  {
            //      caption: 'Staff',
            //      field: 'staff.name',
            //      key: 'staff',
            //  },
            //  {
            //      caption: 'Weight',
            //      field: 'weight',
            //      key: 'weight',
            //  },
            //  {
            //      caption: 'Date',
            //      field: 'created_at',
            //      key: 'created_at',
            //  },
        ];
    }

    /**
    
     /**
      *
      * @returns
      */

    getDoctorDeatils = (id) => {
        return ApiUtils.get({
            url: `doctor/${id}`,
        });
    };
    /**
     *  get Reason
     * @param {} id
     * @returns
     */
    getReasonDetails = (id) => {
        return ApiUtils.get({
            url: `Generallist?genl_code=${id}`,
        });
    };

    /**
     * Get the data from the table
     */
    // get = (queries, model) => {

    //     return ApiUtils.get().then(()=>{

    //     })

    //     return FirebaseUtils.getRecords(queries, model).then((result) => {

    //         return Promise.all(result.collections.map(this.loadStaff)).then((result) => {

    //             return { collections: result };

    //         })
    //     })
    // }

    // Attach the related staff with the object

    /**
     * Card Component for Staff
     *
     * @param {*} record
     * @returns
     */
    // Card = (record) => {
    //     return (
    //         <Link className="booking-card card card-shadow" to={`/collections/${record['cold_id']}`}>
    //             <div className="card">
    //                 <h2 className="">{record.cold_user ? record.cold_user : null}</h2>

    //                 <h3 className="title">{record.cold_amt}</h3>

    //                 <p className="address">Collected on {record.cold_tm}</p>

    //                 <Tag color="magenta">{record['cold_mode']}</Tag>
    //             </div>
    //         </Link>
    //     );
    // };

    //  AddComponent = () => <CollectionAdd model={this} />
}

export default Doctor;
