import React, { useState, useEffect } from 'react';

import { BarGraph, RangePicker, TableWrapper } from 'soxo-bootstrap-core';

import { Form, Skeleton, Typography, Empty, Button, Statistic, Collapse, Modal } from 'antd';

import { ReloadOutlined } from '@ant-design/icons';

import moment from 'moment-timezone';

import { Location, PieChart, GoogleMap } from 'soxo-bootstrap-core';

import { Bills } from '../../models';

import './screening-dashboard.scss';

const { Panel } = Collapse;

const { Title } = Typography;

var columns = [
    {
        title: '#',
        dataIndex: 'index',
        render: (value, item, index) => {
            if (index === 0) {
                return (
                    <div>
                        <b>{index + 1}</b>
                    </div>
                );
            } else {
                return index + 1;
            }
        },
    },

    {
        title: 'Title',
        key: 'title',
        render: (ele, item, index) => {
            const { counts, data } = ele;

            return counts.title;
        },
    },

    {
        title: 'Total Screening',
        key: 'total_bills',
        render: (ele, item, index) => {
            const { counts, data } = ele;

            return counts.total_cases;
        },
    },

    {
        title: 'XRAY +ve',
        key: 'total_positive_cases',
        render: (ele, item, index) => {
            const { counts, data } = ele;

            return counts.total_positive_cases;
        },
    },

    {
        title: 'XRAY +ve %',
        key: 'total_positive_cases',
        render: (ele, item, index) => {
            let xray_percentage = '-';

            const { counts, data } = ele;

            if (counts.total_positive_cases !== 0) {
                // confirmed_positive = parseFloat((branch.total_lab_positive_cases / branch.total_positive_cases) * 100).toFixed(2);

                xray_percentage = parseFloat((counts.total_positive_cases / counts.total_cases) * 100).toFixed(2);
            }

            return xray_percentage;
        },
    },

    {
        title: 'LAB +ve',
        key: 'total_positive_cases',
        render: (ele, item, index) => {
            const { counts, data } = ele;

            return counts.total_lab_positive_cases;
        },
    },

    {
        title: 'Lab +ve %',
        key: 'total_positive_cases',
        render: (ele, item, index) => {
            let lab_percentage = '-';

            const { counts, data } = ele;

            if (counts.total_lab_positive_cases !== 0) {
                lab_percentage = parseFloat((counts.total_lab_positive_cases / counts.total_cases) * 100).toFixed(2);
            }

            return lab_percentage;
        },
    },

    // {
    //     title: 'Confirmed +ve',
    //     key: 'other_result_cases',
    //     render: (ele, item, index) => {
    //         const { counts, data } = ele;

    //         let confirmed_positive = parseFloat((counts.total_lab_positive_cases / counts.total_cases) * 100).toFixed(2);

    //         return confirmed_positive;
    //     },
    // },

    // {
    //     title: 'False +ve',
    //     key: 'pending_cases',
    //     render: (ele, item, index) => {
    //         const { counts, data } = ele;

    //         let false_positive = '-';

    //         if (counts.total_positive_cases !== 0) {
    //             false_positive =
    //                 parseFloat((counts.total_positive_cases - counts.total_lab_positive_cases) / counts.total_positive_cases).toFixed(2) * 100 +
    //                 ' % ';
    //         }

    //         return false_positive;
    //     },
    // },

    {
        title: '#',
        // key: 'pending_cases',
        render: (records) => {
            return (
                <>
                    <CandidatesModal records={records.data} />
                </>
            );
        },
    },
];

let candidatesColumns = [
    {
        title: '#',
        dataIndex: 'index',
        render: (value, item, index) => {
            return index + 1;
        },
    },
    {
        title: 'Name',
        key: 'name',
        render: (record, item, index) => {
            const { op_bill = [] } = record;

            let bill = {};

            if (op_bill.length) {
                bill = op_bill[0];
            }

            return <>{bill.opb_name}</>;
        },
    },

    {
        title: 'Age',
        key: 'opb_age',
        render: (record, item, index) => {
            const { op_bill = [] } = record;

            let bill = {};

            if (op_bill.length) {
                bill = op_bill[0];
            }

            return <>{bill.opb_age}</>;
        },
    },

    {
        title: 'Gender',
        key: 'gender',
        render: (record) => {
            const { op_bill = [] } = record;

            let bill = {};

            if (op_bill.length) {
                bill = op_bill[0];
            }
            return <>{bill.opb_gender}</>;
        },
    },

    {
        title: 'X-Ray Positive',
        key: 'xray_positive',
        render: (record) => {
            if (record.xray_positive === true) {
                return <>Positive</>;
            } else {
                return <>-</>;
            }
        },
    },
    {
        title: 'Lab Positive',
        key: 'lab_positive',
        render: (record) => {
            if (record.lab_positive === true) {
                return <>Positive</>;
            } else {
                return <>-</>;
            }
        },
    },
];

/**
 * The Component Shows a link and opens a
 * modal to display all the user information
 */
function CandidatesModal({ records = [] }) {
    const [visible, setVisible] = useState(false);

    /**
     *
     */
    function openModal() {
        setVisible(true);
    }

    return (
        <>
            <a onClick={openModal}>View</a>

            <Modal
                onOk={() => {
                    setVisible(false);
                }}
                className="candidates-modal"
                title="Patients"
                visible={visible}
                onCancel={() => {
                    setVisible(false);
                }}
                footer={[
                    <Button onClick={() => setVisible(false)} type="primary">
                        Close
                    </Button>,
                ]}
            >
                <div className="">
                    <TableWrapper columns={candidatesColumns} records={records}></TableWrapper>
                </div>
            </Modal>
        </>
    );
}

export default function ScreeningDashboard({ mapCenter }) {
    // Loading
    const [loading, setLoading] = useState(false);

    var starttime = moment.tz(process.env.REACT_APP_TIMEZONE).startOf('month');

    var endtime = moment.tz(process.env.REACT_APP_TIMEZONE).endOf('day'); //Setting starttime and endtime within a week from today

    const [data, setData] = useState([]);

    var param = Location.search();

    //set center of map
    const [center, setCenter] = useState(mapCenter);

    if (param.start_time) {
        updateRange();
    }

    function updateRange() {
        starttime = moment.tz(param.start_time, process.env.REACT_APP_TIMEZONE).startOf('day');
        endtime = moment.tz(param.end_time, process.env.REACT_APP_TIMEZONE).startOf('day');

        return starttime, endtime;
    }

    /**
     *   Setting Range by default
     */
    const [range, setRange] = useState([starttime, endtime]);

    useEffect(() => {
        setLoading(true);
        getScreeningTrend(range);
    }, []);

    /**
     * update range time
     * @param {} range
     */
    function updateTime(range) {
        setLoading(true);

        setRange(range);
        // setPage(1);

        Location.search({
            start_time: moment(range[0]).format('MM/DD/YYYY'),
            end_time: moment(range[1]).format('MM/DD/YYYY'),
        });

        getScreeningTrend(range);
    }

    /**
     *
     * @param {*} range
     */
    function getScreeningTrend(range) {
        Bills.getScreeningTrend(range).then((result) => {
            setData(result);
            setLoading(false);
        });
    }

    /**
     * Refresh
     */
    function refresh() {
        setLoading(true);
        getScreeningTrend(range);
    }

    return (
        <div className="screening-dashboard card">
            <div className="page-header">
                <div>
                    <Title level={4}>Screening Dashboard</Title>
                </div>

                <Button onClick={refresh} type="secondary" size={'small'}>
                    <ReloadOutlined />
                </Button>
            </div>

            <div>
                <Form>
                    <Form.Item>
                        <RangePicker
                            allowClear={false}
                            inputReadOnly
                            format={'DD/MM/YYYY'}
                            value={range}
                            onChange={(time) => {
                                updateTime(time, range);
                            }}
                            ranges={{
                                Today: [moment(), moment()],

                                Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],

                                'This Week': [moment().startOf('week'), moment().endOf('week')],

                                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],

                                'This Month': [moment().startOf('month'), moment().endOf('month')],

                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            }}
                        />
                    </Form.Item>

                    {/* <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item> */}
                </Form>
            </div>

            {loading ? (
                <Skeleton />
            ) : (
                <div className="custom-reports">
                    {/* Dashboard Content */}

                    <div className="card dashboard-element">
                        <Title level={4}>Summary</Title>

                        <p>Please find below summary of screening</p>

                        {/* Highlight Summary */}
                        <div className="branch-summary">
                            {/* Summary */}
                            <BranchWiseSummary range={range} />
                            {/* Summary Ends */}

                            {/* Map View */}
                            <div className="map-view">
                                {/* <Title level={4}>Region wise Plot</Title> */}

                                {data.length && data[0].data.length ? (
                                    <GoogleMap
                                        CandidatesModal={CandidatesModal}
                                        data={data}
                                        center={center}
                                        height={'500px'}
                                        width={'560px'}
                                        onMapsLoaded={(map, maps) => {
                                            console.log(map);
                                        }}
                                        draggable={true}
                                    />
                                ) : null}
                            </div>
                            {/* Map View Ends */}
                        </div>

                        {/* Highlight Summary Ends */}
                    </div>

                    {data.length && data[0].data.length ? (
                        <>
                            <>
                                <div className="charts">
                                    <div className="card dashboard-element">
                                        <Title level={4}>Campaign Wise Positive Analysis</Title>

                                        <PieChart data={data} />
                                    </div>

                                    <div className="card dashboard-element">
                                        <Title level={4}>X-Ray Analysis</Title>

                                        <BarGraph data={data} index={'title'} />
                                    </div>
                                    <br />
                                </div>
                            </>
                        </>
                    ) : (
                        <>
                            <Empty />
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

/**
 *
 */
function BranchWiseSummary({ range }) {
    const [loading, setLoading] = useState(false);

    const [branches, setBranches] = useState([]);

    useEffect(() => {
        getScreeningTrendBranchwise(range);
    }, []);

    /**
     *
     */
    function getScreeningTrendBranchwise(range) {
        setLoading(true);

        Bills.getScreeningTrendBranchWise(range).then((result) => {
            setBranches(result);
            // setData(result);
            setLoading(false);
        });
    }

    return (
        <>
            {loading ? (
                <Skeleton />
            ) : (
                <Collapse className="branch-wise-summary">
                    {branches
                        .filter((branch) => {
                            const { counts = {}, data = [] } = branch;

                            return counts.title != 'Total Counts';
                        })
                        .map((branch, index) => {
                            const { counts = {}, data = [] } = branch;

                            return (
                                <Panel showArrow={false} header={BranchStatistic(counts)} key={index}>
                                    <TableWrapper columns={columns} records={data} />
                                </Panel>
                            );
                        })}
                </Collapse>
            )}
        </>
    );
}

/**
 * Show the summary of a branch
 */
function BranchStatistic(branch) {
    let xray_percentage = 0;

    if (branch.total_positive_cases !== 0) {
        // xray_percentage = parseFloat((branch.total_lab_positive_cases / branch.total_positive_cases) * 100).toFixed(2);

        xray_percentage = parseFloat(branch.total_positive_cases / branch.total_cases).toFixed(2) * 100;
    }

    let lab_percentage = 0;

    if (branch.total_lab_positive_cases !== 0) {
        lab_percentage = parseFloat(branch.total_lab_positive_cases / branch.total_cases).toFixed(2) * 100;
    }

    return (
        <div className="branch-title">
            {/* Branch  Name */}
            <h1 className="branch-name">{branch.title}</h1>
            {/* Branch Name Ends */}

            {/* Total Cases */}
            <div className="statistic-summary">
                <Statistic title="Total Screening" value={branch.total_cases} />

                <Statistic title="XRAY +ve" value={branch.total_positive_cases} />

                <Statistic title="XRAY +ve %" value={parseFloat((xray_percentage).toFixed(2)) + ' %'} />

                <Statistic title="LAB +ve" value={branch.total_lab_positive_cases} />

                <Statistic title="Lab +ve %" value={parseFloat((lab_percentage).toFixed(2)) + ' %'} />

                {/* <Statistic title="False +ve" value={false_positive} /> */}
            </div>
        </div>
    );
}
