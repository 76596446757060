/**
 * Template Master
 *
 *
 *
 *
 */

import React from 'react';

import Model from './models';

import { ApiUtils, BaseAPI } from 'soxo-bootstrap-core';

class TemplateMaster extends BaseAPI {
    // constructor() {
    //     super();

    //     // this.fields = [
    //     //     {
    //     //         field: 'opb_name',
    //     //         caption: 'Name',
    //     //     },
    //     //     {
    //     //         field: 'opb_opno',
    //     //         caption: 'num',
    //     //     },
    //     // ];
    // }

    get id() {
        return 'template_id';
    }

    get getEndpoint() {
        return 'templatemas';
    }

    get path() {
        return `templatemas`;
    }

    get getName() {
        return this.modelName;
    }

    get modelName() {
        return `templatemas`;
    }
}

export default TemplateMaster;
