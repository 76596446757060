/**
 * 
 * File loader is build specific to Nura case where we need to load the file 
 * 
 * Initially we had seperate application for loading files , 
 * But that did not work well so we have to move to on premise 
 * 
 * @author Ashique Mohammed
 * @author Marvan
 * 
 */
import React, { useEffect, useState, useContext } from 'react';

import { message, Button } from 'antd';

import { DocDetails } from './../../models';

import moment from 'moment-timezone';


let reportTypes = {
    'RAW': 'json',
    'RPT': 'pdf',
    'IMAGE': 'png'
}



/**
 * Component to load the report from file server
 *
 * @param {*} param0
 */
export default function FileLoader({ url, type }) {

    // Variable to save the file url 
    const [digitalreport, setDigitalReport] = useState({});

    let curretDate = moment().format("DD-MM-YYYY") + Math.round(Math.random() * Math.pow(9, 10))

    useEffect(() => {
        loadPDF(url);
    }, [url]);

    /**
     * Load the file from the particular path
     * 
     * @param {*} report_link 
     * @returns 
     */
    async function loadPDF(report_link) {

        console.log('report_link', report_link);


        message.loading('Loading digital report');

        const uncompressedArray = await DocDetails.loadFile(report_link, reportTypes[type]);

        var fileImage = new Blob([uncompressedArray], { type: `application/${reportTypes[type]}` });

        var fileURL = URL.createObjectURL(fileImage);

        // window.open(fileURL);

        setDigitalReport(fileURL);

    }

    function downloadJSON() {

        var name = url.fileName ? url.fileName : curretDate;
        var link = document.createElement('a');
        document.body.appendChild(link);
        link.href = digitalreport;
        link.download = name;
        link.click();
        window.URL.revokeObjectURL(digitalreport);
        link.remove();

    }

    /**
    *
    */
    function reportPreview(selected) {
        console.log("selected", selected)
        switch (selected) {
            case 'pdf':
                return <iframe align="center" src={digitalreport} width="100%" height="400px"></iframe>

                break;

            case 'png':
                return <img src={digitalreport} style={{ width: '100%', height: '400px' }} />

                break;

            case 'json':
                return (
                    <a target="_blank" rel="noreferrer" className={`report-link vehicle-card card`}>
                        <h1>JSON</h1>
                        <Button onClick={downloadJSON} type="secondary" size={'small'}>
                            Download
                        </Button>
                    </a>
                );

                break;
        }
    }

    return (<>

        {/* {digitalreport ? <img src={digitalreport} style={{ width: '100%', height: '400px' }} /> : null} */}
        {digitalreport ? <div>{reportPreview(reportTypes[type])}</div> : null}

    </>)
}



