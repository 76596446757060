import React, { useState, useEffect, useContext, useRef } from 'react';

import { Card, Button, Select, Form, Tag, Row, message, Alert, Skeleton, Typography, Input, Modal, Col, Avatar, InputNumber } from 'antd';

import { Editor } from '@tinymce/tinymce-react';

import moment from 'moment-timezone';

import { ReloadOutlined, OrderedListOutlined, PicCenterOutlined, CaretLeftOutlined, CaretRightOutlined, DeleteOutlined } from '@ant-design/icons';

import './patient-details.scss';

import { Location, Switch, GlobalContext } from 'soxo-bootstrap-core';

import { ModalityGroups, MedicalReports } from './../../../../models/';

const { Title, Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

let initialValues = {};

export default function PatientDetailsResearch({ match, mode = 'RNDR', history ,itemPtr ,sevriceResultsMode,patientMedicalReportModule}) {
    const [options, setOptions] = useState([]);

    const [initial, setInitial] = useState({});

    const [btnloading, setBtnloading] = useState(false);

    var urlParams = Location.search();

    const [templateLoading, setTemplateLoading] = useState(true);

    const [content, setContent] = useState('');

    const { user, isMobile } = useContext(GlobalContext);

    const [templates, setTemplates] = useState('');

    const [loading, setLoading] = useState(true);

    const [formloading, setFormloading] = useState(false);

    // const [report, setReport] = useState({
    //     opbill: {},
    //     opreg: {},
    //     modality: { item: {} },
    //     scrfiles: [],
    // });

    // const [patient, setPatient] = useState({ templatemas: { tmpl_template: 0 } }); //Patiants list array

    const [patient, setPatient] = useState({
        opbill: {},
        opreg: {},
        modality: { item: {} },
        scrfiles: [],
    }); //Patiants list array

    const [form] = Form.useForm();

    const [initialContent, setInitialContent] = useState('');

    const { opno } = match.params;

    let template;

    useEffect(() => {
        // console.log(urlParams);

        if (user) {
            // Patiant list get function
            getPatientDetails(urlParams);
        }
    }, [user]);

    /**
     * Get the templates
     *
     * @param {*} item
     * @param {*} patient
     */
    function getTemplateMaster(item, patient) {
        // Get the templates
        ModalityGroups.getTemplates(item).then((result) => {
            var arr = [];

            var b = [];

            result.forEach((record) => {
                if (record.templatemas) {
                    arr = [].concat(arr, record.templatemas);
                }
            });

            var b = arr.map((item, index) => {
                return {
                    title: `${index + 1} ${item.tmpl_desc}`,
                    description: '',
                    content: `${item.tmpl_template
                        .replace('{{opname}}', patient.opbill.opb_name)
                        .replace('{{opno}}', patient.opreg.op_no)
                        .replace('{{opage}}', patient.opbill.opb_age)
                        .replace('{{opgender}}', patient.opbill.opb_gender)
                        .replace('{{rptdate}}', moment(patient.opreg.op_time).format('DD-MM-YYYY hh:mm a'))
                        .replace('{{refdoctorname}}', patient.opreg.do_name)
                        .replace('{{refdoquali}}', patient.opreg.do_qualfication)}`,
                };
            });
            setTemplates([...b]);

            setTemplateLoading(false);
        });
    }

    /**
     * check patient Report already created in RNDR mode
     * then ger the Report
     */
    function getCurrentPatientReport(item, initialValues) {
        ModalityGroups.getPatientReport(item)
            .then((result) => {
                if (result.length) {
                    var report = result[0];

                    setInitialContent(report);

                    initialValues = {
                        ...initialValues,
                        time_taken: report.pmr_timetaken,
                        report_status: report.pmr_rptstatus === 'S' ? true : false,
                    };

                    setInitial({ ...initialValues });
                }

                setFormloading(false);
            })
            .catch(() => {
                setFormloading(false);
            });
    }

    const handleInit = (evt, editor) => {
        setContent(editor.getContent());
    };

    const handleUpdate = (value, editor, ...props) => {

        const editorContent = editor.getContent();
        setContent(editorContent);
    };

    const handleBeforeAddUndo = (evt, editor) => {
        const length = editor.getContent();
        // note that this is the opposite test as in handleUpdate
        // because we are determining when to deny adding an undo level
        // console.log(length);
    };

    /**
     *
     * Patient Data
     *
     * @param {*} urlParms
     */
    async function getPatientDetails(urlParms) {
        setLoading(true);

        setFormloading(true);

        ModalityGroups.getDetails({ op_no: opno || urlParams.opno, accessionno: urlParams.accessionno, mode: mode}).then((result) => {
            let values = result;

            setPatient(values);

            let item = {
                accessionno: urlParams.accessionno,
                // Patient medical report module 
                mode: patientMedicalReportModule,
                doctorptr: user.staff_id,
            };
            let initialValues = {};

            //function call get templates
            getTemplateMaster(values.modality.mpst_itemptr, values);

            setInitial({});

            var research_entry = result.serviceResultTran.filter((record) => record.srvrs_mode === sevriceResultsMode);

            if (research_entry && research_entry[0]) {
                // if (mode === sevriceResultsMode) {
                    initialValues = {
                        ...initialValues,
                        remarks: research_entry[0].srvrs_resultdedesc,
                        status: research_entry[0].srvrs_resultptr,
                    };
                    setInitial(initialValues);
                // }
            }

            // function call get patient report
            getCurrentPatientReport(item, initialValues);

            // getCOPDReport(urlParams.id);

            setLoading(false);

            getDropdownOptions(values.modality.mpst_itemptr);
        });
    }

    /**
     *
     * @param {*} itemptr
     */
    function getDropdownOptions(itemptr) {
        ModalityGroups.getOptions(itemptr).then((result) => {
            const array = result.sort((a, b) => {
                return a.srvrsm_slno < b.srvrsm_slno ? 1 : -1;
            });

            setOptions(array);
        });
    }

    /**
     *
     * @param {*} values
     */
    const onSubmit = (values) => {
        // setLoading(true);

        if (content) {
            setBtnloading(true);
            values = {
                ...values,
                resultptr: values.status,
                resultdedesc: values.remarks,
                user: user.email,
                doctorptr: user.staffptr,
                // sevriceResultsMode attribute service result tran mode
                mode: mode,
                op_no: opno || urlParams.opno,
                accessionno: urlParams.accessionno,
                slno: 10,

            };
            ModalityGroups.update(values).then((result) => {
                // console.log(result);

                /**
                 *
                 * API - Create Patient Report
                 *
                 */

                if (initialContent.pmr_id) {
                    var formBody = {
                        pmr_report: content,
                        pmr_timetaken: values.time_taken,
                        pmr_rptstatus: values.report_status ? 'S' : 'D',
                    };

                    MedicalReports.update(initialContent.pmr_id, formBody).then((result) => {
                        // console.log(result);

                        message.success('Patient report has been created.');

                        setLoading(false);

                        setBtnloading(false);

                        // Location.back();

                        history.goBack();
                    });
                } else {
                    MedicalReports.create(patient, values, content, user,patientMedicalReportModule).then((result) => {
                        // console.log(result);

                        message.success('Patient report has been created.');

                        setLoading(false);

                        setBtnloading(false);

                        // Location.back();

                        history.goBack();
                    });
                }
            });
        } else {
            Modal.error({
                title: 'Report Empty',
                content: 'You have not entered any content for the patient report. ',
            });
        }
    };

    function refresh() {
        var urlParams = Location.search();

        // console.log(urlParams);

        getPatientDetails(urlParams);
    }

    /**
     * Function handles trigger of onupload
     *
     * @param {*} field
     * @param {*} attachments
     */
    function onUpdate(field, value) {
        form.setFieldsValue({ [field]: value });
    }

    // From the report , get the image and link

    // var image_link = {};

    // if (report.scrfiles) {
    //     var reports = report.scrfiles.filter((record) => record.sflink_type === 'IMG');

    //     if (reports.length) {
    //         image_link = reports[0];
    //     }
    // }

    // We have to disable the form if the report status is signed off .
    // Only for admin we have to enable it

    let disabled = false;


    if (initial.report_status && user.UserGrp !== 'adm') {
        disabled = true;
    }

    return (
        <section className="patient-details">
            {/* Loading Indicator */}
            {loading ? (
                <Card className="card-shadow card">
                    <Skeleton active />
                </Card>
            ) : (
                <>
                    <div className="page-hero">
                        <div className="card-shadow card">
                            <div className="page-header">
                                <div>
                                    <Title level={3}>Guest Details</Title>

                                    <p className="size-hint">{loading ? 'Loading data' : 'Refer below information and update feedback'}</p>
                                </div>

                                <Button onClick={refresh} type="secondary" size={'small'}>
                                    <ReloadOutlined />
                                </Button>
                            </div>

                            <div gutter={0} className="detail-wrapper">
                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>Name</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{patient.opbill.opb_name}</h3>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>Gender</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{patient.opbill.opb_gender}</h3>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>Age</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{patient.opbill.opb_age}</h3>
                                    </Row>
                                </div>

                                {/* <div className="detail-element">
                                <Row span={12}>
                                    <span>Bill Time</span>
                                </Row>
                                <Row span={12}>
                                    <h3>{moment(patient.opbill.opb_tm).format('DD/MM/YYYY HH:mm')}</h3>
                                </Row>
                            </div> */}

                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>Modality</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>
                                            <Tag color={'green'}>{patient.modality.item && patient.modality.item.itm_desc}</Tag>
                                        </h3>
                                    </Row>
                                </div>

                                {/* <div className="detail-element">
                                    <Row span={12}>
                                        <span>Modality Time</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{moment(patient.modality.mpst_processstarttime).format('DD/MM/YYYY HH:mm')}</h3>
                                    </Row>
                                </div> */}

                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>Mobile</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{patient.opbill.opb_mobile}</h3>
                                    </Row>
                                </div>
                            </div>
                        </div>

                        <Alert
                            // message="Note"
                            description="After review of the attached report, Use the template to submit report after your observation."
                            type="info"
                            // showIcon
                        />
                    </div>

                    {/* <div className="report-links">
                        {image_link.sflink_filename ? (
                            <a target="_blank" rel="noreferrer" href={image_link.sflink_filename} className={`report-link vehicle-card card`}>
                                <h1>PACS</h1>
                                View PACS Image
                            </a>
                        ) : null}

                        <div></div>
                    </div> */}

                    {formloading ? (
                        <Skeleton />
                    ) : (
                        <>
                            <div className="ptnt_dtls_form ">
                                <Form
                                    // {...layout}
                                    form={form}
                                    name="new-record"
                                    onFinish={onSubmit}
                                    layout="vertical"
                                    initialValues={initial}
                                >
                                    {/* <Title level={5}>Remarks</Title> */}

                                    <Form.Item
                                        name={'status'}
                                        label="Status"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter status',
                                            },
                                        ]}
                                    >
                                        <Select
                                        // defaultValue="positive"
                                        // style={{ width: 120 }}
                                        >
                                            {options.map((option) => (
                                                <Option value={option.srvrsm_code}>{option.srvrsm_desc}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item
                                        name={'remarks'}
                                        label="Notes"
                                        rules={[
                                            {
                                                // required: true,
                                                message: 'Please enter remarks',
                                            },{
                                                max: 1000, 
                                                message: 'Exceed Limit !',
                                            },
                                        ]}
                                    >
                                        <TextArea rows={4}  />
                                    </Form.Item>

                                    <Form.Item name={'report_status'} label="Report Status">
                                        <Switch checkedChildren="Signed Off" unCheckedChildren="Draft" />
                                    </Form.Item>

                                    <Alert message="Editing is not possible once the report is signed off." type="warning" showIcon closable />

                                    {templateLoading ? (
                                        <Card className="editor">
                                            <Skeleton active />
                                        </Card>
                                    ) : (
                                        <Editor
                                            apiKey="y7bj7rt02vqi84dggp8vydsl2tu4e21rzomlej8at6ynx9ja"
                                            initialValue={initialContent.pmr_report}
                                            value={content}
                                            onInit={handleInit}
                                            onEditorChange={handleUpdate}
                                            onBeforeAddUndo={handleBeforeAddUndo}
                                            init={{
                                                selector: 'textarea',
                                                height: 500,
                                                //  menubar: false,
                                                // menubar: 'insert',
                                                paste_block_drop: false,
                                                plugins: [
                                                    // 'paste',
                                                    'template',
                                                    // 'advlist autolink lists link image charmap print preview anchor paste',
                                                    'searchreplace visualblocks code fullscreen',
                                                    'insertdatetime media table paste code help wordcount',
                                                ],
                                                toolbar:
                                                    'template | undo redo | formatselect | ' +
                                                    'bold italic backcolor | alignleft aligncenter ' +
                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                    'removeformat | help',
                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                                template_cdate_classes: 'cdate creationdate',
                                                template_mdate_classes: 'mdate modifieddate',
                                                template_selected_content_classes: 'selcontent',
                                                template_cdate_format: '%m/%d/%Y : %H:%M:%S',
                                                template_mdate_format: '%m/%d/%Y : %H:%M:%S',
                                                template_replace_values: {
                                                    fullName: 'Jack Black',
                                                    opno: '991234',
                                                },
                                                templates: templates,
                                            }}
                                        />
                                    )}

                                    <Form.Item extra="Record time taken for reporting" name={'time_taken'} label="Time Taken">
                                        <InputNumber min={1} max={9999} suffix="In Minutes" />
                                    </Form.Item>

                                    {/*  */}

                                    {templateLoading ? (
                                        ''
                                    ) : (
                                        <Button loading={btnloading} disabled={disabled} type="primary" htmlType="submit" className="submit-button">
                                            Save
                                        </Button>
                                    )}
                                </Form>
                            </div>
                        </>
                    )}
                </>
            )}
        </section>
    );
}
