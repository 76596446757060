/**
 * Communication Model
 *
 *
 * Communication mode is to decide what mode of communication to be recorded for their registration , appointment or visit. 
 *
 */
import React, { useState, useEffect, useContext } from 'react';

import Model from './models';

class Communication extends Model {

    constructor() {
        super();

        this.fields = [
            {
                field: 'cold_holdername',
                caption: 'Name',
            },
        ];

        // this.columns = ;
    }

    get id() {
        return 'comt_code';
    }

    get getEndpoint() {
        return 'communication';
    }

    get path() {
        return `communication`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `communication`;
    }

    get columns() {
        return [];
    }

}

export default Communication;
