import React, { useState, useEffect, useContext } from 'react';

import AsyncSelect from 'react-select/async';

import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';

import { Button, Form, Select, Card, Typography, Skeleton, DatePicker, Empty, Steps, message, Checkbox } from 'antd';

import './new-registration.scss';

import { ApiUtils, Location } from 'soxo-bootstrap-core';

import BillItems from '../../../common/components/bill-items/bill-items';

import GuestDetails from './guest-details';

import Slots from './slots';

import { EditOutlined, CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { withRouter } from 'react-router-dom';

import moment from 'moment';

import { useRouteMatch, useLocation } from 'react-router-dom';

import { Items, Appointments } from './../../../../models/';
import OpregSearch from '../../../candidate-visit/opreg-search';
// import { Opreg, Opvisits } from '../../models';

const { Option } = Select;

const { Title, Text } = Typography;

const { RangePicker } = DatePicker;

const { Step } = Steps;

const formItemLayout = {
    labelCol: {
        // xs: { span: 8 },
        // sm: { span: 0 },
    },
    wrapperCol: {
        // xs: { span: 24 },
        // sm: { span: 16 },
    },
    layout: 'vertical',
};

/**
 *
 * @returns
 */
function Registration({ history, ...props }) {
    let search = Location.search();

    let match = useRouteMatch();

    let location = useLocation();

    const [current, setCurrent] = useState(0);

    let stage = location.pathname.split('/new-registration/').pop();


    let position = {
        mode: 0,
        slots: 1,
        guest: 2,
        bill: 3,
    };

    useEffect(() => {

    }, []);

    function renderHeading(props) {
        if (props && props.location && props.location.pathname) {
            var routeName = props.location.pathname.split('/');
            if (routeName.length >= 3) {
                switch (routeName[2]) {
                    case 'guest':
                        return 'Guest Information';

                    case 'bill':
                        return 'Billing Screen';

                    default:
                        return 'Appointment';
                }
            }
        }
    }

    // Maintaining an object to show content according to the route
    let content = {};

    //
    let cases = {
        registration: {
            title: 'Registration',
            description: '',
        },
        appointment: {
            title: 'Appointment',
            description: 'Book an appointment for a guest in few easy steps',
        },
        reschedule: {
            title: 'Reschedule Appointment',
            description: 'Reschedule the appointment',
        },
    };

    //
    var title = 'Registration';

    let urlParams = Location.search();

    if (urlParams.redirect === 'appointment') {
        title = 'Appointment';
        content = cases['appointment'];
    }

    if (urlParams.appointId) {
        title = 'Reschedule Appointment';
        content = cases['reschedule'];
    }

    // if(urlParams.editAppointe)

    return (
        <>
            <section className="new-registration page">
                <div className="page-content">
                    {search.edit ? (
                        <>
                            <Title level={3}>Edit {title}</Title>

                            <p>Mention the updates you have to make for the {title}.</p>
                        </>
                    ) : (
                        <>
                            <Title level={3}>{renderHeading(props)}</Title>

                            <p>Book an appointment for a guest in few easy steps.</p>

                            <Steps size="small" current={position[stage]}>
                                <Step title="Select Package" />

                                <Step title="Choose Time Slot" />

                                <Step title="Enter Guest Details" />

                                {/* <Step title="Generate Bill" /> */}
                            </Steps>
                        </>
                    )}

                    <Switch>
                        <Route
                            path="/new-registration/mode"
                            component={() => {
                                return <PackageSelection {...props} />;
                            }}
                        />

                        <Route path="/new-registration/guest" component={GuestDetails} />

                        <Route
                            path="/new-registration/bill"
                            render={(routeParams) => {
                                return <BillItems {...props} {...routeParams} />;
                            }}
                        />

                        <Route path="/new-registration/slots" component={Slots} />
                    </Switch>
                </div>
            </section>
        </>
    );
}

export default withRouter(Registration);

/**
 * Add the Bill Items
 * To confirm the bill
 */
// function BillConfirmation() {
//     return (
//         <div className="bill-confirmation">
//             <BillItems />
//         </div>
//     );
// }

// const modes = ['Walk In', 'Call', 'Online'];
// const modes = ['Walk In', 'Call'];

// const modes = ['New Customer'];

/**
 * Select the Package
 */
function PackageSelection({ campaigns = [], ...props }) {
    let urlParams = Location.search();

    // Modes of booking
    const modes = ['New Customer'];

    if (urlParams.mode !== 'Walk In') {
        modes.push('Existing Customer');
    }

    const [loading, setLoading] = useState(false);

    const [allbranch, setAllbranch] = useState(false);

    const [appointments, setAppointmenets] = useState([]);

    const [choice, setChoice] = useState('Call');

    const [docapp, setDocapp] = useState({});

    const [list, setList] = useState([]);

    const [opreg, setOpReg] = useState([]);

    // const [searchEnable, setSearchEnable] = useState(false);

    const [range, setRange] = useState([moment().startOf('day'), moment().endOf('month')]);

    const dateFormat = 'YYYY-MM-DD';

    useEffect(() => {
        getPackages();

        getAppointments(1, range);
    }, []);

    const [form] = Form.useForm();
    /**
     * Get the doctor appointments
     */
    function getAppointments(pageNo, range) {
        setLoading(true);

        Appointments.getAppointments(1, range, 50).then((result) => {
            // Filter appointments
            let filtered = result.filter((record) => !record.da_newopno && record.preReg && record.da_packagetypevalue);

            setAppointmenets(filtered);

            setLoading(false);
        });
    }

    /**
     * Get all the packages
     */
    const getPackages = () => {
        Items.getPackages().then((result) => {
            //  setList(result);

            // setList(result.result.filter((item) => item.itm_active === 'Y'));
            let items = result.result.filter((item) => item.itm_active === 'Y');

            // Walk in case or metabolic syndrome like case that includes campaigns has to show only those items
            if (urlParams.mode === 'Walk In') {
                let filteredItems = items.filter((item) => {
                    return campaigns.indexOf(parseInt(item.itm_code)) !== -1;
                });

                setList([...filteredItems]);
            } else {
                let filteredItems = items.filter((item) => {
                    return campaigns.indexOf(parseInt(item.itm_code)) === -1;
                });

                setList([...filteredItems]);
            }
        });
    };

    /**
     *
     *
     * @param {*} values
     */
    const onFinish = async (values) => {
        //If non existing name is entered
        if (choice === 'Existing Customer') {
            if (opreg === null || (opreg && !opreg.op_no && !opreg.prereg_id)) {
                message.error('Enter an Existing Customer');
                return false;
            }
        }

        let selected = list.filter((record) => record.itm_code === values.selected.value).pop();

        //Gender Validation
        var genderitm = null;
        var opregGender;
        if (opreg && (opreg.gender || opreg.prereg_gender)) {
            if (opreg.gender === 'M' || opreg.prereg_gender === 'M') {
                opregGender = 'M';
            } else if (opreg.gender === 'Male' || opreg.prereg_gender === 'Male') {
                opregGender = 'M';
            } else if (opreg.gender === 'MALE' || opreg.prereg_gender === 'MALE') {
                opregGender = 'M';
            } else if (opreg.gender === 'F' || opreg.prereg_gender === 'F') {
                opregGender = 'F';
            } else if (opreg.gender === 'Female' || opreg.prereg_gender === 'Female') {
                opregGender = 'F';
            } else if (opreg.gender === 'FEMALE' || opreg.prereg_gender === 'FEMALE') {
                opregGender = 'F';
            }
        }
        if (selected.itm_gender === 'M') {
            genderitm = 'M';
        } else if (selected.itm_gender === 'F') {
            genderitm = 'F';
        } else if (selected.itm_gender === 'PF') {
            genderitm = 'F';
        }

        if (opreg && (opreg.op_no || opreg.prereg_id)) {
            if (genderitm != null) {
                if (genderitm != opregGender) {
                    message.error('You have Selected the Wrong Gender');
                    return false;
                }
            }
        }

        var queryParam = {
            // da_id: docapp.da_id,
            da_packagetypevalue: values.selected.value,
            mode: choice,
            da_doctorptr: selected.itm_slotdoctorptr,
            item_gender: selected.itm_gender,
            // prereg_id: docapp.preReg.prereg_id
            ...urlParams,
        };


        // Return location of guest
        if (opreg && opreg.db_ptr && opreg.db_ptr !== props.settings.headers.db_ptr.toString()) {
            queryParam = {
                ...queryParam,
                db_ptr: opreg.db_ptr,
            };
        }


        /**
         * This Api is used for  when guest select other branch we have to load opreg and from the other location.
         * In here we have to pass db_ptr  of  guest loation
         * If the customer is from other location
         */

        if (opreg && opreg.db_ptr && opreg.db_ptr !== props.settings.headers.db_ptr.toString()) {

            const opReg = await Appointments.createExistingCustomer(opreg)


            if (opReg && opReg.opReg && opReg.opReg.op_id) {
                queryParam = {
                    ...queryParam,
                    id: opReg.opReg.op_id,
                    table: 'opreg',
                    repeat: true,
                };
            } else if (opReg && opReg.preReg && opReg.preReg.prereg_id) {
                queryParam = {
                    ...queryParam,
                    id: opReg.preReg.prereg_id,
                    table: 'prereg',
                    repeat: true,
                };
            }
        } else {

            // maintain the case is repeated, the opreg or prereg id and the mode in url
            if (opreg && opreg.op_id) {
                queryParam = {
                    ...queryParam,
                    id: opreg.op_id,
                    table: 'opreg',
                    repeat: true,
                };
            } else if (opreg && opreg.prereg_id) {
                queryParam = {
                    ...queryParam,
                    id: opreg.prereg_id,
                    table: 'prereg',
                    repeat: true,
                };
            }
        }


        Location.navigate({ url: '/new-registrations/slots', queryParam });
    };

    //Get Option matching the input

    function loadHeads(query) {
        setOpReg(null);

        let formBody = {
            search_by: 'first_name',
            criteria: 'anywhere',
            search_text: query,
        };

        if (allbranch) {
            formBody = {
                ...formBody,
                index: 'ALL',
            };
        } else {
            formBody = {
                ...formBody,
                index: localStorage.db_ptr ? localStorage.db_ptr : process.env.REACT_APP_DB_PTR,
            };
        }
        if (query.length >= 3) {
            return Appointments.consolidatedSearch(formBody).then((response) => {

                return response.result.map((item) => {
                    if (item.op_number !== null)
                        return {
                            value: item.op_number,
                            label: item.first_name + ' ' + item.last_name + ' ' + '-' + ' ' + item.mobile1 + ' ' + '-' + ' ' + item.op_number,
                            ...item,
                        };
                    else
                        return {
                            value: item.prereg_id,
                            label: item.first_name + ' ' + item.last_name + ' ' + '-' + ' ' + item.mobile1 + ' ' + '-' + ' ' + item.prereg_id,
                            ...item,
                        };
                });
            });
        }
    }

    //Set opreg with selected customers details
    const handleChange = (result) => {
        if (result) setOpReg(result);
        else {
            setOpReg(null);
        }
    };

    /**
     *
     */
    const confirmAppointment = () => {
        if (choice === 'Online') {
            if (docapp && docapp.da_id) {
                var queryParam = {
                    da_id: docapp.da_id,
                    da_packagetypevalue: docapp.da_packagetypevalue,
                    da_doctorptr: docapp.da_doctorptr,
                    prereg_id: docapp.preReg.prereg_id,
                    mode: 'Online',
                };

                Location.navigate({ url: '/new-registration/guest', queryParam });
            }
        } else if (choice === 'Call') {
            var slotParam = {
                da_id: docapp.da_id,
                da_packagetypevalue: docapp.da_packagetypevalue,
                prereg_id: docapp.preReg.prereg_id,
            };

            Location.navigate({ url: '/new-registrations/slots', queryParam: slotParam });
        } else message.error('Please Select a Pateint');
    };

    function updateTime(dt) {
        setRange(dt);

        Location.search({
            start_time: moment(dt[0]).format('MM/DD/YYYY'),
            end_time: moment(dt[1]).format('MM/DD/YYYY'),
        });

        getAppointments(1, dt); //Patiant list get function
    }

    /**
     * Update all the branch
     *
     * @param {*} value
     */
    function updateAllBranch(event) {
        setAllbranch(event.target.checked);
    }

    return (
        <div className="package-selection">
            {/* Different Packages */}
            <Card className="card form-step">
                <Title level={5}>Mode of Appointment</Title>

                <p>Please select the mode of Customer</p>

                <div className="registration-mode">
                    {modes.map((mode, key) => {
                        return (
                            <div
                                key={key}
                                className={`mode ${mode === choice ? 'active' : ''}`}
                                onClick={() => {
                                    setChoice(mode);
                                    // setSearchEnable(false);
                                }}
                            >
                                <CheckCircleOutlined />
                                {mode}
                            </div>
                        );
                    })}
                    {/** consolidatedSearch Api search start */}

                    {/** consolidatedSearch Api search  Ends */}
                </div>
                {choice === 'Existing Customer' ? (
                    <>
                        {/* {searchEnable ? null : ( */}
                        <div className="registration-mode">
                            <Form
                                form={form}
                                onValuesChange={(feilds) => {
                                    setOpReg(null);
                                }}
                            >
                                <div className="checkbox-input" style={{ width: '350px' }}>
                                    <Checkbox value={allbranch} onChange={updateAllBranch} />

                                    <span className="caption">Search ALL Branches</span>
                                    <p>Search Guest</p>
                                </div>

                                <div className="consolidated-search" style={{ width: '700px' }}>
                                    <AsyncSelect
                                        className="search"
                                        value={opreg}
                                        onChange={handleChange}
                                        // cacheOptions
                                        defaultOptions
                                        loadOptions={loadHeads}
                                        isClearable="true"
                                    />
                                    <OpregSearch
                                        enableVisit={false}
                                        callback={(opreg) => {
                                            setOpReg(opreg);
                                            // setSearchEnable(true);
                                        }}
                                        model={Appointments}
                                        title={'Advance Search'}
                                    />
                                </div>
                            </Form>
                        </div>
                        {/* // )} */}

                        <div className="registration-mode">
                            <div className="details">
                                {opreg && opreg.first_name ? (
                                    <>
                                        <p>Selected Guest</p>
                                        <div className="customer">
                                            Name : {opreg.first_name} {opreg.last_name}
                                            <br />
                                            OP No: {opreg.op_number}
                                            <br />
                                            {/** Gender validations  */}
                                            Gender :{' '}
                                            {opreg.gender.toUpperCase() === 'M' || opreg.gender.toUpperCase() === 'MALE'
                                                ? 'Male'
                                                : opreg.gender.toUpperCase() === 'F' || opreg.gender.toUpperCase() === 'FEMALE'
                                                    ? 'Female'
                                                    : 'Other'}
                                            <br />
                                            {/**This Condition added for checking  date of birth.if dob present then return dob else return age */}
                                            {opreg.dob ? (
                                                <>DOB : {moment(opreg.dob).format('DD/MM/YYYY')}</>
                                            ) : (
                                                <>
                                                    Age :
                                                    {opreg.age_year !== 0
                                                        ? opreg.age_year + ' Years'
                                                        : opreg.age_month !== 0
                                                            ? opreg.age_month + ' Months'
                                                            : opreg.age_day + ' Days'}
                                                </>
                                            )}
                                            <br />
                                            Address: {opreg.address1}
                                            {opreg.address1 ? <br /> : null}
                                            {opreg.address2}
                                            <br />
                                            Mobile : {opreg.mobile1}
                                        </div>
                                    </>
                                ) : opreg && opreg.prereg_id ? (
                                    <>
                                        <p>Selected Guest</p>
                                        <div className="customer">
                                            Name : {opreg.prereg_fname} {opreg.prereg_lname}
                                            <br />
                                            PreReg ID: {opreg.prereg_id}
                                            <br />
                                            Gender : {opreg.prereg_gender}
                                            <br />
                                            DOB : {moment(opreg.prereg_dob).format('DD/MM/YYYY')}
                                            <br />
                                            Address: {opreg.prereg_address1}
                                            {opreg.prereg_address1 ? <br /> : null}
                                            {opreg.prereg_address2}
                                            <br />
                                            Mobile : {opreg.prereg_mobile1}
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </>
                ) : null}

                {/* Selection Content */}

                <div className="form-content">
                    {/* Package selection for Offline Cases */}
                    {choice !== 'Online' ? (
                        <>
                            <Form
                                {...formItemLayout}
                                // form={form}
                                name="register"
                                onFinish={onFinish}
                                scrollToFirstError
                            >
                                <Form.Item name="selected" label="Package">
                                    <Select labelInValue>
                                        {list.map((item, key) => (
                                            <Option key={key} value={item.itm_code}>
                                                {item.itm_desc}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>

                                <Button type="primary" htmlType="submit">
                                    Proceed
                                </Button>
                            </Form>
                        </>
                    ) : null}
                    {/* Package selection */}

                    {choice === 'Online' ? (
                        <>
                            <div className="block-header">
                                <div>
                                    {/* Appointment Listing */}
                                    <Title level={5}>Continue with Registration</Title>

                                    <p style={{ marginTop: '35px' }}>
                                        {loading ? (
                                            'Loading appointments'
                                        ) : (
                                            <>{appointments.length ? 'Select the appointment to continue' : 'No results'}</>
                                        )}
                                    </p>
                                </div>

                                <RangePicker
                                    allowClear={false}
                                    inputReadOnly
                                    format={'DD/MM/YYYY'}
                                    value={range}
                                    onChange={(time) => {
                                        updateTime(time, range);
                                    }}
                                    ranges={{
                                        Today: [moment(), moment()],

                                        Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],

                                        'This Week': [moment().startOf('week'), moment().endOf('week')],

                                        'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],

                                        'This Month': [moment().startOf('month'), moment().endOf('month')],

                                        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                                    }}
                                />
                            </div>

                            {loading ? (
                                <Skeleton />
                            ) : (
                                <>
                                    {appointments.map((guest, key) => {
                                        return <AppointmentCard key={key} selected={docapp} onClick={() => setDocapp(guest)} guest={guest} />;
                                    })}
                                    {appointments.length ? (
                                        <>
                                            <Button type="primary" onClick={confirmAppointment}>
                                                Proceed
                                            </Button>
                                        </>
                                    ) : (
                                        <>
                                            <Empty description={false} />
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    ) : null}
                </div>

                {/* Selection Content Ends */}
            </Card>

            <div className="notice-card card">
                <Title level={5}>
                    <InfoCircleOutlined /> Notes
                </Title>

                <p>
                    Now book an appointment easily by sharing the minimal details of the guest .
                    {/* You have <h3>
        {days}
    </h3> days remaining to complete this process. */}
                </p>

                {/* <Link to="/change-password">
                    Do it Now
                </Link> */}
            </div>
            <>
                {/* <Timeline>
                    <Timeline.Item color={'green'} dot={<CheckCircleOutlined style={{ fontSize: '16px' }} />}>
                        Select Package
                    </Timeline.Item>

                    <Timeline.Item color={'green'} dot={<CheckCircleOutlined style={{ fontSize: '16px' }} />}>
                        Choose Time Slot
                    </Timeline.Item>

                    <Timeline.Item color={'green'} dot={<CheckCircleOutlined style={{ fontSize: '16px' }} />}>
                        Enter Guest Details
                    </Timeline.Item>

                    <Timeline.Item color={'green'} dot={<CheckCircleOutlined style={{ fontSize: '16px' }} />}>
                        Create Bill
                    </Timeline.Item>
                </Timeline> */}
            </>
        </div>
    );
}

/**
 * Component to show the appointment
 *
 * @param {*} param0
 * @returns
 */
function AppointmentCard({ guest, callback, onClick, selected }) {
    return (
        <div className={`appointment-card ${selected.da_id === guest.da_id ? 'active' : ''}`} onClick={onClick}>
            <Text level={5} className="top-content">
                <p className={'name'}>
                    {guest.da_fname} {guest.da_lname}
                </p>

                <p>{moment(guest.da_date).format('DD/MM/YYYY HH:MM')}</p>
            </Text>

            <CheckCircleOutlined />
        </div>
    );
}
