/***
 *
 *
 * @description
 * @author jinshakappil
 */
import React, { useState } from 'react';
import './age-selection.scss';

import { DatePicker, Form, Input, Radio } from 'antd';

import { InputComponent } from 'soxo-bootstrap-core';

import moment from 'moment';

import { useEffect } from 'react';

// Add , Edit

// Date / Age-Month-Year

// Decide Mode Accordingly to the input

// Maintain State within component

//

export default function AgeSelection({ form, ageValues, field, disabled }) {

    const [mode, setMode] = useState(() => {
        if (ageValues && ageValues.dob) {
            return 'dob';
        } else if (ageValues && !ageValues.dob && (ageValues.year || ageValues.month || ageValues.day)) {
            return 'age';
        } else {
            return 'dob';
        }
    });

    const [age, setAge] = useState(() => {
        if (ageValues) {
            return ageValues;
        } else {
            return { dob: '', year: '', month: '', day: '' };
        }
    });

    useEffect(() => {
        //This is done to set initial value of form
        if (ageValues) {
            form.setFieldsValue({
                dob: ageValues.dob,
                year: ageValues.year,
                month: ageValues.month,
                day: ageValues.day,
                [field]: { dob: ageValues.dob, year: ageValues.year, month: ageValues.month, day: ageValues.day },
            });

            if (ageValues.dob) {
                form.setFieldsValue({
                    dob: ageValues.dob,
                });
            }

            // Temporary fix for update of state
            // setTimeout(() => {

            //     setMode('age');

            // }, 0);
        } else {
            form.setFieldsValue({ dob: '', year: '', month: '', day: '' });
        }
    }, []);

    /**
     * When prepopulating, dob is present in guest,hence we give datepicker value as guest.dob
     * Since defaultpickervalue and value does not work with datepicker at the same time, we pass value as props
     */

    let dateProps = {};

    if (age.dob) {
    } else {
        dateProps = {
            defaultPickerValue: moment('01-01-1990', 'DD-MM-YYYY'),
        };
    }

    /**
     *
     * @param {*} type
     * @param {*} value
     */
    function onChange(type, value) {
        if (type === 'datepicker') {
            if (value) {
                //calculate age from dob
                const today = moment();
                const date_of_birth = moment(value);
                const ages = moment.duration(today.diff(date_of_birth));
                const age_year = ages.years();
                const age_month = ages.months();
                const age_day = ages.days();

                let age_type = 'Years';
                // We also have to set the age type 
                // The calculation that was otherways done on the registration screen
                // needs to be available in the component

                if (age_year) {

                    age_type = 'Years';

                } else if (!age_year && age_month) {

                    age_type = 'Months';

                } else if (!age_year && age_day && !age_month) {

                    age_type = 'Days';

                }

                var formattedValues = {
                    age: age_year,

                    dob: date_of_birth,
                    year: age_year,
                    month: age_month,
                    day: age_day,
                    age_type
                };

                //only if this is done parent form gets validated
                form.setFieldsValue({
                    ...formattedValues,
                    [field]: formattedValues
                });

                setAge({
                    age: age_year,


                    dob: value,
                    year: age_year,
                    month: age_month,
                    day: age_day,
                });
            }
        } else {
            age.dob = null;

            // Set value according to age type
            age[type] = value;

            Object.keys(age).forEach((key) => {
                if (!age[key] && key != 'dob' && key != type) {
                    age[key] = 0;
                }
            });
            setAge(age);

            // only if this is done parent form gets validated
            form.setFieldsValue({ ...age, [field]: age });
        }
    }

    /**
     * Dob radio On change
     */
    function onModeChange(event) {
        let value = event.target.value;

        setMode(value);

        if (value) {
        } else {
            setAge(null);

            form.setFieldsValue({ [field]: value });
            form.resetFields(['dob-section']);
        }
    }

    /**
     * Disable dates
     * @param {*} current
     * @returns
     */
    function disabledDates(current) {
        return current > moment().endOf('day');
    }

    return (
        <>
            {/* <Form
                // form={form}
                initialValues={{
                    year: age.year,
                    month: age.month,
                    day: age.day,
                }}
                onFinish={onFinish}
            > */}
            <div className="age-selection">
                {/*  */}
                <Radio.Group defaultValue={mode} className="age-selection-radio-group">
                    <Form.Item name="mode">
                        <Radio value="dob" id="1" onChange={onModeChange}>
                            {' '}
                            DOB
                        </Radio>

                        <DatePicker
                            {...dateProps}
                            value={age.dob}
                            onChange={(value) => {
                                onChange('datepicker', value);
                            }}
                            format="DD/MM/YYYY"
                            disabledDate={disabledDates}
                            disabled={mode !== 'dob' || disabled}
                        />
                    </Form.Item>

                    <Radio id="2" value="age" className="radio-age" onChange={onModeChange}>
                        {' '}
                        Age
                    </Radio>
                    <div className="age-input">
                        <>
                            <Form.Item
                                name="year"
                                rules={[
                                    {
                                        pattern: /^\d{1,3}?$/,
                                        message: 'Please Enter Valid Age !',
                                    },

                                    () => ({
                                        validator(_, value) {
                                            // Year not more than 170 allowed
                                            if (value && value > 170) {
                                                return Promise.reject('Please enter valid age');
                                            }

                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <InputComponent
                                    placeholder="Years"
                                    className="age-year"
                                    value={age.year}
                                    onChange={(value) => {
                                        onChange('year', value);
                                    }}
                                    disabled={mode === 'dob' || disabled}
                                    maxLength={3}
                                />
                            </Form.Item>
                            <Form.Item
                                name="month"
                                // Using this property so that validation is checked whenever year is entered
                                dependencies={['year']}
                                rules={[
                                    {
                                        pattern: /^\d{1,3}?$/,
                                        message: 'Please enter valid age',
                                    },

                                    // Get the value in year and compare
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            // When year is entered month should not be greater than or equal to 12
                                            if (value && getFieldValue('year') != 0 && value >= 12) {
                                                return Promise.reject('Please enter valid age');
                                            }

                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <InputComponent
                                    placeholder="Months"
                                    maxLength={3}
                                    className="age-year"
                                    value={age.month}
                                    onChange={(value) => {
                                        onChange('month', value);
                                    }}
                                    disabled={mode === 'dob' || disabled}
                                />
                            </Form.Item>
                            <Form.Item
                                name="day"
                                dependencies={['month', 'year']}
                                rules={[
                                    {
                                        pattern: /^\d{1,4}?$/,
                                        message: 'Please enter valid age',
                                    },

                                    // Get the value in month and compare
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            // When month is entered days should not be greater than 31
                                            if (
                                                (value && getFieldValue('month') != 0 && value >= 31) ||
                                                (value && getFieldValue('year') != 0 && value >= 365)
                                            ) {
                                                return Promise.reject('Please enter valid age');
                                            }

                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <InputComponent
                                    placeholder="Days"
                                    className="age-year"
                                    value={age.day}
                                    onChange={(value) => {
                                        onChange('day', value);
                                    }}
                                    disabled={mode === 'dob' || disabled}
                                />
                            </Form.Item>
                        </>

                        {/* )} */}
                    </div>
                </Radio.Group>
            </div>
            {/* </Form> */}
        </>
    );
}
