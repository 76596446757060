/**
 *
 * This should ideally be satisfied by a generic listing page
 *
 *
 */

import React, { useState, useEffect, useContext } from 'react';

//
import { Card, Space, DatePicker, Switch, Form, Button, Typography, Table, Skeleton, Pagination, Input, Checkbox, Tag, Popover } from 'antd';

import { Route, Link } from 'react-router-dom';

import { ModalityGroups } from './../../../../models';

// import useDeviceDetect from '../../../../hooks/device-detect';

// import '../../physician-landing.scss';

import Select from 'react-select';

import { Location, GlobalContext } from 'soxo-bootstrap-core';

import moment from 'moment-timezone';

import './patient-list.scss';

import { ReloadOutlined, OrderedListOutlined, PicCenterOutlined, CaretLeftOutlined, CaretRightOutlined, DeleteOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const { RangePicker } = DatePicker;

const { Search } = Input;

let defaultValue = {
    value: { mod_code: 'all' },
    label: 'All',
};

const filterOptions = [
    {
        label: 'All',
        value: 'all',
    },
    {
        label: 'Reported',
        value: 'reported',
    },
    {
        label: 'Unreported',
        value: 'unreported',
    },
];

/**
 * Patient List
 *
 * @param {*} match
 * @returns
 */
export default function PatientListResearch({ match, mode, redirect, filter = 'TECH' }) {
    // location params
    var param = Location.search();

    const [range, setRange] = useState([
        moment.tz(param.start_time, process.env.REACT_APP_TIMEZONE).startOf('day'),
        moment.tz(param.end_time, process.env.REACT_APP_TIMEZONE).endOf('day'),
    ]);


    const [groups, setGroups] = useState([]);

    const { user = {}, isMobile } = useContext(GlobalContext);

    const [pending, setPending] = useState(filterOptions[0]);

    const [loading, setLoading] = useState(false);

    const [page, setPage] = useState(1); // Pagination number

    const [limit, setLimit] = useState(20);

    const [patients, setPatients] = useState([]); //Patients list array

    const [view, setView] = useState(isMobile ? true : false); //Need to check this condition

    const [modality, setModality] = useState(defaultValue);

    const [subModality, setSubModality] = useState('');

    const [suboptions, setSuboptions] = useState([]);

    // Number of total records
    const [count, setCount] = useState(0);

    var [query, setQuery] = useState('');

    useEffect(() => {
        // On url update load the data
        loadData();
    }, [match.params]);

    /**
     * Load the data
     */
    function loadData() {
        let urlParams = Location.search();

        // Match params
        let params = {
            start_time: range[0].format('MM/DD/YYYY'),
            end_time: range[1].format('MM/DD/YYYY'),
            limit: 20,

            ...urlParams,
        };

        loadGuestRecords(params);
    }

    useEffect(() => {
        // if (user) {
        //     getPatientDetails(pageNo, range, modality.value.mod_code); //Patiant list get function
        // }

        

        getGroups();
    }, [user]);

    /**
     * load the data
     *
     * @param {*} params
     */
    function loadGuestRecords(params) {
        //
        setLoading(true);

        // Load the data
        ModalityGroups.loadGuests({ ...params, user, mode }).then((result) => {
            setPatients([...result[0]]);

            // If the count is present , update the size of the pagination component
            if (result[0] && result[0][0] && result[0][0].Count) {
                setCount(result[0][0].Count);
            }

            setLoading(false);
        });
    }

    /**
     * On Page Change
     *
     * @param {*} data
     */
    function onPagination(page, limit) {
        setPage(page);

        setLimit(limit);

        Location.search({ page, limit });
    }

    

    /**
     * Get all the groups
     */
    function getGroups() {
        // get groups
        ModalityGroups.getModality().then((result) => {
            setGroups([
                defaultValue,

                ...result.result
                    .filter((item) => item.mod_active === 'Y')
                    .map((record) => {
                        return {
                            value: record,
                            label: record.mod_desc,
                        };
                    }),
            ]);
        });
    }

    /**
     * On change of datetime
     *
     * @param {*} dt
     */
    function updateTime(dt) {
        setPage(1);
        setRange(dt);
        setSubModality('');

        Location.search({
            page: 1,
            limit: 20,
            start_time: moment(dt[0]).format('MM/DD/YYYY'),
            end_time: moment(dt[1]).format('MM/DD/YYYY'),
        });
    }

    /**
     * Change view
     *
     * @param {*} result
     */
    function changeView(result) {
        setView(result);
    }

    /**
     * Refresh the
     */
    function refresh() {
        setSubModality('');

        loadData();
    }

    /**
     * for Search
     * @param {*} event
     */
    function onSearch(event) {
        setQuery(event.target.value);
    }

    /**
     * Filter by modality
     *
     * @param {*} value
     * @param {*} content
     */
    function filterModality(value, content) {
        setPage(1);

        setModality(value);

        setSubModality('');

        Location.search({ modality: value.value.mod_code, page: 1 }, { reset: true });

        if (value.label !== 'All') {
            ModalityGroups.getModalityGroupItemGroupMap(value.value.mod_groupptr).then((result) => {
                setSuboptions(
                    result.map((record) => {
                        return {
                            value: record,
                            label: record.itm_desc,
                        };
                    })
                );
            });
        } else {

        }
    }

    /**
     * Subfilter
     *
     * @param {*} value
     */
    function subFilterModality(value) {
        setSubModality(value);

        Location.search({ submodality: value.value.itm_code });
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            // render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1,
            render: (value, item, index) => (page - 1) * limit + index + 1,

            // render: (value, item, index) => page * index + 1,
        },

        {
            title: 'Name',
            dataIndex: 'Name',
            key: 'name',
        },

        {
            title: 'Date',
            dataIndex: 'ProcessStartTime',
            key: 'ProcessStartTime',
        },
        {
            title: 'Age',
            dataIndex: 'Age',
            key: 'age',
        },

        {
            title: 'Gender',
            // dataIndex: 'Gender',
            render: (record) => {
                return (
                    <>
                        <Tag color={record.Gender === 'Male' ? 'blue' : 'pink'}> {record.Gender}</Tag>
                    </>
                );
            },
            key: 'gender',
        },
        // {
        //     title: 'Billed  Date',
        //     dataIndex: 'ProcessStartTime',
        //     key: 'ProcessStartTime',
        // },
        {
            title: 'OP No',
            // dataIndex: 'opno',
            render: (record) => {
                return (
                    <>
                        <Tag color="green"> {record.opno}</Tag>
                    </>
                );
            },
            key: 'OpNo',
        },

        {
            title: 'Modality',
            dataIndex: 'Modality',
            key: 'Modality',
            // filters: filterChoices && filterChoices[0],
            // onFilter: (value, record) => (record.Modality ? record.Modality.indexOf(value) === 0 : null),
        },

        {
            title: 'Accession No',
            dataIndex: 'AccessionNo',
            key: 'accessionno',
        },

        {
            title: 'Tech Result',
            dataIndex: 'TechResult',
            key: 'TechResult',
        },

        {
            title: 'Tech Remarks',
            dataIndex: 'TechDesc',
            key: 'TechDesc',
        },

        {
            title: 'Dr Result',
            key: 'result',
            render: (text, record) => {
                const content = (
                    <div>
                        <p>{text.DrRemarks}</p>
                    </div>
                );

                return (
                    <>
                        <Popover content={content} title="Remarks">
                            {text.DrResult}
                        </Popover>
                    </>
                );
            },
        },

        {
            title: '',
            key: 'action',
            fixed: 'right',
            render: (text, record) => {
                let isPresent = false;

                if (text.DrResult) {
                    isPresent = true;
                }

                return (
                    <Space size="middle">
                        {/* ${record.MODE} */}

                        <Link to={`/${redirect}/${record.opno}?accessionno=${record.AccessionNo}&mode=${isPresent ? 'RAD' : 'TECH'}&opb_id=${record.billid}&item_ptr=${record.itemptr}`}>View</Link>
                    </Space>
                );
            },
        },
    ];

    // Defining Data source for passing to list
    var dataSource = patients.filter((item) => {
        query = query.toUpperCase();

        if (query) {
            if (filter === 'TECH') {
                if (pending.value === 'reported' && item.Name.toUpperCase().indexOf(query) != -1) {
                    return item.TechResult;
                } else if (pending.value === 'unreported' && item.Name.toUpperCase().indexOf(query) != -1) {
                    return !item.TechResult;
                } else if (item.Name.toUpperCase().indexOf(query) != -1) {
                    return true;
                }
            } else if (filter === 'RAD') {
                if (pending.value === 'reported' && item.Name.toUpperCase().indexOf(query) != -1 && item.MODE === 'RAD') {
                    return item.DrResult;
                } else if (pending.value === 'unreported' && item.Name.toUpperCase().indexOf(query) != -1 && item.MODE === 'RAD') {
                    return !item.DrResult;
                } else if (item.Name.toUpperCase().indexOf(query) != -1 && item.MODE === 'RAD') {
                    return true;
                }
            }
        } else {
            if (filter === 'TECH') {
                if (pending.value === 'reported') {
                    return item.TechResult;
                } else if (pending.value === 'unreported') {
                    return !item.TechResult;
                } else {
                    return true;
                }
            } else if (filter === 'RAD') {
                if (pending.value === 'reported') {
                    return item.DrResult;
                } else if (pending.value === 'unreported') {
                    return !item.DrResult;
                } else {
                    return true;
                }
            }
        }
    });

    return (
        <section className="patient-list card">
            {/* Page Header */}
            <div className="page-header">
                <div className="left">
                    <Title style={{ marginBottom: '0px' }} level={3}>
                        Patient List
                    </Title>

                    <Search
                        placeholder="Enter Search Value"
                        allowClear
                        style={{ width: 300, marginTop: '10px', marginBottom: '20px' }}
                        onChange={onSearch}
                    />
                </div>

                <div className="right">
                    <div style={{ marginRight: '10px' }} className="modality-name">
                        <div className="action-header">Filter</div>
                        <Select
                            options={filterOptions}
                            labelInValue={'mod_desc'}
                            value={pending}
                            onChange={(value) => {
                                setPending(value);
                            }}
                            // defaultValue="all"
                            style={{ width: 120 }}
                        ></Select>
                    </div>

                    {/* not needed for stoptb */}
                    <div style={{ marginRight: '10px' }} className="modality-name">
                        <div className="action-header">Modality</div>
                        <Select
                            options={groups}
                            // labelInValue={'mod_desc'}
                            value={modality}
                            onChange={(value) => {
                                filterModality(value);
                            }}
                            // defaultValue="all"
                            style={{ width: 120 }}
                        ></Select>
                    </div>

                    {/* not needed for stoptb */}
                    {/* Filter for Sub Modality */}
                    {modality.label !== 'All' ? (
                        <div style={{ marginRight: '10px' }} className="sub-modality-name">
                            <div className="action-header">Sub Modality</div>
                            <Select options={suboptions} value={subModality} onChange={subFilterModality} style={{ width: 120 }}></Select>
                        </div>
                    ) : null}
                    {/* Filter for Sub Modality Ends */}

                    {/* Filtering data */}

                    <div className="date-and-fltr">
                        <div className="action-header">Date</div>
                        <RangePicker
                            allowClear={false}
                            inputReadOnly
                            format={'DD/MM/YYYY'}
                            value={range}
                            onChange={(time) => {
                                updateTime(time, range);
                            }}
                            ranges={{
                                Today: [moment(), moment()],

                                Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],

                                'This Week': [moment().startOf('week'), moment().endOf('week')],

                                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],

                                'This Month': [moment().startOf('month'), moment().endOf('month')],

                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            }}
                        />

                        <Button onClick={refresh} type="secondary" size={'small'}>
                            <ReloadOutlined />
                        </Button>

                        <Switch
                            defaultChecked={view}
                            onChange={changeView}
                            checked={view}
                            checkedChildren={<OrderedListOutlined />}
                            unCheckedChildren={<PicCenterOutlined />}
                        />
                    </div>
                </div>
            </div>

            {/* Page Header Ends */}

            <p className="size-hint">{loading ? 'Loading' : dataSource.length} records.</p>

            {/* Content Below */}
            {loading ? (
                <>
                    <Skeleton active />
                </>
            ) : (
                <>
                    {view ? (
                        <>
                            <CardList dataSource={dataSource} redirect={redirect} />
                        </>
                    ) : (
                        <>
                            <Card>
                                <Table
                                    rowKey={(record) => record.AccessionNo}
                                    dataSource={dataSource}
                                    scroll={{ x: true }}
                                    columns={columns}
                                    pagination={false}
                                />{' '}
                            </Card>
                        </>
                    )}
                </>
            )}

            <div className="table-footer">
                {!loading ? <Pagination pageSize={limit} showSizeChanger={true} onChange={onPagination} defaultCurrent={page} total={count} /> : null}
            </div>
        </section>
    );
}

//Mobile view card Section
function CardList({ dataSource, redirect }) {
    return dataSource.map((item, index) => {
        let isPresent = false;

        if (item.DrResult) {
            isPresent = true;
        }
        return (
            <Link
                key={index}
                className="report-item"
                to={`/${redirect}/${item.opno}?accessionno=${item.AccessionNo}&mode=${isPresent ? 'RAD' : 'TECH'}`}
            >
                <ModalityGroups.GuestCard record={item} />
            </Link>
        );
    });
}
