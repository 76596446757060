/**
 *  Branches  model to group all apis related to it. 
 @description
 @author Jinishakappil
 */

import Model from './models';

import { ApiUtils } from 'soxo-bootstrap-core';


class Branches extends Model {
    constructor() {
        super();

        this.fields = [
            {
                field: 'name',
                caption: 'Name',
            },
        ];

    }

    /**
     * Get Branches 
     * @returns 

     */
    getBranches = () => {
        return ApiUtils.get({
            url: `branch-master/get-records`,
            headers: {
                db_ptr: 'nuramho',
            },
        });
    };


    /**
     * Get profile 
     * @returns 
     */
    getProfile = (index) => {
        return ApiUtils.get({
            url: `auth/profile`,

            headers: {
                db_ptr: index
            },
        });
    };


}

export default Branches;
