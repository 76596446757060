/**
 * @description
 * x-ray reporting Detail screen 
 * 
 */


import React, { useState, useEffect, useContext } from 'react';

import { Card, Button, Select, Form, Row, message, Skeleton, Typography, Input, Space } from 'antd';

import { ReloadOutlined } from '@ant-design/icons';

import './un-patient-details.scss';

import { GlobalContext, Location } from 'soxo-bootstrap-core';

import { SCRFilesLink } from '../../../../models';

import moment from 'moment';

const { Title } = Typography;

const { TextArea } = Input;

const { Option } = Select;

let initialValues = {};

export default function PatientDetails({ match, mode = 'RNDR', history, ...props }) {
    const [initial, setInitial] = useState({});

    const [btnloading, setBtnloading] = useState(false);

    const { user, isMobile } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    const [formloading, setFormloading] = useState(false);

    let abdomenOptions = props.formOptions


    // const [choices, setChoices] = useState(abdomenOptions.map((record) => { return { [record.code]: null } }));

    const [choices, setChoices] = useState({});

    const [report, setReport] = useState({
        opbill: {},
        opreg: {},
        modality: { item: {} },
        scrfiles: [],
    });

    // const [patient, setPatient] = useState({ templatemas: { tmpl_template: 0 } }); //Patiants list array

    const [patient, setPatient] = useState({
        opbill: {},
        opreg: {},
        modality: { item: {} },
        scrfiles: [],
    }); //Patiants list array

    const [form] = Form.useForm();

    const [initialContent, setInitialContent] = useState('');

    const opno = match.params.id;

    let template;

    useEffect(() => {
        getPatientDetails(opno); //Patiant list get function
    }, [template]);

    /**
     *
     * Patient Data
     *
     * @param {*} urlParms
     */
    async function getPatientDetails(opno) {
        setLoading(true);

        setFormloading(true);

        SCRFilesLink.getRecord(opno).then((result) => {

            let values = result;

            setPatient(values.result);

            setInitial({});

            setLoading(false);

            // Parse the json object to prefill the form
            let record = values.result.sflink_otherrefdet1;
            // We have to parse the xml json data to prefill the input fields
            if (record && record.root && record.root.data) {
                abdomenOptions.forEach((value) => {
                    value.fields.map((choice, index) => {

                        let data = record.root.data[0];
                        // let keys = Object.keys(data);
                        let keys = Object.keys(data);

                        // let matching = keys[0];
                        // let matching = keys.find((ele) => {

                        //     // let entry = Object.keys(ele)[0];

                        //     return ele === choice.code;

                        // });

                        // let matching = record.root.data.find((ele) => ele.code[0] === choice.code);

                        if (data[choice.code] && data[choice.code][0]) {
                            choices[choice.code] = data[choice.code][0];

                            // // Initial Values
                            initialValues[choice.code] = data[choice.code][0];
                            // initialValues[choice.code] = matching[choice.code][0];

                            // Append the choice with location
                            if (data[choice.code + '_Location']) {
                                choices[choice.code + '_Location'] = data[choice.code + '_Location'][0];

                                // Initial values
                                initialValues[choice.code + '_Location'] = data[choice.code + '_Location'][0];
                            }

                            // // Initial Values
                            initialValues[choice.code] = data[choice.code][0];
                        }
                    })
                });
                setInitial({ ...initialValues });

                // Set the choices
                setChoices({ ...choices });
            }

            setFormloading(false);
        });
    }

    /**
     * Once the field is updated , Updating the array of choices
     */
    const onFieldsChange = (fields) => {
        let element = fields[0];

        let name = element.name[0];

        // if (name.indexOf('Location') !== -1 && name.indexOf('Calcification') === -1) {

        //     if (Array.isArray(element.value)) {

        //         choices[name] = element.value.join();

        //     } else {

        //         choices[name] = element.value;

        //     }

        // } else {

        //     choices[name] = element.value;

        // }

        //
        // let matching = abdomenOptions.filter((record) => record.code === name)

        // if (matching && matching[0]) {

        //     if (matching[0].locationSelection) {

        //         if (element.value === 'Yes') {

        //             choices[name + '_Location'] = []
        //         }
        //     }
        // }

        choices[name] = element.value;

        setChoices({ ...choices });
    };

    /**
     *
     * @param {*} values
     */
    const onSubmit = async (values) => {
        // We need to convert the object to an array for easiness to
        // convert to xml

        abdomenOptions.forEach((option) => {

            if (values[option['code']] === 'No') {
                values = {
                    ...values,
                    [option['code'] + '_Location']: null,
                };
            }

        })



        let keys = Object.keys(values);

        let content = keys.map((key) => {
            return { [key]: values[key] };
        });

        setBtnloading(true);

        // let formBody = {
        //     sflink_otherrefdet1: [values],
        //     ReportedTime: moment().format(),
        // };

        let formBody = values

        // values = {
        //     ...values,
        //     resultptr: values.status,
        //     resultdedesc: values.remarks,
        //     user: user.UserName,
        //     doctorptr: user.staffptr,
        //     mode: mode,
        //     op_no: opno || urlParams.opno,
        //     accessionno: urlParams.accessionno,
        //     slno: 10,
        // };

        const result = await SCRFilesLink.update(opno, formBody);

        if (result.success) {

            // setLoading(true);
            setBtnloading(false);

            message.success('Success');

            history.goBack();

        } else {
            // setLoading(true);
            setBtnloading(false);

            message.error(result.message);
        }
    };

    function refresh() {

        getPatientDetails(opno);
    }

    // From the report , get the image and link

    var image_link = {};

    if (report.scrfiles) {
        var reports = report.scrfiles.filter((record) => record.sflink_type === 'IMG');

        if (reports.length) {
            image_link = reports[0];
        }
    }

    // We have to disable the form if the report status is signed off .
    // Only for admin we have to enable it

    let disabled = false;

    if (initial.report_status && user.UserGrp !== 'adm') {
        disabled = true;
    }

    return (
        <section className="patient-details">
            {/* Loading Indicator */}
            {loading ? (
                <Card className="card-shadow card">
                    <Skeleton active />
                </Card>
            ) : (
                <>
                    <div className="page-hero">
                        <div className="card-shadow card">
                            <div className="page-header">
                                <div>
                                    <Title level={3}>Guest Details</Title>

                                    <p className="size-hint">{loading ? 'Loading data' : 'Refer below information and update feedback'}</p>
                                </div>

                                <Button onClick={refresh} type="secondary" size={'small'}>
                                    <ReloadOutlined />
                                </Button>
                            </div>

                            <div gutter={0} className="detail-wrapper">
                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>Ref No</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{patient.sflink_refno}</h3>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>Created at</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{moment(patient.sflink_dttm).format('DD/MM/YYYY')}</h3>
                                    </Row>
                                </div>
                            </div>
                        </div>

                        <div className="report-links">
                            {patient.sflink_filename ? (
                                <a target="_blank" rel="noreferrer" href={patient.sflink_filename} className={`report-link vehicle-card card`}>
                                    <h1>PACS</h1>
                                    View PACS Image
                                </a>
                            ) : null}
                        </div>
                    </div>
                    {formloading ? (

                        <Skeleton />
                    ) : (
                        <>
                            <div className="un-form ">
                                <Form
                                    // {...layout}
                                    form={form}
                                    name="new-record"
                                    onFinish={onSubmit}
                                    layout="vertical"
                                    onFieldsChange={onFieldsChange}
                                    initialValues={initial}
                                >
                                    {/* <Form.Item
                                        name={`OverallImpression`}
                                        label="Overall Impression"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter Overall Impression',
                                            },
                                        ]}>
                                        <TextArea rows={4} />
                                    </Form.Item> */}

                                    {abdomenOptions.map((value) => {


                                        return (
                                            /** suboptions for test */
                                            <div className='suboptions'>
                                                <Card>
                                                    <h1>{value.name ? value.name : null}</h1>

                                                    {value.fields.map((option, index) => {

                                                        return (
                                                            <div className="choice-row">

                                                                <Form.Item
                                                                    name={option.code}
                                                                    // name={option.code}
                                                                    label={option.desc}
                                                                    rules={[
                                                                        {
                                                                            required: option.notRequired ? false : true,
                                                                        },
                                                                    ]}
                                                                >
                                                                    {option.options ? (
                                                                        <Select>
                                                                            {option.options.map((option) => {
                                                                                return <Option value={option}>{option}</Option>;
                                                                            })}
                                                                        </Select>
                                                                    ) : (
                                                                        <TextArea rows={4} />
                                                                    )}
                                                                </Form.Item>

                                                                {(option.locationSelection && choices[option.code] && choices[option.code] === 'Present') ||
                                                                    (option.locationSelection && choices[option.code] && choices[option.code] === 'Yes') ? (
                                                                    <>
                                                                        <Form.Item
                                                                            name={option.locationIndex}
                                                                            label="Location"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: 'Please enter Locationx`',
                                                                                },
                                                                            ]}
                                                                        >
                                                                            {/* {option.desc === 'Calcification' ? */}
                                                                            <Input />
                                                                            {/* : */}
                                                                            {/* 
                                                                    <Select mode="multiple">

                                                                        {
                                                                            ['RU', 'RM', 'RL', 'LU', 'LL'].map((option) => {

                                                                                return (<Option value={option}>
                                                                                    {option}
                                                                                </Option>)

                                                                            })
                                                                        }

                                                                    </Select>
                                                                } */}
                                                                        </Form.Item>
                                                                    </>
                                                                ) : null}
                                                            </div>
                                                        );
                                                    })}
                                                </Card>
                                            </div>
                                        );

                                    })}

                                    {/* <Form.Item
                                        name={'remarks'}
                                        label="Remarks"
                                        rules={[
                                            {
                                                // required: true,
                                                message: 'Please enter remarks',
                                            },
                                    ]}
                                    >
                                        <TextArea rows={4} />
                                    </Form.Item> */}

                                    {/*  */}

                                    <Space>
                                        <Button loading={btnloading} disabled={disabled} type="primary" htmlType="submit" className="submit-button">
                                            SUBMIT
                                        </Button>

                                        <Button
                                            onClick={() => {
                                                history.goBack();
                                            }}
                                            loading={btnloading}
                                            type="secondary"
                                            className="submit-button"
                                        >
                                            Cancel
                                        </Button>
                                    </Space>
                                </Form>
                            </div>
                        </>
                    )}
                </>
            )}
        </section>
    );
}



