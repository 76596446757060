/***
 *
 *
 * @description
 * @author Sneha
 */

import React,{ useEffect, useState, useContext } from 'react';

import { Link } from 'react-router-dom';

import { Bills, RewardTransactions } from '../../models';

import { Card, Typography, Input, Skeleton, message, Empty, Tag } from 'antd';

import { ConfirmModal, DateUtils } from 'soxo-bootstrap-core';

import './create-rewards.scss';

const { Search } = Input;

const { Title } = Typography;

export default function RewardsAuthorise({ match }) {
    //Authorization list array
    const [results, setResults] = useState([]);

    const [loading, setLoading] = useState(true);

    var [query, setQuery] = useState('');

    var [query, setQuery] = useState('');

    useEffect(() => {
        authlist();
    }, []);

    /**
     *  Authorization List
     */
    async function authlist() {
        setLoading(true);

        const autherize = await Bills.getAuthList().then((res) => {
            var authlist = res.result;

            let list = [];

            console.log(authlist);

            authlist.forEach((element) => {
                // if (element.reward_transaction && element.reward_transaction.other_details && element.reference_details && element.coupon_mapped_details) {

                // console.log(element.id, element.reference_details, element.coupon_mapped_details, element.reward_transaction.other_details)
                try {
                    list.push({
                        ...element.reward_transaction,
                        reward_transaction_detail_id: element.id,
                        mode: element.mode,
                        points: element.points,
                        reference_details: JSON.parse(element.reference_details),
                        // coupon_mapped_details: {},
                        coupon_mapped_details: JSON.parse(element.coupon_mapped_details),
                        // other_details: {}
                        other_details: JSON.parse(element.reward_transaction.other_details),
                        created_at: element.created_at,
                    });
                    // } else {
                } catch (error) {
                    console.log(error);

                    console.log(element, element.reference_details);
                }

                //     console.log(element.id, element.reference_details, element.coupon_mapped_details, element.reward_transaction.other_details)

                //     list.push({
                //         ...element.reward_transaction,
                //         reward_transaction_detail_id: element.id, mode: element.mode, points: element.points,
                //         reference_details: JSON.parse(element.reference_details),
                //         coupon_mapped_details: JSON.parse(element.coupon_mapped_details),
                //         // other_details: JSON.parse(element.reward_transaction.other_details)
                //     });

                // }
            });

            setResults(list);
        });

        setLoading(false);
    }

    let filtered = results.filter((record) => {
        query = query.toUpperCase();

        if (query) {
            // console.log("query",query);
            // if(record.opbill){
            if (record.other_details.name.toUpperCase().indexOf(query) != -1) {
                return true;
            } else if (record.other_details && record.other_details.mobile.indexOf(query) != -1) {
                return true;
            } else if (record && record.account_code.indexOf(query) != -1) {
                return true;
            } else if (record && record.account_code.indexOf(query) != -1) {
                return true;
            }

            // }
        } else {
            return true;
        }
    });

    function onSearch(event) {
        setQuery(event.target.value);
    }

    return (
        <section className="customer-rewardsList(opno)">
            <div className="left">
                <Title level={2}> Authorize List</Title>

                <p className="size-hint">{loading ? 'Loading' : results.length} records.</p>
            </div>

            {loading ? (
                <>
                    <Skeleton active />
                </>
            ) : (
                <>
                    <>
                        <div className="page-header">
                            <Search
                                placeholder="Enter Search Value"
                                allowClear
                                style={{ width: 300, marginTop: '10px', marginBottom: '20px' }}
                                onChange={onSearch}
                            />
                        </div>

                        <div>
                            {filtered.length ? (
                                <>
                                    <CardList callback={authlist} datasource={filtered} />
                                </>
                            ) : (
                                <>
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />,
                                </>
                            )}
                        </div>
                    </>
                </>
            )}
        </section>
    );
}

// Card View of Autherization List

function CardList({ datasource, callback }) {
    return datasource.map((value, index) => {
        return (
            <div key={index} className="report-item">
                <Authorisation callback={callback} record={value} />
            </div>
        );
    });
}

/**
 * Authorization List
 *
 * @param {*} param0
 * @returns
 */
function Authorisation({ record, callback }) {
    const [loading, setLoading] = useState(false);

    /**
     * Authorize
     *
     * @param {*} record
     */
    async function onAuthorize(record, callback) {
        setLoading(true);

        record = { ...record, id: record.reward_transaction_detail_id };

        await RewardTransactions.authorize(record, '', record.reward_master_id);

        message.success('Approved');

        console.log(record);

        callback();

        setLoading(false);
    }

    /**
     * Show message according to mode
     *
     * @param {*} record
     * @returns
     */
    function displayMessage(record) {
        const { reference_details, other_details, coupon_mapped_details } = record;

        if (reference_details || other_details) {
            switch (record.mode) {
                case 'GG':
                    return `${other_details.name} has gained ${record.points} points as ${reference_details.name} has completed screening`;
                    break;

                case 'AI':
                    return `Wallet has been activated for ${other_details.name} with OPNO ${record.account_code} with coupon ${coupon_mapped_details.coupon_no} `;

                    break;

                case 'I':
                    return `Coupon ${coupon_mapped_details.coupon_no} has been issues to ${other_details.name} with OPNO ${record.account_code}`;
                    break;

                case 'RD':
                    return `${other_details.name} has redeemed ${record.points} points`;
                    break;

                default:
                    break;
            }
        }
    }

    //
    const { other_details = {} } = record;

    return (
        <Card className="card vehicle-card">
            <div className="title">
                <h1>{displayMessage(record)}</h1>

                {/* <h1>Name : {record.other_details.name}</h1> */}
            </div>

            <div className="values">
                <h4>
                    <p>Name : {other_details.name}</p>
                </h4>
            </div>

            <div className="values">
                <h4>
                    <p>
                        Mode : <Tag color="pink"> {record.mode}</Tag>
                    </p>
                </h4>
            </div>

            <div className="values">
                <h4>
                    <p>Email : {other_details.email}</p>
                </h4>
            </div>

            <div className="values">
                <h4>
                    <p>
                        {' '}
                        OPNO : <Tag color="orange">{record.account_code}</Tag>
                    </p>
                </h4>
            </div>

            <div className="values">
                <h4>
                    {' '}
                    <p>Phone : {other_details.mobile}</p>
                </h4>
            </div>

            <div className="values">
                <h4>
                    <p>Created At : {DateUtils.formatDate(record.created_at)}</p>
                </h4>
            </div>

            <Link to={`/wallet/${record.account_code}`}>View Wallet</Link>

            <div className="modal">
                <ConfirmModal
                    loading={loading}
                    caption={'Authorize'}
                    type="primary"
                    size="big"
                    title="Are you sure you want to Authorize this collection? "
                    callback={(values) => onAuthorize(record, callback)}
                />
            </div>
        </Card>
    );
}
