import React from 'react';

import { useEffect, useState } from 'react';

import { Tag, Row, Button, Popconfirm, Card } from 'antd';

import './bill-info.scss';

export default function BillInfo({ guest = {}, guestop = {} }) {

    // Opreg
    const { opreg = {} } = guest;
    

    return (
        <>

            {/*Guest Information */}
            <div className="guest-info ">

                {/*Guest Detail */}
                <div gutter={0} className="detail-wrapper">
                    <div className="detail-element">
                        <Row >
                            <span>Name</span>
                        </Row>

                        <Row >

                            <span>
                                <strong>
                                    {opreg.op_fname} {opreg.op_lname}
                                </strong>
                            </span>
                        </Row>


                    </div>

                    {/*Payment Status */}
                    <div className="detail-element-card">
                        <Row span={12}>
                            <span>OP No</span>
                        </Row>
                        <Row span={12}>
                            <span>{opreg.op_no ? <Tag color="green">{opreg.op_no}</Tag> : null}</span>
                        </Row>

                    </div>

                    <div className="detail-element">
                        <Row >
                            <span>Address</span>
                        </Row>
                        <Row >
                            <span> <strong>{opreg.opb_add1} </strong></span>
                        </Row>

                    </div>

                    <div className="detail-element">
                        <Row >
                            <span>Contact</span>
                        </Row>
                        <Row >
                            <span> <strong>{opreg.op_mobile} </strong></span>
                        </Row>
                    </div>

                    <div className="detail-element-card">
                        <Row span={12}>
                            <span>Email</span>
                        </Row>
                        <Row span={12}>
                            <span>
                                <strong>
                                    {opreg.op_email}
                                </strong>
                            </span>
                        </Row>
                    </div>

                    {/* Package Name */}
                    {/* <div className="detail-element-card">
                        <Row span={12}>
                            <span>Package</span>
                        </Row>
                        <Row span={12}>
                            <span>
                                {/* <strong>
                                    {guest.drAppointment && guest.drAppointment.item ? guest.drAppointment.item.itm_desc : null}
                                </strong> */}

                    {/* </span> */}
                    {/* </Row> */}
                    {/* </div>  */}


                    {/*Registration Number */}

                    <div className="detail-element-card">
                        <Row span={12}>
                            <span>Registered Status</span>
                        </Row>
                        <Row span={12}>
                            <span>
                                <span>{opreg.op_no ? <Tag color="green">Booked</Tag> : <Tag color="green">Booked</Tag>}</span>

                            </span>
                        </Row>
                    </div>

                    {/*Appointment Time */}

                    {/* <div className="detail-element-card">
                        <Row span={12}>
                            <span>Billed At</span>
                        </Row>
                        <Row span={12}>
                            <span>
                                <strong>
                                    {/* {moment(guest.opb_dt).format("DD/MM/YYYY")} */}
                    {/* </strong> */}
                    {/* </span> */}
                    {/* </Row> */}

                    {/* </div>  */}

                    {/*Payment Status */}
                    {/* <div className="detail-element-card">
                        <Row span={12}>
                            <span>Payment Status</span>
                        </Row>
                        <Row span={12}>
                            {/* <span>{guest.opb_paidflg === 'Y' ? <Tag color="green">Paid</Tag> : <Tag color="orange">Pending</Tag>}</span> */}
                    {/* </Row> */}

                    {/* </div>  */}


                </div>
            </div>


            {/* <Avatar shape="square" size={64} icon={<UserOutlined />} /> */}
        </>
    );

}