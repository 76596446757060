/***
 *
 *
 * @description
 * @author Sameena
 */

/**
 * Scenarios to handle
 *
 * 1. New Guest : Create Doctor Appointment and Create Prereg
 *
 * 2. Create OPreg
 *
 * 3. Edit Prereg/ DoctorAppointment
 *
 * 4. Edit opreg
 */

import React, { useState, useEffect } from 'react';

import {
    Skeleton,
    Row,
    Col,
    Button,
    Input,
    Form,
    Select,
    Card,
    Typography,
    Space,
    message,
    Checkbox,
    Popconfirm,
    Modal,
} from 'antd';

import { DateUtils, Location, ReferenceSelect, InputComponent } from 'soxo-bootstrap-core';

import moment from 'moment';

import { Registrations, Customers, Referrers, Appointments, Items, Prereg, Opreg } from './../../../../models/';

import AgeSelection from '../../../candidate-visit/multiple-candidate/components/age-selection';

import './guest-details.scss';

const { Option } = Select;

const { Title, Text } = Typography;

const { TextArea } = Input;

const formItemLayout = {
    labelCol: {
        // xs: { span:0 },
        // sm: { span: 0},
    },
    wrapperCol: {
        // xs: { span: 0},
        // sm: { span: 0},
    },
    layout: 'vertical',
};

const title = ['Mr', 'Ms', 'Mrs', 'Dr'];

export default function GuestDetails(props) {
    
    const [btnloading, setBtnLoading] = useState(false);

    const [loading, setLoading] = useState(true);

    const [form] = Form.useForm();

    const [billno, setBillno] = useState([]);

    const [modes, setModes] = useState([]);

    const [selected, setSelected] = useState({});

    const [corporate, setCorporate] = useState(false);

    const [list, setList] = useState([]);

    const [countries, setCountrys] = useState([]);

    const [city, setCity] = useState([]);

    const [coupon, setCoupon] = useState(null);

    const [visible, setVisible] = useState(false);

    const [item, setItem] = useState({}); //Get Package Details

    var urlParams = Location.search();

    useEffect(() => {
        getCustomers().then(() => {
            var params = Location.search();

            // If redirect is appointment and there is da_id in url
            if ((params.da_id && !params.prereg_opno) || (params.da_id && params.redirect === 'appointment')) {
                getAppointment(params.da_id);

                // If appointment id is present , recdirect is registration
            } else if (params.da_id && params.redirect === 'registration') {
                // If registration edit
                getRegistration(params.prereg_opno);
            } else if (params.op_no) {
                // Billng from registration
                getRegistration(params.op_no);
            } else {
                setLoading(false);
            }

            //To get selected package
            getSelectedPackage(params.da_packagetypevalue);

            // Get the communication Mode
            getCommunicationMode();

            //Get Country list
            getCountries();

            //Get City list
            // getCityList();

            //Get Item Details
            getItems();
            // getReferrers();
        });
    }, []);

    //Get country list
    function getCountries() {
        Appointments.getCountries().then((result) => {
            setCountrys(result.result);
        });
    }

    //Get Communication mode
    function getCommunicationMode() {
        Appointments.getCommunication().then((result) => {

            setModes(result);
        });
    }

    /**
     * Get city list of selected country
     */
    function getCityList(value) {
        return Appointments.getCityList(value).then((result) => {

            setCity(
                result.map((record) => {
                    return {
                        label: record,
                        value: record,
                    };
                })
            );
        });
    }
    /**
     * Get the details of selected package
     */
    function getItems() {
        Items.getItems(urlParams.da_packagetypevalue).then((result) => {

            setItem(result);
        });
    }

    //To save Coupon details
    var total, couponno, couponvalue, billvalue;


    /**
     *Get preReg for Registration
     *
     * @param {*} da_id
     */

    function getAppointment(da_id) {
        Appointments.getRecord(da_id).then((res) => {
            var result = res.drAppntmnt;
            var prereg = result.preReg;
            //For Appointments whwere coupon is once applied
            let initial = {
                op_title: prereg.prereg_title,
                op_fname: prereg.prereg_fname,
                op_lname: prereg.prereg_lname,
                op_gender: prereg.prereg_gender,
                op_age: prereg.prereg_ageyear,
                op_add1: prereg.prereg_address1,
                op_add2: prereg.prereg_address2,
                op_place: prereg.prereg_city,
                op_landmark: prereg.prereg_landmark,
                op_country: prereg.prereg_country,
                op_state: prereg.prereg_state,
                op_zip: prereg.prereg_zip,
                op_phone: prereg.prereg_phone,
                op_mobile1: prereg.prereg_mobile1,
                op_mobile2: prereg.prereg_mobile2,
                op_email: prereg.prereg_email,
                prereg_id: prereg.prereg_id,
                op_date: moment(prereg.prereg_dt).format('DD/MM/YY hh:mm A'),
                doctorptr: result.da_doctorptr,
                couponno: result.da_couponno, //Initializing Coupon details
                couponvalue: result.da_couponvalue,
                billvalue: result.da_billvalue,
                refr_code: result.da_referrerptr,
                da_remarks: result.da_remarks,
                op_ageyear: prereg.prereg_ageyear,
                op_agemonth: prereg.prereg_agemonth,
                op_ageday: prereg.prereg_ageday,
            };


            var age = {
                op_ageyear: prereg.prereg_ageyear,
                op_agemonth: prereg.prereg_agemonth,
                op_ageday: prereg.prereg_ageday,
            };

            if (urlParams.redirect === 'appointment' && !guest.op_add1 && !guest.op_add2) {
                addressRequired = true;
            }

            //To populate city in Edit
            getCityList(initial.op_country);

            // Date of Birth is Available
            if (prereg.prereg_dob) {
                initial.op_dob = moment(prereg.prereg_dob);
            }

            setGuest({ ...initial });


            if (result.da_couponno && result.da_couponvalue) {
                setCoupon(result.da_couponno);
            }

            setLoading(false);
            // setCouponLoading(false)
        });
    }




    /**
     *
     *
     * @param {*} id
     */
    function getRegistration(id) {
        setLoading(true);

        // Note : For this api , we have changed the endpoint for the time being ,
        // While reviewing the data flow of the application , we have to make necessary
        // Corrections required to load only mandatory data that is needed to complete the operation
        Registrations.getRegistrationRecord(id).then((res) => {
            var result = res.result;
            // var result = res.response;

            if (result) {

                let initial = {

                    // Once we complete standardising the api data flow , 
                    // we can avoid this individual assigning and rely only on 
                    // the api spread
                    pre_registration: res.pre_registration,

                    op_title: result.op_title,
                    op_fname: result.op_fname,
                    op_lname: result.op_lname,
                    op_gender: result.op_gender,
                    op_dob: moment(result.op_dob),
                    op_add1: result.op_add1,
                    op_add2: result.op_add2,
                    op_place: result.op_place,
                    op_state: result.op_state,
                    op_landmark: result.drAppointment ? result.drAppointment.da_landmark : null,
                    op_country: result.op_country,
                    op_zip: result.op_zip,
                    op_phone: result.op_phone,
                    op_mobile1: result.op_mobile,
                    op_mobile2: result.op_mobile2,
                    op_email: result.op_email,
                    op_modeofcommptr: result.op_modeofcommptr,
                    op_iscorporate: null,
                    op_corporateptr: result.op_corporateptr,

                    // HERE opno should be prepopulated as
                    // we are creating a record with the same
                    // op number
                    op_no: result.op_no,
                };

                if (result && result.drAppointment && result.drAppointment.preReg && result.drAppointment.preReg.prereg_id) {
                    initial = {
                        ...initial,
                        prereg_id: result.drAppointment.preReg.prereg_id,

                        da_email: result.drAppointment.preReg.prereg_username,
                    };
                }

                if (urlParams.da_id || urlParams.edit) {
                    initial = {
                        ...initial,
                        op_date: moment(result.op_dt).format('DD/MM/YYYY '),
                        op_time: result.drAppointment.da_aptime,
                    };
                }

                getCityList(initial.op_country);

                if (result.op_iscorporate === 'Y') {
                    initial.op_iscorporate = result.op_iscorporate;
                }

                setGuest({ ...initial });

                setLoading(false);

                // setCouponLoading(false)
            } else {
                message.error('We could not load the registration data');
            }
        });
    }

    /**
     * Get customers
     *
     * @returns
     */
    function getCustomers() {
        return Customers.getCustomers().then((result) => {
            setList(result);
        });
    }

    //Initialising Values
    const [guest, setGuest] = useState({
        op_patcatptr: 'GEN',
        op_modeofcommptr: '1',
    });

    var emailExists;

    //To verify Email
    function verifyemail(formBody) {
        return Prereg.verifyemail(formBody).then((result) => {
            if (result.result === 'Already Exist') emailExists = 'y';
            else {
                emailExists = 'n';
            }
        });
    }

    /**
     *Get Selected package
     */
    function getSelectedPackage(da_packagetypevalue) {
        // setLoading(true);

        Items.getRecord(da_packagetypevalue).then((result) => {
            // setLoading(false);

            setSelected(result.result);
        });
    }

    /**
     * on Submit
     * @param {*} formBody
     */

    const onFinish = (formBody) => {

        //Either of Date of Birth or Age should be provided for Appointment
        if (!formBody.op_dob && (!formBody.op_dob.day || !formBody.op_dob.month || !formBody.op_dob.year)) {

            message.error('Please Enter Date of Birth or Age');

            setBtnLoading(false);

            return false;

        } else {
            // For entered date of birth we have to change format .
            // Since the component would return in tz format
            if (formBody.op_dob) {

                formBody.op_dob = DateUtils.formatDate(formBody.op_dob, 'MM/DD/YYYY');

            }
        }

        //For gender validation
        var Gender;

        if (formBody && formBody.op_gender) {
            if (formBody.op_gender === 'M') {
                Gender = 'M';
            } else if (formBody.op_gender.toUpperCase() === 'MALE') {
                Gender = 'M';
            }
            // else if (formBody.op_gender === 'MALE') {
            //     Gender = 'M';
            // } 
            else if (formBody.op_gender === 'F') {
                Gender = 'F';
            } else if (formBody.op_gender.toUpperCase() === 'FEMALE') {
                Gender = 'F';
            }
            //  else if (formBody.op_gender === 'FEMALE') {
            //     Gender = 'F';
            // }
        }

        //Validation for Gender
        if (genderitm != null) {
            if (Gender != genderitm) {
                message.error('The Selected Gender does not match with the Selected Package Gender. Please Select the Correct Gender');
                return false;
            }
        }

        //verify whether the email exists or not

        setBtnLoading(true);

        var urlParams = Location.search();

        // Edit appointment flow
        if (urlParams.edit) {
            //Redirecting to Appointment Detail After Edit
            if (urlParams.redirect === 'appointment') {
                formBody = {
                    ...formBody,
                    op_place: formBody.op_place ? formBody.op_place : null,
                    da_date: formBody.selected_date,
                    da_time: urlParams.selected_time,
                    da_tokenno: urlParams.token_no,
                    da_packagetypevalue: urlParams.da_packagetypevalue,
                    da_billvalue: item.ir_rate,
                    da_remarks: formBody.da_remarks,
                    da_referrerptr: formBody.refr_code,
                };

                //If coupon is Applied
                if (coupon && coupon.campgnDisMas && coupon.campgnDisMas.cmgdcm_value) {
                    formBody = {
                        ...formBody,
                        da_couponvalue: coupon.campgnDisMas.cmgdcm_value,
                        da_couponno: coupon.cmgdcpn_couponnumber,
                    };
                }

                updateRegistration(formBody)
                    .then((result) => {
                        Location.navigate({ url: `/appointment/${urlParams.da_id}` });
                    })
                    .catch(() => {
                        setBtnLoading(false);
                    });
            } else {
                //
                editPreReg(formBody)
                    .then((result) => {
                        if (urlParams.redirect === 'bill') {
                            Location.back();
                        } else {
                            Location.navigate({ url: `/registrations/${urlParams.prereg_opno}` });
                        }
                    })
                    .catch(() => {
                        setBtnLoading(false);
                    });
            }
        } else {
            //If not Edit
            //For Registration
            if (urlParams.da_id) {
                formBody = {
                    ...formBody,
                    da_time: urlParams.selected_time,
                    da_tokenno: urlParams.token_no,
                    da_packagetypevalue: urlParams.da_packagetypevalue,
                };

                // Create Opreg record
                createOPRegistration(urlParams, formBody)
                    .then((result) => {
                        let queryParam = {
                            op_no: result.op_no,
                            opreg_id: result.op_id,
                            ...urlParams,
                        };

                        // If corporate is selected
                        if (result.op_iscorporate) {
                            queryParam = {
                                ...queryParam,
                                op_iscorporate: result.op_iscorporate,
                                op_corporateptr: result.op_corporateptr,
                            };
                        }

                        setBtnLoading(false);
                    })
                    .catch((error) => {
                        message.error('Operation Failed');

                        console.log(error);

                        setBtnLoading(false);
                    });

            } else if (urlParams.op_no) {

                formBody = {
                    ...formBody,

                    // #TODO We have to improve this implementation , da_email

                    da_email: guest.da_email,

                    op_place: formBody.op_place ? formBody.op_place : null,
                    da_time: urlParams.selected_time,
                    da_tokenno: urlParams.token_no,
                    da_packagetypevalue: urlParams.da_packagetypevalue,

                    prereg_username: guest.pre_registration.prereg_username,

                    op_no: guest.op_no,
                };


                // Create dr Appointment
                createdrAppointment(formBody)
                    .then((result) => {
                        if (result.success) {
                            message.success('Appointment Booked Successfully');

                            Location.navigate({ url: `/appointment/${result.da_id}` });
                        } else {
                            message.error(result.message);
                            setBtnLoading(false);
                            return false;
                        }
                    })
                    .catch(() => {
                        setBtnLoading(false);
                    });

            } else {

                var record = {
                    user_name: formBody.op_email,
                };

                verifyemail(record).then(() => {
                    if (emailExists === 'y') {
                        message.error('This Email is Already being used');
                        setBtnLoading(false);
                        return false;
                    }

                    // For Online
                    if (urlParams.mode === 'Online') {
                        // Create a Registration and Move to Bill
                        // #NOTE This option is not applicable now
                        // createOPRegistration(urlParams, formBody);
                    } else if (urlParams.mode === 'Walk In') {
                        // If there is already a prereg, id its mostly because its coming from detail page
                        if (urlParams.prereg_id) {
                            // Create a Registration and Move to Bill
                            // #NOTE This option is not enabled now
                            // createOPRegistration(urlParams, formBody);
                        } else {
                            // Form Body
                            formBody = {
                                ...formBody,
                                da_time: urlParams.selected_time,
                                da_tokenno: urlParams.token_no,
                                da_packagetypevalue: urlParams.da_packagetypevalue,
                            };

                            // Create Pre reg record
                            completeReg(urlParams, formBody)
                                .then((result) => {
                                    let queryParam = {
                                        op_no: result.op_no,
                                        opreg_id: result.op_id,
                                        ...urlParams,
                                    };

                                    // If corporate is selected
                                    if (result.op_iscorporate) {
                                        queryParam = {
                                            ...queryParam,
                                            op_iscorporate: result.op_iscorporate,
                                            op_corporateptr: result.op_corporateptr,
                                        };
                                    }

                                    Location.navigate({ url: `/registrations/${result.dataValues.op_no}`, queryParam });

                                    // Location.navigate({ url: '/new-registration/bill', queryParam });

                                    setBtnLoading(false);
                                })
                                .catch((error) => {
                                    message.error('Operation Failed');

                                    console.log(error);

                                    setBtnLoading(false);
                                });
                        }
                        // } else if (['Call', 'New Customer'].indexOf(urlParams.mode) !== -1) {
                    } else {
                        // } else if (['Call', 'New Customer'].indexOf(urlParams.mode) !== -1) {

                        // Call
                        formBody = {
                            ...formBody,
                            op_place: formBody.op_place ? formBody.op_place : null,
                            da_time: urlParams.selected_time,
                            da_tokenno: urlParams.token_no,
                            da_packagetypevalue: urlParams.da_packagetypevalue,
                            da_packagetype: 'ITM',
                        };

                        // Create pre reg
                        createPreReg(formBody)
                            .then((result) => {
                                if (result.success) {
                                    message.success('Appointment Booked Successfully');
                                    Location.navigate({ url: `/appointment/${result.da_id}` });
                                } else {
                                    message.error(result.message);
                                    setBtnLoading(false);
                                    return false;
                                }
                            })
                            .catch(() => {
                                setBtnLoading(false);
                            });
                    }
                });
            }
        }
    };

    /**
     * Create Reg for Appoitment Flow
     */
    function createOPRegistration(urlParams, formBody) {
        var values = form.getFieldValue();
        var iscorporate = null;

        formBody.op_iscorporate ? (iscorporate = 'y') : (iscorporate = 'n');

        var params = {
            op_title: values.op_title,
            op_fname: values.op_fname,
            op_lname: values.op_lname,
            op_gender: values.op_gender,
            op_add1: values.op_add1,
            op_add2: values.op_add2,
            op_place: values.op_place && values.op_place ? values.op_place : null,
            op_ageyear: values.op_age,
            op_country: values.op_country,
            op_zip: values.op_zip,
            op_phone: values.op_phone,
            // op_dob: moment(values.op_dob).format('MM/DD/YYYY hh:mm A'),
            op_mobile: values.op_mobile1,
            // op_mobile2: values.op_mobile2,
            op_email: values.op_email,
            // op_modeofcommptr: values.op_modeofcommptr,
            // op_iscorporate: iscorporate,
            op_patcatptr: values.op_patcatptr,
            op_corporateptr: values.op_corporateptr,
            op_active: 'Y',
            prereg_id: values.prereg_id,
            da_id: urlParams.da_id,
            op_no: billno,
            op_dt: moment(formBody.da_time, 'DD/MM/YYYY hh:mm a').format('YYYY-MM-DD hh:mm A'), // Appointment Date
            op_time: moment(formBody.da_time, 'DD/MM/YYYY HH:mm a').format('hh:mm a'), // Appointment Time
            op_shiftno: 0,
            op_shiftdt: '2011-07-01T00:00:00.000Z',
            op_finyearid: 1,
            op_natureptr: '`NOR`',
            op_dispage: 'null',

            op_doctorptr: guest.doctorptr,
            op_dob: moment(values.op_dob).format('MM/DD/YYYY'),
            op_modeofcommptr: formBody.op_modeofcommptr,
            op_iscorporate: iscorporate,

            da_date: urlParams.da_date,
        };

        if (formBody.op_corporateptr && formBody.op_corporateptr.value) {
            params = {
                ...params,
                op_corporateptr: formBody.op_corporateptr.value,
            };
        }

        setBtnLoading(true);

        Opreg.createRegistration(params)
            .then((result) => {
                let queryParam;

                queryParam = {
                    op_no: result.op_no,
                    opreg_id: result.op_id,

                    ...urlParams,
                };

                if (iscorporate === 'y') {
                    queryParam = {
                        ...queryParam,
                        op_corporateptr: result.op_corporateptr,
                        op_iscorporate: result.op_iscorporate,
                    };
                }

                // Location.navigate({ url: '/new-registration/bill', queryParam });
                Location.navigate({ url: `/registrations/${result.op_no}` });

                setBtnLoading(false);
            })
            .catch((error) => {
                message.error('Operation Failed');

                console.log(error);

                setBtnLoading(false);
            });
    }

    /**
     * Create Prereg and Reg
     */
    function createPreReg(formBody) {
        var values = form.getFieldValue();

        if (!values.op_add1) values.op_add1 = '';
        if (!values.op_add2) values.op_add2 = '';

        var params = {
            prereg_title: values.op_title,
            prereg_fname: values.op_fname,
            prereg_lname: values.op_lname,
            prereg_gender: values.op_gender,

            // Below changes are included as part of age selection
            prereg_dob: values.op_dob.dob,
            // prereg_age: formBody.op_age,
            prereg_ageday: values.op_dob.day,
            prereg_agemonth: values.op_dob.month,
            prereg_ageyear: values.op_dob.year,

            prereg_address1: values.op_add1,
            prereg_address2: values.op_add2,
            prereg_city: values.op_place ? values.op_place : null,
            prereg_country: values.op_country,
            prereg_phone: values.op_phone,
            prereg_mobile1: values.op_mobile1,
            prereg_mobile2: values.op_mobile2,
            prereg_email: values.op_email,
            prereg_state: values.op_state,
            prereg_landmark: values.op_landmark,

            prereg_packagetypevalue: urlParams.da_packagetypevalue, // 18
            prereg_packagetype: 'ITM', // ITN

            prereg_active: 'Y', //Y

            prereg_zip: values.op_zip,
            da_doctorptr: urlParams.da_doctorptr, // 3,4,5

            da_title: values.op_title, // MR

            da_date: moment(formBody.da_time, 'DD/MM/YYYY hh:mm a').format('YYYY-MM-DD h:mm A'), // Appointment Date
            da_aptime: moment(formBody.da_time, 'DD/MM/YYYY HH:mm a').format('h:mm A'), // Appointment Time

            da_tokenno: formBody.da_tokenno,

            da_remarks: values.da_remarks,

            da_referrerptr: values.refr_code,

            da_packagetype: 'ITM', // ITN
            da_packagetypevalue: formBody.da_packagetypevalue, // 18/ Package Code
        };

        //If coupon is Applied
        if (coupon) {
            params = {
                ...params,
                da_couponno: coupon.coupon_no,
            };
        }

        if (values.op_dob.year && !values.op_dob.year && !values.op_dob.month) {
            params = {
                ...params,
                op_age: values.op_dob.year,
                op_agetype: 'Year',
            };
        } else if (!values.op_dob.year && values.op_dob.month && !values.op_dob.day) {
            params = {
                ...params,
                op_age: values.op_dob.month,
                op_agetype: 'month',
            };
        } else if (!values.op_dob.year && !values.op_dob.month && values.op_dob.day) {
            params = {
                ...params,
                op_age: values.op_dob.day,
                op_agetype: 'Days',
            };
        } else if (values.op_dob.year && values.op_dob.month && values.op_dob.day) {
            params = {
                ...params,
                op_age: values.op_dob.year,
                op_agetype: 'Year',
            };
        }

        if (values.op_dob && values.op_dob.dob && values.op_dob.dob != null) {
            params = {
                ...params,
                op_dob: moment(values.op_dob.dob).format('MM/DD/YYYY'),
            };
        } else {
            params = {
                ...params,
                op_dob: null,
            };
        }

        setBtnLoading(true);
        return Prereg.createPrereg(params);
    }

    /**
     *
     * Single API
     */
    function completeReg(urlParams, formBody) {
        var values = form.getFieldValue();

        var params = {
            prereg_title: values.op_title,
            prereg_fname: values.op_fname,
            prereg_lname: values.op_lname,
            prereg_gender: values.op_gender,

            // Below changes are included as part of age selection
            prereg_dob: values.op_dob.dob,
            // prereg_age: formBody.op_age,
            prereg_ageday: values.op_dob.day,
            prereg_agemonth: values.op_dob.month,
            prereg_ageyear: values.op_dob.year,

            prereg_address1: values.op_add1,
            prereg_address2: values.op_add2,
            prereg_city: values.op_place,
            prereg_country: values.op_country,
            prereg_state: values.op_state,
            prereg_phone: values.op_phone,
            prereg_mobile1: values.op_mobile1,
            prereg_mobile2: values.op_mobile2,
            prereg_email: values.op_email,

            prereg_packagetypevalue: urlParams.da_packagetypevalue,
            prereg_packagetype: 'ITM',

            prereg_zip: values.op_zip,
            prereg_landmark: values.op_landmark,
            da_doctorptr: urlParams.da_doctorptr,
            da_tokenno: formBody.da_tokenno,
            da_aptime: moment(formBody.da_time, 'DD/MM/YYYY HH:mm a').format('h:mm A'),
            da_date: moment(formBody.da_time, 'DD/MM/YYYY HH:mm a').format('YYYY/MM/DD'),
            op_country: values.op_country,
            op_modeofcommptr: '1',
            op_patcatptr: values.op_patcatptr,
            op_iscorporate: values.op_corporateptr ? 'Y' : 'N',
            op_corporateptr: values.op_corporateptr,
            op_remarks: values.da_remarks,

            da_packagetypevalue: urlParams.da_packagetypevalue,
            da_packagetype: 'ITM',
            da_remarks: values.da_remarks,
            // Flag to disable Questionnaire Mail # Note Temporary Setup
            disableAppointmentNotifications: true,
        };

        if (formBody.op_corporateptr && formBody.op_corporateptr.value) {
            params = {
                ...params,
                op_corporateptr: formBody.op_corporateptr.value,
            };
        }
        setBtnLoading(true);

        return Prereg.patientRegistration(params);
    }

    /***
     *
     * Create Dr Appointment
     */

    function createdrAppointment(formBody) {

        var values = form.getFieldValue();

        var params = {
            da_doctorptr: urlParams.da_doctorptr,
            da_date: moment(formBody.da_time, 'DD/MM/YYYY hh:mm a').format('YYYY-MM-DD h:mm A'), // Appointment Date
            da_aptime: moment(formBody.da_time, 'DD/MM/YYYY HH:mm a').format('h:mm A'), // Appointment Time

            da_tokenno: formBody.da_tokenno,
            da_time: formBody.da_time, // Insert time

            da_packagetype: 'ITM', // ITN
            da_packagetypevalue: formBody.da_packagetypevalue, // 18/ Package Code
            da_newopno: urlParams.op_no,

            da_referenceid: guest.prereg_id,

            da_opno: urlParams.op_no, // Have to clarify
            da_remarks: values.da_remarks,

            da_email: values.op_email,

            prereg_username: guest.pre_registration.prereg_username,

        };

        if (coupon && coupon.campgnDisMas) {
            params = {
                ...params,
                da_couponno: coupon.cmgdcpn_couponnumber,
            };
        } else if (!coupon) {
            params = {
                ...params,
                da_couponvalue: null,
                da_couponno: null,
                da_billvalue: null,
            };
        }
        setBtnLoading(true);

        return Appointments.createAppointment(params);
    }

    /**
     * Create Prereg
     */
    function editPreReg(formBody) {
        var urlParams = Location.search();

        var values = form.getFieldValue();
        var iscorporate = null;
        values.op_iscorporate ? (iscorporate = 'y') : (iscorporate = 'n');

        var params = {
            op_title: values.op_title,
            op_fname: values.op_fname,
            op_lname: values.op_lname,
            op_gender: values.op_gender,
            op_dob: formBody.op_dob,
            op_add1: values.op_add1,
            op_add2: values.op_add2,
            op_place: values.op_place && values.op_place.value ? values.op_place.value : null,
            op_country: values.op_country,
            op_zip: values.op_zip,
            op_phone: values.op_phone,
            op_mobile: values.op_mobile1,
            op_email: values.op_email,
            op_modeofcommptr: values.op_modeofcommptr,
            op_iscorporate: iscorporate,
            op_patcatptr: values.op_patcatptr,
            da_remarks: values.da_remarks,
            da_referrerptr: values.refr_code,
            updttm: moment().format('MM/DD/YYYY hh:mm:ss'), // Last Updated
        };

        //If coupon is Applied
        if (coupon && coupon.campgnDisMas) {
            params = {
                ...params,
                da_couponvalue: coupon.campgnDisMas.cmgdcm_value,
                da_couponno: coupon.cmgdcpn_couponnumber,
                da_billvalue: total,
            };
        } else if (!coupon) {
            // If coupon is removed/no coupon is applied
            params = {
                ...params,
                da_couponvalue: null,
                da_couponno: null,
                da_billvalue: null,
            };
        }

        setBtnLoading(true);

        return Opreg.updateOpregDetail(urlParams.prereg_opno, params);
    }

    //Edit Appointment
    function updateRegistration(formBody) {
        var urlParams = Location.search();

        var values = form.getFieldValue();
        var iscorporate = null;
        values.op_iscorporate ? (iscorporate = 'y') : (iscorporate = 'n');
        var params = {
            prereg_title: values.op_title,
            prereg_fname: values.op_fname,
            prereg_lname: values.op_lname,
            prereg_gender: values.op_gender,
            prereg_dob: values.op_dob.dob,
            prereg_address1: values.op_add1,
            prereg_address2: values.op_add2,
            prereg_city: formBody.op_place ? formBody.op_place : null,
            prereg_country: values.op_country,
            prereg_state: values.op_state,
            prereg_phone: values.op_phone,
            prereg_mobile1: values.op_mobile1,
            prereg_mobile2: values.op_mobile2,
            prereg_email: values.op_email,
            prereg_packagetypevalue: urlParams.da_packagetypevalue,
            prereg_packagetype: 'ITM',
            prereg_zip: values.op_zip,
            prereg_landmark: values.op_landmark,
            da_dob: values.op_dob,
            da_doctorptr: urlParams.da_doctorptr,
            da_tokenno: formBody.da_tokenno,
            da_remarks: formBody.da_remarks,
            da_referrerptr: formBody.refr_code,
            prereg_ageday: values.op_dob.day,
            prereg_agemonth: values.op_dob.month,
            prereg_ageyear: values.op_dob.year,

        };

        //If coupon is Applied
        if (coupon && coupon.campgnDisMas) {
            params = {
                ...params,
                da_couponvalue: coupon.campgnDisMas.cmgdcm_value,
                da_couponno: coupon.cmgdcpn_couponnumber,
                da_billvalue: total,
            };
        }
        //If coupon is removed/no coupon is applied
        else if (!coupon) {
            params = {
                ...params,
                da_couponvalue: null,
                da_couponno: null,
                da_billvalue: item.ir_rate,
            };
        }

        if (values.op_dob.year && !values.op_dob.year && !values.op_dob.month) {
            params = {
                ...params,
                op_age: values.op_dob.year,
                op_agetype: 'Year',
            };
        } else if (!values.op_dob.year && values.op_dob.month && !values.op_dob.day) {
            params = {
                ...params,
                op_age: values.op_dob.month,
                op_agetype: 'month',
            };
        } else if (!values.op_dob.year && !values.op_dob.month && values.op_dob.day) {
            params = {
                ...params,
                op_age: values.op_dob.day,
                op_agetype: 'Days',
            };
        } else if (values.op_dob.year && values.op_dob.month && values.op_dob.day) {
            params = {
                ...params,
                op_age: values.op_dob.year,
                op_agetype: 'Year',
            };
        }

        if (values.op_dob && values.op_dob.dob) {
            params = {
                ...params,
                op_dob: moment(values.op_dob.dob).format('MM/DD/YYYY'),
            };
        }

        setBtnLoading(true);

        return Prereg.updateRegistration(urlParams.da_id, params);
    }

    useEffect(() => {
        getOPNO();
        //Patiant list get function
    }, []);

    async function getOPNO() {
        Appointments.getBillNo().then((result) => {
            setBillno(result.no);
        });
    }

    //For DOB dates from today are disabled
    function disabledDates(current) {
        return current > moment().endOf('day');
    }

    //Date of Birth Mandatory for Edit Appointment
    var required = false;

    var addressRequired = false;

    if (urlParams.redirect === 'appointment' && !guest.op_dob) {
        required = true;
    }
    // console.log("here",guest)
    if (urlParams.redirect === 'appointment' && !guest.op_add1 && !guest.op_add2) {
        addressRequired = true;
    }

    var editEnable = true;
    if (urlParams.da_id || urlParams.edit) {
        editEnable = false;
    }

    //To get date and time of the Appointment
    var time;

    var date;

    if (urlParams.edit) {
        if (urlParams.redirect === 'registration') {
            date = guest.op_date;
            time = guest.op_time;
        } else if (urlParams.redirect === 'appointment') {
            date = urlParams.selected_date;
            time = urlParams.selected_time;
        } else if (urlParams.op_no) {
            date = urlParams.selected_time;
            time = null;
        }
    } else if (urlParams.op_no) {
        date = urlParams.selected_time;
        time = null;
    } else {
        date = urlParams.selected_time;
        time = null;
    }

    function couponvisibility() {
        setVisible(true);
    }

    //For validation of gender checking item gender from url queryparams
    var gender;
    var genderitm = null;
    var couponType = null;

    if (urlParams.item_gender === 'M') {
        genderitm = 'M';
    } else if (urlParams.item_gender === 'F') {
        genderitm = 'F';
    } else if (urlParams.item_gender === 'PF') {
        genderitm = 'F';
    } else genderitm = null;

    // Rate of the item 
    billvalue = item.ir_rate;

    // If Coupon is Applied the below logic is used to change the summary in UI . 
    if (coupon) {

        // Rate of the item 
        let itemRate = item.ir_rate;

        // Value of the coupon  
        let couponValue = coupon.coupon_value;

        couponType = coupon.value_type;

        if (couponType === 'P') {

            total = itemRate - (couponValue / 100) * (itemRate);

            couponno = coupon.coupon_no;
            couponvalue = coupon.coupon_value;

            // total = item.ir_rate - coupon.campgnDisMas.cmgdcm_value;
            // couponno = coupon.cmgdcpn_couponnumber;
            // couponvalue = coupon.campgnDisMas.cmgdcm_value;
            billvalue = total;

        } else {

            total = itemRate - couponValue;

            couponno = coupon.coupon_no;
            couponvalue = coupon.coupon_value;

            // total = item.ir_rate - coupon.campgnDisMas.cmgdcm_value;
            // couponno = coupon.cmgdcpn_couponnumber;
            // couponvalue = coupon.campgnDisMas.cmgdcm_value;
            billvalue = total;

        }

        // total = item.ir_rate - coupon.campgnDisMas.cmgdcm_value;
        // couponno = coupon.cmgdcpn_couponnumber;
        // couponvalue = coupon.campgnDisMas.cmgdcm_value;
        // billvalue = total;
    }

    //If coupon is applied previously for edit cases
    if (coupon && guest.couponno) {
        total = item.ir_rate - guest.couponvalue;
        couponno = guest.couponno;
        couponvalue = guest.couponvalue;
        billvalue = total;
    }

    var disabled = false;
    var disabledfield = false;
    if (urlParams.edit || urlParams.da_id) {
        disabled = true;
    }
    if (urlParams.op_no) {
        disabledfield = true;
        disabled = true;
    }

    /**
     * Function to be feeded for reference select
     * to default the referrer for all
     * cases the one which has the lowest refr_code
     *
     * @param {*} options
     * @returns
     */
    function identifyDefaultReferrer(options = []) {
        let initial = 1;

        let matching = {};

        options.forEach((record) => {
            if (initial >= parseInt(record['refr_code'])) {
                initial = parseInt(record['refr_code']);

                matching = record;
            }
        });

        return matching;
    }

    /**
     *  The function for  Tittle is selected the gender should pre populate
     * @param {*} value
     */
    function OnTitleChange(value) {
        var GenderSelected;

        if (value === 'Mr.') {
            GenderSelected = 'M';
        } else if (value === 'Ms.' || value === 'Mrs.') {
            GenderSelected = 'F';
        }
        form.setFieldsValue({ op_gender: GenderSelected });
    }
    /**
     *  In this Function is Used to prepopulate Tittle when gender is selected 
     * @param {*} value 
     */

    function OnGenderChange(value) {
        var tittleSelected;

        if (value === 'F') {
            tittleSelected = 'Ms.';
        } else if (value === 'M') {
            tittleSelected = 'Mr.';
        }
        form.setFieldsValue({ op_title: tittleSelected });

    }
    /**
     *  This function is used for prepopulate  country ,state and city  Based on pincode.
     * if we enter any pincode by using the Api response it prepopulate city And country ,state
     * @param {*} value
     */
    function onChangePincode(value, address2) {
        Appointments.getPincodeDetails(value).then((result) => {
            if (result.data) {
                form.setFieldsValue({
                    op_country: result.data.country,
                    op_place: result.data.city,
                    op_state: result.data.state,
                    op_add2: address2 ? address2 : result.data.post_office,
                });
            }
        });
    }


    /**
     *  Function for Onfield change of form 
     * @param {*} fields 
     */
    function onFieldsChange(fields) {
        if (fields[0].name[0] === 'op_iscorporate') {

            setCorporate(fields[0].value);
        }

        if (fields[0].name[0] === 'op_title') {
            if (fields[0].value !== 'Dr.') {
                OnTitleChange(fields[0].value);
            } else {
            }
        }

        if (fields[0].name[0] === 'op_gender') {
            OnGenderChange(fields[0].value);

        }
        // This condition is check field is op_zip  , This Condition is decide to trigger onChangePincode function
        if (fields[0].name[0] === 'op_zip') {
            let address2 = form.getFieldValue('op_add2');
            form.setFieldsValue({ op_country: null, op_place: null, op_state: null });

            if (fields[0].value.length >= 6) {
                if (address2) {
                    onChangePincode(fields[0].value, address2);
                } else {
                    onChangePincode(fields[0].value);
                }
            }
        }
    }



    /**
     * Function for onvalue change
     * @param {} fields 
     */
    function onValuesChange(fields) {
        gender = Object.values(fields);
        var fieldname = Object.keys(fields);
        if (genderitm != null) {
            if (fieldname[0] === 'op_gender') {
                if (gender[0] != genderitm)
                    // message.warning('The Package you selected is not for this gender. Please make sure before proceeding further')
                    message.warning('You have Selected the Wrong Gender');
            }
        }
    }


    return (
        <div className="guest-details">
            <Card className="card vehicle-card form-step">
                {/* Form Content */}
                <div className="form-content">
                    {!loading ? (
                        <>
                            <div className="page-header">
                                <div>
                                    <Title level={4}>Guest Information #{/* {billno} */}</Title>

                                    <p className="page-desc">Please enter the below details to complete the appointment.</p>
                                </div>
                            </div>

                            <Form
                                {...formItemLayout}
                                form={form}
                                name="register"
                                initialValues={{ ...guest }}
                                onFieldsChange={onFieldsChange}
                                onValuesChange={onValuesChange}
                                onFinish={onFinish}
                                scrollToFirstError
                            >
                                <div className="steps-content">
                                    <>
                                        <Row gutter={20}>
                                            <Col xs={24} md={12} xl={8}>
                                                <Form.Item
                                                    name="op_title"
                                                    label="Title"
                                                    rules={[{ required: false, message: 'Please Select Title' }]}
                                                >
                                                    <Select value={title} disabled={disabledfield}>
                                                        <Option value="Mr.">Mr.</Option>
                                                        <Option value="Ms.">Ms.</Option>
                                                        <Option value="Mrs.">Mrs.</Option>
                                                        <Option value="Dr.">Dr.</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col xs={24} md={12} xl={8}>
                                                <Form.Item
                                                    name="op_fname"
                                                    label="First Name"
                                                    rules={[{ required: true, message: 'Please Enter First Name' }]}
                                                >
                                                    <InputComponent disabled={disabledfield} />
                                                </Form.Item>
                                            </Col>

                                            <Col xs={24} md={12} xl={8}>
                                                <Form.Item
                                                    name="op_lname"
                                                    label="Last Name"
                                                    rules={[{ required: true, message: 'Please Enter Last Name' }]}
                                                >
                                                    <InputComponent disabled={disabledfield} />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={20}>
                                            <Col xs={24} md={8} xl={8}>
                                                <Form.Item
                                                    name="op_gender"
                                                    label="Gender"
                                                    rules={[{ required: true, message: 'Please Select Gender' }]}
                                                >
                                                    <Select disabled={disabled}>
                                                        <Option value="M">Male</Option>
                                                        <Option value="F">Female</Option>
                                                        <Option value="Other">Other</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>

                                            <Col>
                                                {' '}

                                                <Form.Item
                                                    className="op_dob"
                                                    name="op_dob"
                                                    label="Date of Birth"
                                                    rules={[{ required: true, message: 'Please Select Date of Birth ' }]}
                                                >
                                                    <AgeSelection
                                                        form={form}
                                                        field="op_dob"
                                                        ageValues={
                                                            guest.op_ageyear
                                                                ?
                                                                {
                                                                    dob: guest.op_dob,
                                                                    year: guest.op_ageyear,
                                                                    month: guest.op_agemonth,
                                                                    day: guest.op_ageday,
                                                                }
                                                                : null
                                                        }
                                                        appointmentMode={urlParams.mode}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <div>
                                            <Row gutter={20}>
                                                <Col xs={24} md={12} xl={8}>
                                                    <Form.Item
                                                        name="op_mobile1"
                                                        label="Mobile Number"
                                                        rules={[
                                                            { required: true, message: 'Please Enter Mobile Number' },
                                                            {
                                                                pattern: /^(\+\d{1,3}[- ]?)?\d{8,}$/,
                                                                message: 'Please ensure the number is correct',
                                                            },
                                                        ]}
                                                    // hidden={urlParams.edit}
                                                    >
                                                        <InputComponent disabled={disabledfield} />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12} xl={8}>
                                                    <Form.Item
                                                        name="op_mobile2"
                                                        label="Alternate Mobile Number"
                                                        rules={[
                                                            { required: false, message: 'Please Enter Mobile Number' },
                                                            {
                                                                pattern: /^(\+\d{1,3}[- ]?)?\d{8,}$/,
                                                                message: 'Please ensure alternate number is correct',
                                                            },
                                                        ]}
                                                    // hidden={urlParams.edit}
                                                    >
                                                        <InputComponent disabled={disabledfield} />
                                                    </Form.Item>
                                                </Col>
                                                {/* {urlParams.edit || urlParams.da_id ? null : */}
                                                <Col xs={24} md={12} xl={8}>
                                                    <Form.Item
                                                        name="op_email"
                                                        label="E-mail"
                                                        rules={[
                                                            {
                                                                type: 'email',
                                                                message: 'The input is not valid E-mail!',
                                                            },
                                                            {
                                                                required: true,
                                                                message: 'Please Enter E-mail!',
                                                            },
                                                        ]}
                                                    >
                                                        <InputComponent disabled={disabled} mode={"lowercase"} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row gutter={20}>
                                                <Col xs={24} md={12} xl={8}>
                                                    <Form.Item
                                                        name="op_zip"
                                                        label="PIN Code"
                                                        rules={[
                                                            { required: false },
                                                            {
                                                                pattern: /(^\d{6}$)|(^\d{5}-\d{4}$)/,
                                                                message: 'Please enter valid Pin Code ',
                                                            },
                                                        ]}
                                                    >
                                                        <InputComponent />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12} xl={8}>
                                                    {addressRequired ? <small style={{ color: 'orange' }}>Address Entry is Pending</small> : null}
                                                    <Form.Item
                                                        name="op_add1"
                                                        label="Address1"
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message: 'Please Enter Primary Address',
                                                            },
                                                            {
                                                                max: 100,
                                                                message: 'Length cannot exceed 100 characters !',
                                                            },
                                                            // {
                                                            //     pattern: /^[ A-Za-z0-9_@.,/#()&-]*$/, //  /^[ A-Za-z0-9_@./#()&-]*$/,
                                                            //     message: 'Enter Valid Address!',
                                                            // },
                                                        ]}
                                                    >
                                                        <InputComponent disabled={disabledfield} />
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={24} md={12} xl={8}>
                                                    {addressRequired ? <small style={{ color: 'orange' }}>Address Entry is Pending</small> : null}
                                                    <Form.Item
                                                        name="op_add2"
                                                        label="Address2"
                                                        rules={[
                                                            {
                                                                required: false,
                                                                message: 'Please Enter Secondary Address',
                                                            },
                                                            {
                                                                max: 200,
                                                                message: 'Length cannot exceed 200 characters!',
                                                            },
                                                            {
                                                                pattern: /^[ A-Za-z0-9_@.,/#()&-]*$/, // /^[ A-Za-z0-9_@./#()&-]*$/,
                                                                message: 'Enter Valid Address!',
                                                            },
                                                        ]}
                                                    >
                                                        <InputComponent disabled={disabledfield} />
                                                    </Form.Item>
                                                </Col>

                                            </Row>

                                            <Row gutter={20}>
                                                <Col xs={24} md={12} xl={8}>
                                                    <Form.Item
                                                        name="op_country"
                                                        label="Country"
                                                        rules={[{ required: false, message: 'Please Enter Country' }]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>

                                                <Col xs={24} md={12} xl={8}>
                                                    <Form.Item
                                                        name="op_state"
                                                        label="State"
                                                        rules={[{ required: false, message: 'Please Enter State' }]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12} xl={8}>
                                                    <Form.Item
                                                        name="op_place"
                                                        label="City/Town"
                                                        rules={[{ required: false, message: 'Please Enter City/Town' }]}
                                                    >
                                                        <Input />
                                                    </Form.Item>
                                                </Col>
                                            </Row>

                                            <Row gutter={20}>
                                                <Col xs={24} md={12} xl={8}>
                                                    <Form.Item label="Referrer" name="refr_code">
                                                        <ReferenceSelect
                                                            field="refr_code"
                                                            config={{
                                                                limit: 50,
                                                            }}
                                                            defaultValueCondition={identifyDefaultReferrer}
                                                            allowClear
                                                            label="refr_desc"
                                                            mode="nura-base"
                                                            model={Referrers}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12} xl={8}>
                                                    <Form.Item
                                                        name="op_landmark"
                                                        label="Land Mark"
                                                        rules={[
                                                            {
                                                                max: 250,
                                                                message: 'Length cannot exceed 250 characters!',
                                                            },
                                                        ]}
                                                    >
                                                        <InputComponent placeholder="Enter Land Mark" />
                                                    </Form.Item>
                                                </Col>
                                                <Col xs={24} md={12} xl={8}>
                                                    <Form.Item
                                                        name="da_remarks"
                                                        label="Remarks"
                                                        rules={[
                                                            {
                                                                max: 250,
                                                                message: 'Length cannot exceed 250 characters!',
                                                            },
                                                        ]}
                                                    >
                                                        <InputComponent placeholder="Enter Remarks" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            {urlParams.mode || (urlParams.edit && urlParams.redirect === 'appointment') ? null : (
                                                <>
                                                    <Row gutter={20}>
                                                        <Col xs={24} md={12} xl={8}>
                                                            <Form.Item
                                                                name="op_modeofcommptr"
                                                                label="Communication Mode"
                                                                rules={[{ required: true, message: 'Please Enter Communication Mode' }]}
                                                            >
                                                                <Select value={title} disabled={disabledfield}>
                                                                    {modes.map((mode) => {
                                                                        return <Option value={mode.comt_code}>{mode.comt_desc}</Option>;
                                                                    })}
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>

                                                        <Col xs={24} md={12} xl={8}>
                                                            <Form.Item
                                                                name="op_patcatptr"
                                                                label="Category"
                                                                rules={[{ required: false, message: 'Please Enter Category' }]}
                                                            >
                                                                <Select disabled={disabledfield}>
                                                                    <Option value="GEN">General</Option>
                                                                </Select>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>

                                                    <Row gutter={20}>
                                                        <Col xs={24} md={12} xl={8}>
                                                            <Form.Item
                                                                valuePropName="checked"
                                                                name="op_iscorporate"
                                                                rules={[{ required: false, message: 'Is this a Corporate Customer' }]}
                                                            >
                                                                <Checkbox disabled={disabledfield}>Is this a Corporate Customer?</Checkbox>
                                                            </Form.Item>
                                                        </Col>

                                                        {corporate || guest.op_corporateptr ? (
                                                            <Col xs={24} md={12} xl={8}>
                                                                <Form.Item
                                                                    name="op_corporateptr"
                                                                    label="Corporate"
                                                                    rules={[{ required: false, message: 'Is this a Corporate Customer' }]}
                                                                >
                                                                    <Select
                                                                        allowClear
                                                                        style={{ width: '100%' }}
                                                                        placeholder="Choose Company"
                                                                        disabled={disabledfield}
                                                                    >
                                                                        {list.map((item, key) => (
                                                                            <Option key={key} value={item.cc_code}>
                                                                                {item.cc_desc}
                                                                            </Option>
                                                                        ))}
                                                                    </Select>
                                                                </Form.Item>
                                                            </Col>
                                                        ) : null}
                                                    </Row>
                                                </>
                                            )}
                                        </div>
                                    </>
                                </div>
                                <Space>
                                    <Button loading={btnloading} type="primary" htmlType="submit">
                                        Save
                                    </Button>

                                    {/* <Button type="secondary">Clear</Button> */}
                                </Space>
                            </Form>
                        </>
                    ) : (
                        <Skeleton />
                    )}
                </div>

                {/* Form Content Ends */}
            </Card>

            {/* Package Details or Other Content */}
            <div className="right-section">
                <div className="card card-shadow">
                    <div className="section-header">
                        <Title level={5}>Package</Title>
                    </div>

                    {loading ? (
                        <Skeleton />
                    ) : (
                        <>
                            {/*  */}
                            <div className="section-content">
                                <div className="section-row">
                                    <div className="section-label">
                                        <Text>
                                            <strong>{selected.itm_desc}</strong>
                                        </Text>
                                    </div>
                                    <div className="section-value">
                                        <strong>{item.ir_rate}</strong>
                                    </div>
                                </div>
                                {(urlParams.da_id && urlParams.redirect === 'registration') ||
                                    (urlParams.da_id && urlParams.token_no) ||
                                    urlParams.db_ptr ? null : (
                                    <div className="section-row">
                                        <div className="section-label">
                                            <Text type="secondary">Coupon Discount</Text>
                                        </div>
                                        <div className="section-value">
                                            {coupon && couponvalue ? (
                                                <>

                                                    {couponType === 'P' ? <strong>{couponvalue} %</strong> : <strong>{couponvalue} Amount</strong>}


                                                    {urlParams.edit !== true ? <Popconfirm
                                                        title="Are you sure you want to Cancel the Coupon ? "
                                                        onConfirm={() => {
                                                            setCoupon(null);
                                                        }}
                                                        className="cancel-coupon-button"
                                                        onCancel={() => { }}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Button size="small" className="cancel-coupon-button">
                                                            Cancel
                                                        </Button>
                                                    </Popconfirm> : null}

                                                    {/* <Popconfirm
                                                        title="Are you sure you want to Cancel the Coupon ? "
                                                        onConfirm={() => {
                                                            setCoupon(null);
                                                        }}
                                                        className="cancel-coupon-button"
                                                        onCancel={() => { }}
                                                        okText="Yes"
                                                        cancelText="No"
                                                    >
                                                        <Button size="small" className="cancel-coupon-button">
                                                            Cancel
                                                        </Button>
                                                    </Popconfirm> */}
                                                </>
                                            ) : (
                                                <Button
                                                    size="small"
                                                    // type="ghost"
                                                    // shape="round"
                                                    onClick={couponvisibility}
                                                    disabled={urlParams.mode === 'Walk In' || urlParams.edit}
                                                >
                                                    {' '}
                                                    Apply Coupon
                                                </Button>
                                            )}
                                        </div>
                                    </div>
                                )}

                                <div className="section-row">
                                    <div className="section-label">
                                        <Text>Total</Text>
                                    </div>
                                    <div className="section-value">
                                        <strong>{billvalue}</strong>
                                        {/* <strong>{coupon && total ? total : item.ir_rate}</strong> */}
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>

                {/* Mode and Time */}
                <div className="card card-shadow">
                    <div className="section-header">
                        <Title level={5}>Mode</Title>
                    </div>

                    <div className="section-content">
                        {/* {urlParams.mode ?
                             <div className="section-row">
                                 <div className="section-label">
                                     <Text type="secondary">Type</Text>
                                 </div>
                                 <div className="section-value">
                                     <strong>
                                         <Tag color="green">{urlParams.mode}</Tag>
                                     </strong>
                                 </div>
                             </div> : null} */}

                        <div className="section-row">
                            <div className="section-label">
                                <Text type="secondary">Time</Text>
                            </div>
                            {/* {urlParams.redirect === "appointment" ?
                                 <div className="section-value">
                                     <strong>
                                         {urlParams.selected_date} {urlParams.selected_time}
                                     </strong>
                                 </div> : */}

                            <div className="section-value">
                                <strong>
                                    {date} {time}
                                </strong>
                            </div>
                        </div>
                        {editEnable ? (
                            <div className="section-label">
                                <Popconfirm
                                    title="Are you sure you want to edit your Appointment date and time ? "
                                    onConfirm={() => {
                                        urlParams.op_no
                                            ? Location.navigate({
                                                url: `/new-registration/slots?da_packagetypevalue=${selected.itm_code}&op_no=${urlParams.op_no}&da_doctorptr=${urlParams.da_doctorptr}&item_gender=${urlParams.item_gender}`,
                                            })
                                            : Location.navigate({
                                                url: `/new-registration/slots?da_packagetypevalue=${selected.itm_code}&mode=${urlParams.mode}&da_doctorptr=${urlParams.da_doctorptr}&item_gender=${urlParams.item_gender}`,
                                            });
                                    }}
                                    onCancel={() => { }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button size="small">Edit</Button>
                                </Popconfirm>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>

            <Modal
                destroyOnClose={true}
                footer={null}
                title="Apply Coupon"
                visible={visible}
                okText="Okay"
                onOk={() => {
                    setVisible(false);
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <CouponModal item={item} setVisible={setVisible} setCoupon={setCoupon} />
            </Modal>
        </div>
    );
}

function CouponModal({ setVisible, setCoupon, item }) {
    const [loading, setLoading] = useState(false);

    const onFinish = (values) => {
        setVisible(false);

        Appointments.applyCoupon(values.coupon, item.itm_code)
            .then((result) => {

                setLoading(false);

                if (result.success) {

                    let valid = validateCoupon(result.data, item);

                    if (valid) {

                        message.success(result.message);

                        setCoupon(result.data);

                    } else {

                        message.error("Invalid Coupon");

                    }

                } else {

                    message.error(result.message);

                }

            })
            .catch((error) => {
                setLoading(false);

                message.error('Invalid Coupon');
            });
    };



    return (
        <>
            <p>Mention the Coupon</p>

            <Form name="basic" initialValues={{ remember: true }} onFinish={onFinish}>
                <Form.Item label="Coupon" name="coupon" rules={[{ required: true, message: 'Please Enter Coupon' }]}>
                    <InputComponent />
                </Form.Item>
                <Form.Item name="Remarks" label="Remarks" rules={[{ required: true, message: 'Please Enter Reamrks' }]}>
                    <TextArea rules={[{ required: true, message: 'Please enter your remarks' }]} />
                </Form.Item>

                <Form.Item>
                    <Button loading={loading} type="primary" htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}


/**
 * To Do 
 * 
 * This is a temporary logic , This should ideally be in the backend 
 * 
 * 
 */
function validateCoupon(coupon, item) {

    let valid = false;

    let total, billvalue;

    // If Coupon is Applied the below logic is used to change the summary in UI . 
    if (coupon) {

        // Rate of the item 
        let itemRate = item.ir_rate;

        // Value of the coupon  
        let couponValue = coupon.coupon_value;

        let couponType = coupon.value_type;

        if (couponType === 'P') {

            total = itemRate - (couponValue / 100) * (itemRate);

            // couponno = coupon.coupon_no;
            // couponvalue = coupon.coupon_value;

            // total = item.ir_rate - coupon.campgnDisMas.cmgdcm_value;
            // couponno = coupon.cmgdcpn_couponnumber;
            // couponvalue = coupon.campgnDisMas.cmgdcm_value;
            billvalue = total;

            if (billvalue >= 0) {
                valid = true;
            } else {
                valid = false;
            }


        } else {

            total = itemRate - couponValue;

            billvalue = total;

            if (billvalue >= 0) {
                valid = true;
            } else {
                valid = false;
            }


        }
    }

    return valid;

}