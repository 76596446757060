/**
 *
 * This should ideally be satisfied by a generic listing page
 *
 *
 */

import React, { useState, useEffect, useContext } from 'react';

//
import {
    Card,
    Space,
    DatePicker,
    Switch,
    Form,
    Button,
    Typography,
    Table,
    Skeleton,
    Select,
} from 'antd';

import { Link } from 'react-router-dom';

import { SCRFilesLink } from '../../../../models';

import { GlobalContext, Location } from 'soxo-bootstrap-core';

import moment from 'moment-timezone';

import './un-patient-list.scss';

import { ReloadOutlined, OrderedListOutlined, PicCenterOutlined, CaretLeftOutlined, CaretRightOutlined, DeleteOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const { RangePicker } = DatePicker;

const { Option } = Select;

let defaultValue = {
    value: { mod_code: 'all' },
    label: 'All',
};

const filterOptions = [
    {
        label: 'All',
        value: 'all',
    },
    {
        label: 'Reported',
        value: 'reported',
    },
    {
        label: 'Unreported',
        value: 'unreported',
    },
];

/**
 * Patient List
 *
 * @param {*} match
 * @returns
 */
export default function PatientList({ match, caption, redirect, label }) {

    // location params
    var param = Location.search();

    const [range, setRange] = useState([
        moment.tz(param.start_time, 'Asia/Calcutta').startOf('month'),
        moment.tz(param.end_time, 'Asia/Calcutta').endOf('day'),
    ]);


    const { user = {}, isMobile } = useContext(GlobalContext);

    const [pending, setPending] = useState(filterOptions[2]);

    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const [page, setPage] = useState(1); // Pagination number

    const [limit, setLimit] = useState(20);

    const [patients, setPatients] = useState([]); //Patients list array

    const [filterEnabled, setFilterEnabled] = useState(false);

    const [view, setView] = useState(isMobile ? true : false); //Need to check this condition

    const [subModality, setSubModality] = useState('');

    const [suboptions, setSuboptions] = useState([]);

    useEffect(() => {
        // On url update load the data
        loadData();

        // Load data with the updated params
        // getPatientDetails(params);
    }, [match.params]);

    /**
     * Load the data
     */
    function loadData() {
        let urlParams = Location.search();

        // Match params
        let params = {
            start_time: range[0].format('YYYY-MM-DD'),
            end_time: range[1].format('YYYY-MM-DD'),
            limit: 20,
            modality: 'CT1',
            ...urlParams,
        };

        loadGuestRecords(params);
    }

    /**
     * load the data
     *
     * @param {*} params
     */
    function loadGuestRecords(params) {
        //
        var submodality = Location.search().submodality;

        // var submodality = "'CTKGB001','CTLAAS001'"

        setLoading(true);

        // Load the data
        SCRFilesLink.loadData(params).then((result) => {
            var arr = [];

            if (!Array.isArray(result.result)) {
                // message.error('')

                setPatients([]);
            } else {
                let updated = result.result.map((record) => {
                    return {
                        ...record,
                        label: label,
                    };
                });

                // Guests
                setPatients(updated);
            }

            setLoading(false);
        });
    }

    /**
     * On Page Change
     *
     * @param {*} data
     */
    function onPagination(page, limit) {
        setPage(page);

        setLimit(limit);

        Location.search({ page, limit });
    }

    /**
     * On change of datetime
     *
     * @param {*} dt
     */
    function updateTime(dt) {
        setRange(dt);
        setSubModality('');
        setFilterEnabled(false);

        Location.search({
            start_time: moment(dt[0]).format('MM/DD/YYYY'),
            end_time: moment(dt[1]).format('MM/DD/YYYY'),
        });
    }

    /**
     * Change view
     *
     * @param {*} result
     */
    function changeView(result) {

        setView(result);
    }

    /**
     * Refresh the
     */
    function refresh() {
        setSubModality('');
        setFilterEnabled(false);

        loadData();
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            // render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1,
            render: (value, item, index) => (page - 1) * limit + index + 1,

            // render: (value, item, index) => page * index + 1,
        },

        {
            title: 'Case Number',
            dataIndex: 'sflink_refno',
            key: 'sflink_refno',
        },
        // {
        //     title: 'Age',
        //     dataIndex: 'Age',
        //     key: 'age',
        // },

        {
            title: 'Time',
            // dataIndex: 'sflink_dttm',
            render: (ele) => {
                return ele.sflink_dttm ? moment(ele.sflink_dttm).format('DD/MM/YYYY') : null;
            },
            key: 'sflink_dttm',
        },

        // {
        //     title: 'Tech Remarks',
        //     dataIndex: 'TechDesc',
        //     key: 'accessionno',
        // },
    ];

    columns.push({
        title: '',
        key: 'action',
        fixed: 'right',
        render: (text, record) => {
            let isPresent = false;

            if (text.DrResult) {
                isPresent = true;
            }

            // let redirect = 'patient-view';

            if (record.Modality === 'Kidney Gall Bladder Stones') {
                redirect = 'abdomen-details';
            }

            return (
                <Space size="middle">
                    {/* ${record.MODE} */}
                    <Link to={`/${redirect}/${record.sflink_id}`}>View</Link>
                </Space>
            );
        },
    });

    /**
     * Function would filter the patients according to if its reported 
     * or not . Controlled by the state.
     * 
     * @param {*} patients 
     * @param {*} filterEnabled 
     */
    function filterMatchingDoctors(data, filterEnabled) {

        console.log("filterEnabled", filterEnabled);

        console.log("dataaa", data);

        return data.filter((item) => {

            // Returns all data
            if (filterEnabled === 'all') {
                return true
            } else {

                // Variable to check if there is results entered by the doctor
                let matchingDoctorEntries = [];

                // If the item has result entered by the doctor , we should mark it as reported 

                // Cross check to ensure
                if (item.sflink_otherrefdet1 && item.sflink_otherrefdet1.root && item.sflink_otherrefdet1.root.data) {

                    matchingDoctorEntries = item.sflink_otherrefdet1.root.data;


                    matchingDoctorEntries = matchingDoctorEntries.filter((entry) => {

                        // console.log("entryy", entry);
                        if (entry.ReportingRadiologistName[0]) {
                            // To check if the name is matching
                            return entry.ReportingRadiologistName[0] === user.name
                        } else {
                            return false;
                        }

                    });

                    if (filterEnabled === 'reported') {

                        // Checking if matchingDoctorEntries is there, if it exists ,returns reported cases
                        if (matchingDoctorEntries.length) {
                            return true;
                        } else {
                            return false;
                        }

                    } else {

                        if (matchingDoctorEntries.length) {
                            return false;
                        } else {
                            return true;
                        }
                    }

                    // }
                } else {

                    if (filterEnabled === 'reported') {
                        return false

                    } else {
                        return true;
                    }
                }
            }
        })
    }

    // Filter the patients according to filter value
    var dataSource = filterMatchingDoctors(patients, pending.value);

    return (
        <section className="patient-list">
            {/* Page Header */}
            <div className="page-header">
                <div className="left">
                    <Title style={{ marginBottom: '0px' }} level={3}>
                        UN List
                    </Title>
                </div>

                <div className="right">
                    <div style={{ marginRight: '10px' }} className="modality-name">
                        {/* <div className="action-header">Filter</div>
                        <Select
                            options={filterOptions}
                            labelInValue={'mod_desc'}
                            value={pending}
                            onChange={(value) => {
                                setPending(value);
                            }}
                            defaultValue={pending}
                            style={{ width: 120 }}
                        ></Select> */}
                        <div className="action-header">Filter</div>
                        <Select
                            options={filterOptions}
                            labelInValue={'mod_desc'}
                            value={pending}
                            onChange={(value) => {
                                setPending(value);
                            }}
                            // defaultValue="all"
                            style={{ width: 120 }}
                        ></Select>
                    </div>

                    <div className="date-and-fltr">
                        <div className="action-header">Date</div>

                        <RangePicker
                            allowClear={false}
                            inputReadOnly
                            format={'DD/MM/YYYY'}
                            value={range}
                            onChange={(time) => {
                                updateTime(time, range);
                            }}
                            ranges={{
                                Today: [moment(), moment()],

                                Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],

                                'This Week': [moment().startOf('week'), moment().endOf('week')],

                                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],

                                'This Month': [moment().startOf('month'), moment().endOf('month')],

                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            }}
                        />

                        <Button onClick={refresh} type="secondary" size={'small'}>
                            <ReloadOutlined />
                        </Button>

                        <Switch
                            defaultChecked={view}
                            onChange={changeView}
                            checked={view}
                            checkedChildren={<OrderedListOutlined />}
                            unCheckedChildren={<PicCenterOutlined />}
                        />
                    </div>
                </div>
            </div>

            {/* Page Header Ends */}

            <p className="size-hint">{loading ? 'Loading' : dataSource.length} records.</p>

            {/* Content Below */}
            {loading ? (
                <>
                    <Skeleton active />
                </>
            ) : (
                <>
                    {view ? (
                        <>
                            <CardList dataSource={dataSource} redirect={redirect} />
                        </>
                    ) : (
                        <>
                            <Card>
                                <Table
                                    scroll={{ x: true }}
                                    rowKey={(record) => record.AccessionNo}
                                    dataSource={dataSource}
                                    columns={columns}
                                    Pagination={true}
                                />{' '}
                            </Card>
                        </>
                    )}
                </>
            )}

            <div className="phycnListpgntn">
                {/* {!loading ? <Pagination pageSize={limit} showSizeChanger={true} onChange={onPagination} current={page} defaultCurrent={page} total={100} /> : null} */}
            </div>
        </section>
    );
}

//Mobile view card Section
function CardList({ dataSource, redirect }) {
    return dataSource.map((item, index) => {
        return (
            <Link key={index} className="report-item" to={`/${redirect}/${item.sflink_id}`}>
                <SCRFilesLink.ResearchCard record={item} />
            </Link>
        );
    });
}
