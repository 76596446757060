/**
 *
 * Component for Previewing reports
 *
 * @description
 * @author Sneha
 *
 */

import React, { useEffect, useState } from 'react';

import './report-preview.scss';

import { Card, ReferenceSelect, PdfViewer } from 'soxo-bootstrap-core';

import { Samples } from '../../../../models';

import { Checkbox, Dropdown, Select, Button, Form, Skeleton, Empty, message } from 'antd';

import { TemplateMasters, Bills } from '../../../../models';

/**
 *
 *
 * @param {*} param0
 * @returns
 */
export default function ReportPreview({ billId, downloadFileName, groupPointer }) {
    // Variable to store labresults
    const [labResults, setlabResults] = useState([]);

    // // Variable to select all values of labresult
    const [checkAll, setCheckAll] = useState(false);

    // Variable to convert BytesArray
    var [byteArray, setByteArray] = useState([]);

    //Variable to handle button loading
    const [btnloading, setBtnLoading] = useState(false);

    //Variable to handle publish button loading
    const [publishLoading, setPublishLoading] = useState(false);

    // Variable to disable and enable button
    const [disable, setDisable] = useState(false);

    const [templateCode, setTemplateCode] = useState();

    const [form] = Form.useForm();

    useEffect(() => {
        loadLabResults(billId);
    }, []);

    /**
     * This is done to preview pdf on first load
     */
    useEffect(() => {
        preview();
    }, [labResults, templateCode]);

    /**
     * Load the lab results with bill id
     *
     * @param {*} billId
     */
    async function loadLabResults(billId) {
        Samples.getWithItem(billId).then((result) => {
            setCheckAll(true);
            let isTrue = true;

            result.map((record) => {
                record.selected = isTrue ? true : false;

                return record;
            });

            setlabResults(result);
        });
    }

    /**
     * Listened for toggle of checkbox
     *
     * @param {*} event
     */
    const onCheckAllChange = (event) => {
        setCheckAll(event.target.checked);

        let isTrue = event.target.checked;

        labResults.map((record) => {
            record.selected = isTrue ? true : false;

            return record;
        });

        !isTrue ? setDisable(true) : setDisable(false);

        setlabResults(labResults);
    };

    /**
     * Function to preview result
     *
     *
     */
    async function preview(values) {
        if (labResults.length && templateCode) {
            setBtnLoading(true);

            let formbody = {
                lab_results: selectedLabResults,
                opb_id: billId,
                uploadFlag: false,
            };

            //If template is selected
            if (values && values.template_code) {
                formbody = {
                    ...formbody,
                    template_code: values.template_code,
                };
            } else {
                //Loading template on preview
                formbody = {
                    ...formbody,
                    template_code: templateCode,
                };
            }

            Samples.getpatientLabResults(formbody).then((result) => {
                setBtnLoading(true);

                if (result && result.data) {
                    setBtnLoading(false);
                    var res = result.data;
                    var bytearray = Object.keys(res);
                    var arrayelement = Object.values(res);

                    var uint8Array = new Uint8Array(bytearray.length);

                    for (var i = 0; i < uint8Array.length; i++) {
                        uint8Array[i] = arrayelement[i];
                    }

                    for (var i = 0; i < bytearray; i++) {
                        var ascii = arrayelement.charCodeAt(i);
                        uint8Array[i] = ascii;
                    }

                    setByteArray({ data: uint8Array });

                    return uint8Array;
                } else {
                    setByteArray([]);

                    setBtnLoading(false);
                }
            });
        }
    }

    if (labResults.length) {
        //Get selected tests
        var selectedLabResults = labResults
            .filter((record) => {
                return record.selected;
            })
            .map((record) => record.lbtrs_id);
    }

    /**
     * Function to toggle state of the lab results
     *
     * @param {*} index
     */
    function selectLabResult(index) {
        labResults[index].selected = !labResults[index].selected;

        setlabResults([...labResults]);

        let enabledResults = labResults.filter((record) => record.selected);

        if (enabledResults.length === labResults.length) {
            setCheckAll(true);
        } else {
            setCheckAll(false);

            setDisable(false);
        }
    }

    /**
     * To publish report
     */

    async function publishReport() {
        var values = form.getFieldValue();

        setPublishLoading(true);

        let formbody = {
            template_code: values.template_code,
            lab_results: selectedLabResults,
            opb_id: billId,
            uploadFlag: true,
        };

        //Upload report to aws
        await Bills.publishReport(formbody).then(() => {
            setPublishLoading(false);
            message.success('Report Published Successfully');
        });
    }

    /**
     *  Load default Template
     * @param {*} options
     * @returns
     */
    function identifyDefaultTemplate(options = []) {
        let matching = {};

        //To load lab report as default template
        if (options) {
            options.forEach(async (record) => {
                if (record.tmpl_groupptr === groupPointer) {
                    matching = record;
                }
            });
        }

        if (matching) {
            setTemplateCode(matching.tmpl_code);
            return matching;
        }
    }
    return (
        <div className="detail-contents">
            <div className="left-sections">
                <div className="top">
                    <Card className="card-shadows card">
                        <div className="billd-records">
                            <div className="test-names">
                                <h2>Test Names</h2>

                                <Checkbox checked={checkAll} onChange={onCheckAllChange} />
                            </div>

                            {/* Test Names */}
                            {labResults.map((entry, key) => {
                                return (
                                    <div
                                        className="card-shadows card"
                                        onClick={() => {
                                            selectLabResult(key);
                                        }}
                                    >
                                        <div className="tests">
                                            <h5>{entry.item.itm_desc}</h5>

                                            <Checkbox checked={entry.selected} defaultChecked />
                                        </div>
                                    </div>
                                );
                            })}
                            {/* Test Names Ends */}
                        </div>
                    </Card>
                </div>

                <div className="bottom">
                    <Card className="card-shadow card" loading={() => <Skeleton />}>
                        {/* Preview Action Form */}
                        <Form onFinish={preview} form={form}>
                            <div>
                                <h2>Report Actions</h2>
                            </div>

                            {/* <div className='labels'>
                                <label>Header Style</label>
                            </div>

                            <Select className='preview-header' placeholder="- Select header style -" /> */}

                            <div className="labels">
                                <label>Template</label>
                            </div>

                            <div className="template">
                                <Form.Item name="template_code">
                                    <ReferenceSelect
                                        config={{
                                            // limit: 50,
                                            queries: [
                                                {
                                                    field: 'tmpl_groupptr',
                                                    value: groupPointer,
                                                },
                                            ],
                                        }}
                                        defaultValueCondition={identifyDefaultTemplate}
                                        field="tmpl_code"
                                        label="tmpl_desc"
                                        mode="nura-base"
                                        model={TemplateMasters}
                                    />
                                </Form.Item>

                                <Form.Item>
                                    <Button disabled={disable} loading={btnloading} className="preview-button" type="primary" htmlType="submit">
                                        Preview
                                    </Button>
                                </Form.Item>

                                <Button disabled={disable} loading={publishLoading} className="preview-button" type="primary" onClick={publishReport}>
                                    Publish Report
                                </Button>
                            </div>
                        </Form>

                        {/* Preview Action Form */}
                        {/* <Select className='select-action' placeholder="- Select Action -" /> */}
                    </Card>
                </div>
            </div>

            <div className="right-sections">
                {/* Report Preview */}
                {(byteArray.data && byteArray.data.length) || btnloading ? (
                    <div className="report-container">
                        {btnloading ? (
                            <>
                                <Skeleton />
                            </>
                        ) : (
                            <div>
                                <PdfViewer url={byteArray} options={downloadFileName} />
                            </div>
                        )}
                    </div>
                ) : (
                    <Empty />
                )}
                {/* Report Preview Ends */}
            </div>
        </div>
    );
}
