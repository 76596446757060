/***
 *
 *
 * @description
 * @author jinshakappil
 */

import React, { useEffect, useState } from 'react';

import { Opvisits } from '../../../../models';

import './visit-info.scss';

import { Skeleton, Typography, Avatar, Row } from 'antd';

import { UserOutlined } from '@ant-design/icons';

import { DateUtils } from 'soxo-bootstrap-core';
const { Title, Text } = Typography;

/**
 * Display the OPREG data of the VISIT
 */
export default function VisitInfo({ visit_id, patient, setPatient }) {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getPatient();
    }, []);

    /**
     * patient personal information get from opreg
     */
    function getPatient() {

        setLoading(true);

        Opvisits.visitInfo(visit_id).then((result) => {
            if (result.result.length) {
                let opreg = result.result[0];

                setPatient(opreg);
            }

            setLoading(false);
        });

    }

    let { opReg = {} } = patient;

    return (
        <>
            {loading ? (
                <Skeleton />
            ) : (
                <div className="card">
                    <div className="visit-info">
                        <Title level={7} style={{ fontSize: '24px' }}>
                            Guest Information
                        </Title>
                        <div className="first-row">
                            <Avatar shape="circle" size={50} icon={<UserOutlined />} />
                            <div className="name-section">
                                {/* >>>>>>> medicant-main */}
                                <Row>
                                    <label className="label-class">
                                        <span className="details">NAME</span>
                                    </label>
                                </Row>
                                <Row style={{ fontSize: '20px' }}>
                                    <span>
                                        <h5>
                                            <strong>
                                                {opReg.first_name} {opReg.last_name}
                                            </strong>
                                        </h5>
                                    </span>
                                </Row>
                            </div>
                        </div>
                        {/** patient personal details section start */}
                        <div className="second-row">
                            {/* <div gutter={0} className="detail-wrapper" > */}
                            <div className="detail-element" style={{ marginRight: '2rem' }}>
                                <Row>
                                    <label className="label-class">
                                        <span className="details">DOB</span>
                                    </label>
                                </Row>
                                <Row className="row-class">
                                    <span>{opReg.dob ? DateUtils.formatDate(opReg.dob) : null}</span>
                                </Row>
                            </div>
                            {/* </div> */}

                            <div className="detail-element" style={{ paddingRight: '2rem' }}>
                                <Row>
                                    <label className="label-class">
                                        <span className="details">AGE</span>
                                    </label>
                                </Row>
                                <Row className="row-class">
                                    <span>{opReg.age}</span>
                                </Row>
                            </div>

                            {/* </div> */}
                            {/* <div className="second-row"> */}
                            <div className="detail-element" style={{ marginRight: '2rem' }}>
                                {/* <div gutter={0} className="detail-wrapper" > */}
                                {/* {/* <div className="detail-element" style={{ paddingRight: '3rem' }}> */}
                                {/* <div className="detail-element"> */}
                                <Row>
                                    <label className="label-class">
                                        <span className="details">BLOOD GROUP</span>
                                    </label>
                                </Row>
                                <Row className="row-class">{opReg.blood_group ? <span>{opReg.blood_group}</span> : '-'}</Row>
                            </div>
                            <div className="detail-element" style={{ paddingRight: '2rem' }}>
                                <Row>
                                    <label className="label-class">
                                        <span className="details">GENDER</span>
                                    </label>
                                </Row>
                                <Row className="row-class">
                                    <span>{opReg.gender}</span>
                                </Row>
                            </div>

                            <div className="detail-element" style={{ paddingRight: '2rem' }}>
                                <Row>
                                    <label className="label-class">
                                        <span className="details">OP NO</span>
                                    </label>
                                </Row>
                                <Row className="row-class">
                                    <span>{opReg.op_no}</span>
                                </Row>
                            </div>

                            <div className="detail-element" style={{ marginRight: '2rem' }}>
                                <Row>
                                    <label className="label-class">
                                        <span className="details">MOBILE</span>
                                    </label>
                                </Row>
                                <Row className="row-class">
                                    <span>{opReg.mobile}</span>
                                </Row>
                            </div>
                            
                            {/* </div> */}

                            <div className="detail-element" style={{ paddingRight: '2rem' }}>
                                <Row>
                                    <label className="label-class">
                                        <span className="details">EMAIL</span>
                                    </label>
                                </Row>
                                <Row className="row-class">
                                    <span>{opReg.email}</span>
                                </Row>
                            </div>
                            
                            
                            <div className="detail-element" style={{ paddingRight: '2rem' }}>
                                <Row>
                                    <label className="label-class">
                                        <span className="details">REGISTERED DOCTOR</span>
                                    </label>
                                </Row>
                                <Row className="row-class">
                                    <span>{patient.registered_doctor}</span>
                                </Row>
                            </div>

                            <div className="detail-element" style={{ marginRight: '2rem' }}>
                                <Row>
                                    <label className="label-class">
                                        <span className="details">VISIT DOCTOR</span>
                                    </label>
                                </Row>
                                <Row className="row-class">
                                    <span>{patient.visit_doctor}</span>
                                </Row>
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
