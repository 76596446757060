/**
 * Imaging model to group all apis related to it
 *
 *
 * To seperate all logic
 *
 */

import React, { useState, useEffect, useContext, useRef } from 'react';
import { Card, Space, Button, Select, Form, Tag, Row, message, Alert, Skeleton, Typography, Input, Statistic, PageHeader, Col, Avatar } from 'antd';

import Model from './models';

import { ApiUtils } from 'soxo-bootstrap-core';

class Imaging extends Model {
    constructor() {
        super();

        this.title = 'Reporting List';

        this.fields = [
            {
                field: 'name',
                caption: 'Name',
            },
            {
                field: 'amount',
                caption: 'Amount',
            },
        ];
    }

    get id() {
        return 'cold_id';
    }

    get getEndpoint() {
        return 'modality';
    }

    get path() {
        return `collections`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `collection`;
    }

    get columns() {
        return [
            {
                caption: 'Staff',
                field: 'staff.name',
                key: 'staff',
            },
            {
                caption: 'Weight',
                field: 'weight',
                key: 'weight',
            },
            {
                caption: 'Date',
                field: 'created_at',
                key: 'created_at',
            },
        ];
    }

    /**
     * Load guests
     *
     * @returns
     */
    loadGuests = ({ start_time, end_time, user, page = 1, modality, submodality, limit = 20, query, mode }) => {
        // Base url to load data
        var url = `modalitygroup/table?mode=${mode}`;

        // if (user.Admin === 'Y') {
        // } else {
        //     url += `&doctorptr=${user.staffptr || 1}`;
        // }

        url += `&page=${page}&processstarttime=${start_time}&processendtime=${end_time}&groupptr=1&resultptr=1`;

        //for stoptb
        //url += '&modality=CT1&submodality=CXRAY';

        //for nura
        if (modality && modality !== 'All' && modality !== 'all') {
            url += `&modality=${modality}`;
        }

        //for nura
        if (modality !== 'All' && submodality) {
            url += `&submodality=${submodality}`;

            // url += `&submodality=${submodality}`;
        }

        if (limit) {
            url += `&limit=${limit}`;
        }

        if (query) {
            url = `modalitygroup/table?mode=RAD&page=${page}&groupptr=1&resultptr=1&limit=${limit}&opno=${query}`;
            // url = `modalitygroup/table?mode=RAD&opno=${query}`;

        }

        return ApiUtils.get({ url });
    };

    /**
     * Load all the lung cases
     *
     * @param {*} param0
     * @returns
     */
    loadLungCases = ({ start_time, end_time, page = 1, limit = 20, submodality = 'CTLAAS001' }) => {
        // Base url to load data
        var url = `modalitygroup/table?mode=RNDR`;

        url += `&page=${page}&processstarttime=${start_time}&processendtime=${end_time}&groupptr=1&resultptr=1`;

        url += `&submodality=${submodality}`;

        if (limit) {
            url += `&limit=${limit}`;
        }

        return ApiUtils.get({ url });
    };

    getDetails = ({ op_no, accessionno, mode }) => {
        return ApiUtils.get({ url: `modalitygroup/Details?op_no=${op_no}&accessionno=${accessionno}&mode=${mode}` });
    };

    getVisitBills = ({ visit_id }) => {
        return ApiUtils.get({ url: `opbill/visit-modality-detail?visit_id=${visit_id}` });
    };

    /**
     * Get the abdoment Details
     * With xml data converted
     *
     * @param {*} param0
     * @returns
     */
    getAbdomenDetails = ({ op_no, accessionno, mode }) => {
        return ApiUtils.get({ url: `modalitygroup/records?op_no=${op_no}&accessionno=${accessionno}&mode=${mode}` });
    };

    update = (formBody) => {
        return ApiUtils.post({ url: 'service-result-tran/updateRemark', formBody });
    };

    getOptions = (itemptr) => {
        return ApiUtils.get({ url: `service-result-map/drop-down/${itemptr}` });
    };
    getTechMaster = () => {
        return ApiUtils.get({ url: 'serviceresultmas/dropdown' });
    };
    getModality = () => {
        return ApiUtils.get({ url: 'modality' });
    };
    getModalityGroupItemGroupMap = (group) => {
        var url = 'modalitygroupitemgroupmap/group?grup_ptr=' + group;
        return ApiUtils.get({ url });
    };
    getTemplates = (item) => {
        return ApiUtils.get({
            url: `radiologytemplatemaster/${item}`,
        });
    };

    /**
     * Load the emr templates
     * # These functions has to go to respective modals
     * We have to analyse how this would evolve to fix on a an approach
     *
     * @param {*} item
     * @returns
     */
    getEMRTemplates = (item,deptPointer) => {
        if(deptPointer){
        return ApiUtils.get({
            url: `templatemas?tmpl_groupptr=${item}&tmpl_depptr=${deptPointer}`,
        });
    }else{
        return ApiUtils.get({
            url: `templatemas?tmpl_groupptr=${item}`,
        });
    }
    };

    getPatientReport = (item) => {
        return ApiUtils.get({
            url: `patientmedicalreports/get-medical-reports?accession_no=${item.accessionno}&module=${item.mode}&doctor_ptr=${item.doctorptr}`,
        });
    };

    /**
     *
     */
    createNew = (id) => {
        return ApiUtils.post({ url: 'opbill/kiosk/' + id });
    };

    GuestCard = GuestCard;

    ResearchCard = ResearchCard;
}

function GuestCard({ record, selected = {} }) {
    console.log(record);
    return (
        <Card className={`card ${selected.AccessionNo === record.AccessionNo ? 'selected' : ''}`}>
            <div className="title">
                <p>
                    {' '}
                    Name :<strong> {record.Name}</strong>
                </p>
            </div>
            <div>
                <p>
                    {' '}
                    Date : <strong>{record.ProcessStartTime}</strong>
                </p>
            </div>
            <div>
                <p>
                    {' '}
                    Age : <strong>{record.Age}</strong>{' '}
                </p>
            </div>
            <div>
                <p>
                    {' '}
                    Gender :
                    <Tag color={record.Gender === 'Male' || record.Gender === 'M' ? 'blue' : 'pink'}>
                        {record.Gender === 'M' || record.Gender === 'Male' ? 'Male' : 'Female'}
                    </Tag>{' '}
                </p>
            </div>
            <div>
                <p>
                    {' '}
                    OP NO :{' '}
                    <Tag color="green">
                        {' '}
                        <strong>{record.opno}</strong>
                    </Tag>
                </p>
            </div>

            <div>
                <p>
                    Modality :{/* <Tag color="magenta">  */}
                    <strong>{record.Modality}</strong>
                    {/* </Tag> */}
                </p>
            </div>
            <p>
                Accession No:<strong>{record.AccessionNo}</strong>{' '}
            </p>

            {record.TechResult ? (
                <p>
                    Tech Result: <strong>{record.TechResult}</strong>
                </p>
            ) : null}
        </Card>
    );
}

function ResearchCard({ record, selected = {} }) {
    return (
        <Card className={`card ${selected.AccessionNo === record.AccessionNo ? 'selected' : ''}`}>
            <h3 className="title">{record.Name}</h3>

            <h4 className="values">
                {record.Age} <Tag color={record.Gender === 'M' ? 'blue' : 'pink'}> {(record.Gender = 'M' ? 'Male' : 'Female')}</Tag> {selected.opno}
            </h4>

            <p>
                <Tag color="magenta">{'LUNG'}</Tag>
            </p>

            <p>Accession : {record.AccessionNo}</p>

            <p className="no-margin">Process started on {record.ProcessStartTime}</p>
        </Card>
    );
}

export default Imaging;
