import React from "react";

import bgimage from "./../../../../assets/images/background_img.png";

import logoimg from "./../../../../assets/images/logo_1.png";

import healthimg from "./../../../../assets/images/health_1.png";

import "./kiosk-screen.scss";


function Newstate() {
  return (
    <div
      className="main_div"
      style={{
        backgroundImage: `url(${bgimage})`,
      }}
    >
      <div className="logo_newstate">
        <img src={logoimg} className="img" alt="logo_pic" />
      </div>
      <div className="healthimg_div">
        <img src={healthimg} alt="health_pic" className="health" />
      </div>
      <div className="content_div">
        <h3 className="message-content">
          YOUR WRIST BAND IS GENERATING.<br></br>KINDLY PLEASE WAIT.
        </h3>
      </div>
    </div>
  );
}
export default Newstate;
