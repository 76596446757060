/***
 *
 *
 * @description
 * @author jinshakappil
 */
import { EditOutlined } from '@ant-design/icons';

import React, { useEffect, useState } from 'react';

import { Clinicals, Opvisits } from '../../../../models';

import { Card, Skeleton, Modal } from 'antd';

import VitalInformation from '../vital-information/vital-information';

import { Location, LineGraph, DateUtils } from 'soxo-bootstrap-core';

/**
 *
 */
export default function ClinicalInformation({ visit_id, histories }) {
    const [loading, setLoading] = useState(true);

    const [clinical, setClinical] = useState({});

    let [history, setHistory] = useState([]);

    let urlParams = Location.search();

    useEffect(() => {
        getClinicalData(visit_id);

        getPatientHistory(urlParams.op_no);
    }, []);

    /**
     * Get the patient history
     *
     * @param {*} op_number
     */
    function getPatientHistory(op_number) {
        setLoading(true);

        Opvisits.getPatientHistory(op_number).then(async (result) => {
            if (result.result.length) {
                let visits = result.result;

                await Promise.all(
                    visits.map((visit) => {
                        return Clinicals.getVitalInformation(visit.visit_id).then((response) => {
                            if (response.result.length) {
                                response.result.forEach((record) => {
                                    history.push({
                                        Cases: record.srvbcd_weight,
                                        Date: DateUtils.formatDate(visit.date),
                                    });
                                });

                                // history = [].concat(history, response.result)
                            }

                            setLoading(false);
                        });
                    })
                );

                console.log(history);

                // let format = history.map((record) => {

                //     return ({
                //         Date: '10/10/2021',
                //         Cases: record.srvbcd_weight
                //     })
                // })

                setHistory(history);
            }

            setLoading(false);
        });
    }

    /**
     * get clinical data
     */
    async function getClinicalData(visit_id) {
        setLoading(true);

        await Clinicals.getVitalInformation(visit_id).then((result) => {
            if (result.result[0]) {
                setClinical(result.result[0]);
            } else {
                setClinical({});
            }

            setLoading(false);
        });
    }

    const [visible, setVisible] = useState(false);

    /**
     *  edit for clinical information
     */

    function editClinicalinformation() {
        // Location.navigate({ url: `/vital-information/${visit_id}?opvisit_id=${visit_id}&redirect=true` });
        setVisible(true);
    }

    return (
        <div className="card">
            <div className="card-header">
                <div className="card-title">
                    <h3 style={{ fontSize: '16px', lineHeight: '32px', fontWeight: 600 }}>Clinical Information</h3>

                    <div>
                        {/** If the histories returns true then edit is disabled (Show the emr screen contains patient history tab) else edit enabled.*/}
                        {!histories ? (
                            <EditOutlined style={{ color: 'blue' }} onClick={editClinicalinformation} />
                        ) : (null)}
                        <img
                            className="card-icon"
                            src={require(`../../../../assets/icons/external-clinic-infirmary-flat-obvious-flat-kerismaker-38.png`)}
                        />
                    </div>

                    <Modal
                        destroyOnClose={true}
                        title={'Vital Information'}
                        visible={visible}
                        footer={null}
                        okText="Okay"
                        onOk={() => {
                            setVisible(false);
                            // onCancelAppointment();
                        }}
                        onCancel={() => {
                            setVisible(false);
                        }}
                    >
                        <VitalInformation
                            information={clinical}
                            visit_id={visit_id}
                            callback={() => {

                                new Promise(async (resolve, reject) => {

                                    // Get Clinical Data
                                    const clinicalData = await getClinicalData(visit_id);

                                    resolve(clinicalData);

                                });

                                setVisible(false);
                            }}
                        />
                    </Modal>
                </div>
            </div>

            {loading ? (
                <Skeleton />
            ) : (
                <>
                    <div className="blood-section">
                        <div className="card">
                            <h3 className="blood-pressure">Blood Pressure</h3>
                            <div className="values-section">
                                <div className="card">
                                    <h3>Systolic</h3>
                                    <h1>{clinical.srvbcd_bpsys ? clinical.srvbcd_bpsys + ' mmHg ' : '_ _ _'}</h1>
                                    {/* <h5>Condition: High Blood pressure </h5> */}
                                </div>

                                <div className="card">
                                    <h3>Diastolic</h3>
                                    <h1>{clinical.srvbcd_bpdia ? clinical.srvbcd_bpdia + ' mmHg ' : '_ _ _'}</h1>
                                    {/* <h5>Condition: High Blood pressure </h5> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="values-section">
                        <div className="card">
                            <h3>Height</h3>
                            <h1>{clinical.srvbcd_height ? clinical.srvbcd_height + ' cm ' : '_ _ _'} </h1>
                        </div>
                        <div className="card">
                            <h3>Weight</h3>
                            <h1>{clinical.srvbcd_weight ? clinical.srvbcd_weight + ' kg ' : '_ _ _'}</h1>
                        </div>
                    </div>
                    <div className="values-section">
                        <div className="card">
                            <h3>BMI</h3>
                            <h1>{clinical.srvbcd_bmi ? clinical.srvbcd_bmi + ' kg/m^2 ' : '_ _ _'} </h1>
                        </div>
                        <div className="card">
                            <h3>Pulse</h3>
                            <h1>{clinical.srvbcd_pulse ? clinical.srvbcd_pulse + ' bpm ' : '_ _ _'} </h1>
                        </div>
                    </div>
                </>
            )}

            {history.length >= 2 ? (
                <div className="">
                    <LineGraph data={history} />
                </div>
            ) : null}
        </div>
    );
}
