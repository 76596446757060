/**
 * Collection Model
 *
 *
 * Collection is any receipt that a receptionist makes from the guest
 *
 */
import React, { useState, useEffect, useContext } from 'react';

import Model from './models';

import { Link } from 'react-router-dom';

import { Tag } from 'antd';

import CollectionDetailView from './../modules/common/components/collection-detail/collection-detail';

import CollectionAdd from './../modules/reception/components/collections/collections-add';

import { ApiUtils } from 'soxo-bootstrap-core';

const moment = require('moment-timezone');

var paymentTypes = {
    Cash: 'CS',
    'Net Banking': 'OT',
    'Credit Card': 'CC',
    'Debit Card': 'CC',
    Cheque: 'CQ',
};

class Collection extends Model {
    constructor() {
        super();

        this.fields = [
            {
                field: 'cold_holdername',
                caption: 'Name',
            },
            {
                field: 'cold_amt',
                caption: 'Amount',
            },
            {
                field: 'cold_tm',
                caption: 'Date',
            },
            {
                field: 'Collected By',
                caption: 'cold_user',
            },
        ];

        // this.columns = ;
    }

    get id() {
        return 'recipt_no';
    }

    get getEndpoint() {
        return 'colldetails';
    }

    get path() {
        return `collections`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `collection`;
    }

    get = (queries) => {
        return ApiUtils.getRecord(this.getEndpoint + '/receipts', queries);
    };

    /**
     *
     *
     * @param {*} id
     * @returns
     */
    loadReceipt = (id) => {
        return ApiUtils.get({ url: this.getEndpoint + `/receipts/${id}` });
    };
    loadCollections = (range) => {
        // const start_time = moment(range[0]).format('MM/DD/YYYY');

        // const end_time = moment(range[1]).format('MM/DD/YYYY');

        const start_time = moment(range[0]).format('02/02/2022');

        const end_time = moment(range[1]).format(' 04/03/2022');

        return ApiUtils.get({
            url: `colldetails/receipts?start_date=${start_time}&end_date=${end_time}`,
        });
    };

    get columns() {
        return [
            {
                field: 'opbill.opb_name',
                render: (record) => {
                    return record.opbill ? record.opbill.opb_name : null;
                },
                caption: 'Name',
            },
            {
                field: 'opbill.opb_gender',
                render: (record) => {
                    return record.opbill ? record.opbill.opb_gender : null;
                },
                caption: 'Gender',
            },
            {
                field: 'opbill.opb_age',
                render: (record) => {
                    return record.opbill ? record.opbill.opb_age : null;
                },
                caption: 'Age',
            },
            {
                field: 'opbill.opb_mobile',
                render: (record) => {
                    return record.opbill ? record.opbill.opb_mobile : null;
                },
                caption: 'Mobile',
            },
            {
                field: 'opbill.opb_opno',
                render: (record) => {
                    return record.opbill ? record.opbill.opb_opno : null;
                },
                caption: 'OP No.',
            },
            {
                field: 'cold_amt',
                render: (record) => {
                    return record.total_amt;
                },
                caption: 'Amount',
            },
            {
                field: 'receipt no.',

                render: (record) => {
                    return record.recipt_no;
                },
                caption: 'Receipt No.',
            },
            {
                field: 'cold_dt',
                render: (record) => {
                    return record.date ? moment(record.date).format('DD-MM-YYYY') : null;
                },
                caption: 'Date',
            },
            {
                field: 'cold_user',
                render: (record) => {
                    return record.createdBy;
                },
                caption: 'Collected By',
            },
            {
                field: 'cold_amt',
                render: (record) => {
                    return record.remarks ? record.remarks : null;
                },
                caption: 'Remarks',
            },
        ];
    }

    /**
     * Add Method for Collections
     */
    addCustom = (params) => {
        const { op_bill } = params;

        var formBody = {
            // cold_rctno: 0,
            cold_mode: op_bill.opb_mode,
            cold_tranno: op_bill.opb_id,
            cold_refno: op_bill.opb_bno,
            cold_dt: moment().format('YYYY-MM-DD HH:mm'),
            cold_amt: params.amount,
            cold_payertype: 'P' || 'C',
            cold_paymentmode: paymentTypes[params.type] ? paymentTypes[params.type] : 'OT',

            cold_instrumentno: params.instrumentNo,
            cold_holdername: params.holderName,
            cold_bankname: params.bankName,
            cold_cardname: params.cardName,
            cold_issuedby: params.issuedBy,
            cold_expirydt: params.expiryDate,

            cold_chqdt: params.chequeDate,
            cold_remarks: params.remarks,
            cold_slno: 0,
            cold_user: 'string',
            // cold_reconciled: 'string',
            // cold_reconcileddt: '2021-06-26T05:00:57.678Z',
            // cold_reconcileduser: 'string',
            cold_finyearid: 0,
            // cold_canflg: 'string',
            // cold_canuser: 'string',
            // cold_canuserid: 0,
            // cold_cantime: '2021-06-26T05:00:57.678Z',
            // cold_canremarks: 'string',
            // cold_canshiftno: 0,
            // cold_canshiftdt: '2021-06-26T05:00:57.678Z',
            cold_modespec: 'string',
            // cold_cashcounterptr: 'string',
            cold_tm: moment().format('YYYY-MM-DD HH:mm'),
            // cold_collheadptr: 0
        };

        if (params.type === 'Cheque') {
            formBody = {
                ...formBody,
                cold_instrumentno: params.chequeNo,
                cold_expirydt: params.chequeDate,
            };
        }

        return this.add(formBody);
    };

    /**
     * Add multi collection
     *
     * @returns
     */
    addEntry = (records, op_bill, user) => {
        // const { op_bill } = params;

        var formBody = {
            cold_mode: op_bill.opb_mode,
            cold_tranno: op_bill.opb_id,
            cold_refno: op_bill.opb_bno,
            cold_dt: moment().format('YYYY-MM-DD HH:mm'),

            cold_user: user.email,

            // cold_reconciled: string,
            // cold_reconcileddt: '2021-08-01T16:52:18.426Z',
            // cold_reconcileduser: string,

            cold_finyearid: 0,

            // For Cancellation
            // cold_canflg: string,
            // cold_canuser: string,
            // cold_canuserid: 0,
            // cold_cantime: '2021-08-01T16:52:18.426Z',
            // cold_canremarks: string,
            // cold_canshiftno: 0,
            // cold_canshiftdt: '2021-08-01T16:52:18.426Z',

            // cold_modespec: string,

            cold_tm: moment().format('YYYY-MM-DD HH:mm'),
            rows: records.map((record) => {
                return {
                    // cold_rctno: 0,
                    cold_amt: record.amount,
                    cold_payertype: 'P' || 'C',

                    cold_paymentmode: paymentTypes[record.type] ? paymentTypes[record.type] : 'OT',

                    cold_instrumentno: record.instrumentNo,
                    cold_holdername: record.holderName,
                    cold_bankname: record.bankName,
                    cold_cardname: record.cardName,
                    cold_issuedby: record.issuedBy,
                    cold_expirydt: record.expiryDate,

                    cold_chqdt: record.chequeDate,
                    cold_remarks: record.remarks,

                    // cold_slno: 0,
                    // cold_cashcounterptr: string,
                    // cold_collheadptr: 0,
                };
            }),
        };

        // formBody.rows.push(records);

        return ApiUtils.post({ url: 'colldetails/create-collection', formBody });

        //  return this.add(formBody);
    };

    /**
     * Get the data from the table
     */
    // get = (queries) => {

    //     return this.get(queries).then((result) => {

    //         return Promise.all(result.collections.map(this.loadStaff)).then((result) => {

    //             return { collections: result };

    //         })
    //     })
    // }

    // Attach the related staff with the object
    loadStaff = (item) => {
        // return Staffs.getRecord(item.staff, Staffs).then((result) => {
        //     return {
        //         ...item,
        //         staff: result
        //     }
        // })
    };

    /**
     * Card Component for Staff
     *
     * @param {*} record
     * @returns
     */
    // Card = (record) => {
    //     return (
    //         <Link
    //             className=booking-card card card-shadow
    //             to={`/collections/${record['cold_id']}`}
    //         >
    //             <div className=card>
    //                 <h2 className="">
    //                     {record.cold_holdername ? record.cold_holdername : null}
    //                 </h2>

    //                 <h3 className="title">{record.cold_amt}</h3>

    //                 <p className="address">Collected on {record.cold_tm}</p>

    //                 <Tag color="magenta">{record['cold_mode']}</Tag>
    //             </div>
    //         </Link>
    //     );
    // };

    DetailComponent = (routeParams) => <CollectionDetailView {...routeParams} model={this} />;

    AddComponent = () => <CollectionAdd model={this} />;
}

export default Collection;
