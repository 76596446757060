/**
 *
 * This should ideally be satisfied by a generic listing page
 *
 *
 */

import React, { useState, useEffect, useContext } from 'react';

//
import { Card, Space, DatePicker, Switch, Form, Button, Typography, Table, Skeleton, Pagination, Checkbox, Tag, Popover } from 'antd';

import { Route, Link } from 'react-router-dom';

import { ModalityGroups } from './../../../../models';

import Select from 'react-select';

import { Location, GlobalContext } from 'soxo-bootstrap-core';

import moment from 'moment-timezone';

import './patient-list.scss';

import { ReloadOutlined, OrderedListOutlined, PicCenterOutlined, CaretLeftOutlined, CaretRightOutlined, DeleteOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const { RangePicker } = DatePicker;

let defaultValue = {
    value: { mod_code: 'all' },
    label: 'All',
};

const filterOptions = [
    {
        label: 'All',
        value: 'all',
    },
    {
        label: 'Reported',
        value: 'reported',
    },
    {
        label: 'Unreported',
        value: 'unreported',
    },
];

/**
 * Patient List
 *
 * @param {*} match
 * @returns
 */
export default function PatientList({ match, caption, redirect, label, redirect_link }) {

    // location params
    var param = Location.search();

    const [range, setRange] = useState([
        moment.tz(param.start_time, process.env.REACT_APP_TIMEZONE).startOf('day'),
        moment.tz(param.end_time, process.env.REACT_APP_TIMEZONE).endOf('day'),
    ]);

    const [tech, setTech] = useState([]);

    const [groups, setGroups] = useState([]);

    const { user = {}, isMobile } = useContext(GlobalContext);

    const [pending, setPending] = useState(filterOptions[2]);

    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const [page, setPage] = useState(1); // Pagination number

    const [limit, setLimit] = useState(20);

    const [patients, setPatients] = useState([]); //Patients list array

    const [filterEnabled, setFilterEnabled] = useState(false);

    const [view, setView] = useState(isMobile ? true : false); //Need to check this condition

    const [modality, setModality] = useState(defaultValue);

    const [subModality, setSubModality] = useState('');

    var urlParams = Location.search();

    const [suboptions, setSuboptions] = useState([]);

    // const [caption, SetCaption] = useState(String);

    useEffect(() => {
        // On url update load the data
        loadData();

        // Load data with the updated params
        // getPatientDetails(params);
    }, [match.params]);

    /**
     * Load the data
     */
    function loadData() {
        let urlParams = Location.search();

        // Match params
        let params = {
            start_time: range[0].format('MM/DD/YYYY'),
            end_time: range[1].format('MM/DD/YYYY'),
            limit: 20,
            modality: 'CT1',
            ...urlParams,
        };

        loadGuestRecords(params);
    }

    useEffect(() => {
        // if (user) {
        //     getPatientDetails(pageNo, range, modality.value.mod_code); //Patiant list get function
        // }

        getDropdown();

        getGroups();
    }, []);

    /**
     * load the data
     *
     * @param {*} params
     */
    function loadGuestRecords(params) {
        //
        var submodality = Location.search().submodality;

        // var submodality = "'CTKGB001','CTLAAS001'"

        setLoading(true);

        // Load the data
        ModalityGroups.loadLungCases({ ...params, user, submodality }).then((result) => {
            var arr = [];


            let updated = result[0].map((record) => {
                return {
                    ...record,
                    label: label,
                };
            });


            // Guests
            setPatients(updated);

            setLoading(false);
        });
    }

    /**
     * On Page Change
     *
     * @param {*} data
     */
    function onPagination(page, limit) {
        setPage(page);

        setLimit(limit);

        Location.search({ page, limit });
    }

    function getDropdown() {
        ModalityGroups.getTechMaster().then((result) => {
            // console.log(result);

            setTech(result);
        });
    }

    /**
     * Get all the groups
     */
    function getGroups() {
        // get groups
        ModalityGroups.getModality().then((result) => {
            setGroups([
                defaultValue,

                ...result
                    .filter((item) => item.mod_active === 'Y')
                    .map((record) => {
                        return {
                            value: record,
                            label: record.mod_desc,
                        };
                    }),
            ]);

            // console.log(result);
        });
    }

    /**
     * On change of datetime
     *
     * @param {*} dt
     */
    function updateTime(dt) {
        setRange(dt);
        setSubModality('');
        setFilterEnabled(false);

        Location.search({
            start_time: moment(dt[0]).format('MM/DD/YYYY'),
            end_time: moment(dt[1]).format('MM/DD/YYYY'),
        });
    }

    /**
     * Change view
     *
     * @param {*} result
     */
    function changeView(result) {
        setView(result);
    }

    /**
     * Refresh the
     */
    function refresh() {
        setSubModality('');
        setFilterEnabled(false);

        loadData();
    }

    /**
     * Filter by modality
     *
     * @param {*} value
     * @param {*} content
     */
    function filterModality(value, content) {
        setPage(1);

        setModality(value);

        setSubModality('');

        setFilterEnabled(false);

        Location.search({ modality: value.value.mod_code, page: 1 });

        if (value.label !== 'All') {
            ModalityGroups.getModalityGroupItemGroupMap(value.value.mod_groupptr).then((result) => {
                console.log(result);

                // if (urlParams.subModality === 'CTKGB001') {
                //     SetCaption('Abdomen Screening');
                // } else if (urlParams.subModality === 'CTLAAS001') {
                //     SetCaption('Lung Screening');
                // }

                setSuboptions(
                    result.map((record) => {
                        return {
                            value: record,
                            label: record.itm_desc,
                        };
                    })
                );
            });
        }
    }

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            // render: (value, item, index) => (options.page - 1) * options.pageSize + index + 1,
            render: (value, item, index) => (page - 1) * limit + index + 1,

            // render: (value, item, index) => page * index + 1,
        },

        {
            title: 'Name',
            dataIndex: 'Name',
            key: 'name',
        },
        {
            title: 'Age',
            dataIndex: 'Age',
            key: 'age',
        },

        {
            title: 'Gender',
            // dataIndex: 'Gender',
            render: (record) => {
                let gender = null;
                if (record.Gender === 'Male') {
                    gender = 'Male';
                } else if (record.Gender === 'M') {
                    gender = 'Male';
                }
                return (
                    <>
                        <Tag color={gender === 'Male' ? 'blue' : 'pink'}> {gender === 'Male' ? 'Male' : 'Female'}</Tag>
                    </>
                );
            },
            key: 'gender',
        },

        {
            title: 'Modality',
            render: (text, record) => {
                return <Tag color={'green'}> {record.Modality}</Tag>;
            },
            key: 'Modality',
            // filters: filterChoices && filterChoices[0],
            // onFilter: (value, record) => (record.Modality ? record.Modality.indexOf(value) === 0 : null),
        },

        {
            title: 'Accession No',
            dataIndex: 'AccessionNo',
            key: 'accessionno',
        },

        // {
        //     title: 'Tech Remarks',
        //     dataIndex: 'TechDesc',
        //     key: 'accessionno',
        // },
    ];

    if (label !== 'Kidney') {
        columns.push({
            title: 'Result',
            key: 'result',
            render: (text, record) => {
                const content = (
                    <div>
                        <p>{text.DrRemarks}</p>
                    </div>
                );

                return (
                    <>
                        <Popover content={content} title="Remarks">
                            {text.DrResult}
                        </Popover>
                    </>
                );
            },
        });
    }

    columns.push({
        title: '',
        key: 'action',
        fixed: 'right',
        render: (text, record) => {
            let isPresent = false;

            if (text.DrResult) {
                isPresent = true;
            }

            let redirect = 'patient-view';

            if (record.Modality === 'Kidney Gall Bladder Stones') {
                redirect = 'abdomen-details';
            }

            //In case of bill list redirect to reportviewer
            if (redirect_link) {
                redirect = redirect_link;
            }
            return (
                <Space size="middle">
                    {/* ${record.MODE} */}
                    <Link to={`/${redirect}/${record.opno}?accessionno=${record.AccessionNo}&mode=TECH&opb_id=${record.billid}&item_ptr=${record.itemptr}`}>View</Link>
                </Space>
            );
        },
    });

    var dataSource = patients.filter((item) => {
        if (pending.value === 'reported') {
            return item.DrResult;
        } else if (pending.value === 'unreported') {
            return !item.DrResult;
        } else {
            return true;
        }
    });

    return (
        <section className="patient-list card">
            {/* Page Header */}
            <div className="page-header">
                <div className="left">
                    <Title style={{ marginBottom: '0px' }} level={3}>
                        {caption}
                    </Title>
                </div>

                <div className="right">
                    <div style={{ marginRight: '10px' }} className="modality-name">
                        <div className="action-header">Filter</div>
                        <Select
                            options={filterOptions}
                            labelInValue={'mod_desc'}
                            value={pending}
                            onChange={(value) => {
                                setPending(value);
                            }}
                            defaultValue={pending}
                            style={{ width: 120 }}
                        ></Select>
                    </div>

                    <div className="date-and-fltr">
                        <div className="action-header">Date</div>
                        <RangePicker
                            allowClear={false}
                            inputReadOnly
                            format={'DD/MM/YYYY'}
                            value={range}
                            onChange={(time) => {
                                updateTime(time, range);
                            }}
                            ranges={{
                                Today: [moment(), moment()],

                                Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],

                                'This Week': [moment().startOf('week'), moment().endOf('week')],

                                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],

                                'This Month': [moment().startOf('month'), moment().endOf('month')],

                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            }}
                        />

                        <Button onClick={refresh} type="secondary" size={'small'}>
                            <ReloadOutlined />
                        </Button>

                        <Switch
                            defaultChecked={view}
                            onChange={changeView}
                            checked={view}
                            checkedChildren={<OrderedListOutlined />}
                            unCheckedChildren={<PicCenterOutlined />}
                        />
                    </div>
                </div>
            </div>

            {/* Page Header Ends */}

            <p className="size-hint">{loading ? 'Loading' : dataSource.length} records.</p>

            {/* Content Below */}
            {loading ? (
                <>
                    <Skeleton active />
                </>
            ) : (
                <>
                    {view ? (
                        <>
                            <CardList dataSource={dataSource} redirect={redirect} />
                        </>
                    ) : (
                        <>
                            <Card>
                                <Table
                                    scroll={{ x: true }}
                                    rowKey={(record) => record.AccessionNo}
                                    dataSource={dataSource}
                                    columns={columns}
                                    pagination={false}
                                />{' '}
                            </Card>
                        </>
                    )}
                </>
            )}

            <div className="phycnListpgntn">
                {!loading ? (
                    <Pagination pageSize={limit} showSizeChanger={true} onChange={onPagination} current={page} defaultCurrent={page} total={100} />
                ) : null}
            </div>
        </section>
    );
}

//Mobile view card Section
function CardList({ dataSource, redirect }) {
    return dataSource.map((item, index) => {
        return (
            <Link key={index} className="report-item" to={`/${redirect}/${item.opno}?accessionno=${item.AccessionNo}&mode=TECH`}>
                <ModalityGroups.ResearchCard record={item} />
            </Link>
        );
    });
}
