/**
 *  This component list lab test
 * @description
 * @author Jinishakappil
 *
 */

import React, { useEffect, useState, useContext } from 'react';

import { Card, Typography, Table, Skeleton } from 'antd';

import { Link } from 'react-router-dom';

import { GlobalContext } from 'soxo-bootstrap-core';

import { Samples } from '../../../../models';

const { Title, Text } = Typography;

export default function LabTestList() {
    var [list, setList] = useState([]);

    const [loading, setLoading] = useState(true);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const { user = {}, isMobile, dispatch } = useContext(GlobalContext);

    /**
     * Get call
     */
    useEffect(() => {
        getLabTestList();
    }, []);

    /**
     * Listing lab test
     */
    async function getLabTestList() {
        setLoading(true);

        // Get List of all lab tests
        Samples.getLabtestlist().then((result) => {
            setList(result.result);

            setLoading(false);
        });
    }

    /**
     * Table Columns
     */

    var columns = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => index + 1,
        },

        {
            title: 'Test No',
            key: 'test_number',

            render: (ele) => {
                return ele.test_number;
            },
        },
        {
            title: 'Test name',
            key: 'test_name',

            render: (ele) => {
                return ele.test_name;
            },
        },
        {
            title: 'Action',
            fixed: 'right',

            render: (ele) => {
                let redirectLink = `/labtestdetail/:id`;
                redirectLink = redirectLink.replace(':id', ele.test_number);
                return (
                    <div>
                        <Link to={redirectLink}>View Details</Link>
                    </div>
                );
            },
        },
    ];

    return (
        <section className="lab-test-list card portlet-container">
            {/* Page Header */}

            <div className="page-header">
                <div className="left">
                    <Title style={{ marginBottom: '8px' }} level={3}>
                        LAB TEST
                    </Title>
                </div>
            </div>
            {/* Page Header Ends */}

            {/* Content Below */}
            {loading ? (
                <div className="lab-test">
                    <Skeleton active />
                </div>
            ) : (
                <>
                    <div className="lab-test">
                        <div>
                            <>
                                <Card className="table-card">
                                    <Table
                                        scroll={{ x: true }}
                                        dataSource={list}
                                        columns={columns}
                                        pagination={{
                                            // current: page,
                                            onChange(current) {
                                                // setPage(current);
                                            },
                                        }}
                                    />
                                </Card>
                            </>
                        </div>
                    </div>
                </>
            )}
        </section>
    );
}
