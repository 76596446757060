/**
 * Items model to group all apis related to it
 *
 *
 * To seperate all logic
 *
 */

import React, { useState, useEffect, useContext, useRef } from 'react';

import Model from './models';

import { ApiUtils } from 'soxo-bootstrap-core';

class Items extends Model {
    constructor() {
        super();

        this.title = 'Reporting List';

        this.fields = [
            {
                field: 'name',
                caption: 'Name',
            },
            {
                field: 'amount',
                caption: 'Amount',
            },
        ];

        // this.columns = ;
    }

    get id() {
        return 'cold_id';
    }

    get getEndpoint() {
        return 'item';
    }

    get path() {
        return `collections`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `collection`;
    }

    get columns() {
        return [
            {
                caption: 'Staff',
                field: 'staff.name',
                key: 'staff',
            },
            {
                caption: 'Weight',
                field: 'weight',
                key: 'weight',
            },
            {
                caption: 'Date',
                field: 'created_at',
                key: 'created_at',
            },
        ];
    }

    /**
     * Get all the packages
     *
     * @returns
     */
    getPackages = () => {
        return ApiUtils.get({
            url: `item?page=1&limit=50&itm_package=OP`,
        });
    };

    /**
     * Get all the packages
     *
     * @returns
     */
    getDoctorsList = () => {
        return ApiUtils.get({
            url: `doctor`,
        });
    };

    /**
     *
     * @returns Get Genei\ric List
     */
    getReasonsList = () => {
        return ApiUtils.get({
            url: `Generallist?genl_mode=OPRSN&genl_active=Y&limit=200`,
        });
    };

    /**
     *
     * For stoptb
     * Get all the items
     */
    getItemByCategory = (id, category = 'GEN') => {
        return ApiUtils.get({
            url: `item/itemWithRate?itm_code=${id}&category=${category}`,
        });
    };

    /**
     * Get all the items
     */
    getItems = (id) => {
        return ApiUtils.get({
            url: `item/withRate/${id}`,
        });
    };

    /**
     * Get all the items
     */
    getItemsForT = (id) => {
        return ApiUtils.get({
            url: `item`,
        });
    };

    /**
     * Get all the items
     */
    getItemsWithCategory = (id) => {
        return ApiUtils.get({
            url: `item/itemWithCategory`,
        });
    };
}

export default Items;
