/**
 * Scenarios to handle
 *
 * 1. Billing-screen for nura-desk
 *
 * 2. Billing-screen for stoptb
 *
 * 3. Direct-Billing for alsalama
 *
 */

import React, { useEffect, useState, useContext } from 'react';

import {
    Typography,
    Tabs,
    Modal,
    Radio,
    Skeleton,
    Button,
    Card,
    Form,
    Dropdown,
    Input,
    InputNumber,
    Table,
    Drawer,
    Menu,
    Divider,
    Row,
    Select,
    message,
    Col,
    Alert,
    Popconfirm,
} from 'antd';

import { useHistory } from 'react-router-dom';

import moment from 'moment';

import './bill-items.scss';

import { Rupee, Location, InputComponent, ReferenceSelect } from 'soxo-bootstrap-core';

import { MoreOutlined } from '@ant-design/icons';

import { DeleteOutlined, CloseCircleTwoTone, DeleteFilled, EditOutlined } from '@ant-design/icons';

import { withRouter } from 'react-router-dom';

import { ItemSelection } from './../../../items/components/item-selection/item-selection';

import AdvanceSearch from '../../../candidate-visit/advance-search';

// import {ItemSelection} from '../../../emr/components/investigation/investigation';

import { Categories, Referrers, Items, Bills, Opvisits, Opreg, ItemRateTypes } from './../../../../models/';

import VisitInfo from './../../../emr/components/visit-info/visit-info';

import Status from './../../../candidate-visit/multiple-candidate/status-component/status';

const { Option } = Select;

const { Title, Text } = Typography;

const { TextArea } = Input;

const { TabPane } = Tabs;

function BillItems({ defaultItem, buttonEnable, doctorSelectionDisable, order, ...props }) {
    var urlParams = Location.search();

    const [guest, setGuest] = useState({});

    const [corporate, setCorporate] = useState(false);

    const [list, setList] = useState([]);

    //variable to calculate Age
    const [guestAge, setGuestAge] = useState({});

    // Variables defined for Selected Values End

    const [selectedreferror, setSelectedreferrer] = useState('');

    const [selecteddoctor, setSelecteddoctor] = useState(null);

    const [visible, setVisible] = useState(false);

    // Vital information
    const [patient, setPatient] = useState({});

    const [doctors, setDoctors] = useState([]);

    const [form] = Form.useForm();

    const corporateptr = urlParams.op_corporateptr;

    const mode = urlParams.mode;

    useEffect(() => {
        loadData();
        getDoctors();
    }, []);

    /**
     * Get all the packages
     */
    const getDoctors = () => {
        return Items.getDoctorsList().then((result) => {
            setDoctors(result.result);

            return result;
        });
    };

    function loadData() {
        var params = Location.search();

        getCustomers();

        //For direct flow we dont need opReg data
        if (!buttonEnable) {
            getOpRegData(params.op_no);
        }

        // getOPReg(params.op_no)
    }
    const status = {
        // image: require(`./../../../../../public/soxo/doctor1.png`),
        Heading: 'Tip to remember',
        text: 'Contact the admin to preconfigure your billing to simplify your work.',
        colour: '#8369BA',
        width: '40%',
        right: '-15px',
    };

    /**
     *
     */
    // function getOPReg(id) {
    //     Registrations.getOpregDetail(id).then((result) => {
    //         setGuest(result);
    //         getGuestAge(result);
    //         // setGuestAge(age);
    //     });
    // }
    function getOperg(op_no, da_id) {
        if (op_no) {
            Bills.getOperg(op_no, da_id).then((result) => {
                setGuest(result.data);
            });
        } else {
            setGuest([]);
        }
    }
 
    async function getOpRegData(op_no) {
        await Bills.getOpRegData(op_no).then(async (result) => {
            await setSelectedreferrer(result.result[0].op_referrerptr);
            await setGuest(result.result[0]);
        });
    }

    /**
     * Get Customers
     */
    function getCustomers() {
        Bills.getCustomers().then((corporateptr) => {
            setList(corporateptr);
            if (urlParams.op_iscorporate === 'y') {
                let corp = urlParams.op_corporateptr != 'null' ? corporateptr.find((x) => x.cc_code === urlParams.op_corporateptr).cc_desc : 'NA';
                setCorporate(corp);
            }
        });
    }

    function Refresh() {
        window.location.reload(false);
    }

    const Finish = async (values) => {
        values = {
            ...values,
            op_agetype: 'Years',
        };

        setGuest(values);

        setVisible(false);
    };

    function editClinicalinformation() {
        setVisible(true);
    }

    function onCancel() {
        setVisible(false);
    }

    /**
     *
     */
    function editDetails() {
        Location.navigate({
            url: `/new-registration/guest?da_id=${guest.drAppointment.da_id}&da_packagetypevalue=${guest.drAppointment.da_packagetypevalue}&prereg_id=${guest.drAppointment.da_referenceid}&prereg_opno=${guest.op_no}&mode=${mode}&edit=true&redirect=bill`,
        });
    }

    return (
        <div className="bill-summary">
            <div className="page-header">
                <Title className="title" level={4}>
                    BILL SUMMARY
                </Title>

                <div className="top-actions">
                    {/* <Button type="primary" size="small"
                        onClick={() => {
                           

                            setVisible(true);
                        }}

                    >
                        Details
                    </Button> */}

                    {/* </div> */}

                    {/* <div className="page-header"> */}
                    <Button size={'small'} type="secondary" onClick={loadData}>
                        Refresh
                    </Button>
                </div>
            </div>

            <div>
                <Modal
                    destroyOnClose={true}
                    title="Guest-Details"
                    footer={null}
                    visible={visible}
                    okText="Submit"
                    width="85%"
                    onOk={() => {
                        setVisible(false);
                        // Finish();

                        // Location.navigate({ url: `/multiple-candidate/bill` })
                    }}
                    onCancel={() => {
                        setVisible(false);
                    }}
                >
                    <Tabs defaultActiveKey="0">
                        {/* First tab is to enter details manually */}
                        <TabPane tab="Enter Details" key="0">
                            <Form form={form} name="new-record" layout="vertical" onFinish={Finish}>
                                <p>Enter below details</p>

                                <Form.Item
                                    name={'op_fname'}
                                    label="Name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter First Name',
                                        },
                                        {
                                            max: 100,
                                            message: 'Length cannot exceed 100 characters !',
                                        },
                                        {
                                            pattern: /^[a-zA-Z\s]*$/g,
                                            message: 'Please Enter Valid Name!',
                                        },
                                    ]}
                                >
                                    <InputComponent />
                                </Form.Item>

                                <Form.Item
                                    name={'op_age'}
                                    label="Age"
                                    rules={[
                                        {
                                            pattern: /^[0-9]{1,3}$/,
                                            message: 'Please Enter Valid Age!',
                                        },
                                        {
                                            required: true,
                                            message: 'Please Enter Age',
                                        },
                                    ]}
                                >
                                    <InputComponent />
                                </Form.Item>

                                <Form.Item
                                    name={'op_gender'}
                                    label="Gender"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please Enter Gender',
                                        },
                                    ]}
                                >
                                    {/* <InputComponent /> */}

                                    <Select>
                                        <Option value="Male">Male</Option>
                                        <Option value="Female">Female</Option>
                                        <Option value="Other">Other</Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name={'op_mobile'}
                                    label="Mobile"
                                    rules={[
                                        { required: true, message: 'Please Enter Mobile Number' },
                                        {
                                            pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                                            message: 'Please enter 10 digit mobile number',
                                        },
                                    ]}
                                >
                                    <InputComponent />
                                </Form.Item>

                                <div className="footer-actions">
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        onSubmit={() => {
                                            setVisible(false);
                                        }}
                                    >
                                        Save
                                    </Button>

                                    <Button htmlType="submit" type="primary" onClick={onCancel}>
                                        Cancel
                                    </Button>
                                </div>
                            </Form>
                        </TabPane>

                        {/** To Search by op number or name */}
                        <TabPane tab="Advanced Search " key="1">
                            <AdvanceSearch
                                model={Opreg}
                                enableVisit={false}
                                callback={(record) => {
                                    setGuest(record);
                                    setVisible(false);
                                }}
                                title=""
                            />
                        </TabPane>
                    </Tabs>
                </Modal>
            </div>

            <div className="bill-content">
                <div className="data-section">
                    <Card className="right-part card">
                        <Title level={5}>GUEST INFORMATION</Title>
                        {/* Customer Details */}

                        {/* <div className="section-header"> */}
                        {/* <Title level={5}>Guest Details</Title> */}

                        {/* <Button size={'small'} onClick={editDetails}>
                        Edit
                    </Button> */}
                        {/* </div> */}

                        {buttonEnable ? <EditOutlined style={{ color: 'blue' }} onClick={editClinicalinformation} /> : null}

                        {urlParams.visit_id ? (
                            /* Patient Visit Information */
                            <VisitInfo
                                visit_id={urlParams.visit_id}
                                patient={patient}
                                setPatient={async (patient) => {
                                    const result = await getDoctors();

                                    setSelecteddoctor(patient.opv_doctorptr);

                                    setPatient(patient);
                                }}
                            />
                        ) : /* Patient Visit Information Ends */
                        guest ? (
                            <div className="section-content">
                                <div className="section-row">
                                    <div className="section-label">
                                        <Text type="secondary">Name</Text>
                                    </div>
                                    <div className="section-value">
                                        <strong>
                                            {guest.op_fname} {guest.op_lname}
                                        </strong>
                                    </div>
                                </div>

                                <div className="section-row">
                                    <div className="section-label">
                                        <Text type="secondary">Age</Text>
                                    </div>
                                    <div className="section-value">
                                        <strong>
                                            {guest ? guest.op_age : null} {guest.op_agetype}
                                        </strong>
                                    </div>
                                </div>

                                <div className="section-row">
                                    <div className="section-label">
                                        <Text type="secondary">Gender</Text>
                                    </div>
                                    <div className="section-value">
                                        {/* {guest.op_gender === 'M' || guest.op_gender === 'Male' ? <strong>Male</strong> : <strong>Female</strong>} */}
                                        {guest.op_gender === 'M' || guest.op_gender === 'Male' ? <strong>Male</strong> : null}
                                        {guest.op_gender === 'F' || guest.op_gender === 'Female' ? <strong>Female</strong> : null}
                                    </div>
                                </div>

                                <div className="section-row">
                                    <div className="section-label">
                                        <Text type="secondary">Mobile</Text>
                                    </div>
                                    <div className="section-value">
                                        <strong>{guest.op_mobile}</strong>
                                    </div>
                                </div>
                            </div>
                        ) : null}

                        {/* Customer Detail Ends */}

                        {/* Bill Types */}

                        {/* <Title level={5}>Bill Types</Title>
                <Col>
                    <Select
                        onChange={(entry) => onUpdateSelect(entry, setSelectedbilltype)}
                        value={selectedbilltype}
                        style={{ width: '100%' }}
                        placeholder="Choose Bill Type"
                    >
                        {types.map((item, key) => (
                            <Option key={key} value={item.btyp_code}>
                                {item.btyp_caption} | {item.btyp_desc}
                            </Option>
                        ))}
                    </Select>
                </Col> */}
                    </Card>
                    <div className="status-card">
                        <Status status={status}></Status>
                    </div>
                </div>

                <Card className="bill-items card form">
                    <div className="form-content">
                        {/* Component For Billing */}
                        <BillSummary
                            {...props}
                            defaultItem={defaultItem}
                            guest={guest}
                            corporate={corporate}
                            selecteddoctor={selecteddoctor}
                            setSelectedreferrer={setSelectedreferrer}
                            referrer={selectedreferror}
                            setSelecteddoctor={setSelecteddoctor}
                            doctorSelectionDisable={doctorSelectionDisable}
                            doctors={doctors}
                            corporateptr={corporateptr}
                            loadData={loadData}
                            order={order}
                            buttonEnable={buttonEnable}
                        />
                        {/* Component For Billing Ends*/}
                    </div>
                </Card>
            </div>
        </div>
    );
}

export default withRouter(BillItems);

/**
 * Component will load the accesories in cart for this booking
 */
function BillSummary({
    guest,
    defaultItem,
    callback,
    corporateptr,
    referrer,
    loadData,
    order,
    corporate,
    doctorSelectionDisable,
    doctors,
    selecteddoctor,
    setSelecteddoctor,
    setSelectedreferrer,

    buttonEnable,
}) {
    var params = Location.search();

    const history = useHistory();

    const [loading, setLoading] = useState(false);

    const [btnloading, setBtnloading] = useState(false);

    const [master, setMaster] = useState();

    const [visible, setVisible] = useState(false);

    const [selected, setSelected] = useState([]);

    const [selectedbilltype, setSelectedbilltype] = useState('');

    const [referreroptions, setReferreroptions] = useState([]);

    const [category, setCategory] = useState([]);

    const [types, setTypes] = useState([]);

    const [discount, setDiscount] = useState({});

    const [selectedcategory, setSelectedcategory] = useState('');

    const [coupon, setCoupon] = useState({});
    // const [, setCoupon] = useState({});

    const [itmdiscount, setitmDiscount] = useState({});

    const [remarks, setRemarks] = useState('');

    useEffect(() => {
        if (defaultItem) {
            getItems(defaultItem, selectedcategory);
        }
       

        // get Bill types
        getBillTypes();

        // get Categories
        getCategories(category);

        // get Referrer list
        getReferrers();
    }, []);

    const items = [];

    function getBillTypes() {
        Bills.getBillTypes().then((result) => {
            setTypes(result);
            setSelectedbilltype(result[0].btyp_code);
        });
    }

    const onUpdateSelect = (value, updateMethod) => {
        if (value) {
            updateMethod(value);
        } else {
            updateMethod('');
        }
    };

    /**
     *
     */

    /**
     * Get the referrers
     */
    function getReferrers() {
        Referrers.get().then((result) => {
            setReferreroptions(result.result);
        });
    }

    /**
     * Select Doctor onchange of doctor
     * @param {*} value
     */
    const selectDoctor = (value) => {
        setSelecteddoctor(value);
    };

    /**
     * Get the master data to show options for the user
     */
    async function getItems(defaultItem, category, categoryChange) {
        setLoading(true);

        return await Items.getItems(defaultItem, category).then(async (result) => {
            // result = result.map((ele) => {
            //     return {
            //         ...ele,
            //         ir_rate: ele.itemrate[0].ir_rate,
            //         ir_itemptr: ele.itemrate[0].ir_itemptr,
            //     };
            // });

            if (categoryChange) {
                setLoading(false);

                return result[0];
            } else {
                setMaster(result);

                setSelected([result]);

                setLoading(false);
            }
        });
    }
    /**
     * get Categories
     * @param {*} category
     */
    function getCategories() {
        Categories.getCategories().then((data) => {
            //forstoptb
            setCategory(data.result);
            // setCategory(data);

            //for stoptb
            //setSelectedcategory(data.result[0].itmrt_code);)
            setSelectedcategory(data.result[0].ir_ratecatptr);
        });
    }

    /**
     * On change of Category items should update its values
     * @param {*} value
     */

    async function UpdateCategory(value) {
        setSelectedcategory(value);
        var arr = [];
        var categoryChange = true;
        if (selected.length) {
            Promise.all(
                selected.map(async (ele) => {
                    if (ele && ele.itm_code) var newItem = await getItems(ele.itm_code, value, categoryChange);

                    if (newItem) arr.push({ ...newItem });
                    return arr;
                })
            ).then((arr) => {
                setSelected(arr[0]);
            });
        }
    }

    

    /**
     * Get the master data to show options for the user
     */
    function getItemsForT() {
        setLoading(true);

        Items.getItemsForT().then((result) => {
            // setMaster(result);

            setSelected([result[0]]);

            setLoading(false);
        });
    }

    /**
     *
     *
     * @param {*} values
     */
    const createBill = (values) => {
        setBtnloading(true);
        var formBody = {
            op_no: params.op_no,
            opb_opno: params.op_no,
            visit_id: '',
            // opbd_itemptr: params.da_packagetypevalue,
            rate_type: '3', // Not sure if required
            opd_remarks: remarks,
            // opd_corporateptr: "12",
            category: selectedcategory,

            billtype: selectedbilltype,

            cmgdcpn_couponnumber: coupon.coupon_no,

            doctorptr: selecteddoctor,

            refererptr: referrer,
        };

        if (buttonEnable) {
            formBody = {
                ...formBody,
                name: guest.op_fname,
                age: guest.op_age,
                ageType: guest.op_agetype,
                mobile: guest.op_mobile,
                gender: guest.op_gender,
            };
        }

        if ((discount.value && discount.value != 0) || (coupon && coupon.coupon_no && coupon.coupon_value)) {
            if (!formBody.opd_remarks) {
                message.error('Please Enter Remarks');
                setBtnloading(false);
                return false;
            }
        }

        Bills.createBill(formBody, selected, guest, corporateptr, discount, coupon)

            .then((result) => {
                if (result) {
                    if (result.success === false) {
                        setBtnloading(false);
                        message.error('Sorry, The Bill Creation is Failed');
                        return false;
                    }
                    let opbd_hdrid = result.OpBillD[0].opbd_hdrid;

                    message.success('Bill has been successfully created');

                    // if (selectedbilltype === '0') {

                    //     Location.navigate({ method: 'replace', url: `/collections/create?opbd_hdrid=${opbd_hdrid}` })

                    //     // history.push('create/collections')
                    //     // Location.navigate({
                    //     //     method: 'push', url: `/collections`
                    //     // })

                    // } else {
                    if (order) Location.navigate({ method: 'replace', url: '/order/' + opbd_hdrid });
                    else Location.navigate({ method: 'replace', url: '/bill/' + opbd_hdrid });

                    // }

                    setBtnloading(false);
                }
            })
            .catch((result) => {
                setBtnloading(false);

                message.error(JSON.stringify(result.message));
            });
    };

    /**
     * For applying bill level discount
     */
    function onUpdate(values) {
        setDiscount(values);
        setRemarks(values.remarks);
    }

    function onUpdateCoupon(values) {
        setCoupon(values);
        if (values && values.remarks) setRemarks(values.remarks);
    }

    function onUpdateitmdiscount(values) {
        setitmDiscount(values);
    }
    /**
     * On Update remarks
     *
     * @param {*} event
     */
    function onUpdateRemarks(event) {
        setRemarks(event.target.value);
    }

    var groupBy = function (xs, key) {
        return xs.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);

            return rv;
        }, {});
    };

    return (
        <div className="bill-summary">
            {/* Item Selection Component */}
            <div className="top-section">
                <div className="summary-bottom" style={{ width: '30%' }}>
                    <div className="right">
                        <Title level={5}>Category</Title>

                        <ReferenceSelect
                            label="itmrt_code"
                            value={selectedcategory}
                            mode="nura-base"
                            onChange={UpdateCategory}
                            model={ItemRateTypes}
                            field={'itmrt_code'}
                        />
                    </div>
                </div>
                <div className="summary-bottom" style={{ width: '20%' }}>
                    <div className="right">
                        <Title level={5}>Bill Types</Title>
                        <Select
                            style={{ marginLeft: '5px', marginRight: '5px', width: '100%' }}
                            onChange={(entry) => onUpdateSelect(entry, setSelectedbilltype)}
                            value={selectedbilltype}
                            // style={{ width: '100%' }}
                            placeholder="Choose Bill Type"
                        >
                            {types.map((item, key) => (
                                <Option key={key} value={item.btyp_code}>
                                    {item.btyp_caption}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className="summary-bottom" style={{ width: '20%' }}>
                    <div className="right">
                        <Title level={5}>Campaign</Title>
                        <Select
                            value={referrer}
                            onChange={(entry) => onUpdateSelect(entry, setSelectedreferrer)}
                            allowClear
                            // labelInValue={true}
                            style={{ width: '100%' }}
                            placeholder="Choose Campaign"
                        >
                            {referreroptions.map((item, key) => (
                                <Option key={key} value={item.refr_code}>
                                    {item.refr_desc}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <ItemSelection
                    selectedLabel="Selected"
                    includeRate={true}
                    category={selectedcategory}
                    deleteItem={(index) => {
                        index = index + 1;

                        selected.splice(index);
                    }}
                    selected={selected}
                    callback={(selectedItems) => {
                        setSelected(selectedItems);

                        // var arr = groupBy(selected, 'itm_code');

                        // var itemCode = Object.keys(arr);

                        // selectedItems.map((ele) => {
                        //     if (itemCode.indexOf(ele.itm_code) === -1)
                        //         selected.push({
                        //             ...ele,
                        //         });
                        // });
                        loadData();
                    }}
                />
            </div>

            {/* Item Selection Component Starts */}

            {loading ? (
                <Skeleton />
            ) : (
                <>
                    {selected && selected.length ? (
                        <SelectedList
                            callback={callback}
                            guest={guest}
                            items={selected}
                            onUpdate={onUpdate}
                            onUpdateCoupon={onUpdateCoupon}
                            onUpdateitmdiscount={onUpdateitmdiscount}
                            coupon={coupon}
                            setSelected={setSelected}
                            discount={discount}
                            setDiscount={setDiscount}
                            setCoupon={setCoupon}
                            remarks={remarks}
                            setRemarks={setRemarks}
                        />
                    ) : null}
                </>
            )}

            {/* <div className="summary-bottom" style={{ marginLeft: '10px', marginRight: '400px' }}>
                <div className="right">
                    <Title level={5}>Bill Types</Title>
                    <Select
                        style={{ marginLeft: '5px', marginRight: '5px', width: '50%' }}
                        onChange={(entry) => onUpdateSelect(entry, setSelectedbilltype)}
                        value={selectedbilltype}
                        // style={{ width: '100%' }}
                        placeholder="Choose Bill Type"
                    >
                        {types.map((item, key) => (
                            <Option key={key} value={item.btyp_code}>
                                {item.btyp_caption}
                            </Option>
                        ))}
                    </Select>
                </div>
            </div> */}

            <Divider></Divider>

            {corporate ? (
                <div className="summary-bottom" style={{ marginLeft: '10px', marginRight: '400px' }}>
                    <div className="right">
                        <Title level={5}>Corporate</Title>
                        <Col>
                            <div>{corporate}</div>
                        </Col>
                    </div>
                </div>
            ) : null}

            {doctorSelectionDisable ? null : (
                <div className="summary-bottom" style={{ marginLeft: '10px', marginRight: '400px' }}>
                    <div className="right">
                        <Title level={5}>Doctor</Title>
                        <Select
                            onChange={(entry) => selectDoctor(entry)}
                            value={selecteddoctor}
                            style={{ width: '100%' }}
                            placeholder="Choose Doctor"
                        >
                            {doctors.map((item, key) => (
                                <Option key={key} value={item.do_code}>
                                    {item.do_name}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
            )}

            {/* <div className="summary-bottom" style={{ marginLeft: '10px', marginRight: '400px' }}>
                <div className="right">
                    <Title level={5}>Category</Title>
                    <Select
                        onSelect={UpdateCategory}
                        value={selectedcategory}
                        style={{ width: '100%' }}
                        placeholder="Choose Category"
                    >
                        {category.map((item, key) => (
                            <Option key={key} value={item.itmrt_code}>
                                {item.itmrt_desc}
                            </Option>
                        ))}
                    </Select>
                </div>
            </div> */}

            {/* <div className="summary-bottom" style={{ marginLeft: '10px', marginRight: '400px' }}>
                <div className="right">
                    <Title level={5}>Campaign</Title>
                    <Select
                        value={referrer}
                        onChange={(entry) => onUpdateSelect(entry, setSelectedreferrer)}
                        allowClear
                        // labelInValue={true}
                        style={{ width: '100%' }}
                        placeholder="Choose Campaign"
                    >
                        {referreroptions.map((item, key) => (
                            <Option key={key} value={item.refr_code}>
                                {item.refr_desc}
                            </Option>
                        ))}
                    </Select>
                </div>
            </div> */}

            {/* Footer Actions */}

            <div className="footer-actions">
                {/* <div className="left">
                    <p level={3}>
                        <strong>Remarks</strong>
                    </p>

                    <TextArea value={remarks} onChange={onUpdateRemarks}></TextArea>
                </div> */}

                <div className="footer">
                    <Button
                        loading={btnloading}
                        disabled={(selected && !selected.length) || !guest.op_fname || !selected}
                        className=""
                        type="primary"
                        onClick={createBill}
                    >
                        Create Bill
                    </Button>

                    {/* <Button className="" type="secondary" onClick={() => {}}>
                        Cancel
                    </Button> */}

                    {/* <Button className="" type="secondary" onClick={() => {
                        history.push('/collections/create')
                        // history.push('create/collections')
                        // Location.navigate({
                        //     method: 'push', url: `/collections`
                        // })
                    }}>
                        Redirect
                    </Button> */}
                </div>
            </div>

            {/* Footer Actions Ends */}

            {/* <Drawer
                className="accessory-drawer"
                width="100%"
                destroyOnClose={true}
                title="Choose customer choices"
                visible={visible}
                onClose={() => {
                    setVisible(false);
                }}
            >
                <ItemMaster
                    // selected={selected}
                    choices={master}
                    callback={(items) => { }}
                />
            </Drawer> */}
        </div>
    );
}

/**
 *
 * THIS COMPONENT IS NOT USED
 * Item Master
 */
function ItemMaster({ options = [], choices = [], callback, isBooking }) {
    const [search, setSearch] = useState('');

    const [categories, setCategories] = useState([]);

    const [selectedTab, setSelectedTab] = useState('All');

    const [selected, setSelected] = useState(choices);
    const [items, setItems] = useState({});

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let arr = ['All'];
        options.map((ele) => {
            if (arr.includes(ele.category) === false) {
                arr.push(ele.category);
            }
        });
        setCategories(arr);
        getItems();
    }, []);

    /**
     * Get items
     */
    function getItems() {
        setLoading(true);

        Opvisits.getItems().then((result) => {
            setItems(result);

            setLoading(false);
        });
    }

    const selectItem = (item, index) => {
        selected.push({
            ...item,
            ...{ quantity: 1, id: null, name: item.partName },
        });

        setSelected([...selected]);

        // callback(selected);
    };

    const onTabChange = (key) => {
        setSelectedTab(categories[key]);
    };

    const findArrayLength = (opt) => {
        let arrValue = [];
        options
            .filter((item) => {
                return selected.map((ele) => ele.partName).indexOf(item.partName) == -1;
            })
            .filter((item) => {
                return item.partName.toLowerCase().includes(search.toLowerCase());
            })
            .map((val, key) => {
                if (val.category == selectedTab) {
                    arrValue.push(val);
                }
            });
        return arrValue.length;
    };

    return (
        <div className="accessory-selection">
            <div className="split-list">
                <div
                    style={{
                        width: '30%',
                        border: '1px solid #f0f0f0',
                        padding: '16px',
                        height: 'fit-content',
                    }}
                >
                    <div
                        style={{
                            borderBottom: '1px solid #f0f0f0',
                            paddingBottom: '10px',
                        }}
                    >
                        <div style={{ fontWeight: 'bold', fontSize: '17px' }}>CART</div>
                    </div>
                    {selected &&
                        selected.map((ele) => {
                            return (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        paddingTop: '5px',
                                    }}
                                >
                                    <div>{ele.name}</div>
                                    <div>{ele.quantity}</div>
                                </div>
                            );
                        })}
                    <div
                        style={{
                            borderTop: '1px solid #f0f0f0',
                            paddingTop: '10px',
                            marginTop: '10px',
                        }}
                    >
                        <Button
                            type="dashed"
                            style={{ alignSelf: 'flex-end' }}
                            onClick={() => {
                                callback(selected);
                            }}
                        >
                            Save
                        </Button>
                    </div>
                </div>
                <div className="available">
                    <Tabs defaultActiveKey="0" onChange={onTabChange}>
                        {categories.map((cat, index) => {
                            return (
                                <TabPane tab={cat} key={index}>
                                    <div style={{ width: '30%' }}>
                                        <p level={5}>{selectedTab !== 'All' ? findArrayLength(options) : options.length} available in stock</p>

                                        <Input
                                            placeholder="Search"
                                            value={search}
                                            onChange={(event) => {
                                                setSearch(event.target.value);
                                            }}
                                        />

                                        <div className="list" style={{ paddingTop: '20px' }}>
                                            {options
                                                .filter((item) => {
                                                    return selected.map((ele) => ele.name).indexOf(item.partName) == -1;
                                                })
                                                .filter((item) => {
                                                    return item.partName.toLowerCase().includes(search.toLowerCase());
                                                })
                                                .map((option, key) => {
                                                    if (selectedTab !== 'All') {
                                                        if (option.category == selectedTab) {
                                                            return (
                                                                <div
                                                                    className="item-card"
                                                                    key={key}
                                                                    style={{
                                                                        borderBottom: '1px solid #f0f0f0',
                                                                        padding: '16px',
                                                                        display: 'flex',
                                                                        justifyContent: 'space-between',
                                                                    }}
                                                                >
                                                                    <div className="item-content">
                                                                        <Title level={5}>{option.partName}</Title>
                                                                        <p>
                                                                            {option.partNo} - {option.available} available
                                                                        </p>
                                                                        <Text type="danger">
                                                                            <Title level={5}>{option.price}</Title>
                                                                        </Text>
                                                                    </div>
                                                                    <Button
                                                                        type="dashed"
                                                                        style={{
                                                                            alignSelf: 'flex-end',
                                                                        }}
                                                                        onClick={() => {
                                                                            selectItem(option, key);
                                                                        }}
                                                                    >
                                                                        Add
                                                                    </Button>
                                                                </div>
                                                            );
                                                        }
                                                    } else {
                                                        return (
                                                            <div
                                                                className="item-card"
                                                                key={key}
                                                                style={{
                                                                    borderBottom: '1px solid #f0f0f0',
                                                                    padding: '16px',
                                                                    display: 'flex',
                                                                    justifyContent: 'space-between',
                                                                }}
                                                            >
                                                                <div className="item-content">
                                                                    <Title level={5}>{option.partName}</Title>
                                                                    <p>
                                                                        {option.partNo} - {option.available} available
                                                                    </p>
                                                                    <Text type="danger">
                                                                        <Title level={5}>{option.price}</Title>
                                                                    </Text>
                                                                </div>
                                                                <Button
                                                                    type="dashed"
                                                                    style={{
                                                                        alignSelf: 'flex-end',
                                                                    }}
                                                                    onClick={() => {
                                                                        selectItem(option, key);
                                                                    }}
                                                                >
                                                                    Add
                                                                </Button>
                                                            </div>
                                                        );
                                                    }
                                                })}
                                        </div>
                                    </div>
                                </TabPane>
                            );
                        })}
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

/**
 * Selected List
 */
function SelectedList({
    items = [],
    onUpdate,
    onUpdateitmdiscount,
    onUpdateCoupon,
    guest,
    setSelected,
    discount,
    setDiscount,
    coupon,
    setCoupon,
    remarks,
    setRemarks,
}) {
    const [visible, setVisible] = useState(false);

    const [CouponVisible, setCouponVisible] = useState(false);

    const [roundValue, setRoundValue] = useState(2);

    // const [itmdiscount, setitmDiscount] = useState({});

    // Update the items to add callback functions
    items = items.map((entry) => {
        // Callback Function
        entry.callback = (props) => {
            // While preparing data we prepare discount object
            entry.discount = {
                ...props,
            };

            // setEntries(items);
        };

        entry.quantity = 1;

        entry.amount = entry.ir_rate * entry.quantity;

        return entry;
    });

    /**
     * Delete the selected Item
     * @param {*} value
     */

    function deleteSelected(value) {
        items.map((ele, index) => {
            if (ele.itm_code.indexOf(value.itm_code) !== -1) {
                items.splice(index, 1);
            }
        });
        setSelected(items);

        // setItems(items)
    }

    /**
     * On Update remarks
     *
     * @param {*} event
     */
    function onUpdateRemarks(event) {
        setRemarks(event.target.value);
    }

    /**
     * Function to delete the applied discount in item discounts
     * @param {*} value
     */
    function deleteDiscount(value) {
        items.map((ele, index) => {
            if (ele.itm_code.indexOf(value.itm_code) !== -1) {
                delete ele.discount;
            }
        });
        setSelected(items);
    }

    // const [entries, setEntries] = useState(items);

    // useEffect(() => {

    //     setEntries(items);

    // }, [items])

    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => index + 1,
        },
        {
            title: 'Date',
            // dataIndex: 'no'

            render: () => {
                return moment().format('DD/MM/YYYY');
            },
        },
        {
            title: 'Service Item',
            dataIndex: 'itm_desc',
        },

        // {
        //     title: 'Doctor',
        //     dataIndex: 'price',
        // },

        {
            title: 'Tax %',
            dataIndex: 'price',
        },

        {
            title: 'Rate',
            dataIndex: 'ir_rate',
        },

        {
            title: 'Quantity',
            dataIndex: 'quantity',
        },

        {
            title: 'Discount',
            render: (record) => {
                // const { discount = recorditmdiscount } = record;

                if (record.discount) {
                    return (
                        <>
                            <span>{record.discount.value ? record.discount.value + ' ' + record.discount.type : '-'}</span>
                            <Popconfirm
                                title="Are you sure you want to delete the applied discount ? "
                                onConfirm={() => deleteDiscount(record)}
                                onCancel={() => {}}
                                okText="Yes"
                                cancelText="No"
                            >
                                {/* <Button size="small"> */}
                                <CloseCircleTwoTone twoToneColor="red" />
                                {/* </Button> */}
                            </Popconfirm>
                        </>
                    );
                } else return true;
            },
        },

        {
            title: 'Amount',
            // dataIndex: 'amount',
            render: (record) => {
                const { discount, amount } = record;

                var final = amount;

                if (discount) {
                    if (discount.type === '%') {
                        final = amount - (amount * discount.value) / 100;
                    } else {
                        final = amount - discount.value;
                    }
                }

                return (
                    <span>
                        {discount ? <Rupee value={parseFloat(final.toFixed(roundValue))}></Rupee> : parseFloat(amount.toFixed(roundValue))}
                        {discount ? (
                            <Text type="danger" delete>
                                <Rupee value={parseFloat(amount.toFixed(roundValue))}></Rupee>
                            </Text>
                        ) : null}
                    </span>
                );
            },
        },
        {
            title: '#',
            render: (record) => {
                return (
                    <>
                        <Popconfirm
                            title="Are you sure you want to delete the item ? "
                            onConfirm={() => deleteSelected(record)}
                            onCancel={() => {}}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button size="small">
                                <DeleteFilled />
                            </Button>
                        </Popconfirm>

                        <MoreAction callback={record.callback} record={record} />
                    </>
                );
            },
        },
        // {
        //     title: '',
        //     key: '',
        //     render: (record) => {
        //         return (

        //         );
        //     },
        // },
    ];

    // Calculation to identify total on bill
    let total = 0;

    let subTotal = 0;

    // Find the total of items from the selected list
    items.forEach((entry) => {
        total = total + entry.quantity * parseFloat(entry.amount);

        // Row level discount is saved in each record
        const { discount, amount } = entry;

        // var final = amount;
        if (discount) {
            if (discount.type === '%') {
                total = total - (amount * discount.value) / 100;
            } else {
                total = total - discount.value;
            }
        }
    });

    subTotal = total;

    // Bill level discount if applied
    if (discount) {
        if (discount.type === '%') {
            total = total - (total * discount.value) / 100;
        } else if (discount.type === '₹') {
            if (discount.value <= subTotal) total = total - discount.value;
            else {
                setDiscount({});
                onUpdate({});
            }
        }
    }

    // coupon level discount if applied
    if (guest && guest.drAppointment && guest.drAppointment.da_couponno) {
        total = total - guest.drAppointment.da_couponvalue;
    } else if (coupon && coupon.coupon_value) {
        if (total >= coupon.coupon_value) total = total - coupon.coupon_value;
        else {
            setCoupon();
            onUpdateCoupon({});
        }
    }

    return (
        <div className="summary-content">
            {items.length ? (
                <>
                    {/* <Title level={3}>Proforma Invoice</Title> */}
                    <div className="bill-table-wrapper">
                        <Table
                            className="bill-table"
                            rowKey={(entry) => entry.itm_code}
                            scroll={{ x: true }}
                            pagination={false}
                            columns={columns}
                            dataSource={items}
                            size="small"
                            summary={(pageData) => {
                                let total = 0;

                                // We need to iterate through the rows to add , apply discount and arrive the final value
                                pageData.forEach((entry) => {
                                    var rowTotal = entry.quantity * parseFloat(entry.amount);

                                    // If row level discount is needed we have to mention that

                                    const { discount, amount } = entry;

                                    // var final = amount;

                                    if (discount) {
                                        if (discount.type === '%') {
                                            rowTotal = amount - (amount * discount.value) / 100;
                                        } else {
                                            rowTotal = amount - discount.value;
                                        }
                                    }

                                    total = total + rowTotal;
                                });

                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>
                                            <Table.Summary.Cell>Total</Table.Summary.Cell>
                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            <Table.Summary.Cell></Table.Summary.Cell>

                                            {/* <Table.Summary.Cell></Table.Summary.Cell> */}
                                            <Table.Summary.Cell>
                                                <Text>
                                                    <Rupee value={total}></Rupee>
                                                </Text>
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        />
                    </div>

                    {/* Bottom Section */}
                    <div className="summary-bottom">
                        <div className="right">
                            {/* <Title level={5}>Bill Details</Title> */}

                            <div className="section-content">
                                <div className="section-contents">
                                    <div className="remarks-section">
                                        <div className="left">
                                            <p level={3}>
                                                <strong>Remarks</strong>
                                            </p>

                                            <TextArea value={remarks} onChange={onUpdateRemarks}></TextArea>
                                        </div>
                                    </div>

                                    <div className="bill-summary-bottom">
                                        <div className="sub-section">
                                            <div className="section-row">
                                                <div className="section-label">
                                                    <Text type="secondary">Sub Total</Text>
                                                </div>
                                                <div className="section-value">
                                                    <strong>{parseFloat(subTotal.toFixed(roundValue))}</strong>
                                                </div>
                                            </div>

                                            <div className="section-row">
                                                <div className="section-label">
                                                    <Text type="secondary">Discount</Text>

                                                    <Button
                                                        className="bill-discount-button"
                                                        size="small"
                                                        onClick={() => {
                                                            setVisible(true);
                                                        }}
                                                    >
                                                        Add
                                                    </Button>
                                                    {/**Cancel the applied  discount */}
                                                    {discount && discount.type ? (
                                                        <Button
                                                            style={{ marginLeft: '50px', marginTop: '15px' }}
                                                            className="cancel-coupon-button"
                                                            size="small"
                                                            onClick={() => {
                                                                setDiscount({});
                                                                onUpdate({});
                                                            }}
                                                        >
                                                            Cancel
                                                        </Button>
                                                    ) : null}
                                                </div>
                                                <div className="section-value">
                                                    <strong>
                                                        {discount && discount.type === '₹' ? discount.type : null}{' '}
                                                        {discount && discount.value ? discount.value : 0}{' '}
                                                        {discount && discount.type === '%' ? discount.type + 'age' : null}
                                                    </strong>
                                                </div>
                                            </div>
                                            {guest && guest.drAppointment && guest.drAppointment.da_couponvalue ? (
                                                <>
                                                    <div className="section-row">
                                                        <div className="section-row">
                                                            <div className="section-label">
                                                                <Text type="secondary">Coupon</Text>
                                                            </div>
                                                        </div>
                                                        <div className="section-value">
                                                            <strong>{guest.drAppointment.da_couponvalue}</strong>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="section-row">
                                                        <div className="section-row">
                                                            <div className="section-label">
                                                                <Text type="secondary">Coupon</Text>
                                                            </div>
                                                            {coupon && coupon.coupon_no ? (
                                                                <>
                                                                    {/* <Alert message={validMessage}description={coupon.cmgdcpn_remarks} />  */}
                                                                    <div>
                                                                        <Button
                                                                            style={{ marginLeft: '10px' }}
                                                                            className="cancel-coupon-button"
                                                                            size="small"
                                                                            onClick={() => {
                                                                                setCoupon(null);
                                                                            }}
                                                                        >
                                                                            Cancel
                                                                        </Button>
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <div>
                                                                    <Button
                                                                        style={{ marginLeft: '10px' }}
                                                                        className="add-coupon-button"
                                                                        size="small"
                                                                        onClick={() => {
                                                                            setCouponVisible(true);
                                                                        }}
                                                                    >
                                                                        Add
                                                                    </Button>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="section-value">
                                                            {/* {coupon && coupon.dataValues ? coupon.dataValues.cmgdcpn_couponnumber : null}  */}

                                                            <strong>{coupon ? coupon.coupon_value : 0}</strong>
                                                        </div>
                                                    </div>
                                                </>
                                            )}

                                            {/* <div className="section-row">
                                        <div className="section-label">
                                            <Text type="secondary">Discount Amount</Text>
                                        </div>
                                        <div className="section-value">
                                            {discount && discount.type == '%' ? (
                                                <strong> {parseFloat(((subTotal * discount.value) / 100).toFixed(roundValue))}</strong>
                                            ) : (
                                                <strong>{discount.value}</strong>
                                            )}
                                        </div>
                                    </div> */}
                                        </div>
                                        {/* Discount Section Ends */}

                                        {/* Net Amount */}
                                        <div className="section-row net-amount">
                                            <div className="section-label">
                                                <Title level={4}>Net Amount</Title>
                                            </div>
                                            <div className="section-value">
                                                <Title level={4}>
                                                    {' '}
                                                    <Rupee value={parseFloat(total.toFixed(roundValue))}></Rupee>
                                                </Title>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Net Amount Ends */}
                            </div>

                            {/* <Button
                                type="dashed"
                                style={{
                                    alignSelf:
                                        'flex-end'
                                }}>
                                Apply Coupon
                                </Button> */}
                        </div>
                    </div>

                    {/* Bottom Section Ends */}
                </>
            ) : (
                <>
                    <p>Nothing added yet</p>
                </>
            )}

            {/* Modal for Adding Discount */}
            <Modal
                destroyOnClose={true}
                footer={null}
                title="Apply Discount"
                visible={visible}
                okText="Okay"
                onOk={() => {
                    setVisible(false);
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <DiscountModal
                    callback={(values) => {
                        setVisible(false);

                        onUpdate(values);

                        // callback(values);
                    }}
                    discount={discount}
                    subTotal={subTotal}
                />
            </Modal>
            {/* Modal for Adding Discount Ends */}

            {/* Modal for Adding Coupon */}
            <Modal
                destroyOnClose={true}
                footer={null}
                title="Apply Coupon"
                visible={CouponVisible}
                okText="Okay"
                onOk={() => {
                    setCouponVisible(false);
                }}
                onCancel={() => {
                    setCouponVisible(false);
                }}
            >
                <CouponModal
                    callback={(values) => {
                        setCouponVisible(false);

                        onUpdateCoupon(values);

                        // callback(values);
                    }}
                    setCouponVisible={setCouponVisible}
                    subTotal={subTotal}
                />
            </Modal>
            {/* Modal for Adding Coupon Ends */}
        </div>
    );

    /**
     * More Action for Row
     *
     * @returns
     */
    function MoreAction({ callback, record }) {
        const [visible, setVisible] = useState(false);

        const handleClick = (params) => {
            switch (params.key) {
                case 'item_discount':
                    setVisible(true);

                    break;
                case 'item_discount':
                    break;
                default:
            }
        };

        const menu = (
            <Menu onClick={handleClick}>
                <Menu.Item key="item_discount" className="item-discount">
                    Add Discount
                </Menu.Item>
            </Menu>
        );

        return (
            <>
                <Dropdown overlay={menu} placement="bottomLeft">
                    <Button size="small">
                        <MoreOutlined />
                    </Button>
                </Dropdown>

                {/* Modal for Adding Discount */}
                <Modal
                    destroyOnClose={true}
                    footer={null}
                    title="Item Discount"
                    visible={visible}
                    okText="Okay"
                    onOk={() => {
                        setVisible(false);
                    }}
                    onCancel={() => {
                        setVisible(false);
                    }}
                >
                    <DiscountModal
                        callback={(values) => {
                            setVisible(false);

                            callback(values);

                            onUpdateitmdiscount(values);
                        }}
                        subTotal={record.amount}
                        avoidRemark={true}
                        discount={record.discount}
                    />
                </Modal>
                {/* Modal for Adding Discount Ends */}
            </>
        );
    }
}

const layout = {
    labelCol: {
        // span: 8
    },
    wrapperCol: {
        // span: 16
    },
};
const tailLayout = {
    wrapperCol: {
        // offset: 8,
        // span: 16
    },
};

/**
 *
 *
 * @returns ]
 */
function DiscountModal({ callback, subTotal, discount, avoidRemark }) {
    const [initialValues, setInitailValues] = useState(discount);

    const onFinish = (values) => {
        callback(values);
    };

    return (
        <>
            <p>Mention the discount</p>

            <Form layout="vertical" name="basic" initialValues={{ ...initialValues, type: '%', remember: true }} onFinish={onFinish}>
                <Row gutter={20}>
                    <Col xs={24} md={12} xl={8}>
                        <Form.Item name="type" label="Discount" rules={[{ required: true, message: 'Please Select Discount Type' }]}>
                            <Radio.Group>
                                <Radio value="%">In %age</Radio>
                                <Radio value="₹">In Amount</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={20}>
                    <Col xs={24} md={12} xl={8}>
                        <Form.Item
                            label="Value"
                            name="value"
                            dependencies={['type']}
                            rules={[
                                { required: true, message: 'Please Input your Amount' },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('type') === '₹') {
                                            if (value > subTotal) {
                                                return Promise.reject(new Error('Discount should not exceed net amount.'));
                                            } else if (value && value <= 0) {
                                                return Promise.reject(new Error('Discount cannot be negative or zero'));
                                            } else return Promise.resolve();
                                        } else if (!value || getFieldValue('type') === '%') {
                                            if (value > 100) {
                                                return Promise.reject(new Error('Discount should not exceed 100%.'));
                                            } else if (value && value <= 0) {
                                                return Promise.reject(new Error('Discount cannot be negative or zero'));
                                            } else return Promise.resolve();
                                        } else {
                                            return Promise.resolve();
                                        }
                                    },
                                }),
                            ]}
                        >
                            <InputNumber />
                        </Form.Item>
                    </Col>
                </Row>
                {avoidRemark ? null : (
                    <Row gutter={20}>
                        <Col xs={24} md={24} xl={24}>
                            <Form.Item name="remarks" label="Remarks" rules={[{ required: true, message: 'Please Enter Remarks' }]}>
                                <TextArea rules={[{ required: true }]} />
                            </Form.Item>
                        </Col>
                    </Row>
                )}
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}

/**
 *
 *
 * @returns
 */
function CouponModal({ callback, setCouponVisible, subTotal }) {
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const [coupon, setCoupon] = useState();

    /**
     * Check whether the applied coupon is valid and also does the amount exceed total
     * Onfinish set coupon and deduct the applied coupon ammount
     * @param {*} event
     */

    //

    const onFinish = async (values) => {
        setLoading(true);

        var value = values.coupon;

        if (value) {
            Bills.validatecoupon(value)
                .then((result) => {
                    if (result.success) {
                        setCoupon(result);

                        //Check whether the coupon amount is greater than the net total

                        if (result.data.coupon_value > subTotal) {
                            message.error('Please make sure the Coupon amount do not exceed the net Amount');

                            setLoading(false);

                            // setCouponVisible(false)

                            return false;
                        }

                        setLoading(false);

                        callback(result.data);
                    } else {
                        setLoading(false);

                        message.error('Invalid Coupon');
                    }
                })
                .catch((error) => {
                    console.log(error);

                    setLoading(false);

                    message.warning('The Entered Coupon is an Invalid Coupon. Please Enter a Valid Coupon');
                });
        }
    };

    return (
        <>
            <p>Enter the Coupon Details</p>

            <Form
                form={form}
                layout="vertical"
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                rules={[{ required: true, message: 'Please Enter the Coupon Number' }]}
            >
                <Row gutter={20}>
                    <Col xs={24} md={24} xl={24}>
                        <Form.Item
                            label="Coupon"
                            name="coupon"
                            rules={[{ required: true, message: 'Please Enter Coupon' }]}
                            // onChange={validateCoupon}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    <Button loading={loading} type="primary" htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
