/***
 *
 *
 * @description
 * @author Jinisha kappil
 * Custom  componet used for user search.
 * This componet will accept a callback function that will get triggered once a user selects a guest .
 * Here we Accepts two attributes model,enableVisit
 * 
 * We enter value for searching that time it trigger Opreg Api based on query. It listing results based on query 
 *  Next table is showing based on opvisit id corresponding with the opno 
 *  
 */



import React, { useState, useEffect } from 'react';

import { Modal, Button } from 'antd';

import { DateUtils, } from 'soxo-bootstrap-core';

import AdvanceSearch from './advance-search';

/**
 * This  Component  is used for Common  Search . Is Based On criteria and Search By its comes from model  .
 * In this componet open a model and and select searchby and criteria after that it load corresponding results
 *
 * @param {*} param0
 * @returns
 */
export default function OpregSearch({ model, enableVisit, callback,title }) {

    let [visible, setVisible] = useState(false);

    return (
        <div className="head-selector">
            <Button
                className="billing"
                onClick={() => {
                    setVisible(true);
                }}
                type="secondary"
                shape="round"
                // size="small"
                color="green"
            >
                {/* Search{' '} */}
            {title}
            </Button>

            <Modal
                destroyOnClose={true}
                visible={visible}
                // title='Advance Search'
                footer={null}
                className=""
                onCancel={() => {
                    setVisible(false);
                    //setResult(null);
                }}
                width={'80%'}
                maskClosable={false}
            >
             {/** Functionality for search is done in advancesearch component */}
                <AdvanceSearch setVisible={setVisible} model={model} enableVisit={enableVisit}  callback={callback} title={"Advanced Search"} />
               
            </Modal>
        </div>
    );
}
