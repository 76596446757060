/**
 * @author Hasna
 * Component to add lab test
 * @returns
 */

import { useEffect, useState } from 'react';

import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';

import { Form, Select, Row, Col, Button, Checkbox, Space, message } from 'antd';

import TextArea from 'antd/lib/input/TextArea';

import { InputComponent, ReferenceSelect } from 'soxo-bootstrap-core';

import './lab-test-add.scss';

import { LabGenSettLists, Samples } from '../../../../models';

const { Option } = Select;

export default function LabTestAdd({ id, edit, testNumber, callback }) {
    const [unit, setUnit] = useState();

    const [format, setFormat] = useState();

    const [loading, setLoading] = useState(false);

    const [isResultOptionsEditable, setisResultOptionsEditable] = useState(true);

    const [form] = Form.useForm();

    useEffect(() => {
        // Get labtest data when edit is true, to show data in modal
        if (edit) {
            getLabTestData(id);
        }
    }, []);

    /**
     * Get lab report data with id
     */
    const getLabTestData = (id) => {
        Samples.getLabReportWithId(id).then((result) => {
            var initial = {
                particulars: result.lbtr_particulrs,
                value_type: result.lbtr_vtype,
                minimum: result.lbtr_refmin,
                maximum: result.lbtr_refmax,
                range: result.lbtr_refrange,
                editable: result.lbtr_isrefrangeeditable,
                unit: result.lbtr_unit,
                lower_bound: result.lbtr_lbound,
                upper_bound: result.lbtr_ubound,
                result_format: result.lbtr_resformat,
                number: result.lbtr_slno,
            };

            // This is done to handle case when lbtr_otherdetails1 is null
            if (
                result.lbtr_otherdetails1 &&
                result.lbtr_otherdetails1.lab_result_parameters &&
                result.lbtr_otherdetails1.lab_result_parameters.option
            ) {
                initial = {
                    ...initial,
                    parameters: result.lbtr_otherdetails1.lab_result_parameters.option,
                };
            } else {
                initial = {
                    ...initial,
                    parameters: result.lbtr_otherdetails1,
                };
            }
            if (result.lbtr_isfulllenresult === 'Y') {
                // Set checkbox values as true or false according to column value
                initial = {
                    ...initial,
                    result: true,
                };
            } else {
                initial = {
                    ...initial,
                    result: false,
                };
            }

            if (result.lbtr_isremovable === 'Y') {
                initial = {
                    ...initial,
                    removable: true,
                };
            } else {
                initial = {
                    ...initial,
                    removable: false,
                };
            }

            if (result.lbtr_isrefrangeeditable === 'Y') {
                initial = {
                    ...initial,
                    editable: true,
                };
            } else {
                initial = {
                    ...initial,
                    editable: false,
                };
            }

            // Data prepopulated in form
            form.setFieldsValue({ ...initial });

            // setting options
            getOptions();
        });
    };

    //To identify default value for unit and format
    function identifyDefaultValue(options = []) {
        let matching = {};

        if (options) {
            options.forEach(async (record) => {
                // mode to get unit
                if (record.lgsl_mode === 'LABUN') {
                    matching = record;
                }
                // mode to get result format
                else if (record.lgsl_mode === 'LBFMT') {
                    matching = record;
                }
            });
        }

        if (matching) {
            return matching;
        }
    }

    const onFinish = (values) => {
        var editable, removable, result;

        setLoading(true);

        // If checkbox is checked value is Y otherwise value is N
        values.editable ? (editable = 'Y') : (editable = 'N');
        values.removable ? (removable = 'Y') : (removable = 'N');
        values.result ? (result = 'Y') : (result = 'N');

        var formBody = {
            ...values,
            editable: editable,
            removable: removable,
            result: result,
            item_pointer: testNumber,
        };

        // In case of edit , Lab test is edited
        if (edit) {
            Samples.editLabTest({ formBody, id }).then((result) => {
                if (result.success === true) {
                    message.success('Lab Test edited successfully');
                    setLoading(false);
                    callback();
                }
            });
        } else {
            // New labtest created
            Samples.createLabTest({ formBody }).then((result) => {
                if (result.success === true) {
                    message.success('Lab Test created successfully');
                    setLoading(false);
                    callback();
                }
            });
        }
    };

    /**
     *  Setting options for VALUE TYPE
     */
    function getOptions() {
        // get field value 
        let value = form.getFieldsValue();
      // checking conditions
        if (value && value.value_type === 'Select') {
            setisResultOptionsEditable(true);
        } else {
            setisResultOptionsEditable(false);
        }
    }

    /**
     * OnChange of options 
     * @param {*} event
     */
    function onChange(event) {
        // checking events based on onchange
        if (event === 'Select') {
            setisResultOptionsEditable(true);
        } else {
            setisResultOptionsEditable(false);
        }
    }

    return (
        <div>
            <Form
                form={form}
                layout="vertical"
                initialValues={
                    !edit
                        ? {
                              unit: unit,
                              result_format: format,
                              value_type: 'Select',
                              parameters: [{ label: 'Positive', value: '(Positive)' }],
                          }
                        : null
                }
                onFinish={onFinish}
            >
                <Row gutter={26}>
                    <Col span={12}>
                        <Form.Item label="PARTICULARS" name="particulars">
                            <InputComponent />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="VALUE TYPE" name="value_type">
                            <Select onChange={onChange}>
                                <Option key="Select" value="Select">
                                    Select
                                </Option>

                                <Option key="Text" value="Text">
                                    Text
                                </Option>

                                <Option key="Numeric" value="Numeric">
                                    Numeric
                                </Option>

                                <Option key="Paragraph" value="Paragraph">
                                    Paragraph
                                </Option>

                                <Option key="Heading" value="Heading">
                                    Heading
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                {/**
                 * Result option sectin starts
                 */}
                {isResultOptionsEditable ? (
                    <div className="result-options">
                        <Form.Item label="RESULT OPTIONS">
                            <Row gutter={50}>
                                <Form.List name="parameters">
                                    {(fields, { add, remove }) => (
                                        <>
                                            <Col span={20}>
                                                {fields.map(({ key, name, ...restField }) => (
                                                    <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                        <Form.Item {...restField} name={[name, 'label']}>
                                                            <InputComponent />
                                                        </Form.Item>
                                                        <Form.Item {...restField} name={[name, 'value']}>
                                                            <InputComponent />
                                                        </Form.Item>
                                                        <Button type="secondary" onClick={() => remove(name)} size="small">
                                                            <MinusCircleOutlined />
                                                            Delete
                                                        </Button>
                                                    </Space>
                                                ))}
                                            </Col>
                                            <Col span={4}>
                                                <div className="add-button">
                                                    <Form.Item>
                                                        <Button type="primary" onClick={() => add()}>
                                                            <PlusOutlined />
                                                            Add
                                                        </Button>
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                        </>
                                    )}
                                </Form.List>
                            </Row>
                        </Form.Item>
                    </div>
                ) : null}
                {/**Result option section ends */}

                <br />
                {/**Range section starts */}
                <Row gutter={26}>
                    <Col span={12}>
                        <Form.Item label="REF. RANGE">
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item label="MIN" name="minimum">
                                        <InputComponent />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="MAX" name="maximum">
                                        <InputComponent />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="REF. RANGE (DISPLAY)" name="range">
                            <TextArea rows={4} />
                        </Form.Item>
                    </Col>
                </Row>

                {/**Range section ends */}

                <Form.Item name="editable" valuePropName="checked">
                    <Checkbox>Is Ref.Range editable in result entry?</Checkbox>
                </Form.Item>

                <Row gutter={26}>
                    <Col span={6}>
                        <Form.Item label="SL No." name="number">
                            <InputComponent />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="UNIT" name="unit">
                            <ReferenceSelect
                                config={{
                                    // limit: 50,
                                    queries: [
                                        {
                                            field: 'lgsl_mode',
                                            // mode to get unit
                                            value: 'LABUN',
                                        },
                                    ],
                                }}
                                defaultValueCondition={identifyDefaultValue}
                                label="lgsl_desc"
                                mode="nura-base"
                                model={LabGenSettLists}
                                field={'lgsl_id'}
                                onChange={(e) => setUnit(e)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="LBOUND" name="lower_bound">
                            <InputComponent />
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item label="UBOUND" name="upper_bound">
                            <InputComponent />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={26}>
                    <Col span={12}>
                        <Form.Item label="RESULT FORMAT" name="result_format">
                            <ReferenceSelect
                                config={{
                                    // limit: 50,
                                    queries: [
                                        {
                                            field: 'lgsl_mode',
                                            // mode to get format
                                            value: 'LBFMT',
                                        },
                                    ],
                                }}
                                defaultValueCondition={identifyDefaultValue}
                                label="lgsl_desc"
                                mode="nura-base"
                                model={LabGenSettLists}
                                field={'lgsl_id'}
                                onChange={(e) => setFormat(e)}
                            />
                        </Form.Item>
                    </Col>

                    <Col span={12}>
                        <Form.Item label="L.I PARAM CD">
                            <InputComponent />
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    {' '}
                    <Form.Item name="removable" valuePropName="checked">
                        <Checkbox>Is Removable in result entry?</Checkbox>
                    </Form.Item>
                    <Form.Item name="result" valuePropName="checked">
                        <Checkbox>Is Full width in result print?</Checkbox>
                    </Form.Item>
                </Row>

                <Row>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Submit
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            onClick={() => {
                                callback();
                            }}
                        >
                            Cancel
                        </Button>
                    </Form.Item>
                </Row>
            </Form>
        </div>
    );
}
