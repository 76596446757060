/**
 * SCRFilesLinks Model
 *
 *
 *
 *
 */
import React from 'react';

import Model from './models';

import { ApiUtils } from 'soxo-bootstrap-core';

import { Card, Tag } from 'antd';

class SCRFilesLinks extends Model {
    constructor() {
        super();

        this.fields = [
            {
                field: 'name',

                caption: 'Name',
            },
        ];
    }

    get id() {
        return 'scrfileslinks';
    }

    get getEndpoint() {
        return 'scrfileslinks';
    }

    get path() {
        return `scrfileslinks`;
    }

    get getName() {
        return this.modelName;
    }

    get modelName() {
        return `scrfileslinks`;
    }

    //  getReports = (opb_id) =>{

    //         return ApiUtils.get({

    //             url: `opbill/load-scr-filelink/${opb_id}`,

    //         });

    //     }

    

    /**
     * get all scrfilelinks data
     * @param {*} param0 
     * @returns 
     */
    loadData = ({ start_time, end_time }) => {
        return ApiUtils.get({
            baseUrl: 'https://dev.nura.in/dev/',
            headers:{
                db_ptr:'un'
            },
            url: `scrfileslinks/get-scrfileslinks?date_gte=${start_time}&date_lte=${end_time}`,
        });
    };

    /**
     *
     * @param {*} param0
     * @returns
     */
    getDetails = ({ op_no, accessionno, mode }) => {
        return ApiUtils.get({ url: `modalitygroup/Details?op_no=${op_no}&accessionno=${accessionno}&mode=${mode}` });
    };

    /**
     * update scrfileslinks
     * @param {*} formBody
     * @returns
     */
    update = (opno, formBody) => {
        return ApiUtils.put({ url: `scrfileslinks/${opno}`, formBody });
    };
    /**
     *
     * @param {*} itemptr
     * @returns
     */

    getOptions = (itemptr) => {
        return ApiUtils.get({ url: `service-result-map/drop-down/${itemptr}` });
    };

    /**
     * 
     * @param {*} value 
     * @returns 
     */

    getRecord = (value) => {
        return ApiUtils.get({ url: `scrfileslinks/scr/${value}` });
    };

    // Load scrfilelink
    getReports = (id, item_ptr) => {
        return ApiUtils.get({
            // url: `opbill/load-scr-filelink/${id}`,
            url: `opbill/load-scr-filelink/${id}?item_ptr=${item_ptr}`,
            // url: `opbill/load-scr-filelink?accession_no=${accessionno}&op_no=${opno}`,
        });
    };

    loadScrfile = (id) => {
        return ApiUtils.get({
            url: `scrfileslinks/get-scrfile/${id}`,
        });
    };

    //Upload Report to scrfilelink
    // uploadReport = (formData) => {
    uploadReport = (formData, accession_no) => {
        console.log("eeeeeeeeeeeee", formData)
        return fetch(process.env.REACT_APP_ON_PREMISE_URL + `/scrfileslinks/upload-file?accession_no=${accession_no}`, {
            // return fetch(`https://blrffdkhportal.nura-in.com/FileFSLinkTest/files/upload-file`, {
            // return fetch(`scrfileslinks/upload-file`, {

            // return fetch(`https://blrffdkhportal.nura-in.com/FileFSLinkTest/dev/scrfileslinks/upload-file`, {
            // Your POST endpoint
            // mode: 'no-cors',
            method: 'POST',
            headers: {
                //   'App-Type': 313,
                //   'App-Version': '1.0.1',
                // Authorization: 'Bearer ' + localStorage.access_token,
                // type:'multipart/formData',
                // 'db_ptr': 'nura'
            },
            //   credentials: 'include',
            body: formData,
        }).then(
            (result) => {
                console.log('result', result)
                // return result.json();
                return result
            },
            (error) => {
                console.log(error);
                return error;
            },
            (progress) => {
                console.log(progress);
                return progress;
            }
        );
        // return ApiUtils.post({
        //     url: `scrfileslinks/upload-file?accession_no=${accession_no}`,
        //     formData,
        // });
    };

    /**
     * Api call to publish report
     * @param {*} opb_id 
     * @returns 
     */
    publishReport = (opb_id) => {
        return ApiUtils.post({
            url: `scrfileslinks/publish-report/${opb_id}`
        })
    }

}


    GuestCard = GuestCard;

    ResearchCard = GuestCard;
    
function GuestCard({ record, selected = {} }) {
    return (
        <Card className={`card ${selected.AccessionNo === record.AccessionNo ? 'selected' : ''}`}>
            <h3 className="title">Case No : {record.sflink_refno}</h3>

            <p>{/* <Tag color="magenta">{record.Modality}</Tag> */}</p>

            {/* <p>Accession : {record.AccessionNo}</p> */}

            <p className="no-margin">{record.sflink_dttm}</p>
        </Card>
    );
}
function ResearchCard({ record, selected = {} }) {
    return (
        <Card className={`card ${selected.AccessionNo === record.AccessionNo ? 'selected' : ''}`}>
            <h3 className="title">{record.Name}</h3>

            <h4 className="values">
                {record.Age} <Tag color={record.Gender === 'Male' ? 'blue' : 'pink'}> {record.Gender}</Tag> {selected.opno}
            </h4>

            <p>
                <Tag color="magenta">{'LUNG'}</Tag>
            </p>

            <p>Accession : {record.AccessionNo}</p>

            <p className="no-margin">Process started on {record.ProcessStartTime}</p>
        </Card>
    );
}

export default SCRFilesLinks;
