/***
 *
 *
 * @description
 * @author jinshakappil
 */
import React, { useEffect, useState, useContext } from 'react';

import { Opvisits } from '../../../../models';

import { Skeleton, Timeline } from 'antd';

import { DateUtils, Location, GlobalContext } from 'soxo-bootstrap-core';

import moment from 'moment';

import { CheckCircleTwoTone, HistoryOutlined } from '@ant-design/icons';

import './patient-visits.scss';

/**
 * Component accepts the OPNO and displays the patient history/visits
 */
export default function PatientHistory({ op_number, callback, visit_id, doctorFilter, histories }) {

    // Variable to save the selected visit from the list of visits loaded under the opno
    const [selected, setSelected] = useState(null)

    // Patient history

    const [patientHistory, setPatientHistory] = useState([]);

    const { user = {}, isMobile, dispatch } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    let urlParams = Location.search();

    useEffect(() => {

        getPatientHistory(op_number);

    }, []);

    /**
     * Get the patient history
     *
     * @param {*} op_number
     */
    function getPatientHistory(op_number) {

        setLoading(true);

        /**
         * 
         * doctorFilter is a attribute is Used to filter data by using Staff_id
         * We create a patient visit list if the logged in is a doctor
         * Staff_id passed a doctor_ptr in  opvisit Api .
         */

        if (doctorFilter) {

            let doctorPtr = user.staff_id;

            // staff_id is passed if user wants to view only specific cases.
            Opvisits.getPatientHistory(op_number, doctorPtr).then((result) => {

                if (result.result.length) {

                    setPatientHistory(result.result);

                    setSelected(result.result[0]);

                }

                setLoading(false);

            });

        } else {

            Opvisits.getPatientHistory(op_number).then((result) => {

                if (result.result.length) {

                    setPatientHistory(result.result);

                    setSelected(result.result[0]);

                }

                setLoading(false);

            });

        }

    }

    return (

        <>

            <div className="patient-visit-modal">

                <div className="card">

                    <div className="card-header">

                        <div className="card-title">

                            <h3 className='heading'>Visit History</h3>

                            <img

                                className="card-icon"

                                src={require(`../../../../assets/icons/external-history-computer-programming-icons-flaticons-lineal-color-flat-icons.png`)}
                            />

                        </div>

                        <p>{loading ? 'Loading history' : patientHistory.length === 1 ? `1 Visit` : `${patientHistory.length} Visits`}</p>

                    </div>

                    {loading ? (

                        <Skeleton />

                    ) : (

                        <div>

                            {patientHistory.map((ele) => {

                                return (

                                    <div

                                        onClick={() => {

                                            setSelected(ele);

                                            // if (!histories) {

                                            Location.navigate({ url: `/emr/${ele.visit_id}?op_no=${op_number}` });
                                            // callback(ele.visit_id);
                                            // }
                                            callback(selected.visit_id);

                                        }}
                                        className={`times card card-shadow ${visit_id === ele.visit_id ? 'active' : ''}`}
                                    >

                                        <h3>{DateUtils.formatDate(ele.date)} </h3>

                                        <p className='time'>{moment.tz(ele.time, '').format('hh:mm a')}</p>

                                        {ele.reason ? <p>{ele.reason}</p> : null}

                                        {histories ? (<p>{ele.doctor_name}</p>) : null}
                                        {/* 
                                        {moment.tz(ele.time, '').format('hh:mm a')}

{ele.reason ? <p>{ele.reason}</p> : null}

<p>{ele.doctor_name}</p> */}

                                    </div>

                                );

                            })}



                        </div>)}


                </div>


            </div>

        </>
    );
}
