import React, { useState, useEffect, useContext } from 'react';

import { Tag, Card, Modal, Button, Form, Table, Row, message, Skeleton, Typography, Input, Timeline, Result } from 'antd';

import RegistrationInfo from './../../../common/components/registration-info/registration-info';

import moment from 'moment-timezone';

import { ReloadOutlined } from '@ant-design/icons';

import './lab-summary.scss';

import { Location, GlobalContext, DateUtils } from 'soxo-bootstrap-core';

import { Samples, ResultEntry } from '../../../../models';

const { Title } = Typography;

export default function ResultDetail({ bill_id, mode }) {
    const [loading, setLoading] = useState(true);

    const [resultloading, setResultloading] = useState(false);

    const [result, setResult] = useState([]);

    const [labentries, setLabentries] = useState({ entries: [], index: null });

    const [detail, setDetail] = useState([]);

    const [test, setTest] = useState({});

    const [selected, setSelected] = useState({});

    var urlParams = Location.search();

    const [patient, setPatient] = useState({}); //Patiants list array

    const billno = bill_id;

    useEffect(() => {
        getPatientDetails(urlParams);
    }, []);

    const columns = [
        {
            title: '#',
            key: 'lbtr_particulrs',
            render: (value, item, index) => index + 1,
        },

        {
            title: 'Description',
            dataIndex: 'lbtr_particulrs',
            key: 'lbtr_particulrs',
        },
        {
            title: 'Date',

            render: (value) => {
                const { opbilld } = patient;
                if (opbilld) {
                    var result = opbilld.filter((ele) => {
                        return ele.item.itm_code === value.lbtr_itemptr;
                    });
                    return moment(result[0].opbd_srvddt).format('DD/MM/YYYY');
                } else {
                    return null;
                }
            },
        },
        // {
        //     title: 'Authorise',

        //     render: (value) => {

        //         const{labresult} = result

        //         if(labresult === 'N' && labresult === null  && labresult === false ){
        //             return  (
        //                 <Tag color="orange"> Not authorised</Tag>
        //             )
        //         }else{
        //             <Tag color="orange">  Authorise</Tag>

        //         }
        //         // lbtrsd_isclosed

        //     },
        // },
        {
            title: 'Result',
            render: (item, record) => {
                return (
                    <>
                        {/* Valus */}
                        {item.values && item.values.result}

                        {item.values && item.values.status === 'Pending for Submission' ? <Tag color="orange">Pending for Submission</Tag> : null}
                    </>
                );
            },
            key: 'lbtrsd_result',
        },

        {
            width: 150,
            title: 'Range',
            dataIndex: 'lbtr_refrange',
            key: 'lbtr_refrange',
        },

        {
            title: 'Unit',
            dataIndex: 'lbtrsd_unit',
            key: 'lbtrsd_unit',
        },
    ];

    const result_columns = [
        {
            title: '#',
            key: 'lbtr_particulrs',
            render: (value, item, index) => index + 1,
        },

        {
            title: 'Description',
            dataIndex: 'lbtrsd_particulrs',
            key: 'lbtrsd_particulrs',
        },
        {
            title: 'Date',

            render: (value) => {
                const { opbilld } = patient;
                if (opbilld) {
                    var result = opbilld.filter((ele) => {
                        return ele.item.itm_code === value.lbtrsd_itemptr;
                    });
                    return moment(result[0].opbd_srvddt).format('DD/MM/YYYY');
                } else {
                    return null;
                }
            },
        },

        {
            title: 'Result',
            render: (item, record) => {
                let isEdit = false;

                if (item.values && item.values.result) {
                    isEdit = true;
                }

                return (
                    <>
                        {isEdit ? (
                            <>
                                {item.values.result}

                                {item.values && item.values.status === 'Result Updated' ? <Tag color="orange">Result Updated</Tag> : null}
                            </>
                        ) : (
                            <>{item.lbtrsd_result}</>
                        )}
                    </>
                );
            },
            key: 'lbtrsd_result',
        },

        {
            width: 150,
            title: 'Range',
            dataIndex: 'lbtrsd_refrange',
            key: 'lbtrsd_refrange',
        },

        {
            title: 'Unit',
            dataIndex: 'lbtrsd_unit',
            key: 'lbtrsd_unit',
        },
        {
            fixed: 'right',
            render: (value) => {
                const { labresult } = result;
                if (value.lbtrsd_isclosed === 'N') {
                    return <Tag color="orange"> Not authorised</Tag>;
                } else {
                    return <Tag color="orange"> Authorised</Tag>;
                }
            },
        },
    ];

    /**
     *
     * Patient Data
     *
     * @param {*} urlParms
     */
    async function getPatientDetails(urlParms) {
        setLoading(true);

        Samples.getLabDetail(billno, mode).then((result) => {
            setDetail(result);

            let { opbilld, opbill } = result;

            if (opbilld.length) {
                setSelected(opbilld[0]);

                opbilld = opbilld[0];

                loadTestData(opbill, opbilld);
            }
            setPatient(result);

            setLoading(false);
        });
    }

    /**
     * Get the Lab Test Detail
     *
     * @param {*} param0
     */
    function getLabTest({ labTest }) {
        Samples.getLabTest(labTest.lbt_itemptr).then((result) => {
            if (result.length) {
                setTest(result[0]);

                // setDetail(result);
            }
        });
    }

    /**
     * Get the Lab Report
     *
     */
    function getLabReport({ labTest }) {
        Samples.getLabReport(labTest.lbt_itemptr, billno).then((result) => {
            // setReport(result);

            setLabentries({ entries: result });
        });
    }

    /**
     * Get the Lab Report
     *
     */
    function getLabResult(opbill, opbilld) {
        setResultloading(true);

        Samples.getLabResult(opbilld.opbd_hdrid, opbilld.opbd_itemptr).then((result) => {
            setResult(result[0] || false);

            setResultloading(false);

            if (result[0]) {
                getLabResultDetail(result[0], opbill, opbilld);
            }

            // setReport(result);
        });
    }

    /**
     * Get the Lab Report
     *
     */
    function getLabResultDetail(labresult, opbill, opbilld) {
        Samples.getLabResultDetail(labresult, opbill.opb_id, opbilld.opbd_itemptr).then((result) => {
            // setLabentries(result);
            // setReport(result);
        });
    }

    /**
     * load the lab test data
     */
    function loadTestData(opbill, opbilld) {
        // Set result loading
        setResultloading(true);

        // opbilld // opbiild lab test
        if (opbilld && opbilld.labTest) {
            // get lab test
            getLabTest(opbilld);

            getLabReport(opbilld);

            getLabResult(opbill, opbilld);
        } else {
            setResultloading(false);

            message.error('Laboratory Test is missing');
        }
    }

    function refresh() {
        var urlParams = Location.search();

        getPatientDetails(urlParams);
    }

    const { opbill, opbilld } = patient;

    return (
        <section className="lab-summary">
            <div className="page-header">
                <div>
                    <Title level={3}>Laboratory Summary</Title>

                    <p className="size-hint">{loading ? 'Loading data' : 'Please find below results'}</p>
                </div>

                <Button onClick={refresh} type="secondary" size={'small'}>
                    <ReloadOutlined />
                </Button>
            </div>

            {/* Loading Indicator */}
            {loading ? (
                <Card className="card-shadow card">
                    <Skeleton active />
                </Card>
            ) : (
                <>
                    {/* <div className="">
                        <RegistrationInfo guest={opbill} />
                    </div> */}

                    <div className="detail-content card">
                        <div className="left">
                            <Title level={5}>Test Names ({opbilld.length})</Title>

                            <div className="billd-records">
                                {opbilld.map((entry, key) => {
                                    return (
                                        <div
                                            onClick={() => {
                                                setSelected(entry);

                                                loadTestData(detail.opbill, entry);
                                            }}
                                            className={`lab-test card card-shadow ${selected.opbd_id === entry.opbd_id ? 'active' : ''}`}
                                            key={key}
                                        >
                                            {key + 1}. {entry.opbd_itemdesc}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        <div className="right ">
                            {/* <Title level={5}>Result</Title> */}

                            {/* Loading Indicator */}
                            {resultloading ? (
                                <Card className="card-shadow card" style={{ width: '100%' }}>
                                    <Skeleton active style={{ width: '100%' }} />
                                </Card>
                            ) : (
                                <>
                                    <>
                                        {/* <div className="card card-shadow lab-result">
                                            <div className="timeline-wrapper">
                                                <Timeline>
                                                    <Timeline.Item color="green">
                                                        Ordered
                                                        <p>
                                                            <small>{moment.tz(opbill.opb_tm, '').format('DD/MM/YYYY HH:mm')}</small>
                                                        </p>
                                                    </Timeline.Item>

                                                    <Timeline.Item color={selected && selected.status === 'Collected' ? 'green' : 'gray'}>
                                                        Sample Collected
                                                        <p>
                                                            <small>
                                                                {selected && selected.status === 'Collected' ? (
                                                                    <>
                                                                        <></>
                                                                        <small>
                                                                            {moment.tz(result.lbtrs_samcollddttm, '').format('DD/MM/YYYY HH:mm')}
                                                                        </small>
                                                                    </>
                                                                ) : (
                                                                    <>Pending</>
                                                                )}
                                                            </small>
                                                        </p>
                                                    </Timeline.Item>

                                                    <Timeline.Item color={result.lbtrs_issamcolld === 'Y' ? 'green' : 'gray'}>Lab In</Timeline.Item>

                                                    <Timeline.Item color={result.lbtrs_istestdone === 'Y' ? 'green' : 'gray'}>
                                                        Test Done
                                                        <p>
                                                            <small>{moment.tz(result.lbtrs_testdonedttm, '').format('DD/MM/YYYY HH:mm')}</small>
                                                        </p>
                                                    </Timeline.Item>

                                                    <Timeline.Item color={result.lbtrs_isclosed === 'Y' ? 'green' : 'red'}>
                                                        Closed
                                                        <p>
                                                            <small>
                                                                {result.lbtrs_isclosed === 'Y' ? (
                                                                    <>{moment.tz(result.lbtrs_closeddttm, '').format('DD/MM/YYYY HH:mm')}</>
                                                                ) : (
                                                                    <>Pending</>
                                                                )}
                                                            </small>
                                                        </p>
                                                    </Timeline.Item>
                                                </Timeline>
                                            </div>
                                            <div className="result-content">
                                                <div gutter={0} className="detail-wrapper">
                                                    <div className="detail-element">
                                                        <Row span={12}>
                                                            <span>Notes</span>
                                                        </Row>
                                                        <Row span={12}>
                                                            <h3>
                                                                (Out - {test.lbt_outsource}) {test.lbt_specimen} (Method - {test.lbt_method})
                                                            </h3>
                                                        </Row>
                                                    </div>

                                                    <div className="detail-element">
                                                        <Row span={12}>
                                                            <span>Chief Technician</span>
                                                        </Row>
                                                        <Row span={12}>
                                                            <h3>{test.lbtrs_chieftechnician || 'No Data'}</h3>
                                                        </Row>
                                                    </div>

                                                    <div className="detail-element">
                                                        <Row span={12}>
                                                            <span>Technician</span>
                                                        </Row>
                                                        <Row span={12}>
                                                            <h3>{test.lbtrs_technician || 'No Data'}</h3>
                                                        </Row>
                                                    </div>

                                                    <div className="detail-element">
                                                        <Row span={12}>
                                                            <span>Remarks</span>
                                                        </Row>
                                                        <Row span={12}>
                                                            <h3>{result.lbtrs_remarks || 'No Data'}</h3>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}

                                        {/* Result Section */}
                                        <Title level={5}>Parameters</Title>

                                        {result.labtresultd ? (
                                            <>
                                                <Table
                                                    rowKey={(record, key) => key}
                                                    // scroll={{ x: true }}
                                                    pagination={false}
                                                    dataSource={result.labtresultd}
                                                    columns={result_columns}
                                                />
                                            </>
                                        ) : (
                                            <>
                                                <Table
                                                    rowKey={(record, key) => key}
                                                    // scroll={{ x: true }}
                                                    pagination={false}
                                                    dataSource={labentries.entries}
                                                    columns={columns}
                                                />
                                            </>
                                        )}
                                    </>
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
        </section>
    );
}

const layout = {
    labelCol: {
        // span: 8
    },
    wrapperCol: {
        // span: 16
    },
};
const tailLayout = {
    wrapperCol: {
        // offset: 8,
        // span: 16
    },
};
