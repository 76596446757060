/***
 *
 * Vital Information
 *
 * @description
 * @author Sneha
 */

import React, { useEffect, useState, useContext } from 'react';

import { Clinicals } from '../../../../models';
import { Location, InputComponent } from 'soxo-bootstrap-core';

import './vital-information.scss';

import { Card, message, Form, Input, Button, Skeleton, Typography } from 'antd';

export default function VitalInformation({ visit_id, information, callback }) {

    const [form] = Form.useForm();

    /**
     * on cancel
     */

    function onCancel() {
        callback();
    }

    /**
     * On submit of form
     *
     * @param {*} event
     */
    async function onSubmit(values) {

        let body = {
            ...values,
            srvbcd_height: parseInt(values.srvbcd_height),
            srvbcd_weight: parseFloat(values.srvbcd_weight),
            srvbcd_pulse: parseInt(values.srvbcd_pulse),
            srvbcd_bpsys: parseInt(values.srvbcd_bpsys),
            srvbcd_bpdia: parseInt(values.srvbcd_bpdia),
        };

        //In case of edit
        if (information.srvbcd_id) {
            await Clinicals.updateVitalInformation(information.srvbcd_id, body)
                .then((result) => {
                    if (result.success === false) {
                        message.error('Updation failed!');
                    } else {
                        message.success('Details updated successfully!');
                        callback();
                    }
                })
                .catch((e) => {
                    message.error('Updation failed!');
                });
        } else {
            //When entering new case
            await Clinicals.enterVitalInformation(visit_id, body)
                .then((result) => {
                    if (result.success === false) {
                        message.error('Failed!');
                    } else {
                        message.success('Details added successfully!');
                        callback();
                    }
                })
                .catch((e) => {
                    message.error('Failed!');
                });
        }

    }

    function onFieldsChange(fields, values) {
        let field = fields[0];
        if (field && field.value) {
            if (field.name == 'srvbcd_height' || field.name == 'srvbcd_weight') {
                // console.log("", values[0].value);

                if (values[0].value && values[1].value) {
                    var bmi = (values[1].value / (values[0].value * values[0].value)) * 10000;

                    values[2].value = Math.round(bmi * 100) / 100;

                    values[2] = values[2].value;

                    form.setFieldsValue({ srvbcd_bmi: values[2] });
                } else {
                    values[2].value = null;
                }
            }
        }
    }

    return (
        <div className="vital-information">
            {/* <p className="size-hint">{loading ? 'Loading data' : 'Refer below information'}</p> */}

           
                <div className="form-step">
                    <Form
                        className="present-illness"
                        form={form}
                        initialValues={{
                            ...information,
                        }}
                        layout="vertical"
                        onFinish={onSubmit}
                        onFieldsChange={onFieldsChange}
                    >
                        <p>Enter below details</p>

                        <Form.Item
                            name={'srvbcd_height'}
                            label="Height (In cm)"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Height !',
                                },
                                {
                                    pattern: /^[0-9]*$/,
                                    message: 'Please Enter Valid Height !',
                                },
                                // {
                                //     max: 3,
                                //     message: 'Length cannot exceed 3 characters !',
                                // },
                            ]}
                        >
                            <InputComponent maxLength={3} />
                        </Form.Item>

                        <Form.Item
                            name={'srvbcd_weight'}
                            label="Weight (In kg)"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Weight',
                                },
                                {
                                    pattern: /^\d{1,3}(\.\d{1,2})?$/,
                                    message: 'Please Enter Valid Weight !',
                                },
                            ]}
                        >
                            <InputComponent />
                        </Form.Item>

                        <Form.Item disabled name={'srvbcd_bmi'} label="BMI">
                            <InputComponent readOnly={true} />
                        </Form.Item>

                        <Form.Item
                            name={'srvbcd_bpsys'}
                            label="Blood Pressure (Systolic)"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Systolic Blood Pressure',
                                },
                                {
                                    pattern: /^[0-9]*$/,
                                    message: 'Please Enter Valid Systolic Blood Pressure !',
                                },
                            ]}
                        >
                            <InputComponent maxLength={3} />
                        </Form.Item>

                        <Form.Item
                            name={'srvbcd_bpdia'}
                            label="Blood Pressure (Diastolic)"
                            rules={[
                                { required: true, message: 'Please Enter Diastolic Blood Pressure' },
                                {
                                    pattern: /^[0-9]*$/,
                                    message: 'Please Enter Valid Diastolic Blood Pressure !',
                                },
                            ]}
                        >
                            <InputComponent maxLength={3} />
                        </Form.Item>

                        <Form.Item
                            name={'srvbcd_pulse'}
                            label="Pulse"
                            rules={[
                                { required: true, message: 'Please Enter Pulse' },
                                {
                                    pattern: /^[0-9]*$/,
                                    message: 'Please Enter Valid Pulse Rate !',
                                },
                            ]}
                        >
                            <InputComponent maxLength={3} />
                        </Form.Item>

                        <div className="footer-actions">
                            <Button htmlType="submit" type="primary">
                                Save
                            </Button>

                            <Button onClick={onCancel}>Cancel</Button>
                        </div>
                    </Form>
                </div>
            
        </div>
    );
}
