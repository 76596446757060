/**
 * Registration Page specific to StopTB but also built considering 
 * Nura/Matria and All medicant requirements
 * 
 * The component in support with new registration api 
 * should support creation of appointment, registration , visit creation etc
 * 
 * @description
 * @author jinshakappil 
 * @author Ashique Mohammed
 * @author Sameena

 * This component is used to gather information from the guest .
 * 
 * This components demos/explores the first use of generic forms 
 * along with custom forms . 
 * 
 * We have defined useFormUtils which accepts a configuration that is specific to the form 
 * and allows binding client side scripting to expose all the custom functions that 
 * are maintained within the useFormUtils 
 *
 */
import React, { useState, useEffect } from 'react';

import { Row, Col, Form, Select, Typography, Input, Tabs, Button, Skeleton, message, Modal, Card } from 'antd';

import { InputComponent, CountryPhoneInput, Location, useFormUtils, ReferenceSelect } from 'soxo-bootstrap-core';

import AgeSelection from './components/age-selection';

import AdditionalKeyGenerate from './components/additional-key-generate';

import { GuestPreferences } from './guest-preference/guest-preferences';

// import { TabPane } from 'rc-tabs';

import moment from 'moment';

// Below are components created to support the registration form

import { AddressDetails } from './address-details/address-details';
import { GuestRegistrationAddressDetails } from './registration-address-details/registration-address-details';
import { PersonalDetails } from './personal-details/personal-details';
import { CampaignDetails } from './campaign-details/campaign-details';
import { OtherDetailsOfGuest } from './other-details/other-details';

// Models for use in the form
import { Opreg, Communications, Preferences, Registrations, Referrers, Prereg, Appointments, ServiceResultMaster } from '../../../models';

// Form Configuration specific to stoptb
// # TODO This configuration will from preferences.
import { formSettings } from './registration-form-settings/registration-form-settings';

import './new-guest-information.scss';
import { Summary } from './summary/summary';
import { ApplyCoupon } from './apply-coupon/apply-coupon';
// import { Tabs } from 'antd'

import ExistingCustomerList from './list-existing-customers';

const { Option } = Select;
const { Title, Text } = Typography;
const { TabPane } = Tabs;

const formItemLayout = {
    layout: 'vertical',
};

// const { TabPane } = Tabs;

export default function FormContainer(props) {
    const [loading, setLoading] = useState(true);

    const [preference, setPreference] = useState({});

    useEffect(() => {
        Preferences.getRecord(props.formPreferenceId).then(result => {
            let data = JSON.parse(result.result.scope_value);

            setPreference(data);

            setLoading(false);
        });
    }, []);

    return loading ? <Skeleton /> : <GuestDetailsForNewPatient {...props} preference={preference} />;
}

/**
 *
 *
 * @param {*} props
 * @returns
 */
function GuestDetailsForNewPatient(props) {
    // Variable to control the loading state of the form
    const [loading, setLoading] = useState(true);

    // Control the loading state of the button on submission
    const [btnloading, setBtnLoading] = useState(false);

    // Using the form reference supported by antd for further use
    const [form] = Form.useForm();

    // UseFormUtils have specific functions as an event listner and control the configuration of the form
    const { setVisible, config, onChange } = useFormUtils(form, formSettings, props.preference);

    // Field configuration that is formatted as per requirement by the formUtils
    const { fields = {}, add_on_fields = [] } = config;

    const [initialValues, setInitialValues] = useState({});

    const [additionalValues, setAdditionalValues] = useState([]);

    const [disableCountryCode, setDisableCountryCode] = useState(false);

    const [coupon, setCoupon] = useState(null);

    const [discount, setDiscount] = useState(null);

    const [total, setTotal] = useState(null);

    const [genderItem, setGenderItem] = useState();

    // All customers with the same email is save in existingCustomers
    const [existingCustomers, setExistingCustomers] = useState([]);

    // Visible for the modal to be opened., if any previous customer had the same mail address
    const [existingCustomerVisible, setExistingCustomerVisible] = useState(false);

    // The selcted Customer, if the mail address was used by a previous customer and that customer is selected
    const [selectedCustomer, setSelectedCustomer] = useState({});

    const params = Location.search();

    let additionalkey;

    useEffect(async () => {
        // If url contains id and table, it is an indication to repeated case.
        // To prepopulate the columns, we will have to load guest details
        if (params.id || params.edit) {
            // Get guest information to initialize form values from id in url
            await loadGuestInformation();
        } else {
            if (config) {
                setLoading(false);
            }
        }
    }, [config]);

    /**
     * On Change of values in the form
     * We have to trigger all the configuration
     *
     * @param {*} field
     * @param {*} values
     */
    const onValuesChange = (keys, values) => {
        Opreg.onValuesChange(keys, values, form, genderItem, fields, add_on_fields, onChange, initialValues);
    };

    /**
     * This function is triggerd onblur of email address input to check if
     *  any previous customer has used the same email address
     *  Here consolidated opreg api is hit, to see if any customer in the same location had used the email address previously
     *  If yes, all those cases are listed and any account can be selected from the list
     *  It will navigate to existing-customer url, and manage the case in existing customer flow
     * @param {*} event
     */
    const onBlur = async event => {
        let formBody = {
            search_by: 'email',
            criteria: 'anywhere',
            search_text: event.target.value,
            index: 'ALL',
        };

        var existingCustomerArray;

        if (event.target.value.length > 3) {
            existingCustomerArray = await Appointments.consolidatedSearch(formBody);

            setExistingCustomers(existingCustomerArray.result);

            if (existingCustomerArray.result.length > 0) {
                setExistingCustomerVisible(true);
            }
        }
    };

    /**
     * Load guest details for repeated case.
     * We get the table and id from url, get data accordingly
     */
    async function loadGuestInformation() {
        setLoading(true);

        // If redirect is appointment, table is taken as prereg
        if (params.redirect && params.redirect === 'appointment') params.table = 'prereg';
        // If redirect is registration, table is taken as opreg
        else if (params.redirect && params.redirect === 'registration') params.table = 'opreg';

        let result = await Opreg.loadGuestInformation(params.id, params.table, params.da_id);

        result = result.data;

        // If coupon is applied, set coupon only for edit cases
        // For existing customer flow, this step is to be avoided
        // If couponno is present, this indicates coupon was applied
        // Else disocunt is applies
        if (result && result.coupon && params.edit) {
            if (result.coupon && result.couponno)
                setCoupon({
                    coupon_value: result.coupon,
                    coupon_amount: result.coupon,
                    coupon_no: result.couponno,
                });
            else if (result.coupon && !result.coupono)
                setDiscount({
                    discount: result.coupon,
                });
        }
        if (result && result.billValue >= 0)
            // Set bill value to total
            setTotal(result.billValue);
        // If not for edit cases, refferer should be made null, as the data here is got from doctor appointment table
        if (!params.edit && params.table === 'prereg') {
            result = {
                ...result,
                camp_site: null,
                remarks: null,
            };
        }
        // Set mobile number and country code
        // Both mobile_number and alternate_mobile are set according to the phone input
        var mobile_number = await validatePhoneNumber(result.mobile_number, result.countrycode);
        var alternate_mobile = await validatePhoneNumber(result.alternate_mobile, result.countrycode2);

        result = {
            ...result,
            mobile_number,
            alternate_mobile,
        };

        if (result.date_of_birth && result.date_of_birth.dob) {
            result.date_of_birth = {
                ...result.date_of_birth,
                dob: moment(result.date_of_birth.dob),
            };
        } else {
            result.date_of_birth = {
                ...result.date_of_birth,
                dob: null,
            };
        }

        setInitialValues(result);

        // Set initial values to prepopulate
        form.setFieldsValue(result);

        if (config) setLoading(false);
    }

    /**
     *
     * @returns
     */
    function validatePhoneNumber(mobile_number, countrycode) {
        // Trim mobile number if any white space is included
        if (mobile_number) mobile_number = mobile_number.trim();
        // 1. Two Columns - mobile + country_code | Component || API || Form

        // 2. Single Column = With Country Code

        // 3. Single Column - Without Country Code

        let phoneNumber = {};

        let valid = false;
        if (mobile_number && countrycode) {
            // Joining country code with mobile number
            let numberWithCode = countrycode.concat(mobile_number);

            // checking the number is valid
            if (countrycode == 91 && numberWithCode.length == 12) {
                // India
                valid = true;
            } else if (countrycode == 971 && numberWithCode.length == 12) {
                // UAE
                valid = true;
            } else if (countrycode == 966 && numberWithCode.length == 12) {
                //KSA
                valid = true;
            } else if (countrycode == 976 && numberWithCode.length == 11) {
                // Mongolia
                valid = true;
            } else if (countrycode == 65 && numberWithCode.length == 10) {
                // Singapore
                valid = true;
            } else if (countrycode == 1 && numberWithCode.length == 11) {
                // USA Aand Canada
                valid = true;
            } else if (countrycode == 61 && numberWithCode.length == 12) {
                // Australia
                valid = true;
            } else if (countrycode == 974 && numberWithCode.length == 11) {
                //Qatar
                valid = true;
            } else if (countrycode == '+00' && numberWithCode.length == 13) {
                // For cases without countrycode, country code is adde as'+00'
                valid = true;
            }

            phoneNumber = {
                value: mobile_number,
                code: {
                    dialCode: countrycode,
                },
                valid: valid,
            };
        } else {
            if (mobile_number && mobile_number.length == 10) {
                phoneNumber = {
                    value: mobile_number,
                    code: {
                        dialCode: '91',
                    },
                    valid: true,
                };
                setDisableCountryCode(true);
            } else if (mobile_number && mobile_number.indexOf('+91') && mobile_number.length === 12) {
                phoneNumber = {
                    value: mobile_number.substring(2, mobile_number.length),
                    code: {
                        dialCode: '+91',
                    },
                    valid: true,
                };
            } else if (mobile_number && mobile_number.indexOf('+971') && mobile_number.length === 12) {
                phoneNumber = {
                    value: mobile_number.substring(2, mobile_number.length),
                    code: {
                        dialCode: '+971',
                    },
                    valid: true,
                };
            }
        }

        return {
            ...phoneNumber,
        };
    }

    /**
     * On submission of the form
     */
    async function onFinish(values) {
        let queryParam = {};

        const sessionId = localStorage.getItem('SessionId')

        setBtnLoading(true);

        let appointment_mode;

        // if (!params.edit) {
        const genderValidation = await Opreg.validateGender(values.gender, genderItem);

        // If gender validation is false, selectedngender does not match the gender specified for the selected package
        if (!genderValidation) {
            message.error('The Selected Gender does not match with the Selected Package Gender. Please Select the Correct Gender');
            setBtnLoading(false);
            return false;
        }
        // }

        // Append additional key in case of stoptb
        values = {
            ...values,
            addition_key_id: additionalkey,
            session_id: sessionId
        };

        if (params.edit && params.redirect === 'appointment') {
            values = {
                ...values,
                id: params.da_id,
            };
        }
        // If address is not entered save it as an empty string
        if (!values.address1) values.address1 = '';
        if (!values.address2) values.address2 = '';

        // Get appointment_mode
        if (props.appointment_mode) appointment_mode = eval(props.appointment_mode)();

        /**Get mobile number and countrycode from the entered data */
        // Storing value of op_mobile to a variable
        var mobile = values.mobile_number;

        var dialCodeLength,
            mobile_number,
            countryCode = null;

        //Storing country code length
        dialCodeLength = mobile.code.dialCode.length;

        if (mobile.value.length > 10) mobile_number = mobile.value.substring(dialCodeLength);
        else mobile_number = mobile.value;

        countryCode = mobile.code.dialCode;

        var alternate_mobile;

        // If alternate Mobile number is entered, get the mobile number from the value by avoiding the country code
        if (values.alternate_mobile && values.alternate_mobile.value)
            if (values.alternate_mobile.value.length > 10)
                alternate_mobile = values.alternate_mobile.value.substring(values.alternate_mobile.code.dialCode.length);
            else alternate_mobile = values.alternate_mobile.value;

        const time = moment(params.selected_time, 'DD/MM/YYYY hh:mm A').valueOf();

        values = {
            ...values,
            package_value: params.da_packagetypevalue,
            doctor: params.da_doctorptr,
            token_no: params.token_no,
            age_type: values.date_of_birth.age_type,
            age: values.date_of_birth.age,
            age_day: values.date_of_birth.day,
            age_month: values.date_of_birth.month,
            age_year: values.date_of_birth.year,
            mobile_number: mobile_number,
            countrycode: countryCode,
            dob: values.date_of_birth.dob,
            opno: initialValues && initialValues.opno ? initialValues.opno : null,
            // Visit Remarks
            visit_remarks: params.remarks,
            total: total,
            discount: discount,
            package_type: 'ITM',
            referrer_pointer: values.camp_site,

            // op_iscorporate: 'N',

            // mode_of_communication: '3',
            // op_dispage: 'null',
        };

        // I f alternate mobile number is present save country code and mobile number
        if (values.alternate_mobile && values.alternate_mobile.value) {
            values = {
                ...values,
                alternate_mobile: alternate_mobile,
                countrycode2: values.alternate_mobile.code.dialCode,
            };
        } else {
            values = {
                ...values,
                alternate_mobile: null,
                countrycode2: null,
            };
        }
        console.log(values);
        // Check if the entered phone number is valid or not
        if (values.mobile_number && values.countrycode) {
            const phoneValidation = await validatePhoneNumber(values.mobile_number, values.countrycode);
            // If not valid, show an error message
            if (phoneValidation.valid) {
            } else {
                message.error('Please Check the Entered Phone Number');
                setBtnLoading(false);
                return false;
            }
        }else{
            message.error('Please Enter Phone Number');
            setBtnLoading(false);
            return false
        }

        // Check if the entered alternate phone number is valid or not
        if (values.alternate_mobile && values.countrycode2) {
            const phoneValidation = await validatePhoneNumber(values.alternate_mobile, values.countrycode2);
            // If not valid, show an error message
            if (phoneValidation.valid) {
            } else {
                message.error('Please Check the Entered Phone Number');
                setBtnLoading(false);
                return false;
            }
        }
        // If coupon is present
        // To show total in summary, coupon value is reduced from total,when coupon is applied
        // On submission, the coupon is validated once more in backend and if that is a success, coupon value is deducted from da_billvalue ie, total
        // So we are adding back the coupon value as it would be deducted from backend once again
        if (coupon && coupon.coupon_amount) {
            values = {
                ...values,
                coupon: coupon,
            };
        }
        if (discount && discount.discount) {
            values = {
                ...values,
                disocunt: discount.discount,
            };
        }
        // If selected_time is present in url, append appointment date and time
        if (params.selected_time) {
            values = {
                ...values,
                appointment_date: moment(time).format('YYYY-MM-DD'),
                appointment_time: moment(time).format('hh:mm A'),
            };
        }
        // In case of existing customer flow, add prereg or opreg id to the values
        if (params.table === 'prereg') {
            values = {
                ...values,
                prereg_id: params.id,
            };
        } else if (params.table === 'opreg') {
            values = {
                ...values,
                opreg_id: params.id,
            };
        }

        /**Get all the add_on fields and it's corresponding values */
        // Get keys of values entered in the form
        const fields = Object.keys(values);
        // Get values of values entered in the form
        const value = Object.values(values);

        // Get field name from add_on_fields
        const extraFields = Object.values(add_on_fields);

        // For each add_on_field element, get the values entered and store it in an array
        // This array is used to store data in op_otherdetails1in xml format
        if (extraFields && extraFields.length)
            extraFields.map(ele => {
                const index = fields.indexOf(ele.field);
                if (value[index])
                    additionalValues.push({
                        field: ele.field,
                        value: value[index],
                    });
            });

        // Add the array with additinal values
        values = {
            ...values,
            additionalValues: additionalValues,
        };

        // ExtraParams for billing is passed as attribute, this includes item to be billed,rate and quantity
        if (props.extraBillingParams) {
            values = {
                ...values,
                ...props.extraBillingParams,
            };
        }
        // Append appointment mode
        if (props.appointment_mode) {
            values = {
                ...values,
                appointment_mode: appointment_mode,
            };
        }
        const result = await Opreg.saveNewRegistration(values);

        if (result.success) {
            // For nura-desk, redirect to appointment detail page
            if (props.redirect) {
                setBtnLoading(false);

                Location.navigate({ url: props.redirect + result.da_id });
            } else {
                //For stoptb visit and bill is created
                if (props.extraBillingParams) {
                    setBtnLoading(false);

                    Location.navigate({ url: `/patient-visits-new` });
                } else {
                    //For alsalama navigate to billing page
                    Location.navigate({ url: `/multiple-candidate/bill`, queryParam });

                    setBtnLoading(false);
                }
            }
        } else {
            setBtnLoading(false);
            message.error(result.message);
        }
    }

    /**
     * Function to be feeded for reference select
     * to default the referrer for all
     * cases the one which has the lowest refr_code
     *
     * @param {*} options
     * @returns
     */
    function identifyDefaultReferrer(options = []) {
        let initial = 1;

        let matching = {};

        options.forEach(record => {
            if (initial >= parseInt(record['refr_code'])) {
                initial = parseInt(record['refr_code']);

                matching = record;
            }
        });

        return matching;
    }

    return loading ? (
        <Skeleton />
    ) : (
        <div className="registration-screen">
            {/** Guest card section start */}
            <div className="guest-details card ">
                <Form form={form} {...formItemLayout} onValuesChange={onValuesChange} onFinish={onFinish} initialValues={initialValues}>
                    <div className="guest-information">
                        {/** Page Header section start */}
                        <div className="page-header">
                            <div>
                                <Title level={4}>GUEST INFORMATION</Title>

                                <p className="page-desc">Please enter the below details to complete the registration.</p>
                            </div>
                        </div>
                        {/** Page Header section start */}

                        {/** Form Content of patient section start */}
                        <div className="form-content  ">
                            <div className="steps-content">
                                <Row>
                                    {fields.email_address.visible ? (
                                        <Col xs={24} md={12} xl={12}>
                                            <Form.Item
                                                autoFocus={true}
                                                name="email_address"
                                                label={fields.email_address.caption}
                                                rules={[
                                                    {
                                                        type: 'email',
                                                        message: `The input is not valid ${fields.email_address.caption}`,
                                                    },
                                                    {
                                                        required: fields.email_address.required,
                                                        message: `Please Enter ${fields.email_address.caption}`,
                                                    },
                                                    {
                                                        max: 100,
                                                        message: 'Invalid Email!',
                                                    },
                                                    // {
                                                    //     validator(_, value) {

                                                    //         if (existingCustomers.length>0) {
                                                    //             return Promise.resolve('This email address is already used');
                                                    //         } else {
                                                    //             return Promise.resolve('This email is available');
                                                    //         }
                                                    //     },
                                                    // }
                                                ]}
                                            >
                                                <Input mode={'lowercase'} disabled={props.disabled || props.editFieldsDisable} onBlur={onBlur} />
                                            </Form.Item>
                                        </Col>
                                    ) : null}
                                </Row>

                                <Row gutter={20}>
                                    {fields.title.visible ? (
                                        <Col xs={24} md={12} xl={6}>
                                            <Form.Item
                                                name="title"
                                                label={fields.title.caption}
                                                rules={[
                                                    { required: fields.title.required, message: 'Please Select Title' },
                                                    {
                                                        validator(_, value) {
                                                            var genderValid;

                                                            // Validate if the selcted title and gender is suitable for the gender of the item
                                                            if (value === 'Mr.') {
                                                                genderValid = 'Male';
                                                            } else if (value === 'Ms.' || value === 'Mrs.') {
                                                                genderValid = 'Female';
                                                            } else if (value === 'Dr.') {
                                                                genderValid = 'Both';
                                                            } else {
                                                                genderValid = 'Both';
                                                            }
                                                            const validGender = Opreg.validateGender(genderValid, genderItem);
                                                            if (!validGender) {
                                                                return Promise.reject(
                                                                    'This title cannot be used as it does not match with Package gender'
                                                                );
                                                            } else {
                                                                return Promise.resolve();
                                                            }
                                                        },
                                                    },
                                                ]}
                                            >
                                                <Select showSearch optionFilterProp="children" disabled={props.disabled}>
                                                    <Option value="Mr.">Mr.</Option>
                                                    <Option value="Ms.">Ms.</Option>
                                                    <Option value="Mrs.">Mrs.</Option>
                                                    <Option value="Dr.">Dr.</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    ) : null}

                                    {fields.first_name.visible ? (
                                        <Col xs={24} md={12} xl={6}>
                                            <Form.Item
                                                name="first_name"
                                                label={fields.first_name.caption}
                                                rules={[
                                                    {
                                                        required: fields.first_name.required,
                                                        message: 'Please Enter First Name',
                                                    },
                                                    {
                                                        max: 100,
                                                        message: 'Length cannot exceed 100 characters !',
                                                    },
                                                    {
                                                        pattern: /^[a-zA-Z\s]*$/g,
                                                        message: 'Please Enter Valid Name!',
                                                    },
                                                ]}
                                            >
                                                <InputComponent disabled={props.disabled} />
                                            </Form.Item>
                                        </Col>
                                    ) : null}

                                    {fields.last_name.visible ? (
                                        <Col xs={24} md={12} xl={6}>
                                            <Form.Item
                                                name="last_name"
                                                label={fields.last_name.caption}
                                                rules={[
                                                    {
                                                        required: fields.last_name.required,
                                                        message: 'Please Enter Last Name',
                                                    },
                                                    {
                                                        max: 100,
                                                        message: 'Length cannot exceed 100 characters !',
                                                    },
                                                    {
                                                        pattern: /^[a-zA-Z\s]*$/g,
                                                        message: 'Please Enter Valid Name!',
                                                    },
                                                ]}
                                            >
                                                <InputComponent disabled={props.disabled} />
                                            </Form.Item>
                                        </Col>
                                    ) : null}

                                    {fields.gender.visible ? (
                                        <Col xs={24} md={12} xl={6}>
                                            <Form.Item
                                                name="gender"
                                                label={fields.gender.caption}
                                                rules={[{ required: fields.gender.required, message: 'Please Select Gender' }]}
                                            >
                                                <Select disabled={props.disabled || props.editFieldsDisable}>
                                                    <Option value="Male">Male</Option>
                                                    <Option value="Female">Female</Option>
                                                    <Option value="Other">Other</Option>
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                    ) : null}
                                </Row>

                                <Row gutter={20}>
                                    {fields.date_of_birth.visible ? (
                                        <Form.Item
                                            name="date_of_birth"
                                            // label="DATE OF BIRTH"
                                            label={fields.date_of_birth.caption}
                                            rules={[{ required: true, message: 'Please Select Date of Birth ' }]}
                                        >
                                            <AgeSelection
                                                field="date_of_birth"
                                                form={form}
                                                ageValues={
                                                    //In case of edit age should prepopulate
                                                    initialValues && initialValues.date_of_birth
                                                        ? {
                                                              dob: initialValues.date_of_birth.dob,
                                                              year: initialValues.date_of_birth.year,
                                                              month: initialValues.date_of_birth.month,
                                                              day: initialValues.date_of_birth.day,
                                                          }
                                                        : null
                                                }
                                                disabled={props.disabled}
                                            />
                                        </Form.Item>
                                    ) : null}

                                    {fields.mobile_number.visible ? (
                                        <Col xs={24} md={12} xl={12}>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: fields.mobile_number.required,
                                                        message: `The input is not valid ${fields.mobile_number.caption}`,
                                                    },
                                                    // {
                                                    //     validator(_, value) {

                                                    //         if (value) {
                                                    //             // checking the number is valid or not
                                                    //             let numberValid = validatePhoneNumber(value.value, value.code.dialCode)

                                                    //             if (!numberValid.valid) {

                                                    //                 return Promise.reject('Please provide a valid mobile number');

                                                    //             } else {

                                                    //                 return Promise.resolve();

                                                    //             }
                                                    //         } else {
                                                    //             return Promise.resolve();
                                                    //         }
                                                    //     },
                                                    // },
                                                ]}
                                                name="mobile_number"
                                                label={fields.mobile_number.caption}
                                                className="phone-input"
                                            >
                                                <CountryPhoneInput
                                                    disableCountryCode={disableCountryCode}
                                                    required={fields.mobile_number.required}
                                                    disabled={props.disabled}
                                                    defaultCountryCode={process.env.REACT_APP_COUNTRYCODE || 'mn'}
                                                />
                                            </Form.Item>
                                        </Col>
                                    ) : null}

                                    {fields.alternate_mobile.visible ? (
                                        <Col xs={24} md={12} xl={12}>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: fields.alternate_mobile.required,
                                                        message: `The input is not valid ${fields.alternate_mobile.caption}`,
                                                    },
                                                    // {
                                                    //     validator(_, value) {

                                                    //         if (value && value.value && value.code) {

                                                    //             // checking the number is valid or not
                                                    //             let numberValid = validatePhoneNumber(value.value, value.code.dialCode)

                                                    //             if (!numberValid.valid) {

                                                    //                 return Promise.reject('Please provide a valid mobile number');

                                                    //             } else {

                                                    //                 return Promise.resolve();

                                                    //             }
                                                    //         } else {
                                                    //             return Promise.resolve();
                                                    //         }

                                                    //     },
                                                    // },
                                                ]}
                                                name="alternate_mobile"
                                                label={fields.alternate_mobile.caption}
                                                className="phone-input"
                                            >
                                                <CountryPhoneInput
                                                    disableCountryCode={disableCountryCode}
                                                    required={fields.alternate_mobile.required}
                                                    disabled={props.disabled}
                                                    defaultCountryCode={process.env.REACT_APP_COUNTRYCODE || 'mn'}
                                                />
                                            </Form.Item>
                                        </Col>
                                    ) : null}

                                    {/* Communication Mode */}
                                    {fields.mode_of_communication.visible ? (
                                        <Col xs={10} md={10} xl={10}>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: fields.mode_of_communication.required,
                                                        message: 'The input is not valid selection!',
                                                    },
                                                ]}
                                                name="mode_of_communication"
                                                label={fields.mode_of_communication.caption}
                                                className="phone-input"
                                            >
                                                <ReferenceSelect
                                                    // disabled={disabledfield}
                                                    label="comt_desc"
                                                    mode="nura-base"
                                                    model={Communications}
                                                    field={'comt_code'}
                                                    disabled={props.disabled}
                                                />
                                            </Form.Item>
                                        </Col>
                                    ) : null}
                                </Row>
                                {/**guestPreferenceDisableis the attribute used to check if the case is of nura or stoptb
                                 * Here is guestPreferenceDisable is true, campsite is shown as a part of basic information
                                 */}
                                <Row gutter={20}>
                                    {fields.camp_site.visible && props.guestPreferenceDisable ? (
                                        <Col xs={24} md={12} xl={8}>
                                            <Form.Item
                                                name={'camp_site'}
                                                label={fields.camp_site.caption}
                                                rules={[
                                                    {
                                                        required: fields.camp_site.required,
                                                        message: `Please enter ${fields.camp_site.caption}`,
                                                    },
                                                ]}
                                            >
                                                <ReferenceSelect
                                                    label="refr_desc"
                                                    mode="nura-base"
                                                    model={Referrers}
                                                    field={'refr_code'}
                                                    defaultValueCondition={identifyDefaultReferrer}
                                                />
                                            </Form.Item>
                                        </Col>
                                    ) : null}
                                    {fields.remarks.visible ? (
                                        <Col xs={10} md={10} xl={10}>
                                            <Form.Item
                                                rules={[
                                                    {
                                                        required: fields.remarks.required,
                                                        message: 'Enter Remarks',
                                                    },
                                                ]}
                                                name="remarks"
                                                label={fields.remarks.caption}
                                                className="phone-input"
                                            >
                                                <InputComponent />
                                            </Form.Item>
                                        </Col>
                                    ) : null}
                                    </Row>
                                    <Row gutter={20}>
                                        {fields.pay_status.visible ? (
                                            <Col xs={24} md={12} xl={8}>
                                                <Form.Item
                                                    name={'pay_status'}
                                                    label={fields.pay_status.caption}
                                                    rules={[
                                                        {
                                                            required: fields.pay_status.required,
                                                            message: `Please enter ${fields.pay_status.caption}`,
                                                        },
                                                    ]}
                                                >
                                                    <ReferenceSelect
                                                        config={{
                                                            queries: [
                                                                {
                                                                    field: 'srvrsm_category',

                                                                    value: 'PAY',
                                                                },
                                                            ],
                                                        }}
                                                        label="srvrsm_desc"
                                                        mode="nura-base"
                                                        model={ServiceResultMaster}
                                                        field={'srvrsm_code'}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        ) : null}
                                    </Row>


                                {props.disable_cluster ? null : (
                                    <>
                                        {/* Communication Mode */}
                                        <Row gutter={20}>
                                            <AdditionalKeyGenerate
                                                seperator={props.seperator}
                                                form={form}
                                                callback={values => (additionalkey = values)}
                                                // In case of edit cluster_number and serial_number needs to be prepopulated
                                                additionalKeyValues={
                                                    initialValues && initialValues.cluster_number
                                                        ? {
                                                              cluster_number: initialValues.cluster_number,
                                                              serial_number: initialValues.serial_number,
                                                          }
                                                        : null
                                                }
                                                //Only in case of edit cluster field should be readonly
                                                edit={params.edit ? true : false}
                                            ></AdditionalKeyGenerate>
                                        </Row>
                                    </>
                                )}

                                {/* Communication Mode Ends */}
                            </div>
                            {/** Form content  of patient section Ends */}

                            {/** other details of patient section start */}

                            <div className="other-details ">
                                <div className="section-2">
                                    <Tabs defaultActiveKey="0">
                                        {/** Tab used for address details of guest start */}
                                        <TabPane tab="ADDRESS DETAILS" key="0">
                                            <AddressDetails config={config} props={props} />
                                        </TabPane>

                                        {/** Tab used for address details of guest end */}

                                        {/** Tab used for Registration details of guest start */}

                                        {/* <TabPane tab="REGISTRATION DETAILS" key="1">
                                                <GuestRegistrationAddressDetails config={config} props={props} />
                                            </TabPane> */}
                                        {/** Tab used for Registration details of guest end */}

                                        {/** Tab used for personal details of guest start */}

                                        {/* <TabPane tab="PERSONAL DETAILS" key="2">
                                                <PersonalDetails config={config} />
                                            </TabPane> */}
                                        {/** Tab used for address details of guest ends */}

                                        {/** Tab used for Campaign details of guest start */}

                                        {/* <TabPane tab="CAMPAIGN DETAILS" key="3"> */}
                                        {/**guestPreferenceDisableis the attribute used to check if the case is of nura or stoptb
                                         * Here is guestPreferenceDisable is true, campsite is shown as a part of basic information
                                         * Here it is disabled
                                         */}
                                        {/* {props.guestPreferenceDisable ? null : <CampaignDetails config={config} />} */}
                                        {/* </TabPane> */}
                                        {/** Tab used for Other details of guest start */}
                                        {/* {props.otherdetails_disable ? null :
                                                <TabPane tab="OTHER DETAILS" key="4">
                                                    <OtherDetailsOfGuest config={config} />
                                                </TabPane>} */}
                                        {/** Tab used for other details of guest end */}
                                    </Tabs>
                                </div>
                            </div>
                            {/** other details of patient section ends */}
                        </div>
                    </div>

                    <div className="footer-actions">
                        <div className="actions">
                            <Button type="primary" htmlType="submit" loading={btnloading}>
                                Submit
                            </Button>

                            {/* <Button htmlType="submit">
                                        Cancel
                                    </Button> */}
                        </div>
                    </div>
                </Form>
                {/** Modal to list existing customer with same mail address starts */}
                <Modal
                    destroyOnClose={true}
                    footer={null}
                    title="Account Selection"
                    visible={existingCustomerVisible}
                    width={'50%'}
                    okText="Okay"
                    cancelText="Cancel"
                    onOk={() => {
                        setExistingCustomerVisible(false);
                    }}
                    onCancel={() => {
                        setExistingCustomerVisible(false);
                    }}
                >
                    <ExistingCustomerList
                        selectedCustomer={selectedCustomer}
                        existingCustomers={existingCustomers}
                        props={props}
                        callBack={element => {
                            setSelectedCustomer(element);
                        }}
                        setVisible={() => setExistingCustomerVisible(false)}
                    />
                </Modal>
                {/** Modal to list existing customer with same mail address ends */}
            </div>
            {/** GuestPreferences card section start */}

            <div className="primary-details">
                {/* Guest Preference */}
                {props.guestPreferenceDisable ? null : <GuestPreferences />}
                {/* Guest Preference Ends */}

                {/*  */}
                {initialValues ? (
                    <>
                        {/* Summary Section */}
                        <Summary
                            setGender={setGenderItem}
                            appliedDiscount={discount}
                            appliedCoupon={coupon}
                            totalValue={total}
                            callback={(values, mode, total, result) => {
                                if (mode === 'coupon') {
                                    // If coupon is null, it indicates applied coupon was cancelled
                                    // If coupon is present set discount as null
                                    if (values === null) setCoupon(null);
                                    else setCoupon(result.data);
                                    setDiscount(null);
                                } else if (mode === 'discount') {
                                    // If discount is null, it indicates applied discount was cancelled
                                    // If discount is present st coupon as null
                                    if (values === null) setDiscount(null);
                                    else setDiscount(values.discount);
                                    setCoupon(null);
                                }
                                setTotal(total);
                            }}
                        />
                        {/* Summary Section Ends */}
                    </>
                ) : null}
            </div>

            {/** GuestPreferences card section ends  */}
        </div>
    );
}
