/**
 * labtest details page
 */

import { useEffect, useState } from 'react';

import { Card, Switch, Button, Typography, Table, Skeleton, Modal } from 'antd';

import { EditOutlined, PlusOutlined } from '@ant-design/icons';

import './lab-test-details.scss';

import { Link } from 'react-router-dom';
import { Samples } from '../../../../models';
import { result } from 'lodash';
import LabTestAdd from './lab-test-add';

const { Title, Text } = Typography;

export default function LabtestDetails({ match }) {
    const { params } = match;

    const [testDetail, setTestDetail] = useState({ labtest: {}, parameters: [] });

    const [isAddVisible, setIsAddVisible] = useState(false);

    const [loading, setLoading] = useState(true);

    const [labReportId, setLabReportId] = useState();

    const [edit, setEdit] = useState(false);

    var testNumber = params.id;

    useEffect(() => {
        //Get parameters of corresponding labtest
        getLabTestDetails();
    }, []);

    /**
     * Get lab test details of corresponding lab test
     */
    async function getLabTestDetails() {
        setLoading(true);

        // Get lab test details with test number
        await Samples.getLabTestDetail({ testNumber }).then((result) => {
            setTestDetail(result.result[0]);
            setLoading(false);
        });
    }

    /**
     * To open modal to edit lab test
     */
    function openModal(lbtr_id) {
        // lbtr_id is saved to a state to pass into modal
        setLabReportId(lbtr_id);

        // In case of edit edit state is set to true
        setEdit(true);

        // To show modal
        setIsAddVisible(true);
    }

    // Defining columns to be shown in table
    var columns = [
        {
            title: 'Sl.',
            dataIndex: 'index',
            render: (value, item, index) => index + 1,
            width: 40,
            fixed: 'left',
        },

        {
            title: 'Particular',
            dataIndex: 'particular',
            width: 200,
            fixed: 'left',
        },
        {
            title: 'Value Type',
            dataIndex: 'value_type',
            width: 100,
        },
        {
            title: 'Ref. Range',
            dataIndex: 'reference_range',
            width: 250,
        },
        {
            title: 'Editable Ref. Range',
            dataIndex: 'editable_reference_range',
            width: 150,
        },
        {
            title: 'Unit',
            dataIndex: 'unit',
            width: 120,
        },
        {
            title: 'LBound',
            dataIndex: 'lower_bound',
            width: 80,
        },
        {
            title: 'UBound',
            dataIndex: 'upper_bound',
            width: 80,
        },
        {
            title: 'Template',
            dataIndex: 'template',
            width: 100,
        },
        {
            title: 'Action',
            fixed: 'right',

            render: (ele) => {
                return (
                    <Link
                        onClick={() => {
                            // lbtr_id is passed to modal to prepopulate lab test data on edit
                            openModal(ele.lbtr_id);
                        }}
                    >
                        <EditOutlined /> Edit
                    </Link>
                );
            },
            width: 100,
        },
    ];

    /**
     * To show modal to add lab test
     */
    const showModal = () => {
        // In case of add edit is set to false
        setEdit(false);

        // To show modal
        setIsAddVisible(true);
    };

    return (
        <div className="card">
            <div className="page-header">
                <div>
                    <p>Refer below details and add details if any.</p>
                </div>
                <div>
                    <Button type="primary" size="small" onClick={showModal}>
                        <PlusOutlined /> Add Test
                    </Button>
                </div>
            </div>
            <div className="labtest">
                <div className="test-details card">
                    <h4> TEST DETAILS</h4>

                    <h5> TEST NO. </h5>
                    <p>{testDetail.labtest.test_number}</p>

                    <h5> TEST NAME</h5>
                    <p>{testDetail.labtest.test_name}</p>
                </div>

                {loading ? (
                    <div className="">
                        <Skeleton active />
                    </div>
                ) : (
                    <div className="test-details-table">
                        <Card className=" details table-card">
                            <Table
                                size="small"
                                scroll={{
                                    x: 1100,
                                }}
                                dataSource={testDetail.parameters}
                                columns={columns}
                            />
                        </Card>
                    </div>
                )}
            </div>

            {/**
             * Modal to add or edit lab test starts here
             */}
            <Modal
                title="TEST REPORTING ITEM DETAILS"
                width={700}
                destroyOnClose={true}
                visible={isAddVisible}
                onCancel={() => setIsAddVisible(false)}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
            >
                <LabTestAdd
                    edit={edit}
                    id={labReportId}
                    testNumber={testNumber}
                    callback={() => {
                        // On callback modal is closed and data is reloaded
                        setIsAddVisible(false);
                        getLabTestDetails();
                    }}
                />
            </Modal>

            {/**
             * Modal to add or edit lab test ends here
             */}
        </div>
    );
}
