/**
 * Appointments Model
 *
 *
 * Appointments is a record created on
 *
 */
import React, { useState, useEffect, useContext } from 'react';

import Model from './models';

import { ApiUtils, BaseAPI } from 'soxo-bootstrap-core';

import { Link } from 'react-router-dom';

import { Tag, Card, Typography, Button } from 'antd';

import { GetData } from 'soxo-bootstrap-core';

import moment from 'moment';

const { Text } = Typography;

class ServiceResultMaster extends BaseAPI {
    constructor() {
        super();

        this.fields = [
            {
                field: 'name',
                caption: 'Name',
            },
            {
                field: 'amount',
                caption: 'Amount',
            },
        ];

        // this.columns = ;
    }

    get id() {
        return 'da_id';
    }

    get getEndpoint() {
        return 'serviceresultmas';
    }

    get path() {
        return `serviceresultmas`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `serviceresultmas`;
    }

    get columns() {
        return [
            {
                caption: 'Staff',
                field: 'staff.name',
                key: 'staff',
            },
            {
                caption: 'Weight',
                field: 'weight',
                key: 'weight',
            },
            {
                caption: 'Date',
                field: 'created_at',
                key: 'created_at',
            },
        ];
    }

    /**
     * Get all Service Result mas Options Against Category
     */

    getServiceOptions = (category) => {
        return ApiUtils.get({
            url: `serviceresultmas/serviceResult?srvrsm_category=${category}`,
        });
    };

    
    /**
     * Get status
     */

    updateStatus = ({formBody,id}) => {
        return ApiUtils.put({ url: `doctor-appointment/update-status/${id}`, formBody });
    };

      
    /**
     * Get all Service Result mas Options Against Category
     */

    getLog = ({id,apiMode}) => {
        return ApiUtils.get({ url: `doctor-appointment/get-status-log/${id}?mode=${apiMode}` });
    };


    /**
     * Get the data from the table
     */
    // get = (queries, model) => {

    //     return ApiUtils.get().then(()=>{

    //     })

    //     return FirebaseUtils.getRecords(queries, model).then((result) => {

    //         return Promise.all(result.collections.map(this.loadStaff)).then((result) => {

    //             return { collections: result };

    //         })
    //     })
    // }

    // Attach the related staff with the object
    loadStaff = (item) => {
        // return Staffs.getRecord(item.staff, Staffs).then((result) => {
        //     return {
        //         ...item,
        //         staff: result
        //     }
        // })
    };

    // Attach the related staff with the object
    getDetail = (url) => {
        return fetch(url).then((res) => res.json());
    };

    // Self Check in Api

    checkIn = (opno) => {
        return GetData({ url: `doctor-appointment/check-in/${opno}` });
    };

    /**
     *  Get data from serviceresultmaster
     * @param {*} category
     * @param {*} mode
     * @returns
     */

    getServiceResultMaster = (category, mode) => {
        // The url to get data from serviceresultmas
        var url = `serviceresultmas/get-serviceresultmas?category=${category}`
        // If mode is present add mode to the url
        if (mode)
            url = url + `&mode=${mode}`
    // getServiceResultMaster = (category,mode) => {
        
        return ApiUtils.get({
            url: url,
        });
    };

    /**
     * Update service resultmaster
     * @param {*} formBody
     * @returns
     */

    updateServiceResultMaster = (formBody) => {
        return ApiUtils.put({
            url: `serviceresultmas/updateResultmas`,
            formBody,
        });
    };

    /**
     * Card Component for Staff
     *
     * @param {*} record
     * @returns
     */
    // Card = (record) => {
    //     return (
    //         <Link className="booking-card card card-shadow" to={`/collections/${record['cold_id']}`}>
    //             <div className="card">
    //                 <h2 className="">{record.cold_user ? record.cold_user : null}</h2>

    //                 <h3 className="title">{record.cold_amt}</h3>

    //                 <p className="address">Collected on {record.cold_tm}</p>

    //                 <Tag color="magenta">{record['cold_mode']}</Tag>
    //             </div>
    //         </Link>
    //     );
    // };

    Card = ({ ...props }) => {
        return <GuestCard {...props}></GuestCard>;
    };

    SmallCard = ({ record }) => {
        return (
            <Link to={`/appointment/${record.da_id}`} className="card small-card">
                <div className="card">
                    <div>
                        <h4 className="title">
                            {record.da_fname} {record.da_lname}
                        </h4>

                        <p className="values">
                            {moment(record.da_date).format('dddd, MMM Do')} {record.da_aptime}
                        </p>
                    </div>

                    <Button size="small" className="">
                        View
                    </Button>

                    {/* {record.da_newopno ? <Tag color="green">Booked</Tag> : <Tag color="orange">Pending</Tag>} */}
                </div>
            </Link>
        );
    };

    //  AddComponent = () => <CollectionAdd model={this} />
}

function GuestCard({ record = {} }) {
    return (
        <Card className="card vehicle-card">
            <div className="card">
                <div className="title">
                    <h1>
                        Name:{' '}
                        <strong>
                            {record.da_fname ? record.da_fname.toUpperCase() : null} {record.da_lname ? record.da_lname.toUpperCase() : null}
                        </strong>
                    </h1>
                </div>

                <div className="values">
                    {record.da_aptime ? (
                        <p>
                            <strong>Appointment Time: {record.da_aptime}</strong>
                        </p>
                    ) : null}
                </div>

                <div className="values">
                    {record.item ? (
                        <p>
                            <strong>Package: {record.item.itm_desc}</strong>
                        </p>
                    ) : null}
                </div>

                <div className="values">{record.preReg && record.preReg.prereg_ageyear ? <p> Age: {record.preReg.prereg_ageyear}</p> : null}</div>

                <div className="values">{record.da_tokenno ? <p>Token No.: {record.da_tokenno}</p> : null}</div>

                <div>
                    <strong>Bill Status</strong> <Tag color={record.da_billid ? 'green' : 'orange'}>{record.da_billid ? 'Billed' : 'Pending'}</Tag>
                    <strong>Questionnaire Status</strong>{' '}
                    <Tag color={record.da_questfilled === 'F' ? 'orange' : 'green'}>{record.da_questfilled === 'F' ? 'Filled' : 'Pending'}</Tag>
                    <strong> Appointment Status</strong> {record.da_newopno ? <Tag color="green">Booked</Tag> : <Tag color="orange">Pending</Tag>}
                </div>

                {/* <h3 className="values">
                 Primary Result : {record.PrimaryResult || 'Pending'}
 
             </h3> */}

                <Text type="secondary">{record.place}</Text>

                <div className="values" style={{ marginTop: '10px' }}>
                    {record.da_date ? <p>Date: {moment(record.da_date).format('DD/MM/YYYY')}</p> : null}
                </div>
            </div>
        </Card>
    );
}

export default ServiceResultMaster;
