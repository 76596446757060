/**
 * Component to Show bill details
 *
 *
 * @author Masuma
 */

import React, { useEffect, useState, Fragment, useRef, useContext } from 'react';

import { Typography, Table, Skeleton, Button } from 'antd';

import { useReactToPrint } from 'react-to-print';

import { Location, GlobalContext } from 'soxo-bootstrap-core';

import './bill-detail.scss';

import { BillDetails } from '../../../../models/';

import { Rupee } from 'soxo-bootstrap-core';

import moment from 'moment-timezone';

// To get logo in bill from env
// var brandLogo = require('../../../.' + process.env.REACT_APP_BRAND_LOGO);

var brandLogo = (process.env.REACT_APP_BRAND_LOGO);


const { Title, Text } = Typography;

export default function BillDetailView({ match, order, ...props }) {
    const [record, setRecord] = useState({});

    const [items, setItems] = useState([]);

    const print = useRef();

    const [loader, setLoader] = useState(true);

    const { id } = match.params;

    useEffect(() => {
        loadData();
    }, []);

    const handlePrint = useReactToPrint({
        content: () => print.current,
        bodyClass: 'bill-details-component bill-details-template',
    });

    function collectRecord() {
        Location.navigate({ url: `/collections/create?opbd_hdrid=${id}` });
    }

    /**
     * Bill Details
     */
    function loadData() {
        setLoader(true);

        BillDetails.getBillDetails(id).then((result) => {
            setItems(result.data);
            setLoader(false);
        });
    }

    return (
        <div className="bill-details-component page-content card">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end', marginBottom: '15px' }}>
                <div>
                    <Title level={3}>ORDER DETAILS</Title>
                </div>
                <div style={{ paddingBottom: '10px' }}>
                    <Button type="primary" className="print-button" onClick={handlePrint}>
                        Print Invoice
                    </Button>
                </div>
            </div>

            {loader ? (
                <>
                    <Skeleton active />
                </>
            ) : (
                <Fragment>
                    <div className="card card-shadow bill-preview" style={{ padding: '30px' }}>
                        <div>
                            {/* Bill Content */}
                            <BillContent record={record} items={items} brandLogo={brandLogo} />
                            {/* Bill Content Ends */}
                        </div>

                        <div>
                            <Button type="dashed" className="print-button" onClick={handlePrint}>
                                Print Invoice
                            </Button>
                        </div>

                        {/* Print Block */}
                        <div className="print-block-bill-detail">
                            <WrapperClass ref={print}>
                                <div className="card card-shadow bill-preview">
                                    {/* Bill Content */}
                                    <BillContent record={record} items={items} brandLogo={brandLogo} />
                                    {/* Bill Content Ends */}
                                </div>
                            </WrapperClass>
                        </div>

                        {/* Print Block ends */}
                        {/* </div> */}
                    </div>
                </Fragment>
            )}
        </div>
    );
}

function BillContent({ items, record, brandLogo }) {
    return (
        <>
            <div>
                {/* <div className="card"> */}
                <div className="bill-header">
                    <div>
                        {/* <img className="brand-logo" src={logo} alt="Logo" /> */}

                        <h2>Order No: {items.opb_bno}</h2>
                    </div>
                    <div className="logo">
                        {/* <img style={{ width: '100px' }} src={brandLogo} alt="onedesk" /> */}
                    </div>
                </div>
                <hr />
                <div className="bill-details">
                    <div>
                        <p>ORDER NO.</p>

                        <Title level={5}>{items.opb_bno}</Title>
                    </div>
                    <div>
                        <p>OP NO.</p>

                        <Title level={5}>{items.opb_opno}</Title>
                    </div>
                    <div>
                        <p>NAME</p>

                        <Title level={5}>{items.opb_name}</Title>
                    </div>
                    <div>
                        <p>ADDRESS</p>

                        <Title level={5}>
                            {items.opb_add1}
                            {items.opb_add1 && items.opb_add2 ? ',' : null}
                            {items.opb_add2}
                        </Title>
                    </div>
                    <div>
                        <p>DATE & TIME</p>

                        <Title level={5}>{moment.tz(items.opb_tm, '').format('DD-MM-YYYY hh:mm:ss A')}</Title>
                    </div>
                    <div>
                        <p>AGE/ GENDER</p>

                        <Title level={5}>
                            {items.opb_age} / {items.opb_gender}
                        </Title>
                    </div>
                </div>

                <Table
                    className="bill-summary"
                    scroll={{ x: true }}
                    dataSource={items.opbilld}
                    columns={billColumns}
                    pagination={false}
                    summary={(pageData) => {
                        let Total = 0;
                        pageData.forEach((entry) => {
                            Total += entry.opbd_rate * entry.opbd_qty;
                        });

                        return (
                            <>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <strong>Total</strong>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <strong>
                                            <Rupee value={items.opb_amt}></Rupee>
                                        </strong>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>

                                <Table.Summary.Row>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <strong>Discount</strong>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <strong>
                                            <Rupee value={items.opb_bdisamt}></Rupee>
                                        </strong>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>

                                <Table.Summary.Row>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <strong>Net Amount</strong>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <Title level={4}>
                                            <Rupee value={items.opb_netamt}></Rupee>
                                        </Title>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            </>
                        );
                    }}
                />

                <div className="bill-footer">
                    <div>
                        <p>Created On: {moment.tz(items.opb_tm, '').format('DD-MM-YYYY hh:mm:ss A')}</p>
                        <p>Printed On: {moment.tz(items.opb_tm, '').format('DD-MM-YYYY hh:mm:ss A')}</p>
                    </div>
                    <p>Signature</p>
                </div>
            </div>
        </>
    );
}

class WrapperClass extends React.Component {
    render() {
        return <div style={{ width: '100%' }}>{this.props.children}</div>;
    }
}

const billColumns = [
    {
        title: '#',
        dataIndex: 'index',
        render: (value, item, index) => index + 1,
    },
    {
        title: 'Item Description',
        render: (ele) => {
            return <div>{ele.opbd_itemdesc}</div>;
        },
        key: 'Description',
    },
    {
        title: 'Rate',
        render: (ele) => {
            return <div>{ele.opbd_rate}</div>;
        },
        key: 'rate',
    },
    {
        title: 'Quantity',
        render: (ele) => {
            return <div>{ele.opbd_qty}</div>;
        },
        key: 'quantity',
    },
    // {
    //     title: 'Amount',
    //     render: (items) => {
    //         return <div>{items.opbd_qty*items.opbd_rate}</div>
    //     },
    //     key: 'amount',
    // },
    {
        title: 'Discount',
        render: (ele) => {
            return <div>{ele.opbd_disamt}</div>;
        },
        key: 'discount',
    },
    {
        title: 'Total',
        render: (ele) => {
            return <div>{ele.opbd_net}</div>;
        },
        key: 'total',
    },
];
