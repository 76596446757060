/***
 *
 *
 * @description
 * @author safvanpk
 */
import React, { useEffect, useState } from 'react';

import './status.scss';
import { Typography } from 'antd';
import { BulbFilled } from '@ant-design/icons';
const { Title } = Typography;

export default function Status({ status }) {
    return (
        <div className="main" style={{}}>
            <div className="status-card">
                <div className="card" style={{ borderColor: status.colour }}>
                    <div>
                        <Title level={5}>
                            <BulbFilled style={{ Color: status.colour }} /> <span style={{ Color: status.colour }}> {status.Heading}</span>
                        </Title>
                        <p>{status.text}</p>
                    </div>

                    {/* <img className="status" style={{ width: status.width, right: status.right }} src={status.image} alt="status-logo" /> */}
                </div>
            </div>
        </div>
    );
}
