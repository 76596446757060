/**
 *@description
 * @author Sameenaammad
 * This component is for applying coupon
 * on click of apply coupon, a modal opens up and the coupon is enterd there
 * on submit it is checked if the coupon is valid or not
 * If yes the coupon will be stored when the whole form is submitted
 */

import React,{ useState, useEffect } from 'react';

import { Button, Input, Typography, Modal, message, Form, Popconfirm } from 'antd';

import { Location, InputComponent } from 'soxo-bootstrap-core';

import { Appointments } from './../../../../models';

import './apply-coupon.scss'

const { Title, Text } = Typography;

const { TextArea } = Input;

export function ApplyCoupon({ coupon, setCoupon, setTotal, total, initialValues }) {

    var urlParams = Location.search();

    const [visible, setVisible] = useState(false);

    const [loading, setLoading] = useState(false)

    useEffect(() => {

    })


    function couponvisibility() {
        setVisible(true);
    }


    return (
        <div className="right-content ">
            <div className="card card-shadow">
                <div className="section-header">
                    <Title level={5}>APPLY COUPON </Title>
                </div>

                <div className="section-row apply-coupon">
                    <div className="section-label">
                        <Text type="secondary">Coupon Discount</Text>
                    </div>
                    <div className="section-value">
                        {coupon ? (
                            <>
                                <strong>{coupon && coupon.coupon_amount ? coupon.coupon_amount : null}</strong>

                                <Popconfirm
                                    title="Are you sure you want to Cancel the Coupon ? "
                                    onConfirm={() => {
                                        setTotal(total + coupon.coupon_amount)
                                        setCoupon(null);
                                    }}
                                    className="cancel-coupon-button"
                                    onCancel={() => { }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button size="small" className="cancel-coupon-button" >
                                        Cancel
                                    </Button>
                                </Popconfirm>
                            </>
                        ) : (
                            <Button
                                loading={loading}
                                size="small"
                                type="secondary"
                                // shape="round"
                                onClick={couponvisibility}
                                disabled={urlParams.mode === 'Walk In' || urlParams.edit}
                            >
                                {' '}
                                Apply Coupon
                            </Button>
                        )}
                    </div>
                </div>


            </div>
            {/**Coupon Modal starts */}
            <Modal
                destroyOnClose={true}
                footer={null}
                title="Apply Coupon"
                visible={visible}
                okText="Okay"
                onOk={() => {
                    setVisible(false);
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <CouponModal setVisible={setVisible} setCoupon={setCoupon} setTotal={setTotal} total={total} setLoading={setLoading} itemCode={urlParams.da_packagetypevalue} />
            </Modal>
            {/**Coupon Modal Ends */}
        </div>
    );
}

/**
 * Modal to apply coupon
 * @param {*} param0 
 * @returns 
 */
function CouponModal({ setVisible, setCoupon, setTotal, total, setLoading, itemCode }) {

    const onFinish = (values) => {
        setVisible(false);
        setLoading(true)

        Appointments.applyCoupon(values.coupon, itemCode)
            .then((result) => {
                if (result.success) {

                    let couponAmount

                    if (result.data.value_type === 'P')
                        couponAmount = total * (result.data.coupon_value / 100)
                    else if (result.data.value_type === 'A')
                        couponAmount = result.data.coupon_value
                    result.data = {
                        ...result.data,
                        coupon_amount: couponAmount
                    }
                    // If after applying coupon, the total becomes less tha zero, it should send an error message
                    if (total - result.data.coupon_value < 0) {
                        message.error('Coupon Amount exceeds total Amount. Please check your coupon')
                        setLoading(false)
                        return false
                    } else {
                        message.success(result.message)

                        setTotal(total - result.data.coupon_amount)

                        setCoupon(result.data);

                        setLoading(false);
                    }
                }
                else {
                    message.error(result.message)
                    setLoading(false)
                }
            })
            .catch((error) => {
                setLoading(false);

                message.error('Invalid Coupon');
            });
    };

    return (
        <>
            <p>Mention the Coupon</p>

            <Form name="basic" initialValues={{ remember: true }} onFinish={onFinish}>
                <Form.Item label="Coupon" name="coupon" rules={[{ required: true, message: 'Please Enter Coupon' }]}>
                    <InputComponent />
                </Form.Item>
                <Form.Item name="Remarks" label="Remarks" rules={[{ required: true, message: 'Please Enter Remarks' }]}>
                    <TextArea rules={[{ required: true, message: 'Please enter your remarks' }]} />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
}
