/**
 * Status-change Component
 *
 * @description Component to change status
 * @author Sneha T
 */

import React, { useState } from 'react';

import { Form, Input, Timeline, Button, Modal, message, Select } from 'antd';

import { ServiceResultMaster } from '../../../../models';

import moment from 'moment';

import './status-change.scss';

/**
 *
 */
export default function StatusChange({ description, statusValue, mode, apiMode, id, callback, name, updateBtnDisabled }) {

    const { TextArea } = Input;

    const { Option } = Select;

    const [status, setStatus] = useState([]); //state for status options

    const [visible, setVisible] = useState(false);// state to manage modal visibility

    const [logs, setLogs] = useState([]); //state to store previous log of status

    const [disabled, setDisabled] = useState('true') //state to disable and enable button

    const [btnloading, setBtnLoading] = useState(false); //state to manage button loading

    /**
    * Get all the status options
    */
    async function getOptions() {

        const result = await ServiceResultMaster.getServiceOptions(mode);

        setStatus(result)

    }

    /**
     * Update the status of the mode
     * 
     * @param {*} values 
     * @returns 
     */
    async function updateStatus(values) {

        let formBody = {

            "status": values.srvrsm_code,

            "remarks": values.srvrsm_remarks,

            "mode": apiMode

        }

        setBtnLoading(true);

        return await ServiceResultMaster.updateStatus({ formBody, id }).then((result) => {

            setBtnLoading(false);

            setVisible(false);

            message.success(result.message);

            callback();

        });
    }

    /**
     * Get status log
    */
    const getStatusLog = () => {

        getOptions()

        setDisabled(true)

        setVisible(true)

        return ServiceResultMaster.getLog({ id, apiMode }).then((result) => {

            setLogs(result)

        });

    }

    return (

        <div className='status-change'>

            <div>
                <p>{description || <i>Pending</i>}</p>
            </div>

            <div className="actions">

                <Button className={"field-editor"} size="small" onClick={getStatusLog} disabled={updateBtnDisabled}>
                    Update
                </Button>

            </div>

            <div className="status-section" style={{ display: 'flex' }}>

                <Modal

                    width={'50%'}

                    header={null}

                    destroyOnClose

                    visible={visible}

                    title={'Status Update'}

                    footer={null}

                    onCancel={() => setVisible(false)}>

                    <div className="status-modal">

                        <div className='form-section'>

                            <p>Update the {name}</p>

                            <Form onFieldsChange={(fields) => {

                                // Enable submit button while changing the status 
                                if (fields[0].name[0] === 'srvrsm_code') {

                                    setDisabled(false)
                                }
                            }}

                                onFinish={updateStatus} initialValues={{ srvrsm_code: statusValue }}

                                name="status-change-form" layout="vertical" label="update status">

                                <Form.Item

                                    name="srvrsm_code"

                                    label="Status"

                                    rules={[
                                        {
                                            required: true,

                                            message: 'Please select status',
                                        },

                                    ]}

                                >
                                    <Select
                                    >
                                        {status.map((data, key) => (

                                            <Option value={data.srvrsm_code}>

                                                {data.srvrsm_desc}

                                            </Option>
                                        ))}

                                    </Select>


                                </Form.Item>

                                <Form.Item name="srvrsm_remarks" label="Remarks"

                                    rules={[

                                        {
                                            required: false,
                                        },

                                        {
                                            max: 250,

                                            message: 'Length cannot exceed 250 characters !',
                                        },
                                    ]}>

                                    <TextArea rows={4} />

                                </Form.Item>

                                <Button disabled={disabled} loading={btnloading} htmlType='submit' type="primary" size="small">

                                    Submit

                                </Button>

                            </Form>

                        </div>

                        <div className='history'>

                            <Timeline>

                                {
                                    logs.map((ele) => {

                                        return (

                                            <Timeline.Item color="green">

                                                {ele.mode_status_description}

                                                <p className='details'><small> {moment.tz(ele.status_updated_at, '').format('DD/MM/YYYY hh:mm a')}</small></p>

                                                <p className='details'>

                                                    <small><strong>Remarks:</strong> {ele.remarks}</small>
                                                </p>
                                                <p className='details'>

                                                    <small><strong>Updated By:</strong> {ele.updated_by}</small>
                                                </p>

                                            </Timeline.Item>
                                        );

                                    })}

                            </Timeline>

                        </div>

                    </div>

                </Modal>

            </div >

        </div >

    );

}
