/**
 * @description
 * @author Sneha
 *
 * Corporate-Master page to list customer, add new customers, edit customers
 */

import React, { useState, useEffect } from 'react';

import { Customers } from '../../../../models';

import moment from 'moment-timezone';

import { InputComponent, CountryPhoneInput } from 'soxo-bootstrap-core';

import { isValidPhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';

import { Skeleton, Button, Input, Form, Card, Table, Typography, Space, Switch, Modal, Row, Col, message, Select } from 'antd';

import { ReloadOutlined } from '@ant-design/icons';

import { Link } from 'react-router-dom';

const { Title, Text } = Typography;

const { Option } = Select;

const { TextArea, Search } = Input;

const formItemLayout = {

    labelCol: {

        // xs: { span:0 },
        // sm: { span: 0},
    },

    wrapperCol: {

        span: 50,

    },

    layout: 'vertical',
};

export default function CorporateMaster({ cc_mode }) {

    const [customer, setCustomers] = useState([]);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [page, setPage] = useState(1);

    const [limit, setLimit] = useState(10);

    const [view, setView] = useState(false);

    const [loading, setLoading] = useState(true);

    //To disable feilds
    const [disabled, setDisabled] = useState(false);

    const [btnloading, setBtnloading] = useState(false);

    var [query, setQuery] = useState('');

    const [initial, setInitial] = useState({});

    const [edit, setEdit] = useState(false);

    //To disable all fields on view
    const [viewDisable, setViewDisable] = useState(false);

    useEffect(() => {

        getCustomers(cc_mode);

    }, []);

    /**
     *  Get Customers
     */

    function getCustomers(cc_mode) {

        Customers.getCustomers(cc_mode).then((result) => {

            setCustomers(result.result);

            setLoading(false);

        });
    }

    /**
     * Search function
     */

    function onSearch(event) {

        setQuery(event.target.value);
    }

    /**
     *
     * @param {*} formBody
     */
    const onSubmit = (value) => {

        setBtnloading(true);

        // // Storing value of op_mobile to a variable
        // var mobile = value.cc_mobile;

        // //Storing country code length
        // var dialCodeLength = mobile.code.dialCode.length;

        //When Adding new customer
        if (!edit) {

            var values = {

                cc_code: value.cc_code,

                cc_desc: value.cc_desc,

                cc_add1: value.cc_add1,

                cc_add2: value.cc_add2,

                cc_place: value.cc_place,

                cc_zip: value.cc_zip,

                cc_phone: value.cc_phone,

                cc_fax: value.cc_fax,

                cc_regno1: value.cc_regno1,

                cc_regno2: value.cc_regno2,

                cc_contactper: value.cc_contactper,

                cc_cntperdesg: value.cc_cntperdesg,

                cc_mobile: value.cc_mobile,

                // cc_mobile: mobile.value.substring(dialCodeLength),

                cc_acpostcode: value.cc_acpostcode,

                cc_slno: value.cc_slno,

                cc_active: value.cc_active,

                cc_remarks: value.cc_remarks,

                // cngd_dt: moment().format('YYYY-MM-DD'),

                cc_mode: cc_mode,

                cc_type: value.cc_type,

            };

            Customers.addCustomer(values).then(() => {

                getCustomers(cc_mode);

                setBtnloading(false);

                handleOk();

                message.success('Customer Added Successfully!');
            });

        } else {

            // // Storing value of op_mobile to a variable
            // var mobile = value.cc_mobile;

            // //Storing country code length
            // var dialCodeLength = mobile.code.dialCode.length;

            //In case of edit
            var values = {

                // cc_code: value.cc_code,

                cc_desc: value.cc_desc,

                cc_add1: value.cc_add1,

                cc_add2: value.cc_add2,

                cc_place: value.cc_place,

                cc_zip: value.cc_zip,

                cc_phone: value.cc_phone,

                cc_fax: value.cc_fax,

                cc_regno1: value.cc_regno1,

                cc_regno2: value.cc_regno2,

                cc_contactper: value.cc_contactper,

                cc_cntperdesg: value.cc_cntperdesg,

                cc_mobile: value.cc_mobile,

                // cc_mobile: mobile.value.substring(dialCodeLength),

                cc_acpostcode: value.cc_acpostcode,

                cc_slno: value.cc_slno,

                cc_active: value.cc_active,

                cc_remarks: value.cc_remarks,

                // cngd_dt: moment().format('YYYY-MM-DD'),

                // cc_mode: cc_mode,

                cc_type: value.cc_type,

            };

            Customers.editCustomer(value.cc_code, values).then(() => {

                setBtnloading(false);

                handleOk();

                message.success('Campaign Edited Successfully!');

            });

        };
    };

    //add customer
    const addCustomer = () => {

        setEdit(false);

        setDisabled(false);

        setViewDisable(false);

        setInitial({});

        showModal();
    };

    //edit customer
    const getCustomerDetails = async (code) => {

        await getCustomerWithId(code);

        await showModal();
    };


    const showModal = () => {

        setIsModalVisible(true);
    };

    const handleOk = () => {

        setIsModalVisible(false);

        getCustomers(cc_mode)
    };

    /**
 * get customer with Id
 * @param {} result
 */

    async function getCustomerWithId(code) {

        // Getting customer with code
        var result = await Customers.getCustomerWithId(code);

        if (result.result.cc_code) {

            result = {
                ...result.result,

            };
        }

        // Set as initial values of form
        setInitial(result);

    }

    const handleCancel = () => {

        setIsModalVisible(false);
    };

    var validationProps = [

        {

            required: true,

            message: 'Please Enter Code',

        },

        {
            max: 10,

            message: ' Length cannot exceed 10 characters!',

        },

        {
            pattern: /^[ A-Za-z0-9]*$/,

            message: ' Please Enter valid Code!',

        },

    ];

    //To check if entered customer code is unique
    const checkCode = async (code, callback) => {

        const result = await Customers.checkCode(code, cc_mode);

        if (result.data == null) {

            return true;

        } else {

            return false;
        }

    };

    /**
     *  columns
     */
    const Customercolumns = [

        ...[

            {

                title: '#',

                dataIndex: 'index',

                render: (value, item, index) => {

                    return (page - 1) * limit + index + 1;
                },

            },

        ],

        {

            title: 'Sl No.',

            key: 'cc_slno',

            render: (record) => {

                return record.cc_slno;

            },

        },

        {

            title: 'Code',

            key: 'cc_code',

            render: (record) => {

                return record.cc_code;

            },

        },

        {
            title: 'Name',

            key: 'cc_desc',

            width: '15px',

            render: (record) => {

                return record.cc_desc;

            },

        },

        {
            title: 'Address 1',

            key: 'cc_add1',

            render: (record) => {

                return record.cc_add1;

            },

        },

        {
            title: 'Address 2',

            key: 'cc_add1',

            render: (record) => {

                return record.cc_add2;

            },

        },

        {
            title: 'Place',

            key: 'cc_place',

            render: (record) => {

                return record.cc_place;

            },

        },

        {
            title: 'Zip Code',

            key: 'cc_zip',

            render: (record) => {

                return record.cc_zip;

            },

        },

        {
            title: 'Phone',

            key: 'cc_phone',

            render: (record) => {

                return record.cc_phone;

            },
        },

        {
            title: 'Fax',

            key: 'cc_fax',

            render: (record) => {

                return record.cc_fax;

            },

        },

        {
            title: 'Register No. 1',

            key: 'cc_regno1',

            render: (record) => {

                return record.cc_regno1;

            },

        },

        {

            title: 'Register No. 2',

            key: 'cc_regno2',

            render: (record) => {

                return record.cc_regno2;

            },

        },

        {
            title: 'Contact Person',

            key: 'cc_contactper',

            render: (record) => {

                return record.cc_contactper;

            },

        },

        {
            title: 'Designation',

            key: 'cc_cntperdesg',

            render: (record) => {

                return record.cc_cntperdesg;

            },

        },

        {

            title: 'Mobile',

            key: 'cc_mobile',

            render: (record) => {

                return record.cc_mobile;

            },

        },

        {

            title: 'Account Code',

            key: 'cc_acpostcode',

            render: (record) => {

                return record.cc_acpostcode;

            },

        },

        {

            title: 'Active',

            key: 'cc_active',

            render: (record) => {

                return record.cc_active;

            },

        },

        {

            title: 'Remarks',

            key: 'cc_remarks',

            render: (record) => {

                return record.cc_remarks;

            },

        },

        {

            title: 'Date',

            key: 'cngd_dt',

            render: (record) => {

                return moment(record.cngd_dt).format('DD/MM/YYYY')

            },

        },

        {

            title: 'Mode',

            key: 'cc_mode',

            render: (record) => {

                return record.cc_mode;

            },

        },

        {

            title: 'Customer Type',

            key: 'cc_type',

            render: (record) => {

                return record.cc_type;

            },

        },

        {
            title: '',

            key: 'action',

            fixed: 'right',

            render: (record) => {

                function onClick() { }

                return (

                    <Space size="middle">

                        <Link

                            onClick={() => {

                                setEdit(false);

                                setDisabled(false);

                                setViewDisable(true);

                                getCustomerDetails(record.cc_code);

                            }}
                        >
                            View
                        </Link>

                    </Space>
                );
            },
        },

        {
            title: '',
            key: 'action',
            fixed: 'right',

            render: (record) => {

                function onClick() { }

                return (
                    <Space size="middle">

                        <Link

                            onClick={async () => {

                                setEdit(true);

                                setDisabled(true);

                                setViewDisable(false);

                                await getCustomerDetails(record.cc_code);

                            }}
                        >
                            Edit
                        </Link>

                    </Space>

                );
            },
        },

    ];


    // Defining Data source for passing to list
    var dataSource = customer.filter((item) => {

        query = query.toUpperCase();

        if (query) {

            if (item.cc_desc.toUpperCase().indexOf(query) != -1) {

                return true;

            }

        } else {

            return true;
        }

    });

    //In case of edit, validation of code is not required
    if (!edit) {

        validationProps.push({

            //Custom validation
            validator(rule, value) {

                return new Promise(async (resolve, reject) => {
                    //Check if code is unique
                    const result = await checkCode(value);

                    //if result returns false this message is shown
                    if (!result) {

                        reject('Please enter unique code');

                    } else {

                        resolve();

                    }

                });

            },

        });
    }

    return (

        <div className="card">

            {loading ? (

                <Skeleton />

            ) : (

                <>
                    <div className="page-header">

                        <div className="left">

                            <Title level={3}>Corporate</Title>

                            <Search

                                placeholder="Enter Search Value"

                                allowClear

                                style={{ width: 300, marginTop: '10px', marginBottom: '20px' }}

                                onChange={onSearch}

                            />

                        </div>

                        <div className="table-actions right">

                            <div className="button-container">

                                <Space size="small">

                                    <Button onClick={getCustomers} size={'small'}>

                                        <ReloadOutlined />

                                    </Button>

                                    <Button type="primary" size="small" onClick={addCustomer} >

                                        Add
                                    </Button>

                                </Space>

                            </div>

                        </div>

                    </div>

                    <div className="table-card">

                        <Table

                            dataSource={dataSource}

                            scroll={{ x: true }}

                            columns={Customercolumns}

                            pagination={{

                                onChange(current) {

                                    setPage(current);
                                },

                            }}

                        />

                    </div>

                </>
            )}

            {/** Modal For Add New Customer  Start */}
            <Modal title=" Corporate Master" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} destroyOnClose footer={[]} width={600}>

                <div>
                    <Card className="card">

                        <Form

                            {...formItemLayout}

                            initialValues={{

                                ...initial,

                                cc_mode: cc_mode

                            }}

                            onFinish={onSubmit}
                        >
                            <Row gutter={20}>

                                <Col xs={12} md={12} xl={12}>

                                    <Form.Item

                                        name="cc_code"

                                        label="Code"

                                        // rules={[

                                        //     {
                                        //         pattern: /^[0-9]*$/g,

                                        //         message: 'Please Enter Number',
                                        //     },
                                        // ]}

                                        rules={validationProps}

                                        hasFeedback


                                    >
                                        <InputComponent placeholder="Enter Code" disabled={viewDisable || disabled} />

                                    </Form.Item>

                                </Col>

                                <Col xs={12} md={12} xl={12}>

                                    <Form.Item name="cc_mode" label="Mode">

                                        <InputComponent placeholder="Enter mode" disabled={viewDisable || true} />

                                    </Form.Item>

                                </Col>

                            </Row>

                            <Row gutter={20}>

                                <Col xs={24} md={24} xl={24}>

                                    <Form.Item

                                        name="cc_desc"

                                        label="Name"

                                        rules={[

                                            {

                                                required: true,

                                                message: 'Please Enter Name!',

                                            },

                                            {

                                                pattern: /^[a-zA-Z\s]*$/g,

                                                message: 'Please Enter Valid Name!',

                                            },

                                            {

                                                max: 50,

                                                message: 'Length cannot exceed 50 characters !',
                                            },

                                        ]}

                                    >
                                        <InputComponent placeholder="Enter Name" disabled={viewDisable} />

                                    </Form.Item>

                                </Col>

                            </Row>

                            <Row gutter={20}>

                                <Col xs={24} md={24} xl={24}>

                                    <Form.Item name="cc_add1" label="Address 1"

                                        rules={[

                                            {

                                                required: false,

                                                message: 'Please Enter Address ',

                                            },

                                            {

                                                max: 50,

                                                message: 'Length cannot exceed 50 characters !',

                                            },

                                            {

                                                pattern: /^[ A-Za-z0-9_.,()&-]*$/,

                                                message: 'Enter Valid Address !',

                                            },

                                        ]}>

                                        <InputComponent placeholder="Enter Address" disabled={viewDisable} />

                                    </Form.Item>

                                </Col>

                            </Row>

                            <Row gutter={20}>

                                <Col xs={24} md={24} xl={24}

                                    rules={[

                                        {
                                            required: false,

                                            message: 'Please Enter Address',
                                        },

                                        {
                                            max: 50,

                                            message: 'Length cannot exceed 50 characters !',
                                        },

                                        {
                                            pattern: /^[ A-Za-z0-9_.,()&-]*$/,

                                            message: 'Enter Valid Address !',
                                        },

                                    ]}>

                                    <Form.Item name="cc_add2" label="Address 2">

                                        <InputComponent placeholder="Enter Address" disabled={viewDisable} />

                                    </Form.Item>

                                </Col>

                            </Row>

                            <Row gutter={20}>

                                <Col xs={12} md={12} xl={12}>

                                    <Form.Item

                                        name="cc_place"

                                        label="Place"

                                        rules={[

                                            {
                                                required: false,

                                                message: 'Please Enter Address',
                                            },

                                            {
                                                max: 50,

                                                message: 'Length cannot exceed 50 characters !',
                                            },

                                            {
                                                pattern: /^[ A-Za-z0-9_.,()&-]*$/,

                                                message: 'Enter Valid Address !',
                                            },

                                        ]}

                                    >

                                        <InputComponent placeholder="Enter Place" disabled={viewDisable} />

                                    </Form.Item>

                                </Col>

                                <Col xs={12} md={12} xl={12}>

                                    <Form.Item name="cc_zip" label="Zip Code"

                                        rules={[

                                            {

                                                required: false

                                            },

                                            {
                                                max: 10,

                                                message: 'Length cannot exceed 10 characters !',
                                            },

                                            {

                                                pattern: /(^\d{6,7}$)|(^\d{5}-\d{4}$)/,

                                                message: 'Please enter valid Zip Code ',

                                            },

                                        ]}>

                                        <InputComponent placeholder="Enter Zip Code" disabled={viewDisable} />

                                    </Form.Item>

                                </Col>

                            </Row>

                            <Row gutter={20}>

                                <Col xs={12} md={12} xl={12}>

                                    <Form.Item

                                        name="cc_phone"

                                        label="Phone"

                                        disabled={viewDisable}

                                        rules={[

                                            {
                                                pattern: /^\d{10}$/g,

                                                message: 'Please Enter Mobile !',
                                            },

                                        ]}

                                    >

                                        <InputComponent placeholder="Enter Phone" disabled={viewDisable} />

                                    </Form.Item>

                                </Col>

                                <Col xs={12} md={12} xl={12}>

                                    <Form.Item name="cc_fax" label="Fax"

                                        rules={[

                                            {
                                                max: 25,

                                                message: 'Length cannot exceed 25 characters !',
                                            },

                                            {
                                                pattern: /^[0-9]*$/g,

                                                message: 'Please Enter Fax',
                                            },

                                        ]}>

                                        <InputComponent placeholder="Enter Fax" disabled={viewDisable} />

                                    </Form.Item>

                                </Col>

                            </Row>

                            <Row gutter={20}>

                                <Col xs={12} md={12} xl={12}>

                                    <Form.Item disabled={viewDisable}

                                        name="cc_regno1"

                                        label="Reg No. 1"

                                        rules={[

                                            {
                                                max: 50,

                                                message: 'Length cannot exceed 50 characters !',
                                            },

                                            {
                                                pattern: /^[a-zA-Z0-9]*$/g,

                                                message: 'Please Enter Reg No.',
                                            },

                                        ]}

                                    >

                                        <InputComponent placeholder="Enter Register No. 1" disabled={viewDisable} />

                                    </Form.Item>

                                </Col>

                                <Col xs={12} md={12} xl={12}>

                                    <Form.Item name="cc_regno2" label="Reg No. 2"

                                        rules={[

                                            {
                                                max: 50,

                                                message: 'Length cannot exceed 50 characters !',
                                            },

                                            {
                                                pattern: /^[a-zA-Z0-9]*$/g,

                                                message: 'Please Enter Reg No.',
                                            },

                                        ]}>

                                        <InputComponent placeholder="Enter Register No. 2" disabled={viewDisable} />

                                    </Form.Item>

                                </Col>

                            </Row>

                            <Row gutter={20}>

                                <Col xs={24} md={24} xl={24}>

                                    <Form.Item name="cc_contactper" label="Contact Person"

                                        rules={[

                                            {
                                                max: 50,

                                                message: 'Length cannot exceed 50 characters !',
                                            },

                                            {
                                                pattern: /^[a-zA-Z\s]*$/g,

                                                message: 'Please Enter Valid Name!',
                                            },

                                        ]}>

                                        <InputComponent placeholder="Enter Contact person" disabled={viewDisable} />

                                    </Form.Item>

                                </Col>

                            </Row>

                            <Row gutter={20}>

                                <Col xs={24} md={24} xl={24}>

                                    <Form.Item name="cc_cntperdesg" label="Designation"

                                        rules={[

                                            {
                                                max: 50,

                                                message: 'Length cannot exceed 50 characters !',
                                            },

                                            {
                                                pattern: /^[a-zA-Z\s]*$/g,

                                                message: 'Please Enter Valid Name!',
                                            },

                                        ]}>

                                        <InputComponent placeholder="Enter Designation" disabled={viewDisable} />

                                    </Form.Item>

                                </Col>

                            </Row>

                            <Row gutter={20}>

                                <Col xs={24} md={24} xl={24}>

                                    <Form.Item

                                        name="cc_mobile"

                                        label="Mobile"

                                        rules={[

                                            {
                                                pattern: /^\d{10}$/g,

                                                message: 'Please Enter Mobile !',
                                            },

                                        ]}

                                    // rules={[
                                    //     { required: true, message: 'Please Enter Mobile Number' },
                                    //     () => ({
                                    //         validator(_, value) {
                                    //             var code = value.code.countryCode.toUpperCase();

                                    //             // Check if the number is valid
                                    //             if (isValidPhoneNumber(value.value, code)) {
                                    //                 return Promise.resolve();
                                    //             } else {
                                    //                 // Returns the reason for the number to be invalid,mostly TOO SHORT
                                    //                 var errorMessage = validatePhoneNumberLength(value.value, code);

                                    //                 if (errorMessage) {
                                    //                     return Promise.reject(errorMessage);
                                    //                 } else {
                                    //                     return Promise.reject('Invalid Number');
                                    //                 }
                                    //             }
                                    //         },
                                    //     }),
                                    // ]}
                                    >
                                        {/* <CountryPhoneInput disabled={viewDisable} /> */}

                                        <InputComponent placeholder="Enter Mobile Number" disabled={viewDisable} />

                                    </Form.Item>

                                </Col>

                            </Row>

                            <Row gutter={20}>

                                <Col xs={12} md={12} xl={12}>

                                    <Form.Item name="cc_acpostcode" label="Account Code"
                                        rules={[

                                            {

                                                pattern: /^[0-9]+$/g,

                                                message: 'Please Enter Valid Account Code!',

                                            },

                                            {

                                                max: 25,

                                                message: 'Length cannot exceed 25 characters !',
                                            },

                                        ]}>

                                        <InputComponent placeholder="Enter Account code" disabled={viewDisable} />

                                    </Form.Item>

                                </Col>

                                <Col xs={12} md={12} xl={12}>

                                    {/* <Form.Item name="cc_mode" label="Mode">

                                        <InputComponent placeholder="Enter mode" disabled={viewDisable || true} />

                                    </Form.Item> */}
                                    <Form.Item name="cc_slno" label="Sl No."

                                        rules={[

                                            {
                                                pattern: /^[0-9]*$/g,

                                                message: 'Please Enter Valid Sl No. !',
                                            },

                                        ]}>

                                        <InputComponent placeholder="Enter Sl No." disabled={viewDisable} />

                                    </Form.Item>

                                </Col>


                            </Row>

                            <Row gutter={20}>

                                <Col xs={12} md={12} xl={12}>

                                    <Form.Item name="cc_type" label="Customer Type" placeholder="Enter Customer type">

                                        <Select disabled={viewDisable}>

                                            <Option value="PYR">PAYER</Option>

                                            <Option value="TPA">TPA</Option>

                                        </Select>

                                    </Form.Item>

                                </Col>

                                <Col xs={12} md={12} xl={12}>

                                    <Form.Item name="cc_active" label="Active">

                                        <Select disabled={viewDisable}>

                                            <Option value="Y">Yes</Option>

                                            <Option value="N">No</Option>

                                        </Select>

                                    </Form.Item>

                                </Col>

                            </Row>

                            <Row gutter={20}>

                                <Col xs={24} md={24} xl={24}>

                                    <Form.Item name="cc_remarks" label="Remarks"

                                        rules={[

                                            {
                                                max: 250,

                                                message: 'Length cannot exceed 250 characters !',
                                            },

                                            {
                                                pattern: /^[a-zA-Z\., ]*$/g,

                                                message: 'Please Enter Valid Remarks !',
                                            },

                                        ]}>

                                        <InputComponent placeholder="Enter remarks" disabled={viewDisable} />

                                    </Form.Item>

                                </Col>

                            </Row>
                            {/* <Row gutter={20}>
                                <Col xs={24} md={24} xl={24}>
                                    <Form.Item name="cngd_dt" label="Date">
                                        <InputComponent placeholder="Enter date" />
                                    </Form.Item>
                                </Col>
                            </Row> */}

                            <Form.Item>

                                <div>

                                    <Button loading={btnloading} type="primary" htmlType="submit" disabled={viewDisable}>
                                        Save
                                    </Button>

                                </div>

                            </Form.Item>

                        </Form>

                    </Card>

                </div>

            </Modal >

            {/** end  */}
        </div >
    );
}
