/**
 * 
 * @author Sameena Ammad
 *  To list all the customer who have used the same email address previously
 *  If any customer is selected, the case is handled as an existing customer repeated case
 *  Else, The normal flow is applied
 * @param {*}  
 * @returns 
 */

import { Button, Card, message } from 'antd';

import { Location } from 'soxo-bootstrap-core';

import { CheckCircleOutlined } from '@ant-design/icons';
import { Appointments } from '../../../models';
import React, { useState } from 'react';

export default function ExistingCustomerList({ selectedCustomer, existingCustomers, callBack, setVisible ,props}) {

    const params = Location.search()

    const [btnLoading, setBtnLoading] = useState(false)

    const [disabled, setDisabled] = useState(true)


    async function onFinish() {

        setBtnLoading(true)

        let queryParam

        if (selectedCustomer && selectedCustomer.user_name) {

            // If the customer is not a repeated customer of the same location, we will have to create a prereg or opreg in this location
            if (selectedCustomer && selectedCustomer.db_ptr && selectedCustomer.db_ptr !== props.settings.headers.toString()) {

                const opReg = await Appointments.createExistingCustomer(selectedCustomer)

                if (opReg && opReg.opReg && opReg.opReg.op_id) {

                    queryParam = {
                        ...params,
                        id: opReg.opReg.op_id,
                        table: 'opreg',
                        repeat: true,
                    };
                } else if (opReg && opReg.preReg && opReg.preReg.prereg_id) {
                    queryParam = {
                        ...params,
                        id: opReg.preReg.prereg_id,
                        table: 'prereg',
                        repeat: true,
                    };
                }

            } else {

                // maintain the case is repeated, the opreg or prereg id and the mode in url
                if (selectedCustomer && selectedCustomer.op_id) {
                    queryParam = {
                        ...params,
                        id: selectedCustomer.op_id,
                        table: 'opreg',
                        repeat: true,
                    };
                } else if (selectedCustomer && selectedCustomer.prereg_id) {
                    queryParam = {
                        ...params,
                        id: selectedCustomer.prereg_id,
                        table: 'prereg',
                        repeat: true,
                    };
                }
            }

            setBtnLoading(false)

            // Navigate to existing customer flow url
            Location.navigate({ url: `/new-registrations/existing-guest`, queryParam })
        }
    }
    return (
        <div>
            <p> We have found that {existingCustomers.length} {existingCustomers.length > 1 ? 'accounts' : 'account'}  are already linked to the email address <strong>{existingCustomers[0].email}</strong></p>
            <p>Please select an account to continue the Appointment Process</p>
            {existingCustomers && existingCustomers.length && existingCustomers.map((element) => {

                return (

                    <Card className={`mode ${selectedCustomer.prereg_id ? selectedCustomer.prereg_id === element.prereg_id ? 'active' : '' : selectedCustomer.op_id === element.op_id ? 'active' : ''}`} onClick={() => {
                        callBack(element);
                        setDisabled(false)

                    }}>
                        <div className='primary-element'>

                            <h4><CheckCircleOutlined /> {element.first_name} {element.last_name}</h4>
                            <div className='details'>
                                {element && element.op_number ? <>
                                    <div>
                                        <p>OP Number</p>
                                        <p>{element.op_number}</p>
                                    </div>


                                </> : <div>
                                    <p>Account ID</p>
                                    <p>{element.prereg_id}</p>
                                </div>}
                                <div className='vertical-line' />
                                <div>
                                    <p>Username</p>
                                    <p> {element.user_name}</p>

                                </div>
                                <div className='vertical-line' />
                                <div>
                                    <p>Mobile Number</p>
                                    <p> {element.mobile1}</p>
                                </div>
                                <div className='vertical-line' />
                                <div>
                                    <p>Address</p>
                                    <p> {element.address1}</p>
                                    <p> {element.address2}</p>
                                </div>

                            </div>
                        </div>
                    </Card>

                )
            })}
            <Button onClick={() => setVisible()}>Book For Another Person</Button>

            <div style={{ marginTop: '10px' }}>
                <Button type='primary' htmlType='submit' onClick={() => onFinish()} loading={btnLoading} disabled={disabled}>Continue</Button>
                <Button onClick={() => setVisible()}>Cancel </Button>
            </div>

        </div>
    )
}