import React, { useState, useEffect, useContext } from 'react';

import { Tag, Card, Modal, Button, Form, Table, Row, message, Select, Skeleton, Typography, Input, Timeline, Result, InputNumber, Alert } from 'antd';

import RegistrationInfo from './../../../common/components/registration-info/registration-info';

import moment from 'moment-timezone';

import { ReloadOutlined } from '@ant-design/icons';

import './result-detail.scss';

import { Location, GlobalContext, InputComponent,ReferenceSelect,ConfirmModal } from 'soxo-bootstrap-core';

import ReportPreview from '../report-preview/report-preview';

import { Samples, ResultEntry, Bills, GeneralLists } from '../../../../models';

const { Title } = Typography;

const { TextArea } = Input;

const { Option } = Select;

export default function ResultDetail({ mode = 'bill', bill_id, preview, groupPointer, match, allowUnauthorise }) {
    const [selectedreport, setSelectedreport] = useState({});

    const [btnloading, setBtnloading] = useState(false);

    const [loading, setLoading] = useState(true);

    const [resultloading, setResultloading] = useState(false);

    const [labReportResult, setLabReportResult] = useState({ test: {}, result: {}, entries: [], index: null });

    const [selected, setSelected] = useState({});

    const [chiefTechnician, setCheifTechnician] = useState('');

    const [technician, setTechnician] = useState('');

    var urlParams = Location.search();

    const { user, isMobile, index } = useContext(GlobalContext);

    //Patients list array
    const [patient, setPatient] = useState({});

    //Index of each test
    const [testIndex, setTestIndex] = useState(0);

    const [form] = Form.useForm();

    var [byteArray, setByteArray] = useState([]);

    const [reportModalVisible, setReportModalVisible] = useState(false);

    const [report, setReport] = useState(true);

    const [remarks, setRemarks] = useState('');

    const [alert, setAlert] = useState(false);

    var [labresult, setLabResult] = useState([]);

    const [disabledfield, setDisabledfield] = useState(false);

    const [disabled, setDisabled] = useState(false);

    var billId;

    // If mode visit,we pass visit id as props
    if (mode === 'visit') {
        billId = bill_id;
    } else {
        var { id } = match.params;

        billId = id;
    }

    useEffect(() => {
        getPatientDetails(urlParams, testIndex);
    }, []);

    /**
     * @param {*} value
     * Format result entered accordng to lbtr_resformat
     */

    async function formatResult(value, item, index, edit) {
        var formatFlag = true;
        var name;

        // When editing result field name will change
        if (edit === true) {
            name = 'lbtrsd_result';
        } else {
            name = 'result';
        }

        //To get validity status of form item
        await form.getFieldsError().some((i) => {
            if (i.name[0] === name + '_' + item.testKey + '_' + index && (i.warnings[0] != undefined || i.errors[0] != undefined)) {
                formatFlag = false;
            }
        });

        var formattedValue;

        // Formatting of value is done only if formatFlag is true,
        // If the field validity status is in error or warning ,formatting of value is not done, formatflag will be false.
        if (formatFlag) {
            var format = item.lbtr_resformat;

            // If there is no format given for the item, then value entered is taken as it is.
            if (format === '') {
                formattedValue = value;
            } else if (format.includes('.')) {
                // Format value according to res_format
                // Different scenarios considered here are:
                // - Case when format contains . (eg: #.0#)
                // - Case when format contains , (eg: #,##,###)
                // - Case when format does not contain . or , (eg: #0)

                //  Get string before and after decimal point in format
                var arr = format.split('.');

                //  string after decimal in format
                var decimalPart = arr[1];

                if (value.length) {
                    // Here we consider different scenarios.

                    // Once the value is entered, we check if decimal part of format has 0 in it.

                    // If only decimal part is entered as value (eg: .8)
                    // Under this case again different scenarious are considered.
                    // - When value entered decimal part length is greater than decimal part of format. (We roundoff value according to length of decimal part in format)
                    // - When entered value has same number of decimal part or lesser it is kept as it is.

                    // If a decimal number containing both whole number part and decimal part is entered, then we check length of decimal part and roundoff accordingly.

                    // If nothing is entered after decimal point in value  or if 0 is entered.(eg: 11. or 11.0 then only 11 will be the formatted value)

                    if (!decimalPart.includes('0')) {
                        // Get only wholenumber
                        var valueSplitted = value.split('.');

                        // If whole number part is not entered
                        if (valueSplitted[0] === '') {
                            // Decimal part should include same number of characters as in format
                            if (valueSplitted[1] && valueSplitted[1].length > decimalPart.length) {
                                formattedValue = `0${parseFloat(value).toFixed(decimalPart.length)}`;
                            } else {
                                // When decimal part in value is less than or equal to decimal part in format
                                formattedValue = `0${value}`;
                            }
                        } else if (valueSplitted[1] && valueSplitted[1].length > decimalPart.length) {
                            // Case when decimal number is entered as value
                            formattedValue = parseFloat(value).toFixed(decimalPart.length);
                        } else if ((value.includes('.') && valueSplitted[1].length === 0) || /^[0]*$/.test(valueSplitted[1]) === true) {
                            // If decimal is given but no digit entered in decimal part(case where decimall part format includes only #)
                            formattedValue = valueSplitted[0];
                        } else {
                            // If just a number is entered without decimal then the value is considered as it is.
                            formattedValue = value;
                        }
                    } else {
                        // Case when decimal part of format contains 0 in it.
                        // If a number without decimal is entered then it is converted to decimal number, that has same length of decimal part as in format
                        // (eg : if decimal part length of format is 2 and value entered is 1, then it is converted into 1.00 )
                        // If a decimal number is entered then round off is done according to decimal part length in format

                        // Convert into integer
                        if (!value.includes('.')) {
                            value = parseInt(value);
                        } else {
                            value = parseFloat(value);
                        }

                        //To show as decimal
                        formattedValue = value.toFixed(decimalPart.length);
                    }
                } else {
                    // If no value is entered , then 0 will be the value
                    formattedValue = 0;
                }
            } else if (format.includes(',')) {
                //Case when format includes ','

                // If no value entered then 0 will be the value
                // When value entered contain '.' then values after decimal will not be considered and number before decimal is convereted  into comma format.
                // When normal number is entered without . or , it is converted to comma format

                if (!value.length) {
                    formattedValue = 0;
                } else if (value.includes('.')) {
                    // If format contains , and if '.' is entered , then values after decimal will not be considered
                    value = value.substring(0, value.indexOf('.'));

                    if (value.length) {
                        value = parseInt(value);

                        formattedValue = value.toLocaleString('en-IN');
                    } else {
                        formattedValue = 0;
                    }
                } else {
                    if (value.includes(',')) {
                        // If value entered includes , then
                        // Remove , from value
                        // And then format
                        value = value.replace(/\,/g, '');

                        value = parseInt(value);
                    } else {
                        value = parseInt(value);
                    }

                    formattedValue = value.toLocaleString('en-IN');
                }
            } else if (!format.includes(',') && !format.includes('.')) {
                // Case when format does not include . or ,

                // If no value entered then 0 will be the value
                // If decimal number is entered then formatted value will be only whole number part of the number.
                // If value entered includes , then comma is removed from value.
                // If number starts with 0 then first character is  removed

                if (value.length) {
                    if (value.includes('.')) {
                        // Show only wholenumber
                        value = value.split('.');

                        //if whole number includes zero in first index,then remove 0
                        if (value[0].charAt(0) === '0') {
                            formattedValue = value[0].slice(1);
                        } else {
                            formattedValue = value;
                        }
                    } else if (value.includes(',')) {
                        // Remove , from value and display
                        value = value.replace(/\,/g, '');

                        //if  number includes zero in first index,then remove 0
                        if (value.charAt(0) === '0') {
                            formattedValue = value.slice(1);
                        } else {
                            formattedValue = value;
                        }
                    } else if (value === '') {
                        // If nothing entered then value would be zero
                        formattedValue = 0;
                    } else if (value.charAt(0) === '0' && value.length != 1) {
                        // if starts with 0 then remove 0
                        value = value.slice(1);

                        formattedValue = value;
                    } else {
                        //same value
                        formattedValue = value;
                    }
                } else {
                    formattedValue = 0;
                }
            }

            // Number has to be converted to string since lbtrsd_result column type is string. Api will fail otherwise.
            formattedValue = formattedValue.toString();

            //To save the formatted result
            labReportResult.entries.forEach((record, index) => {
                if (record.lbtr_id === item.lbtr_id) {
                    // Update the data to save the Results
                    record.values = {
                        result: formattedValue,
                        status: 'Pending for Submission',
                        // status: selectedreport.lbtr_id && !selectedreport.lbtrsd_id?'Pending for Submission':'Pending for Updation',
                    };
                }
            });

            //We include testindex and specimen index along with name
            form.setFieldsValue({ [name + '_' + item.testKey + '_' + index]: formattedValue });

            // Result is updated with formatted value
            await updateResult(formattedValue, item);
        } else {
            await updateResult(value, item);
        }

        setDisabled(true);
    }

    // Columns shown before saving any result
    const reportColumns = [
        {
            title: '#',
            key: 'lbtr_particulrs',
            render: (value, item, index) => index + 1,
        },

        {
            title: 'Description',
            dataIndex: 'lbtr_particulrs',
            key: 'lbtr_particulrs',
        },

        {
            title: 'Result',
            width: 150,
            render: (value, item, index) => {
                var resultFieldName = `result_${item.testKey}_${index}`;
                var warning;

                // Rules given when lbtr_vtype is numeric is given here, as for each format rule will change
                var rules = [
                    {
                        max: 50,
                        message: 'Length cannot exceed 50 characters !',
                    },

                    {
                        validator(_, value) {
                            if (((item.lbtr_lbound || item.lbtr_ubound) != 0 && value > item.lbtr_ubound) || value < item.lbtr_lbound) {
                                warning = true;

                                return Promise.reject('Result out of bound');
                            } else {
                                warning = false;

                                return Promise.resolve();
                            }
                        },

                        warningOnly: { warning },
                    },
                ];

                // , is allowed only if resformat includes ,
                if (item.lbtr_resformat && item.lbtr_resformat.includes(',')) {
                    rules.push({
                        pattern: /^[0-9.,]*$/g,
                        message: 'Please Enter Valid Result!',
                    });
                } else {
                    rules.push({
                        pattern: /^[0-9.]*$/g,
                        message: 'Please Enter Valid Result!',
                    });
                }

                if (item.lbtr_vtype === 'Text') {
                    return (
                        <Form.Item
                            name={resultFieldName}
                            rules={[
                                {
                                    max: 50,
                                    message: 'Length cannot exceed 50 characters !',
                                },
                                {
                                    pattern: /^[a-zA-Z\s]*$/g,
                                    message: 'Please Enter Valid Result!',
                                },
                            ]}
                            hasFeedback
                        >
                            <Input
                                autoComplete="off"
                                onChange={(event) => {
                                    updateResult(event.target.value, item);
                                }}
                                // In emr screen result entry disabled
                                disabled={mode === 'visit'}
                            ></Input>
                        </Form.Item>
                    );
                } else if (item.lbtr_vtype === 'Numeric') {
                    return (
                        <>
                            <Form form={form}>
                                <Form.Item name={resultFieldName} rules={rules} hasFeedback>
                                    <Input
                                        autoComplete="off"
                                        // In emr screen result entry disabled
                                        disabled={mode === 'visit'}
                                        onBlur={(e) => formatResult(e.target.value, item, index)}
                                    />
                                </Form.Item>
                            </Form>
                            <p> {item.lbtr_resformat ? <p style={{ color: 'gray', fontSize: '12px' }}>(format:{item.lbtr_resformat})</p> : null}</p>
                        </>
                    );
                } else if (item.lbtr_vtype === 'Select') {
                    return (
                        <Form.Item name={resultFieldName} rules={[{ required: true, message: 'Please select result' }]}>
                            <Select
                                //defaultValue={item.lbtr_otherdetails1.lab_result_parameters.value[0]}
                                onSelect={(event) => {
                                    updateResult(event, item);
                                }}
                                // In emr screen result entry disabled
                                disabled={mode === 'visit'}
                            >
                                {item.lbtr_otherdetails1.lab_result_parameters.option.map((otherDetail) => {
                                    return <Option value={otherDetail.value}>{otherDetail.label}</Option>;
                                })}
                            </Select>
                        </Form.Item>
                    );
                }
            },
        },

        // {
        //     title: 'Value',
        //     render: (item, record) => {
        //         return <>{item.values && item.values.result}</>;
        //     },
        //     key: 'lbtrsd_result',
        // },

        {
            width: 150,
            title: 'Range',
            dataIndex: 'lbtr_refrange',
            key: 'lbtr_refrange',
        },

        {
            title: 'Unit',
            dataIndex: 'lbtr_unit',
            key: 'lbtr_unit',
        },
    ];

    // Columns shown after saving result
    const resultColumns = [
        {
            title: '#',
            key: 'lbtr_particulrs',
            render: (value, item, index) => index + 1,
        },

        {
            title: 'Description',
            dataIndex: 'lbtrsd_particulrs',
            key: 'lbtrsd_particulrs',
        },

        {
            width: 150,
            title: 'Value',
            render: (record, item, index) => {
                var resultFieldName = `lbtrsd_result_${item.testKey}_${index}`;
                var name = 'lbtrsd_result';
                var initialValues;

                //We need different name for each field
                initialValues = {
                    [name + '_' + item.testKey + '_' + index]: item.lbtrsd_result,
                };

                var warning;

                // Rules given when lbtr_vtype is numeric is given here, as for each format rule will change
                var rules = [
                    {
                        max: 50,
                        message: 'Length cannot exceed 50 characters !',
                    },

                    {
                        validator(_, value) {
                            if (((item.lbtr_lbound || item.lbtr_ubound) != 0 && value > item.lbtr_ubound) || value < item.lbtr_lbound) {
                                warning = true;

                                return Promise.reject('Result out of bound');
                            } else {
                                warning = false;

                                return Promise.resolve();
                            }
                        },

                        warningOnly: { warning },
                    },
                ];

                // , is allowed only if resformat includes ,
                if (item.lbtr_resformat && item.lbtr_resformat.includes(',')) {
                    rules.push({
                        pattern: /^[0-9.,]*$/g,
                        message: 'Please Enter Valid Result!',
                    });
                } else {
                    rules.push({
                        pattern: /^[0-9.]*$/g,
                        message: 'Please Enter Valid Result!',
                    });
                }

                return (
                    <>
                        <Form initialValues={initialValues} form={form}>
                            {/**Case when dropdown is needed.To select postive or negative */}
                            {item.lbtr_vtype === 'Select' ? (
                                <Form.Item name={resultFieldName} rules={[{ required: true, message: 'Please select result' }]}>
                                    <Select
                                        // defaultValue={item.lbtr_otherdetails1.lab_result_parameters.option[0].value[0]}
                                        onSelect={(event) => {
                                            updateResult(event, item);
                                        }}
                                        // In emr screen result entry disabled
                                        disabled={disabledfield || mode === 'visit'}
                                    >
                                        {item.lbtr_otherdetails1.lab_result_parameters.option.map((otherDetail) => {
                                            return <Option value={otherDetail.value}>{otherDetail.label}</Option>;
                                        })}
                                    </Select>
                                </Form.Item>
                            ) : item.lbtr_vtype === 'Numeric' ? (
                                <>
                                    <Form.Item
                                        // validateTrigger="onBlur"

                                        // validateFirst="parallel"
                                        // validateStatus="warning"
                                        name={resultFieldName}
                                        rules={rules}
                                        hasFeedback
                                    >
                                        <Input
                                            autoComplete="off"
                                            disabled={disabledfield || mode === 'visit'}
                                            onBlur={(e) => formatResult(e.target.value, item, index, true)}
                                        ></Input>
                                    </Form.Item>

                                    <p>
                                        {item.lbtr_resformat ? (
                                            <p style={{ color: 'gray', fontSize: '12px' }}>(format:{item.lbtr_resformat})</p>
                                        ) : null}
                                    </p>
                                </>
                            ) : item.lbtr_vtype === 'Text' ? (
                                <Form.Item
                                    name={resultFieldName}
                                    rules={[
                                        {
                                            max: 50,
                                            message: 'Length cannot exceed 50 characters !',
                                        },
                                        {
                                            pattern: /^[a-zA-Z\s]*$/g,
                                            message: 'Please Enter Valid Result!',
                                        },
                                    ]}
                                    hasFeedback
                                >
                                    <Input
                                        autoComplete="off"
                                        disabled={disabledfield || mode === 'visit'}
                                        onChange={(event) => {
                                            updateResult(event.target.value, item);
                                        }}
                                    ></Input>
                                </Form.Item>
                            ) : null}
                        </Form>
                    </>
                );
                // }
            },
            //key: 'lbtrsd_result',
        },

        {
            width: 150,
            title: 'Range',
            dataIndex: 'lbtrsd_refrange',
            key: 'lbtrsd_refrange',
        },

        {
            title: 'Unit',
            dataIndex: 'lbtrsd_unit',
            key: 'lbtrsd_unit',
        },
    ];

    // Add column to show authorised or unauthorised if mode is visit
    if (mode === 'visit') {
        resultColumns.push({
            fixed: 'right',
            render: (item) => {
                if (item.lbtrsd_isclosed === 'N') {
                    return <Tag color="orange"> Not authorised</Tag>;
                } else {
                    return <Tag color="orange"> Authorised</Tag>;
                }
            },
        });
    }

    var columns;

    if (labReportResult.entries.length && labReportResult.entries[0].lbtrsd_id) {
        columns = resultColumns;
    } else {
        columns = reportColumns;
    }

    /**
     *
     * Patient Data
     *
     * @param {*} urlParms
     */
    async function getPatientDetails(urlParms, index) {
        setLoading(true);

        Samples.getLabDetail(billId, mode).then((result) => {
            // We get opbilld and opbill in result
            setPatient(result);
            // Load selected test details
            loadTestDatawithIndex({ index, result });

            setLoading(false);
        });
    }

    /**
     * Get the Lab Report and Lab result if present
     */
    function getLabReportResult(opbilld, status, key) {
        Samples.getLabReportResult(opbilld.labTest.lbt_itemptr, opbilld.opbd_hdrid).then((result) => {
            //To add status with each parameters
            result.result.parameters = result.result.parameters.map((ele) => {
                return {
                    ...ele,
                    status: status,
                    testKey: key || 0,
                };
            });

            setLabReportResult({ test: result.result.labTest, result: result.result.labTestResult, entries: result.result.parameters });

            if (result.result.labTestResult) {
                {
                    /** Set  values for Technican and remarks  */
                }
                setRemarks(result.result.labTestResult.lbtrs_remarks);

                setTechnician(result.result.labTestResult.lbtrs_technician);

                setCheifTechnician(result.result.labTestResult.lbtrs_chieftechnician);
            }

            if (result.result.labTestResult && result.result.labTestResult.lbtrs_isclosed === 'Y') {
                setDisabledfield(true);
            } else {
                setDisabledfield(false);
            }
            // setTest(result.result.labTest);

            // setResult(result.result.labTestResult);

            // setLabentries({ entries: result.result.parameters });

            setResultloading(false);
        });
    }

    /**
     * load the lab test data
     */
    function loadTestData(test, key) {
        // Set result loading
        setResultloading(true);

        var status = test.status;

        if (test && test.labTest) {
            // get lab test
            getLabReportResult(test, status, key);
        } else {
            setResultloading(false);

            message.error('Laboratory Test is missing');
        }
    }

    function refresh() {
        var urlParams = Location.search();

        getPatientDetails(urlParams, testIndex);
    }

    /**
     * Update the values for the selected test
     */
    function updateResult(test, values) {
        var arr = [];
        setSelectedreport(values);
        // Pushing values from table
        arr.push({ test: test, id: values.lbtr_id });

        // This condition is used to disable Submit Button
        if (arr.length > 0) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }

        if (selectedreport.lbtr_id || values.lbtr_id) {
            var rowIndex = null;

            labReportResult.entries.forEach((record, index) => {
                // Only when lbtr_id of selected record is same as lbtr_id the result we entered is saved for particular parameter
                if (record.lbtr_id === values.lbtr_id) {
                    rowIndex = index;

                    arr[rowIndex] = test;

                    // Update the data to save the Results
                    record.values = {
                        result: test,
                        status: 'Pending for Submission',
                    };
                }
            });

            setLabReportResult({ ...labReportResult, entries: labReportResult.entries, index });
        } else {
            var rowIndex = null;

            labReportResult.entries.forEach((record, index) => {
                if (record.lbtr_id === values.lbtr_id) {
                    rowIndex = index;

                    labReportResult.entries[index].values = {
                        result: test,
                        status: 'Result Updated',
                    };
                }
            });

            setLabReportResult({ ...labReportResult, entries: labReportResult.entries, index });
        }
    }

    /**
     * Once the results are entered , we submit to update on db
     */
    async function submitChanges() {
        setBtnloading(true);

        var validFlag = true;

        //To get validity status of form
        await form.getFieldsError().map((i) => {
            if (i.errors[0] != undefined) {
                validFlag = false;
            }
        });

        // Check if technicain and chief technician is selected
        if (technician && chiefTechnician) {
            // Shows error if result is not valid
            if (validFlag === false) {
                message.error('Enter Valid Result');
            } else {
                ResultEntry.saveResults({ patient, selectedreport, labReportResult, user, technician, chiefTechnician, remarks })
                    .then((result) => {
                        setLabResult(result);

                        message.success('Your entries has been recorded');

                        var index;

                        // After saving result of each test next test is sekected by default
                        if (testIndex + 1 != opbilld.length) {
                            index = testIndex + 1;
                        } else {
                            // If last test result is saved then firt result is automatically selected
                            index = 0;
                        }

                        loadTestDatawithIndex({ index });
                        //   getPatientDetails(urlParams, index);

                        setDisabled(false);

                        setReport(true);
                    })
                    .catch((error) => {
                        message.error('Operation failed');
                    });
            }
        } else {
            message.warning('Please Enter Technician ');
        }

        setBtnloading(false);

        setLoading(false);
    }
    /**
     * Once the results are entered , we submit to update on db
     */
    async function updateChanges() {
        setBtnloading(true);
        var formValues = form.getFieldValue();

        // Formbody Used for edit lab Results
        let formBody = {
            // Lab results
            record: labReportResult.entries,
            // this Object is used to pass Labrsult id
            labresult: labReportResult.result,
            // form values of technicans and Remarks
            form: formValues,
        };
        var validFlag = true;

        //To get validity status of form
        await form.getFieldsError().map((i) => {
            if (i.errors[0] != undefined) {
                validFlag = false;
            }
        });

        // Shows error message if entered value is not valid
        if (validFlag === false) {
            message.error('Enter Valid Result');
        } else {
            // To update result
            const result = await ResultEntry.updateResults(formBody);

            message.success('Your entries has been recorded');

            var index;

            //This is done to select next test after result is saved
            if (testIndex + 1 != opbilld.length) {
                index = testIndex + 1;
            } else {
                index = 0;
            }

            loadTestDatawithIndex({ index });

            setDisabled(false);
        }

        setBtnloading(false);

        setLoading(false);
    }

    /**
     * Used to load test data when each test is selected
     *
     *
     * @param {*} param0
     */
    function loadTestDatawithIndex({ index, result }) {
        var test;

        // Initially on page load we get opbilld from result passed here
        if (result) {
            test = result.opbilld;
        } else {
            // On saving or updating result, in such cases we get opbilld from state variable
            test = opbilld;
        }

        if (test.length) {
            // To load selected test after saving result
            setSelected(test[index]);

            setTestIndex(index);

            // opbilld data of the index is passed to loadTestData to load the details
            test = test[index];

            loadTestData(test, index);
        }
    }

    /**
     * Function to authorize the result. Here the lab result lbtrs_isclosed is updates to 'Y'
     * indicating that the resultis authorized
     */

    async function authorizeResult() {
        var params = {
            lbtrs_itemptr: labReportResult.result.lbtrs_itemptr,
            lbtrs_refmode: labReportResult.result.lbtrs_refmode,
            lbtrs_refid: labReportResult.result.lbtrs_refid,
            lbtrs_isclosed: 'Y',
        };

        //Only if all results are entered authorise result is possible
        const filter = labReportResult.entries.filter((ele) => ele.lbtr_vtype === 'Heading' || ele.lbtrsd_result !== '');

        if (filter.length === labReportResult.entries.length) {
            Samples.authorizeLabResult(params).then((res) => {
                // Index is passed so that even after reloading the same test is selected
                getPatientDetails(urlParams, testIndex);
                setDisabled(false);
            });
            message.success('You have Successfully Authorized the Result');
        } else {
            message.warning('You have to enter Full Results ');
        }
    }

    /**
     *  Api is Used to unAuthorize lab Result
     */
    async function unAuthorizeResult(values) {
        var params = {
            id: labReportResult.result.lbtrs_id,
            remarks: values.remarks,
        };

        Samples.UnauthorizeLabResult(params).then((res) => {
            // Index is passed so that even after reloading the same test is selected
            getPatientDetails(urlParams, testIndex);
            if (res) {
                message.success('You have Successfully Unauthorized the Result');
            }
            setDisabled(false);
        });
    }
    /**
     * On Update remarks
     *
     * @param {*} event
     */
    function onUpdateRemarks(event) {
        setRemarks(event.target.value);
    }
    var { opbill, opbilld } = patient;

    var arr = [];
    var collectedTime = null;
    var recievedTime = null;
    var collectedRemarks = null;
    var recievedRemarks = null;

    if (selected && selected.result && selected.result[0]) {
        // collectedTime = selected.result[0].lbss_samtrandttm
        var sampleCollected = selected.result.filter((ele) => ele.lbss_samtranmode === 'SC');

        var sampleRecieved = selected.result.filter((ele) => ele.lbss_samtranmode === 'LR');

        if (sampleCollected && sampleCollected.length && (selected.status === 'Collected' || selected.status === 'Lab Recieved')) {
            // Sample collected remarks
            collectedRemarks = sampleCollected[0].lbss_remarks;

            // Sample collected time
            collectedTime = sampleCollected[0].lbss_samtrandttm;

            sampleCollected.map((sample) => {
                if (sample.lbss_samtrandttm > collectedTime) collectedTime = sample.lbss_samtrandttm;
            });
        }
        if (sampleRecieved && sampleRecieved.length && selected.status === 'Lab Recieved') {
            recievedRemarks = sampleRecieved[0].lbss_remarks;

            recievedTime = sampleRecieved[0].lbss_samtrandttm;

            sampleRecieved.map((sample) => {
                if (sample.lbss_samtrandttm > recievedTime) recievedTime = sample.lbss_samtrandttm;
            });
        }
    }

    /**
     *  Load default Technician an CheifTechnician
     * @param {*} options
     * @returns
     */
    function identifyDefaultTechnician(options = []) {
        let matching = {};
        if (!technician && !chiefTechnician) {
            if (options) {
                options.forEach(async (record) => {
                    if (record.genl_mode === 'LCTEC' && user.staff_id === record.genl_code) {
                        matching = record;
                    } else if (record.genl_mode === 'LTEC' && user.staff_id === record.genl_code) {
                        matching = record;
                    }
                });
            }

            if (matching) {
                return matching;
            }
        }
    }

    /**
     * View the Report
     */

    function viewReport() {
        setReportModalVisible(true);
    }

    const downloadFileName = patient.opbill;

    // This filtering is used to Display The submit an d edit buttons Based on result
    const labresults = labReportResult.entries.filter((ele) => ele.lbtrsd_result);

    return (
        <section className="result-detail card">
            <div className="page-header">
                {/** Title should be different when mode is visit */}
                {mode === 'visit' ? (
                    <>
                        <Title level={3}>Laboratory Summary</Title>
                    </>
                ) : (
                    <>
                        <div>
                            <Title level={3}>Result Entry</Title>

                            <p className="size-hint">{loading ? 'Loading data' : 'Refer below information and update feedback'}</p>
                        </div>

                        <div>
                            {report === false || !preview ? null : (
                                <Button className="preview-report-trigger" type="dashed" onClick={viewReport}>
                                    Preview Report{' '}
                                </Button>
                            )}
                            <Button onClick={refresh} type="secondary" size={'small'}>
                                <ReloadOutlined />
                            </Button>
                        </div>
                    </>
                )}
            </div>

            {/* Modal for Viewing Report */}
            <Modal
                width={'80%'}
                destroyOnClose={true}
                footer={null}
                title="Report Preview"
                visible={reportModalVisible}
                cancelButtonProps={{ style: { display: 'none' } }}
                okText="Close"
                onOk={() => {
                    setReportModalVisible(false);
                }}
                onCancel={() => {
                    setReportModalVisible(false);
                }}
            >
                <ReportPreview billId={billId} downloadFileName={downloadFileName} groupPointer={groupPointer} />
            </Modal>

            {/* Modal for Viewing Report */}

            {/* Loading Indicator */}
            {loading ? (
                <Card className="card-shadow card">
                    <Skeleton active />
                </Card>
            ) : (
                <>
                    {mode === 'visit' ? null : (
                        <div className="">
                            <RegistrationInfo guest={opbill[0]} />
                        </div>
                    )}

                    <div className="detail-content card">
                        <div className="left">
                            <Title level={5}>Test Names ({opbilld.length})</Title>

                            <div className="billd-records">
                                {opbilld.map((entry, key) => {
                                    return (
                                        <div
                                            onClick={() => {
                                                // When each test is selected we need to load data of selected test
                                                setSelected(entry);

                                                setTestIndex(key);

                                                // Load Test Details
                                                loadTestData(entry, key);
                                            }}
                                            className={`lab-test card card-shadow ${selected.opbd_id === entry.opbd_id ? 'active' : ''}`}
                                            key={key}
                                        >
                                            {key + 1}. {entry.opbd_itemdesc}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>

                        <div className="right ">
                            {mode === 'visit' ? null : (
                                <div>
                                    <Title level={5}>Result</Title>
                                </div>
                            )}

                            {/* Loading Indicator */}
                            {resultloading && mode != 'visit' ? (
                                <Card className="card-shadow card" style={{ width: '100%' }}>
                                    <Skeleton active style={{ width: '100%' }} />
                                </Card>
                            ) : (
                                <>
                                    <>
                                        {mode === 'visit' ? null : (
                                            <div className="card card-shadow lab-result">
                                                <div className="timeline-wrapper">
                                                    {/* Timeline is to show the status of each test. Also the time it was done*/}
                                                    <Timeline>
                                                        <Timeline.Item color="green">
                                                            Ordered
                                                            <p>
                                                                <small>{moment.tz(opbill[0].opb_tm, '').format('DD/MM/YYYY HH:mm a')}</small>
                                                            </p>
                                                        </Timeline.Item>

                                                        <Timeline.Item
                                                            color={
                                                                (selected && selected.status === 'Collected') ||
                                                                    (selected && selected.status === 'Lab Recieved')
                                                                    ? 'green'
                                                                    : 'gray'
                                                            }
                                                        >
                                                            Sample Collected
                                                            <p>
                                                                <small>
                                                                    {(selected && selected.status === 'Collected') ||
                                                                        (selected && selected.status === 'Lab Recieved') ? (
                                                                        <>
                                                                            <>{moment.tz(collectedTime, '').format('DD/MM/YYYY HH:mm a')}</>
                                                                        </>
                                                                    ) : (
                                                                        <>Pending</>
                                                                    )}
                                                                </small>
                                                            </p>
                                                            <p>
                                                                <small>
                                                                    {(selected && selected.status === 'Collected') ||
                                                                        (selected && selected.status === 'Lab Recieved') ? (
                                                                        <>
                                                                            <>{collectedRemarks}</>
                                                                        </>
                                                                    ) : null}
                                                                </small>
                                                            </p>
                                                        </Timeline.Item>

                                                        <Timeline.Item color={selected && selected.status === 'Lab Recieved' ? 'green' : 'gray'}>
                                                            Lab In
                                                            <p>
                                                                <small>
                                                                    {recievedTime === null
                                                                        ? 'Pending'
                                                                        : moment.tz(recievedTime, '').format('DD/MM/YYYY HH:mm a')}
                                                                </small>
                                                            </p>
                                                            <p>
                                                                <small>{recievedRemarks === null ? null : recievedRemarks}</small>
                                                            </p>
                                                        </Timeline.Item>

                                                        {/* Once the result is entered, status of test changes to test done and also show the time it was done */}
                                                        <Timeline.Item
                                                            color={
                                                                labReportResult.result &&
                                                                    labReportResult.result.lbtrs_istestdone &&
                                                                    labReportResult.result.lbtrs_istestdone === 'Y' &&
                                                                    selected.status !== 'Rejected'
                                                                    ? 'green'
                                                                    : 'gray'
                                                            }
                                                        >
                                                            Test Done
                                                            <p>
                                                                <small>
                                                                    {labReportResult.result &&
                                                                        labReportResult.result.lbtrs_testdonedttm &&
                                                                        selected.status !== 'Rejected'
                                                                        ? moment
                                                                            .tz(labReportResult.result.lbtrs_testdonedttm, '')
                                                                            .format('DD/MM/YYYY HH:mm a')
                                                                        : 'pending'}
                                                                </small>
                                                            </p>
                                                            <p>
                                                                <small>
                                                                    {labReportResult.result &&
                                                                        labReportResult.result.lbtrs_remarks &&
                                                                        selected.status !== 'Rejected'
                                                                        ? labReportResult.result.lbtrs_remarks
                                                                        : null}
                                                                </small>
                                                            </p>
                                                        </Timeline.Item>

                                                        {/* Once result is authorised status changes to closed and authorised time is shown  */}
                                                        <Timeline.Item
                                                            color={
                                                                labReportResult.result &&
                                                                    labReportResult.result.lbtrs_isclosed === 'Y' &&
                                                                    selected.status !== 'Rejected'
                                                                    ? 'green'
                                                                    : 'gray'
                                                            }
                                                        >
                                                            Closed
                                                            <p>
                                                                <small>
                                                                    {labReportResult.result && labReportResult.result.lbtrs_isclosed === 'Y' ? (
                                                                        labReportResult.result &&
                                                                            labReportResult.result.lbtrs_closeddttm &&
                                                                            selected.status !== 'Rejected' ? (
                                                                            moment
                                                                                .tz(labReportResult.result.lbtrs_closeddttm, '')
                                                                                .format('DD/MM/YYYY HH:mm a')
                                                                        ) : null
                                                                    ) : (
                                                                        <>Pending</>
                                                                    )}
                                                                </small>
                                                            </p>
                                                        </Timeline.Item>
                                                    </Timeline>
                                                </div>
                                                <div className="result-content">
                                                    <div gutter={0} className="detail-wrapper">
                                                        <div className="detail-element">
                                                            <Row span={12}>
                                                                <span>Notes</span>
                                                            </Row>
                                                            <Row span={12}>
                                                                <h3>
                                                                    (Out - {labReportResult.test.lbt_outsource}) {labReportResult.test.lbt_specimen}{' '}
                                                                    (Method - {labReportResult.test.lbt_method})
                                                                </h3>
                                                            </Row>
                                                        </div>
                                                        <Form
                                                            form={form}
                                                            initialValues={{
                                                                remarks: remarks,
                                                                chief_technician: chiefTechnician,
                                                                technician: technician,
                                                            }}
                                                        >
                                                            <div className="detail-element">
                                                                <Row span={12}>
                                                                    <span>Chief Technician</span>
                                                                </Row>
                                                                {/* <Row span={12}>
                                                            <h3>{(labReportResult.result && labReportResult.result.lbtrs_chieftechnician) || ''}</h3>
                                                        </Row> */}
                                                                <Form.Item
                                                                    name="chief_technician"
                                                                    rules={[{ required: true, message: 'Please select Technician ' }]}
                                                                >
                                                                    <ReferenceSelect
                                                                        // value={
                                                                        //     labReportResult.result
                                                                        //         ? labReportResult.result.lbtrs_chieftechnician
                                                                        //         : chiefTechnician
                                                                        // }
                                                                        field="genl_code"
                                                                        config={{
                                                                            // limit: 50,
                                                                            queries: [
                                                                                {
                                                                                    field: 'genl_mode',
                                                                                    operator: 'LIKE',
                                                                                    value: 'LCTEC',
                                                                                },
                                                                            ],
                                                                        }}
                                                                        defaultValueCondition={identifyDefaultTechnician}
                                                                        onChange={(e) => setCheifTechnician(e)}
                                                                        allowClear
                                                                        label="genl_desc"
                                                                        mode="nura-base"
                                                                        model={GeneralLists}
                                                                    />
                                                                </Form.Item>
                                                            </div>

                                                            <div></div>
                                                            {/** Technican And Remark Select selection Start  */}

                                                            <div className="detail-element">
                                                                <Row span={12}>
                                                                    <span>Technician</span>
                                                                </Row>
                                                                <Form.Item
                                                                    name="technician"
                                                                    className="Tech"
                                                                    rules={[{ required: true, message: 'Please select Technician ' }]}
                                                                >
                                                                    <ReferenceSelect
                                                                        // value={
                                                                        //     labReportResult.result ? labReportResult.result.lbtrs_technician : technician
                                                                        // }
                                                                        field="genl_code"
                                                                        config={{
                                                                            // limit: 50,
                                                                            queries: [
                                                                                {
                                                                                    field: 'genl_mode',
                                                                                    operator: 'LIKE',
                                                                                    value: 'LTEC',
                                                                                },
                                                                            ],
                                                                        }}
                                                                        defaultValueCondition={identifyDefaultTechnician}
                                                                        allowClear
                                                                        label="genl_desc"
                                                                        mode="nura-base"
                                                                        onChange={(e) => setTechnician(e)}
                                                                        model={GeneralLists}
                                                                    />
                                                                </Form.Item>
                                                            </div>

                                                            <div></div>
                                                            <Form.Item name="remarks">
                                                                <div className="detail-element">
                                                                    <Row span={12}>
                                                                        <span>Remarks</span>
                                                                    </Row>
                                                                    <Row span={12}>
                                                                        <TextArea
                                                                            maxLength={100}
                                                                            value={remarks}
                                                                            onChange={onUpdateRemarks}
                                                                        ></TextArea>

                                                                        {/* <h3>{(labReportResult.result && labReportResult.result.lbtrs_remarks) || ''}</h3> */}
                                                                    </Row>
                                                                </div>
                                                            </Form.Item>
                                                        </Form>
                                                        {/** Technician and remark  Select section Ends */}
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {/* Result Section */}
                                        <Title level={5}>Parameters</Title>

                                        <>
                                            <Table
                                                rowKey={(record, key) => key}
                                                pagination={false}
                                                dataSource={labReportResult.entries}
                                                columns={columns}
                                            />
                                            {mode != 'visit' && labReportResult.result && labReportResult.result.lbtrs_isclosed === 'N' ? (
                                                <div className="edit-changes">
                                                    {alert ? (
                                                        <Alert
                                                            showIcon
                                                            type="warning"
                                                            message="Result is not within range"
                                                            style={{ margin: '10px 0px' }}
                                                        />
                                                    ) : null}

                                                    {/* <Form.Item className="result-submit" {...tailLayout}> */}

                                                    <div className="submit-changes">
                                                        <Button type="primary" onClick={updateChanges} loading={btnloading}>
                                                            Save
                                                        </Button>

                                                        <Button type="primary" onClick={authorizeResult}>
                                                            Authorize Result
                                                        </Button>
                                                    </div>
                                                </div>
                                            ) : mode != 'visit' &&
                                                labReportResult &&
                                                labReportResult.entries &&
                                                !labReportResult.entries[0].lbtrsd_result &&
                                                labresults.length === 0 ? (
                                                <div className="submit-changes">
                                                    <Button type="primary" disabled={!disabled} onClick={submitChanges} loading={btnloading}>
                                                        Save
                                                    </Button>
                                                </div>
                                            ) : /*Section start  unAuthorize lab results */

                                                labReportResult.result.lbtrs_isclosed === 'Y' && allowUnauthorise ? (
                                                    <div className="submit-changes">
                                                        <ConfirmModal
                                                            maxLength={250}
                                                            type="primary"
                                                            className="submit-changes"
                                                            caption={'Unauthorize'}
                                                            description="Are you sure you want to unauthorise this result? "
                                                            callback={(values) => {
                                                                unAuthorizeResult(values);
                                                            }}
                                                        ></ConfirmModal>
                                                    </div>
                                                ) : /*Section End  unAuthorize lab results*/

                                                    null}
                                        </>
                                    </>
                                </>
                            )}
                        </div>
                    </div>
                </>
            )}
        </section>
    );
}
