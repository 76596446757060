/**
 * @description
 * @author Jinishakappil
 */

import React, { useEffect, useState, useContext } from 'react';

import { Link } from 'react-router-dom';

import moment from 'moment-timezone';

import { GlobalContext, DateUtils, Location } from 'soxo-bootstrap-core';

import { Card, Switch, Button, Typography, Table, Skeleton, Divider, Input, Tag, Tooltip } from 'antd';

import { ReloadOutlined, OrderedListOutlined, PicCenterOutlined, PlusOutlined } from '@ant-design/icons';

import { RangePicker } from 'soxo-bootstrap-core';

import './candidate-visit.scss';

import { Opreg } from '../../models';

const { Title, Text } = Typography;
const { Search } = Input;

export default function RegistrationList({ redirect_link }) {
    // const placesServie = usePlacesService();

    var [Candidate, setCandidate] = useState([]);

    const [loading, setLoading] = useState(true);

    const [page, setPage] = useState(1);

    var param = Location.search();

    var starttime = moment.tz(process.env.REACT_APP_TIMEZONE).startOf('day');

    var endtime = moment.tz(process.env.REACT_APP_TIMEZONE).endOf('day'); //Setting starttime and endtime within a week from today

    var [query, setQuery] = useState('');

    const { user = {}, isMobile, dispatch } = useContext(GlobalContext);

    const [view, setView] = useState(isMobile ? true : false); //Need to check this condition

    
    const [limit, setLimit] = useState(10);

    /**
     * update range
     */

    if (param.start_time) {
        updateRange();
    }

    function updateRange() {
        starttime = moment.tz(param.start_time, process.env.REACT_APP_TIMEZONE).startOf('day');
        endtime = moment.tz(param.end_time, process.env.REACT_APP_TIMEZONE).startOf('day');

        return starttime, endtime;
    }
    /**
     *   Setting Range by default
     */
    const [range, setRange] = useState([starttime, endtime]);

    function changeView(result) {
        setView(result);
    }
    /**
     * update range time
     * @param {} range
     */
    function updateTime(range) {
        setRange(range);
        setPage(1);

        Location.search({
            start_time: moment(range[0]).format('MM/DD/YYYY'),
            end_time: moment(range[1]).format('MM/DD/YYYY'),
        });
        getRegistration(range, page);
    }

  
    function refresh() {
        getRegistration(range, page);
    }

    /**
     * Get all registration with the selected range
     */

    useEffect(() => {
        getRegistration(range, page);
    }, []);

    /**
     * Get candidate Registration List
     */
    async function getRegistration(range, page) {
        setLoading(true);
        Opreg.getRegistration(range, page).then((result) => {
            let list = result;
            setCandidate(list);
            setLoading(false);
        });
    }

    /**
     * refresh the page
     */

    function refresh() {
        getRegistration(range, page);
    }

    /**
     * for Search
     * @param {*} event
     */
    function onSearch(event) {
        setQuery(event.target.value);
    }

    function changeView(result) {
        setView(result);
    }

    /**
     *  columns
     */
    const columns = [
        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => {
                return (page - 1) * limit + index + 1;
            },
        },

        {
            title: 'Name',
            key: 'name',
            render: (ele) => {
                if (ele) {
                    return ele.op_fname + ' ' + ele.op_lname;
                } else {
                    return null;
                }
            },
        },

        {
            title: 'Date',
            key: 'date',
            render: (ele) => {
                return DateUtils.formatDate(ele.op_dt);
            },
        },

        {
            title: 'OP No',
            key: 'op no',

            render: (ele) => {
                return <span>{ele && ele.op_no ? <Tag color="blue">{ele.op_no}</Tag> : null}</span>;
            },
        },

        {
            title: 'Gender',
            key: 'gender',
            render: (ele) => {
                if (ele && ele != null && ele.op_gender != null && ele.op_gender) {
                    let gender = null;
                    if (ele && ele.op_gender === 'Male') {
                        gender = 'Male';
                    } else if (ele && ele.op_gender === 'M') {
                        gender = 'Male';
                    } else if (ele && ele.op_gender === 'Female') {
                        gender = 'Female';
                    } else if (ele && ele.op_gender) {
                        gender = 'Female';
                    }
                    return gender;
                }
            },
        },

        // {
        //     title: 'Mail',
        //     key: 'Mail',
        //     render: (ele) => {
        //         const { op_reg } = ele;

        //         if (op_reg) {
        //             return op_reg.op_email;
        //         } else {
        //             return 0;
        //         }
        //     },
        // },

        {
            title: 'Contact',
            key: 'contact',
            render: (ele) => {
                if (ele) {
                    return ele.op_mobile;
                } else {
                    return 0;
                }
            },
        },

        {
            title: 'Action',
            fixed: 'right',

            render: (ele) => {
                let redirectLink = `/multiple-candidate/guest-details`;
                if (redirect_link) {
                    redirectLink = redirect_link;
                }

                //  redirectLink = redirectLink.replace(':id', ele.op_no);

                if (ele && ele.op_reason) {
                    return (
                        <Link to={`${redirectLink}?reason=${ele.op_reason}&remarks=${ele.op_remarks ? ele.op_remarks : ''}&op_no=${ele.op_no}`}>
                            View
                        </Link>
                    );
                }
            },
        },
    ];

    /**
     * Search in candidate visit List
     */
    let filtered = Candidate.filter((record) => {
        query = query.toUpperCase();

        if (query) {
            if (record.op_fname && record.op_fname.toUpperCase().indexOf(query) != -1) {
                return true;
            } else if (record.op_mobile && record.op_mobile.indexOf(query) != -1) {
                return true;
            } else if (record.op_no.indexOf(query) != -1) {
                return true;
            }
        } else {
            return true;
        }
    });

    return (
        <section className="Candidate-list card portlet-container">
            {/* Page Header */}

            <div className="page-header">
                <div className="left">
                    <Title style={{ marginBottom: '0px' }} level={3}>
                        Registration List
                    </Title>
                </div>

                <div className="right">
                    <div className="date-and-fltr">
                        <RangePicker
                            allowClear={false}
                            inputReadOnly
                            format={'DD/MM/YYYY'}
                            value={range}
                            onChange={(time) => {
                                updateTime(time, range);
                            }}
                            ranges={{
                                Today: [moment(), moment()],

                                Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],

                                'This Week': [moment().startOf('week'), moment().endOf('week')],

                                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],

                                'This Month': [moment().startOf('month'), moment().endOf('month')],

                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            }}
                        />

                        <Button onClick={refresh} type="secondary" size={'small'}>
                            <ReloadOutlined />
                        </Button>
                    </div>
                </div>
            </div>
            {/* Page Header Ends */}

            {/* Content Below */}
            {loading ? (
                <div className="">
                    <Skeleton active />
                </div>
            ) : (
                <>
                    {/*  */}
                    <div className="">
                        <div className="page-header">
                            <Search
                                placeholder="Enter Search Value"
                                allowClear
                                style={{ width: 300, marginTop: '10px', marginBottom: '20px' }}
                                onChange={onSearch}
                            />

                            <div className="top-actions">
                                {/* Export Data */}
                                <Link to={'/multiple-candidate/mode'}>
                                    <Button type="primary" size="small">
                                        New <PlusOutlined />
                                    </Button>
                                </Link>
                                {/* Export Data Ends */}

                                <Switch
                                    defaultChecked={view}
                                    onChange={changeView}
                                    checked={view}
                                    checkedChildren={<OrderedListOutlined />}
                                    unCheckedChildren={<PicCenterOutlined />}
                                />
                            </div>
                        </div>

                        <div>
                            {view ? (
                                <>
                                    {' '}
                                    <CardList dataSource={filtered} redirect_link={redirect_link} />{' '}
                                </>
                            ) : (
                                <>
                                    <Card className="table-card">
                                        <Table
                                            scroll={{ x: true }}
                                            // rowKey={(record) => record.da_id}
                                            dataSource={filtered}
                                            columns={columns}
                                            pagination={{
                                                current: page,
                                                onChange(current) {
                                                    setPage(current);
                                                },
                                            }}
                                        />
                                    </Card>
                                </>
                            )}
                        </div>

                        {/* <div>{contextHolder}</div> */}
                    </div>
                </>
            )}

            {/* <Divider /> */}
        </section>
    );
}
/**
 * Mobile view card Section for Rgistration  list
 */

function CardList({ dataSource, redirect_link }) {
    const { user = {}, isMobile, dispatch } = useContext(GlobalContext);

    function onClick(record) {
        let redirectLink = `/emr/:id`;
        Location.navigate({
            url: redirectLink,
        });
    }
    return dataSource.map((item, index) => {
        let redirectLink = `/emr/:id`;
        redirectLink = redirectLink.replace(':id', item.op_no);

        return (
            <Link
                to={`${redirectLink}`}
                key={index}
                className="candidate-item"
                onClick={() => {
                    onClick(item);
                }}
            >
                <CandidateCard record={item} />
            </Link>
        );
    });
}

function CandidateCard({ record }) {
    return (
        <Card className="card vehicle-card">
            <div className="title">
                <p>
                    Name: <strong>{record.op_fname} </strong>
                </p>
            </div>
            <div className="title">
                <p>
                    Date: <strong> {moment(record.op_dt).format('DD/MM/YYYY')} </strong>
                </p>
            </div>
            <div className="values">
                <p>
                    OP No :{' '}
                    <Tag color="blue">
                        <strong>{record.op_no}</strong>
                    </Tag>
                </p>
            </div>
            <div className="title">
                <p>
                    Contact: <strong>{record.op_mobile} </strong>
                </p>
            </div>

            <div className="values">
                <p>
                    Gender : <strong>{(record.op_gender = 'M' ? 'Male' : 'Female')}</strong>
                </p>
            </div>
        </Card>
    );
}
