/**
 * Bill-Details Model
 *
 *
 *
 *
 */
import React, { useState, useEffect, useContext } from 'react';

import Model from './models';

import { Tag, Card, Typography } from 'antd';

import { ApiUtils } from 'soxo-bootstrap-core';

const { Text } = Typography;

const moment = require('moment-timezone');

class BillDetail extends Model {
    constructor() {
        super();

        this.fields = [
            {
                field: 'opb_name',
                caption: 'Name',
            },
            {
                field: 'opb_opno',
                caption: 'num',
            },
        ];
    }

    get id() {
        return 'opbd_id';
    }

    get getEndpoint() {
        return 'opbilld';
    }

    get path() {
        return `bill-details`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `BillDetail`;
    }

    disableAdd = () => {
        return true;
    };

    getBillItems = (opbd_id) => {
        return ApiUtils.get({ url: `opbilld/items/${opbd_id}` });
    };
    getDoctor = (opb_doctorptr) => {
        return ApiUtils.get({ url: `doctor/${opb_doctorptr}` });
    };
    /**
     * Bill Details
     * @param {} opbd_id
     * @returns
     */
    getBillDetails = (opbd_id) => {
        return ApiUtils.get({ url: `opbill/bill-details/${opbd_id}` });
    };

    get columns() {
        return [
            {
                field: 'opb_name',
                render: (record) => {
                    return record.opb_name;
                },
                caption: 'Name',
            },
            {
                field: 'opb_gender',
                render: (record) => {
                    return <Tag color={record.opb_gender === 'Male' ? 'green' : 'orange'}>{record.opb_gender}</Tag>;
                },
                caption: 'Gender',
            },
            {
                field: 'opb_age',
                render: (record) => {
                    return record.opb_age;
                },
                caption: 'Age',
            },
            {
                field: 'opb_mobile',
                render: (record) => {
                    return record.opb_mobile;
                },
                caption: 'Mobile',
            },
            {
                field: 'opb_opno',
                render: (record) => {
                    return record.opb_opno;
                },
                caption: 'OP No.',
            },
            {
                // field: 'cold_amt',
                render: (record) => {
                    return record.opb_amt;
                },
                caption: 'Amount',
            },

            {
                // field: 'cold_amt',
                render: (record) => {
                    return record.opb_paidamt;
                },
                caption: 'Paid',
            },

            {
                field: 'opb_remarks',
                render: (record) => {
                    return record.opb_remarks;
                },
                caption: 'Remarks',
            },
        ];
    }

    Card = ({ ...props }) => {
        return <GuestCard record={props} {...props}></GuestCard>;
    };
}

function GuestCard({ record }) {
    return (
        <Card className="card vehicle-card">
            <div className="card">
                <h2 className="title">{record.opb_name}</h2>

                {/* {record.preReg.opb_age} */}

                {/* <h3 className="values">{record.da_aptime}</h3> */}

                {/* <Tag color={record.da_questfilled === 'F' ? 'orange' : 'green'}>{record.da_questfilled === 'F' ? 'Filled' : 'Pending'}</Tag> */}

                <Tag color="green">{record.opb_gender}</Tag>

                <Text type="secondary">{record.opb_mobile}</Text>

                <h4 className="values">{record.opb_opno}</h4>
            </div>
        </Card>
    );
}

export default BillDetail;
