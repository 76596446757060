/***
 * @description
 * @author jinshakappil
 * Component Is usedd show Address Details of Guest
 *
 */

import React from 'react';

import { Row, Col, Form, Select, Typography } from 'antd';

import './address-details.scss';

import { InputComponent } from 'soxo-bootstrap-core';

const { Title } = Typography;

const formItemLayout = {
    layout: 'vertical',
};

export function AddressDetails({ config, props }) {

    const { fields = {} } = config;

    return (
        <div className="address-details">
            <div className="details">
                {/* <Form 
                form={form}
                {...formItemLayout}> */}
                <div className="permanent-address">
                    <div>
                        <Title level={5}>PERMANENT ADDRESS</Title>
                    </div>
                    <Row gutter={20}>

                        {/* Pin code */}
                        {
                            fields.pincode.visible
                                ?
                                <Col xs={24} md={12} xl={6}>
                                    <Form.Item name={fields.pincode.field} label={fields.pincode.caption} rules={[{ required: fields.pincode.required, message: `Please enter ${fields.pincode.caption}` }]}>
                                        <InputComponent disabled={props.disabled} />
                                    </Form.Item>
                                </Col>
                                :
                                null
                        }
                        {/* Pin code ends */}

                        {
                            fields.address1.visible
                                ?
                                <Col xs={24} md={12} xl={6}>
                                    <Form.Item
                                        name={fields.address1.field}
                                        label={fields.address1.caption}
                                        rules={[
                                            {
                                                required: fields.address1.required,
                                                message: `Please Select ${fields.address1.caption}`,
                                            },

                                            {
                                                max: 100,
                                                message: 'Length cannot exceed 100 characters !',
                                            },
                                        ]}
                                    >
                                        <InputComponent disabled={props.disabled} />
                                    </Form.Item>
                                </Col>
                                :
                                null
                        }

                        {
                            fields.address2.visible
                                ?
                                <Col xs={24} md={12} xl={6}>
                                    <Form.Item
                                        name={fields.address2.field}
                                        label={fields.address2.caption}
                                        rules={[
                                            {
                                                required: fields.address2.required,
                                                message: `Please enter ${fields.address2.caption}`,
                                            },

                                            {
                                                max: 200,
                                                message: 'Length cannot exceed 200 characters!',
                                            },
                                        ]}
                                    >
                                        <InputComponent disabled={props.disabled} />
                                    </Form.Item>
                                </Col>
                                :
                                null
                        }

                        {
                            fields.district.visible
                                ?
                                <Col xs={24} md={12} xl={6}>
                                    <Form.Item name={fields.district.field} label={fields.district.caption} rules={[{ required: fields.district.required, message: `Please enter ${fields.district.caption}` }]}>
                                        <InputComponent disabled={props.disabled} />
                                    </Form.Item>
                                </Col>
                                :
                                null
                        }

                        {
                            fields.place.caption
                                ?
                                <Col xs={24} md={12} xl={6}>
                                    <Form.Item name={fields.place.field} label={fields.place.caption} rules={[{ required: fields.place.required, message: `Please enter ${fields.place.caption}` }]}>
                                        <InputComponent disabled={props.disabled} />
                                    </Form.Item>
                                </Col>
                                :
                                null
                        }

                        {
                            fields.state.visible
                                ?
                                <Col xs={24} md={12} xl={6}>
                                    <Form.Item name={fields.state.field} label={fields.state.caption} rules={[{ required: fields.state.required, message: `Please enter ${fields.state.caption}` }]}>
                                        <InputComponent disabled={props.disabled} />
                                    </Form.Item>
                                </Col>
                                :
                                null
                        }

                        {
                            fields.state.visible
                                ?
                                <Col xs={24} md={12} xl={6}>
                                    <Form.Item name={fields.country.field} label={fields.country.caption} rules={[{ required: fields.country.required, message: `Please enter ${fields.country.caption}` }]}>
                                        <InputComponent disabled={props.disabled} />
                                    </Form.Item>
                                </Col>
                                :
                                null
                        }

                        {
                            fields.landmark.visible
                                ?
                                <Col xs={24} md={12} xl={6}>
                                    <Form.Item name={fields.landmark.field} label={fields.landmark.caption} rules={[{ required: fields.landmark.required, message: `Please enter ${fields.country.caption}` }]}>
                                        <InputComponent disabled={props.disabled} />
                                    </Form.Item>
                                </Col>
                                :
                                null
                        }

                    </Row>
                </div>

                {/* <div className="temporary-address">
                    <div>
                        <Title level={5}>TEMPORARY ADDRESS</Title>
                    </div>
                    <Row gutter={20}>
                        <Col xs={24} md={12} xl={6}>
                            <Form.Item
                                name="temporary_palika"
                                label="PALLIKA"
                                rules={[
                                    {
                                        required: fields.temporary_palika.required,
                                        message: 'Please Select Pallika',
                                    },
                                ]}
                            >
                                <InputComponent />
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={12} xl={6}>
                            <Form.Item
                                name="temporary_ward_no"
                                label="WARD NO"
                                rules={[
                                    {
                                        required: fields.temporary_ward_no.required,
                                        message: 'Please Select Ward Number',
                                    },
                                ]}
                            >
                                <InputComponent />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12} xl={6}>
                            <Form.Item
                                name="temporary_tole"
                                label="TOLE"
                                rules={[{ required: fields.temporary_tole.required, message: 'Please Select Tole' }]}>
                                <InputComponent />
                            </Form.Item>
                        </Col>
                    </Row>
                </div> */}
                {/* </Form> */}
            </div>
        </div>
    );
}
