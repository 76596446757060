import React, { useState, useEffect, useContext } from 'react';
import {
    Card,
    Switch,
    Select,
    Tag,
    Button,
    Typography,
    Table,
    Skeleton,
    Modal,
    Input,
} from 'antd';

import { Location, GlobalContext, getExportData, useTranslation, Trans } from 'soxo-bootstrap-core';


import { Link } from 'react-router-dom';

import { Tabs } from 'antd';

import moment from 'moment-timezone';

import StatusChange from '../../../appointment/components/status-change/status-change';

import { ExportReactCSV, RangePicker } from 'soxo-bootstrap-core';

import './registrations.scss';

import { ReloadOutlined, OrderedListOutlined, PicCenterOutlined } from '@ant-design/icons';

import { Registrations } from './../../../../models/';

const { Title, Text } = Typography;

const { Search } = Input;

export default function RegistrationsView(props) {

    var param = Location.search();

    const [loading, setLoading] = useState(true);

    const { pageno } = props.match.params; //Get pagination number

    const [data, setData] = useState([]);

    const { user = {}, isMobile, dispatch } = useContext(GlobalContext);

    const [modal, contextHolder] = Modal.useModal();

    const [view, setView] = useState(isMobile ? true : false); //Need to check this condition

    var [query, setQuery] = useState('');

    const [columns, setColumns] = useState([]);

    const [exportData, setExportData] = useState({});

    const [limit, setLimit] = useState(10);

    const [page, setPage] = useState(1);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        initializeApplication();
    }, []);

    async function initializeApplication() {

        let col = await prepareColumns();

        await getRegistrations(range, col);

        setColumns(col);
    }

    var starttime = moment.tz().startOf('day'); //Setting starttime
    var endtime = moment.tz().add(7, 'day').startOf('day'); //Setting endtime within a week from today

    //If starttime and endtime are in url
    if (param.start_time) {
        updateRange();
    }

    /**
     *
     * @param {*} result
     */
    function changeView(result) {
        setView(result);
    }

    /**
     *
     * @returns
     */
    function updateRange() {
        starttime = moment.tz(param.start_time, process.env.REACT_APP_TIMEZONE).startOf('day');
        endtime = moment.tz(param.end_time, process.env.REACT_APP_TIMEZONE).startOf('day');

        return starttime, endtime;
    }

    //Setting Range by default
    const [range, setRange] = useState([starttime, endtime]);

    //PatiantData fetching function
    async function getRegistrations(range, columns) {
        setLoading(true);

        Registrations.getRegistrations(range).then((result) => {

            let filteredData = [];

            result.result.forEach((element) => {
                if (element.appointment && element.appointment.cancel_flag_status != 'Y') filteredData.push(element);
                else if (element.appointment && element.appointment.cancel_flag_status != '*') filteredData.push(element);
            });

            setData(result.result);

            loadPageConfiguration(result.result, columns);

            setLoading(false);
        });
    }

    async function loadPageConfiguration(data, columns) {
        let exportDatas = getExportData(data, columns);


        if (exportDatas.exportDataColumns.length && exportDatas.exportDataHeaders.length) {
            setExportData({ exportDatas });
        }


        // setExportData(exportData);
    }

    function prepareColumns() {


        const bookingColumns = [
            {
                title: '#',
                dataIndex: 'index',
                // render: (value, item, index) => index + 1,
                render: (value, item, index) => (page - 1) * limit + index + 1,
                key: '#'
            },
            {
                title: t('Bill Id'),
                // dataIndex: 'da_billid',
                render: ele => {
                    // return ele.op_id ? ele.op_id : null;
                    return ele.appointment && ele.appointment.bill_id ? ele.appointment.bill_id : null;
                },
                key: 'billid',
            },
            {
                title: t('Date'),
                // dataIndex: 'da_date',
                render: ele => {
                    return ele.appointment && ele.appointment.date ? moment(ele.appointment.date).format('DD/MM/YYYY') : null;
                },
                key: 'da_date',
            },
            {
                title: t('OP No'),
                // dataIndex: 'da_date',
                render: ele => {
                    return ele.registration && ele.registration.number ? ele.registration.number : null;
                },
                key: 'op_number',
            },
            {
                title: t('Name'),
                //   dataIndex: 'op_fname',
                render: ele => {
                    return ele.registration.first_name + ' ' + ele.registration.last_name;
                },
                key: 'name',
            },
            {
                title: t('Package'),
                // dataIndex: 'da_packagetypevalue',
                render: ele => {
                    if (ele.item && ele.item.description) {
                        return ele.item.description;
                    }
                },
                key: 'package',
            },

            {
                title: t('Current Status'),
                //  dataIndex: 'Subevtm_desc',
                exportDefinition: ele => {
                    if (ele.appointment && ele.appointment.bill_id) {
                        if (ele.event_sub_master) return ele.event_sub_master.description;
                    } else {
                    }
                },

                render: ele => {
                    return (
                        <div>
                            {ele.appointment && ele.appointment.bill_id ? (
                                ele.event_sub_master ? (
                                    <div>{ele.event_sub_master.description}</div>
                                ) : null
                            ) : null}
                        </div>
                    );
                },
                key: 'Current status',
            },
            // {
            //     title: 'Current Status',
            //     // dataIndex: 'op_gender',
            //     // key: 'op_gender',
            // },
            {
                title: t('Questionnaire Status'),
                dataIndex: 'da_questfilled',

                exportDefinition: ele => {
                    return ele.appointment ? (ele.appointment.questionnaire_filled ? 'Filled' : 'Pending') : 'Pending';
                },
                render: (text, ele) => {
                    // if (ele.preReg && ele.preReg.prereg_ageyear) {
                    //     return ele.preReg.prereg_ageyear;
                    // }
                    return (
                        <div>
                            {ele.appointment ? (
                                ele.appointment.questionnaire_filled ? (
                                    <Tag color="green">Filled</Tag>
                                ) : (
                                    <Tag color="orange">Pending</Tag>
                                )
                            ) : (
                                <Tag color="orange">Pending</Tag>
                            )}
                        </div>
                    );
                },
                key: 'da_questfilled',
            },
            {
                title: 'Report',

                exportDefinition: ele => {
                    return ele.appointment ? (ele.appointment.report_description ? ele.appointment.report_description : 'Pending') : '';
                },

                render: ele => {
                    if (ele.appointment) {
                        return (
                            <>
                                <>
                                    <div>
                                        {/* Modal for status change */}
                                        <StatusChange
                                            description={ele.appointment.report_description}
                                            callback={() => {
                                                refresh();
                                            }}
                                            id={ele.appointment.id}
                                            statusValue={ele.appointment.report_status}
                                            // status mode
                                            mode="RPD"
                                            // mode of status for api call
                                            apiMode="REPORT"
                                            name="report status"
                                        />

                                        {/* End Modal */}
                                    </div>
                                </>
                            </>
                        );
                    }
                },
                key: 'report',
            },
            {
                title: t('Fit Kit Status'),

                exportDefinition: ele => {
                    return ele.appointment ? (ele.appointment.fit_kit_description ? ele.appointment.fit_kit_description : 'Pending') : '';
                },

                render: ele => {
                    if (ele.appointment) {
                        //     let current_status = ele.appointment.fitkit_status;

                        return (
                            <>
                                <>
                                    <div>
                                        {/* Modal for status change */}
                                        <StatusChange
                                            description={ele.appointment.fit_kit_description}
                                            callback={() => {
                                                refresh();
                                            }}
                                            id={ele.appointment.id}
                                            statusValue={ele.appointment.fitkit_status}
                                            // status mode
                                            mode="FST"
                                            // mode of status for api call
                                            apiMode="FITKIT"
                                            name="fitkit status"
                                        />

                                        {/* End Modal */}
                                    </div>
                                </>
                            </>
                        );
                    }
                },
                key: 'fitkitstatus',
            },
            // {
            //     title: 'Consent Status',
            //     dataIndex: 'da_consentfilled',

            //     exportDefinition: (ele) => {
            //         return ele.appointment ? (ele.appointment.consent_filled ? 'Submitted' : 'Submitted') : 'Pending';
            //     },
            //     render: (text, ele) => {
            //         return (
            //             <div>
            //                 {ele.appointment ? (
            //                     ele.appointment.consent_filled ? (
            //                         <Tag color="green">Submitted</Tag>
            //                     ) : (
            //                         <Tag color="orange">Submitted</Tag>
            //                     )
            //                 ) : (
            //                     <Tag color="orange">Pending</Tag>
            //                 )}
            //             </div>
            //         );
            //     },
            //     key: 'da_consentfilled',
            // },
            {
                title: t('Billed Status'),
                exportDefinition: (ele) => {
                    return ele.appointment
                        ? ele.appointment.bill_id
                            ? 'Billed'
                            : 'Pending'
                        : 'Pending';
                },

                render: (text, ele) => {
                    return ele.appointment ? (
                        ele.appointment.bill_id ? (
                            <Tag color="green">Billed</Tag>
                        ) : (
                            <Tag color="orange">Pending</Tag>
                        )
                    ) : (
                        <Tag color="orange">Pending</Tag>
                    );
                },
                key: 'billedstatus',
            },

            {
                title: t('Physician Verified'),
                //  dataIndex: "da_phyverified",
                // key: 'gender',
                exportDefinition: ele => {
                    return ele.service_base_clinical_details_transaction && ele.service_base_clinical_details_transaction.doctor_verified == 'Y'
                        ? 'Yes'
                        : 'No';
                },

                render: ele => {
                    return (
                        <div>
                            {ele.service_base_clinical_details_transaction && ele.service_base_clinical_details_transaction.doctor_verified == 'Y' ? (
                                <Tag color="green">Yes</Tag>
                            ) : (
                                <Tag color="orange">No</Tag>
                            )}
                        </div>
                    );
                },
                key: 'Physician Verified',
            },
            {
                title: t('Report Status'),
                //  dataIndex: 'da_rptuploaded',

                exportDefinition: ele => {
                    return ele.appointment ? (ele.report_uploaded ? ele.report_uploaded : 'Pending') : 'Pending';
                },

                render: ele => {
                    return (
                        <div>
                            {ele.appointment && ele.report_uploaded ? (
                                <Tag color="green">{ele.report_uploaded}</Tag>
                            ) : (
                                <Tag color="orange">Pending</Tag>
                            )}
                        </div>
                    );
                },
                key: 'da_rptuploaded',
            },
            {
                title: t('Report Ready Email Send'),
                // dataIndex: 'da_rptuploaded',

                exportDefinition: ele => {
                    return ele.appointment ? (ele.appointment.report_mail_send_status ? 'Sent' : 'Pending') : 'Pending';
                },
                render: ele => {
                    return (
                        <div>
                            {ele.appointment ? (
                                ele.appointment.report_mail_send_status ? (
                                    <Tag color="green">Sent</Tag>
                                ) : (
                                    <Tag color="orange">Pending</Tag>
                                )
                            ) : (
                                <Tag color="orange">Pending</Tag>
                            )}
                        </div>
                    );
                },
                key: 'da_reportmailsendstatus',
            },

            // {
            //     title: 'Report',
            //     exportDefinition: (ele) => {
            //         if (ele.drAppointment && ele.drAppointment.da_rptstatus) {
            //             let option = reportoptions.filter(() => ele.drAppointment.da_rptstatus);

            //             if (option.length) {
            //                 return option[0].srvrsm_desc;
            //             } else {
            //                 return 'Pending';
            //             }
            //         }
            //     },
            //     render: (ele) => {
            //         if (ele.drAppointment) {
            //             return (
            //                 <>
            //                     <Select
            //                         value={ele.drAppointment.da_rptstatus}
            //                         placeholder={'Select Report Status'}
            //                         onChange={(value) => {
            //                             // Confirm the modal
            //                             Modal.confirm({
            //                                 title: 'Are you sure you want to proceed',
            //                                 content:
            //                                     'This will update the report status and trigger a mail to the guest regarding the status update.',
            //                                 onOk() {
            //                                     console.log('OK');

            //                                     setReportStatus(value);

            //                                     let current_value = null;

            //                                     updateRptOption(ele.drAppointment.da_id, value);
            //                                 },
            //                                 onCancel() {
            //                                     console.log('Cancel');
            //                                 },
            //                             });
            //                         }}
            //                     >
            //                         {reportoptions.map((report) => {
            //                             return <Option value={report.srvrsm_code}>{report.srvrsm_desc}</Option>;
            //                         })}
            //                     </Select>
            //                 </>
            //             );
            //         }
            //     },
            //     key: 'report',
            // },
            {
                title: t('Action'),
                disableExport: true,
                fixed: 'right',
                // dataIndex: "billed_status",
                key: 'gender',
                render: record => {
                    return (
                        <span>
                            <Button
                                onClick={() => {
                                    Location.navigate({ url: `/registrations/${record.registration.number}` });
                                }}
                                type="primary"
                                size="small"
                                color="green"
                            >
                                View
                            </Button>
                        </span>
                    );
                },
                key: 'Action',
            },
        ];

        // const bookingColumns = [
        //     {
        //         title: '#',
        //         dataIndex: 'index',
        //         // render: (value, item, index) => index + 1,
        //         render: (value, item, index) => (page - 1) * limit + index + 1,
        //     },
        //     {
        //         title: 'Bill Id',
        //         // dataIndex: 'da_billid',
        //         render: (ele) => {
        //             // return ele.op_id ? ele.op_id : null;
        //             return ele.appointment && ele.appointment.bill_id ? ele.appointment.bill_id : null;
        //         },
        //         key: 'billid',
        //     },
        //     {
        //         title: 'Date',
        //         // dataIndex: 'da_date',
        //         render: (ele) => {
        //             return ele.appointment && ele.appointment.date ? moment(ele.appointment.date).format('DD/MM/YYYY') : null;
        //         },
        //         key: 'Date',
        //     },
        //     {
        //         title: 'OP No',
        //         // dataIndex: 'da_date',
        //         render: (ele) => {
        //             return ele.registration && ele.registration.number ? ele.registration.number : null;
        //         },
        //         key: 'OP No',
        //     },
        //     {
        //         title: 'Name',
        //         //   dataIndex: 'op_fname',
        //         render: (ele) => {
        //             return ele.registration.first_name + ' ' + ele.registration.last_name;
        //         },
        //         key: 'Name',
        //     },
        //     {
        //         title: 'Package',
        //         // dataIndex: 'da_packagetypevalue',
        //         render: (ele) => {
        //             if (ele.item && ele.item.description) {
        //                 return ele.item.description;
        //             }
        //         },
        //         key: 'Package',
        //     },

        //     {
        //         title: 'Current Status',
        //         //  dataIndex: 'Subevtm_desc',
        //         exportDefinition: (ele) => {
        //             if (ele.appointment && ele.appointment.bill_id) {
        //                 if (ele.event_sub_master)
        //                     return ele.event_sub_master.description;
        //             } else {
        //             }
        //         },

        //         render: (ele) => {
        //             return (
        //                 <div>
        //                     {ele.appointment && ele.appointment.bill_id ? (
        //                         ele.event_sub_master ? (
        //                             <div>{ele.event_sub_master.description}</div>
        //                         ) : null
        //                     ) : null}
        //                 </div>
        //             );
        //         },
        //         // key: 'Current status',
        //     },
        //     // {
        //     //     title: 'Current Status',
        //     //     // dataIndex: 'op_gender',
        //     //     // key: 'op_gender',
        //     // },
        //     {
        //         title: 'Questionnaire Status',
        //         dataIndex: 'da_questfilled',

        //         exportDefinition: (ele) => {
        //             return ele.appointment ? (ele.appointment.questionnaire_filled ? 'Filled' : 'Pending') : 'Pending';
        //         },
        //         render: (text, ele) => {
        //             // if (ele.preReg && ele.preReg.prereg_ageyear) {
        //             //     return ele.preReg.prereg_ageyear;
        //             // }
        //             return (
        //                 <div>
        //                     {ele.appointment ? (
        //                         ele.appointment.questionnaire_filled ? (
        //                             <Tag color="green">Filled</Tag>
        //                         ) : (
        //                             <Tag color="orange">Pending</Tag>
        //                         )
        //                     ) : (
        //                         <Tag color="orange">Pending</Tag>
        //                     )}
        //                 </div>
        //             );
        //         },
        //         key: 'da_questfilled',
        //     },
        //     {
        //         title: 'Report',

        //         exportDefinition: (ele) => {

        //             return ele.appointment && ele.appointment.report_description ? ele.appointment.report_description : '';
        //         },

        //         render: (ele) => {

        //             if (ele.appointment) {
        //                 return (
        //                     <>
        //                         <>
        //                             <div>

        //                                 {/* Modal for status change */}
        //                                 <StatusChange
        //                                     description={ele.appointment.report_description}
        //                                     callback={() => {

        //                                         refresh();

        //                                     }}

        //                                     id={ele.appointment.id}

        //                                     statusValue={ele.appointment.report_status}

        //                                     // status mode 
        //                                     mode='RPD'
        //                                     // mode of status for api call
        //                                     apiMode='REPORT'

        //                                     name='report status' />

        //                                 {/* End Modal */}

        //                             </div>

        //                         </>
        //                     </>
        //                 );
        //             }
        //         },
        //         key: 'Report',
        //     },
        //     {
        //         title: 'Fit Kit Status',


        //         exportDefinition: (ele) => {

        //             return ele.appointment && ele.appointment.fit_kit_description ? ele.appointment.fit_kit_description : '';
        //         },

        //         render: (ele) => {
        //             if (ele.appointment) {
        //                 //     let current_status = ele.appointment.fitkit_status;

        //                 return (
        //                     <>
        //                         <>

        //                             <div>
        //                                 {/* Modal for status change */}
        //                                 <StatusChange
        //                                     description={ele.appointment.fit_kit_description}
        //                                     callback={() => {

        //                                         refresh();

        //                                     }}

        //                                     id={ele.appointment.id}

        //                                     statusValue={ele.appointment.fitkit_status}

        //                                     // status mode 
        //                                     mode='FST'
        //                                     // mode of status for api call
        //                                     apiMode='FITKIT'

        //                                     name='fitkit status' />

        //                                 {/* End Modal */}

        //                             </div>

        //                         </>
        //                     </>
        //                 );
        //             }
        //         },
        //         key: 'Fit Kit Status',
        //     },
        //     // {
        //     //     title: 'Consent Status',
        //     //     dataIndex: 'da_consentfilled',

        //     //     exportDefinition: (ele) => {
        //     //         return ele.appointment ? (ele.appointment.consent_filled ? 'Submitted' : 'Submitted') : 'Pending';
        //     //     },
        //     //     render: (text, ele) => {
        //     //         return (
        //     //             <div>
        //     //                 {ele.appointment ? (
        //     //                     ele.appointment.consent_filled ? (
        //     //                         <Tag color="green">Submitted</Tag>
        //     //                     ) : (
        //     //                         <Tag color="orange">Submitted</Tag>
        //     //                     )
        //     //                 ) : (
        //     //                     <Tag color="orange">Pending</Tag>
        //     //                 )}
        //     //             </div>
        //     //         );
        //     //     },
        //     //     key: 'da_consentfilled',
        //     // },
        //     {
        //         title: 'Billed Status',
        //         exportDefinition: (ele) => {
        //             return ele.appointment
        //                 ? ele.appointment.bill_id
        //                     ? 'Billed'
        //                     : 'Pending'
        //                 : 'Pending';
        //         },

        //         render: (text, ele) => {
        //             return ele.appointment ? (
        //                 ele.appointment.bill_id ? (
        //                     <Tag color="green">Billed</Tag>
        //                 ) : (
        //                     <Tag color="orange">Pending</Tag>
        //                 )
        //             ) : (
        //                 <Tag color="orange">Pending</Tag>
        //             );
        //         },
        //         key: 'Billed Status',
        //     },

        //     {
        //         title: 'Physician Verified',
        //         //  dataIndex: "da_phyverified",
        //         // key: 'gender',
        //         exportDefinition: (ele) => {
        //             return ele.service_base_clinical_details_transaction && ele.service_base_clinical_details_transaction.doctor_verified == "Y" ? "Yes" : 'No';
        //         },

        //         render: (ele) => {
        //             return (
        //                 <div>
        //                     {ele.service_base_clinical_details_transaction && ele.service_base_clinical_details_transaction.doctor_verified == "Y" ? (
        //                         <Tag color="green">Yes</Tag>
        //                     ) : (
        //                         <Tag color="orange">No</Tag>
        //                     )}
        //                 </div>
        //             );
        //         },
        //     },
        //     {
        //         title: 'Report Status',
        //         //  dataIndex: 'da_rptuploaded',

        //         exportDefinition: (ele) => {
        //             return ele.appointment ? (ele.report_uploaded ? ele.report_uploaded : 'Pending') : 'Pending';
        //         },

        //         render: (ele) => {
        //             return (
        //                 <div>
        //                     {ele.appointment && ele.report_uploaded ?
        //                         <Tag color="green">{ele.report_uploaded}</Tag> :
        //                         <Tag color="orange">Pending</Tag>
        //                     }
        //                 </div>
        //             );
        //         },
        //         key: 'Report Status',
        //     },
        //     {
        //         title: 'Report Ready Email Send',
        //         // dataIndex: 'da_rptuploaded',

        //         exportDefinition: (ele) => {
        //             return ele.appointment ? (ele.appointment.report_mail_send_status ? 'Sent' : 'Pending') : 'Pending';
        //         },
        //         render: (ele) => {
        //             return (
        //                 <div>
        //                     {ele.appointment ? (
        //                         ele.appointment.report_mail_send_status ? (
        //                             <Tag color="green">Sent</Tag>
        //                         ) : (
        //                             <Tag color="orange">Pending</Tag>
        //                         )
        //                     ) : (
        //                         <Tag color="orange">Pending</Tag>
        //                     )}
        //                 </div>
        //             );
        //         },
        //         key: 'Report Ready Email Send',
        //     },

        //     // {
        //     //     title: 'Report',
        //     //     exportDefinition: (ele) => {
        //     //         if (ele.drAppointment && ele.drAppointment.da_rptstatus) {
        //     //             let option = reportoptions.filter(() => ele.drAppointment.da_rptstatus);

        //     //             if (option.length) {
        //     //                 return option[0].srvrsm_desc;
        //     //             } else {
        //     //                 return 'Pending';
        //     //             }
        //     //         }
        //     //     },
        //     //     render: (ele) => {
        //     //         if (ele.drAppointment) {
        //     //             return (
        //     //                 <>
        //     //                     <Select
        //     //                         value={ele.drAppointment.da_rptstatus}
        //     //                         placeholder={'Select Report Status'}
        //     //                         onChange={(value) => {
        //     //                             // Confirm the modal
        //     //                             Modal.confirm({
        //     //                                 title: 'Are you sure you want to proceed',
        //     //                                 content:
        //     //                                     'This will update the report status and trigger a mail to the guest regarding the status update.',
        //     //                                 onOk() {
        //     //                                     console.log('OK');

        //     //                                     setReportStatus(value);

        //     //                                     let current_value = null;

        //     //                                     updateRptOption(ele.drAppointment.da_id, value);
        //     //                                 },
        //     //                                 onCancel() {
        //     //                                     console.log('Cancel');
        //     //                                 },
        //     //                             });
        //     //                         }}
        //     //                     >
        //     //                         {reportoptions.map((report) => {
        //     //                             return <Option value={report.srvrsm_code}>{report.srvrsm_desc}</Option>;
        //     //                         })}
        //     //                     </Select>
        //     //                 </>
        //     //             );
        //     //         }
        //     //     },
        //     //     key: 'report',
        //     // },
        //     {
        //         title: 'Action',
        //         disableExport: true,
        //         fixed: 'right',
        //         // dataIndex: "billed_status",
        //         key: 'Action',
        //         render: (record) => {
        //             return (
        //                 <span>
        //                     <Button
        //                         onClick={() => {
        //                             Location.navigate({ url: `/registrations/${record.registration.number}` });
        //                         }}
        //                         type="primary"
        //                         size="small"
        //                         color="green"
        //                     >
        //                         View
        //                     </Button>
        //                 </span>
        //             );
        //         },
        //     },
        // ];

        setColumns(bookingColumns);

        return bookingColumns;
    }


    function onSearch(event) {
        setQuery(event.target.value);
    }

    //Search in appointment list
    let filtered = data.filter((record) => {
        query = query.toUpperCase();
        if (query) {
            if ((record.registration.first_name && record.registration.first_name.toUpperCase()).indexOf(query) != -1) {
                return true;
            } else if ((record.registration.last_name && record.registration.last_name.toUpperCase()).indexOf(query) != -1) {
                return true;
            } else if (record.registration.mobile && record.registration.mobile.indexOf(query) != -1) {
                return true;
            }
        } else {
            return true;
        }
    });

    //Updating time and getting pateint record between that time
    function updateTime(dt) {
        setRange(dt);
        // console.log(range);
        Location.search({
            page: 1,
            limit: 20,
            start_time: moment(dt[0]).format('MM/DD/YYYY'),
            end_time: moment(dt[1]).format('MM/DD/YYYY'),
        });

        getRegistrations(dt, columns);
    }

    /**
     * Refresh the page
     */
    function refresh() {

        var params = Location.search();

        var range = [

            moment(params.start_time).format('MM/DD/YYYY'),

            moment(params.end_time).format('MM/DD/YYYY'),
        ]

        getRegistrations(range, columns);
    }

    return (
        <section className="registrations">
            {/* Page Header */}

            <div className="page-header">
                <div className="left">
                    <Title style={{ marginBottom: '0px' }} level={3}>
                        Registrations
                    </Title>
                </div>

                {/*Range picker to select date */}
                <div className="right">
                    <div className="date-and-fltr">
                        <RangePicker
                            allowClear={false}
                            inputReadOnly
                            format={'DD/MM/YYYY'}
                            value={range}
                            onChange={(time) => {
                                updateTime(time);
                            }}
                            ranges={{
                                Today: [moment(), moment()],

                                Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],

                                'This Week': [moment().startOf('week'), moment().endOf('week')],

                                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],

                                'This Month': [moment().startOf('month'), moment().endOf('month')],

                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            }}
                        />

                        <Button onClick={refresh} type="secondary" size={'small'}>
                            <ReloadOutlined />
                        </Button>
                    </div>
                </div>
            </div>
            {/* Page Header Ends */}

            <p className="size-hint">{loading ? 'Loading' : data.length} records.</p>

            {/* Content Below */}
            {loading ? (
                <>
                    <Skeleton active />
                </>
            ) : (
                <>
                    <>
                        <div className="page-header">
                            <Switch
                                defaultChecked={view}
                                onChange={changeView}
                                checked={view}
                                checkedChildren={<OrderedListOutlined />}
                                unCheckedChildren={<PicCenterOutlined />}
                            />

                            <div className="top-actions">
                                {props.mode === 'Walk In' ? (
                                    <Link to={'/new-registration/mode?mode=Walk In'}>
                                        <Button type="primary" size="small">
                                            New Registration
                                        </Button>
                                    </Link>
                                ) : (
                                    ''
                                )}

                                {exportData.exportDatas ? <ExportReactCSV headers={exportData.exportDatas.exportDataHeaders} csvData={exportData.exportDatas.exportDataColumns} /> : null}

                                {/* Export Component */}
                                {/* {columns.length ? <ExportReactCSV csvData={exportData} /> : null} */}
                                {/* Export Component Ends */}

                                <Search
                                    placeholder="Enter Search Value"
                                    allowClear
                                    style={{ width: 300, marginBottom: '20px' }}
                                    onChange={onSearch}
                                />
                            </div>
                        </div>
                        <div>
                            {view ? (
                                //Mobile & Card Veiw
                                <>
                                    <CardList dataSource={filtered} columns={columns} />
                                </>
                            ) : (
                                //Table Listing
                                <>
                                    <Card className="table-card">
                                        <Table
                                            scroll={{ x: true }}
                                            rowKey={(record) => record.da_id}
                                            dataSource={filtered}
                                            columns={columns}
                                            pagination={true}
                                        />{' '}
                                        {''}
                                    </Card>
                                </>
                            )}
                        </div>
                        <div>{contextHolder}</div>
                    </>
                </>
            )}
        </section>
    );
}

//Mobile view card Section
function CardList({ dataSource, columns, url }) {
    // console.log("source", dataSource);
    const { user = {}, isMobile, dispatch } = useContext(GlobalContext);

    function onClick(item) {
        Location.navigate({
            url: `/registrations/${item.registration.number}`,
        });

        dispatch({ type: 'index', payload: item.rowIndex });
    }

    return dataSource.map((item, index) => {
        // console.log("source", dataSource);
        return (
            <div
                key={index}
                className="report-item"
                onClick={() => {
                    onClick(item);
                }}
            >
                <GuestCard record={item} />
            </div>
        );
    });
}

function GuestCard({ record, ele }) {
    return (
        <Card className="card vehicle-card">
            <div className="card">
                {/*Guest Details */}

                <div className="title">
                    <h1>
                        Name:{' '}
                        <strong>
                            {record.registration.first_name ? record.registration.first_name.toUpperCase() : null} {record.registration.last_name ? record.registration.last_name.toUpperCase() : null}
                        </strong>
                    </h1>
                </div>

                <div className="values">
                    <p>
                        Package :{' '}
                        <strong>
                            {record.item && record.item.description
                                ? record.item.description
                                : null}
                        </strong>
                    </p>
                </div>

                <div className="values">
                    <p>
                        Age: <strong> {record.bill ? record.bill.age : null} </strong>
                    </p>
                </div>

                {/* Bill status */}
                {record.appointment && record.appointment.bill_id ? (
                    <div>
                        <strong>Bill Id: {record.appointment.bill_id ? record.appointment.bill_id : null}</strong>
                    </div>
                ) : (
                    <div>
                        <strong>Bill Status</strong> <Tag color="orange">Pending</Tag>
                    </div>
                )}

                {/*Questionnaire, Consent ,Current status */}

                <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <strong>Questionnaire Status</strong>{' '}
                    <Tag
                        color={
                            record.appointment && record.appointment.questionnaire_filled && record.appointment.questionnaire_filled === 'F'
                                ? 'green'
                                : 'orange'
                        }
                    >
                        {record.appointment && record.appointment.questionnaire_filled && record.appointment.questionnaire_filled === 'F'
                            ? 'Filled'
                            : 'Pending'}
                    </Tag>
                    <strong>Consent Status</strong>{' '}
                    <Tag
                        color={
                            record.appointment && record.appointment.consent_filled && record.appointment.consent_filled
                                ? 'green'
                                : 'orange'
                        }
                    >
                        {record.appointment && record.appointment.consent_filled && record.appointment.consent_filled
                            ? 'Filled'
                            : 'Pending'}
                    </Tag>
                    {record.event_sub_master ? (
                        <>
                            <strong> Current Status </strong>
                            <Tag color="green">{record.event_sub_master.description}</Tag>
                        </>
                    ) : null}
                </div>

                {/*Report Status */}

                <div style={{ marginTop: '10px' }}>
                    <strong> Report Uploaded</strong>{' '}
                    {record.appointment && record.appointment.report_uploaded ? (
                        <Tag color="green">Booked</Tag>
                    ) : (
                        <Tag color="orange">Pending</Tag>
                    )}
                    <strong>Report Ready Email Send Status</strong>{' '}
                    <Tag color={record.appointment && record.appointment.report_mail_send_status ? 'green' : 'orange'}>
                        {record.appointment && record.appointment.report_mail_send_status ? 'Sent' : 'Pending'}
                    </Tag>
                </div>

                {/* <h3 className="values">
                Primary Result : {record.PrimaryResult || 'Pending'}

            </h3> */}

                <Text type="secondary">{record.place}</Text>

                <h4 className="values">{record.appointment.date}</h4>
            </div>
        </Card>
    );
}
