import React, { useState, useEffect, useContext } from 'react';

import { Card, Switch, Select, Tag, Button, Typography, Table, Skeleton, Divider, Modal, Input } from 'antd';

import { Link } from 'react-router-dom';

import { Location, GlobalContext, getExportData, useTranslation, Trans } from 'soxo-bootstrap-core';

import { Tabs } from 'antd';

import moment from 'moment-timezone';

import { Appointments } from './../../../../models';

import StatusChange from '../status-change/status-change';

import { ExportReactCSV, RangePicker } from 'soxo-bootstrap-core';

import './appointments.scss';

import { ReloadOutlined, OrderedListOutlined, PicCenterOutlined } from '@ant-design/icons';

const { Title } = Typography;

const { Search } = Input;

let paidValues = {
    Y: 'Paid',
    N: 'Pending',
    P: 'Partially Paid',
};

let appointmentSources = {
    NWEB: 'Website',
    NDES: 'Staff',
};

export default function AppointmentListing(props) {

    var param = Location.search();

    const { user = {}, isMobile, dispatch } = useContext(GlobalContext);

    const [loading, setLoading] = useState(true);

    const { pageno } = props.match.params; //Get pagination number

    const [pageNo, setPageNo] = useState(pageno); // Pagination number

    const [limit, setLimit] = useState(10);

    var [patients, setPatients] = useState([]); //Patients list array

    //Setting starttime and endtime within a week from today
    //endtime = moment.tz().add(7, 'day').startOf('day'); //for stoptb //Setting starttime and endtime within a week from today
    var starttime = moment.tz().startOf('day'),
        endtime = moment.tz().endOf('day').startOf('day');

    const [page, setPage] = useState(1);

    var [query, setQuery] = useState('');

    const [view, setView] = useState(isMobile ? true : false); //Need to check this condition

    const [exportData, setExportData] = useState({});

    const [modal, contextHolder] = Modal.useModal();

    const [columns, setColumns] = useState([]);

    const { t, i18n } = useTranslation();

    useEffect(() => {
        // if (user) {

        // getAppointments(pageNo, range, limit);

        // Initialize the application
        initializeApplication();

        // getPatientDetails(pageNo, range); //Patiant list get function
        // }
    }, []);

    /**
     *
     */
    async function initializeApplication() {

        let col = await prepareColumns();

        getAppointments(pageNo, range, limit, col);
        setColumns(col);
    }



    //If starttime and endtime are in uprl
    if (param.start_time) {
        updateRange();
    }

    function changeView(result) {
        setView(result);
    }

    function updateRange() {
        starttime = moment.tz(param.start_time, 'Asia/Calcutta').startOf('day');

        endtime = moment.tz(param.end_time, 'Asia/Calcutta').startOf('day');

        return starttime, endtime;
    }

    //Setting Range by default
    const [range, setRange] = useState([starttime, endtime]);

    //PatiantData fetching function
    async function getAppointments(pageNo, range, limit, columns) {
        setLoading(true);

        if (props.userFilter) {

            Appointments.getAppointments(pageNo, range, limit, props.userFilter).then((result) => {
                result = result.result.filter((patients) => ['Y', '*'].indexOf(patients.appointment.da_canflg) == -1);

                setPatients(result);

                loadPageConfiguration(result, columns);

                setLoading(false);
            });

        } else {

            Appointments.getAppointments(pageNo, range, limit).then((result) => {
                result = result.result.filter((patients) => ['Y', '*'].indexOf(patients.appointment.da_canflg) == -1);

                setPatients(result);

                loadPageConfiguration(result, columns);

                setLoading(false);
            });
        }
    }

    /**
     *
     */
    async function loadPageConfiguration(patients, columns) {
        // Define export data
        let exportDatas = getExportData(patients, columns);

        if (exportDatas.exportDataColumns.length && exportDatas.exportDataHeaders.length) {
            setExportData({ exportDatas });
        }
    }

    /**
     *
     */
    function prepareColumns() {
        const bookingColumns = [
            {
                title: '#',
                key: '#',
                dataIndex: 'index',
                render: (value, item, index) => (page - 1) * limit + index + 1,
            },


            {
                title: t('Name'),


                //  dataIndex: 'da_fname',
                key: 'name',
                render: (ele) => {
                    var name = ele.appointment.first_name
                    if (ele.appointment && ele.appointment.last_name)
                        name = name + ' ' + ele.appointment.last_name
                    return name;
                },
            },
            {
                title: t('Date'),
                // dataIndex: 'da_date',
                render: (ele) => {
                    return ele.appointment.date ? moment(ele.appointment.date).format('DD/MM/YYYY') : null;
                },
                key: 'da_date',
            },

            {
                title: t('Time'),
                key: 'aptime',
                render: (ele) => {
                    const formattedTime = moment(ele.appointment.appointment_time, 'hh:mm A').format('HH:mm');
                    return formattedTime;
                },
            },

            {
                title: t('Package'),
                //  dataIndex: 'da_packagetypevalue',
                render: (ele) => {
                    if (ele.item && ele.item.description) {
                        return ele.item.description;
                    }
                },

                key: t('package'),
            },
            {
                title: 'Appointment Id',
                key: 'id',
                render: (ele) => {
                    return ele.appointment.id;
                },
            },
            {
                title: 'Created Time',
                key: 'Created Time',
                render: (ele) => {

                    return moment.tz(ele.appointment.created_time, 'DD MM YYYY hh:mm a').format('DD/MM/YYYY, hh:mm a')

                    //   const dateTime = moment(ele.appointment.created_time).format('DD-MM-YYYY HH:mm');
                    //   return dateTime;
                },
            },

            {
                title: t('Gender'),
                render: (ele) => {
                    if (ele.appointment && ele.appointment.gender) {
                        let gender = null;
                        if (ele.appointment.gender === 'M') {
                            gender = 'Male';
                        } else if (ele.appointment.gender === 'F') {
                            gender = 'Female';
                        }
                        return gender;
                    }
                },
                key: 'Gender',
            },

            // {
            //     title: "Gender",
            //     render: (ele) => {

            //         if (ele.preReg && ele.preReg.prereg_gender) {

            //             return ele.preReg.prereg_gender
            //         }

            //     },
            //     key: "place",
            // },

            {
                title: t('Age'),
                render: (ele) => {
                    if (ele.registration && ele.registration.year) {
                        return ele.registration.year;
                    }
                },
                key: 'Age',
            },
            {
                title: 'Address',
                key: 'address',
                render: (ele) => {
                    return ele.appointment.address1;
                },
                exportDefinition: (ele) => {
                    return ele.appointment.address1;
                },
            },
            //Remarks If Age is given during registration
            //Reminder to add Date of Birth and Address
            {
                title: t('Comments'),
                render: (ele) => {
                    if (ele.registration && !ele.registration.dob && ele.registration.address1) {
                        return <Tag style={{ color: 'red' }}>Date of Birth Pending</Tag>;
                    } else if (ele.registration && ele.registration.dob && ele.registration.address1 === '' && ele.registration.address2 === '') {
                        return <Tag style={{ color: 'red' }}>Address Pending</Tag>;
                    } else if (ele.registration && !ele.registration.dob && ele.registration.address1 === '') {
                        return <Tag style={{ color: 'red' }}>Date of Birth and Address Pending</Tag>;
                    } else {
                    }
                },

                exportDefinition: (ele) => {
                    if (ele.registration && !ele.registration.dob && ele.appointment.address1) {
                        return 'Date of Birth Pending';
                    } else if ((!ele.appointment.address1 || !ele.appointment.address2) && ele.registration && ele.registration.dob) {
                        return 'Address Pending';
                    } else if (ele.registration && !ele.registration.dob && !ele.appointment.address1) {
                        return 'Date of Birth and Address Pending';
                    }
                },

                key: 'Comments',
            },
            {
                title: t('Mobile'),
                key: 'mobile',
                render: (ele) => {
                    return ele.appointment.mobile;
                },
                exportDefinition: (ele) => {
                    return ele.appointment.mobile;
                },
            },

            {
                title: t('Registration Status'),
                render: (ele) => {
                    return ele.appointment.op_number ? <Tag color="green">Booked</Tag> : <Tag color="orange">Pending</Tag>;
                },

                exportDefinition: (ele) => {
                    return ele.appointment.op_number ? 'Booked' : 'Pending';
                },
                key: 'Registration Status',
            },

            {
                title: t('OP No'),
                render: (ele) => {
                    return ele.appointment.op_number ? ele.appointment.op_number : '';
                },

                exportDefinition: (ele) => {
                    return ele.appointment.op_number ? ele.appointment.op_number : '';
                },
                key: 'OP No',
            },

            {
                title: t('Fit Kit Status'),
                width: '150px',


                exportDefinition: (ele) => {

                    return ele.appointment && ele.appointment.fit_kit_description ? ele.appointment.fit_kit_description : '';
                },

                render: (ele) => {
                    console.log(ele);
                    if (ele.item && ele.item.code)
                        if (['NWGS', 'NMGS'].indexOf(ele.item.code) === -1)
                            // If the test is not Genetic test fitkit status dropdown is visible
                            return (
                                <>

                                    <div>
                                        {/* Modal for status change */}
                                        <StatusChange

                                            description={ele.appointment.fit_kit_description}

                                            callback={refresh}

                                            id={ele.appointment.id}

                                            statusValue={ele.appointment.fitkit_status}

                                            //status mode
                                            mode='FST'
                                            // mode of status for api call
                                            apiMode='FITKIT'

                                            name='fitkit status' />

                                        {/* End Modal */}

                                    </div>

                                </>
                            );
                },
                exportDefinition: (record) => {
                    return record.appointment && record.appointment.fit_kit_description ? record.appointment.fit_kit_description : null
                },
                key: 'Fit Kit Status',
            },

            {
                title: t('Questionnaire Status'),
                // dataIndex: "billed_status",
                key: 'da_questfilled',
                exportDefinition: (record) => {
                    return record.appointment.questionnaire_filled === 'F' ? 'Filled' : 'Pending';
                },

                render: (record) => {
                    return (
                        <span>
                            <Tag color={record.appointment.questionnaire_filled === 'F' ? 'green' : 'orange'}>
                                {record.appointment.questionnaire_filled === 'F' ? 'Filled' : 'Pending'}
                            </Tag>
                        </span>
                    );
                },
            },

            {
                title: t('Source'),
                // dataIndex: "billed_status",
                key: 'da_sourcetype',
                exportDefinition: (record) => {
                    if (record.appointment.source_type) {
                        return appointmentSources[record.appointment.source_type];
                    }
                },
                render: (record) => {
                    if (record.appointment.source_type) {
                        return (
                            <span>
                                <Tag color={'orange'}>{appointmentSources[record.appointment.source_type]}</Tag>
                            </span>
                        );
                    }
                },
            },

            {
                title: t('Created User'),
                // dataIndex: "billed_status",
                key: 'da_user',
                exportDefinition: (record) => {
                    return record.appointment.user;
                },
                render: (record) => {
                    return <span>{record.appointment.user}</span>;
                },
            },

            // {
            //     title: 'Consent Form Status',
            //     // dataIndex: "billed_status",
            //     key: 'da_consentfilled',
            //     exportDefinition: (record) => {
            //         return record.da_consentfilled === 'F' ? 'Filled' : 'Pending';
            //     },
            //     render: (record) => {
            //         return (
            //             <span>
            //                 <Tag color={record.da_consentfilled === 'F' ? 'green' : 'orange'}>
            //                     {record.da_consentfilled === 'F' ? 'Filled' : 'Pending'}
            //                 </Tag>
            //             </span>
            //         );
            //     },
            // },

            {
                title: t('Token No'),
                render: (record) => {
                    return <span>{record.appointment.token}</span>;
                },
                exportDefinition: (record) => {
                    return record.appointment && record.appointment.token ? record.appointment.token : null
                    // if (record.appointment&&record.appointment.token) {
                    //     return record.appointment.token;
                    // }
                },
                key: 'Token No',
            },

            {
                title: t('Appointment Paid'),
                // dataIndex: "billed_status",
                key: 'gender',
                exportDefinition: (record) => {
                    return record.appointment.payment_status === 'F' ? 'Paid' : 'Pending';
                },
                render: (record) => {
                    return (
                        <span>
                            <Tag color={record.appointment.payment_status === 'F' ? 'green' : 'orange'}>
                                {record.appointment.payment_status === 'F' ? 'Paid' : 'Pending'}
                            </Tag>
                        </span>
                    );
                },
            },

            {
                title: t('Bill Paid'),
                // dataIndex: "billed_status",
                key: 'gender',
                exportDefinition: (record) => {
                    let opbill = record.bill;

                    if (!opbill) {
                        opbill = { paid_flag: 'N' };
                    }

                    return paidValues[opbill.paid_flag];
                },
                render: (record) => {
                    let opbill = record.bill;

                    if (!opbill) {
                        opbill = { paid_flag: 'N' };
                    }

                    return (
                        <span>
                            <Tag color={opbill.paid_flag === 'Y' ? 'green' : 'orange'}>{paidValues[opbill.paid_flag]}</Tag>
                        </span>
                    );
                },
            },
            // {
            //     title: t('Genetic Sample Status'),

            //     render: (ele) => {
            //         if (ele.item && ele.item.code)
            //             // if (['NWGS','NMGS'].indexOf(ele.item.itm_code)!==-1) {
            //             return (
            //                 <div>

            //                     <div>

            //                         {/* Modal for status change */}
            //                         <StatusChange description={ele.appointment.sample_description} callback={() => {

            //                             refresh();

            //                         }}

            //                             id={ele.appointment.id}

            //                             statusValue={ele.appointment.sample_status}

            //                             //status mode
            //                             mode='GS'
            //                             // mode of status for api call
            //                             apiMode='GENETIC'

            //                             name='sample status' />
            //                         {/* End Modal */}
            //                     </div>

            //                 </div>

            //             );
            //         // }
            //     },
            //     exportDefinition: (record) => {
            //         return record.appointment && record.appointment.sample_description ? record.appointment.sample_description : null
            //     },
            //     key: 'report',
            // },

            {
                title: t('Remarks'),
                key: 'Remarks',
                exportDefinition: (record) => {

                    return record.appointment.remarks;

                },
                render: (record) => {
                    return <span>{record.appointment.remarks}</span>;
                },
            },

            {
                title: t('Action'),
                disableExport: true,
                // dataIndex: "billed_status",
                fixed: 'right',
                key: 'gender',

                render: (record) => {
                    return (
                        <span>
                            <Button
                                onClick={() => {
                                    Location.navigate({ url: `/appointment/${record.appointment.id}` });
                                }}
                                type="secondary"
                                size="small"
                                color="green"
                            >
                                {t('View')}
                            </Button>
                        </span>
                    );
                },
            },
        ];

        setColumns(bookingColumns);

        return bookingColumns;
    }


    function onSearch(event) {
        setQuery(event.target.value);
    }


    let filtered = patients.filter((record) => {
        let searchText = query.toUpperCase();

        if (searchText) {
            if ((record.appointment.first_name && record.appointment.first_name.toUpperCase()).indexOf(searchText) != -1) {
                return true;
            } else if (record.appointment.last_name && record.appointment.last_name !== null) {
                if (record.appointment.last_name.toUpperCase().indexOf(searchText) != -1)
                    return true;
            } else if (record.appointment.mobile && record.appointment.mobile.indexOf(searchText) != -1) {
                return true;
            }
        } else {
            return true;
        }
    });

    function updateTime(dt) {
        setRange(dt);

        setPage(1);

        Location.search({
            //  limit: 10 ,
            start_time: moment(dt[0]).format('MM/DD/YYYY'),
            end_time: moment(dt[1]).format('MM/DD/YYYY'),
        });

        setQuery('');

        getAppointments(pageNo, dt, limit, columns); //Patiant list get function
    }

    /**
     * On Page Change
     *
     * @param {*} data
     */
    function onPageChange(page, limit) {

        setLimit(limit);

        setPageNo(page);

        Location.search({ page, limit });

        getAppointments(page, range, limit, columns);
    }

    function refresh() {

        var params = Location.search();

        var range = [

            moment(params.start_time).format('MM/DD/YYYY'),

            moment(params.end_time).format('MM/DD/YYYY'),
        ]

        getAppointments(pageNo, range, limit, columns);

    }

    return (
        <section className="appointments">
            {/* Page Header */}
            <div className="page-header">
                <div className="left">
                    <Title style={{ marginBottom: '0px' }} level={4}>
                        {t('Appointments')}
                    </Title>

                    <p className="size-hint">{loading ? 'Loading' : patients.length} records.</p>
                </div>

                <div className="right">
                    <div className="date-and-fltr">
                        <RangePicker
                            allowClear={false}
                            inputReadOnly
                            format={'DD/MM/YYYY'}
                            value={range}
                            onChange={(time) => {
                                updateTime(time, range);
                            }}
                            ranges={{
                                Today: [moment(), moment()],

                                Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],

                                'This Week': [moment().startOf('week'), moment().endOf('week')],

                                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],

                                'This Month': [moment().startOf('month'), moment().endOf('month')],

                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            }}
                        />

                        <Button onClick={refresh} type="secondary" size={'small'}>
                            <ReloadOutlined />
                        </Button>
                    </div>
                </div>
            </div>
            {/* Page Header Ends */}
            {/* Content Below */}
            {loading ? (
                <>
                    <Skeleton active />
                </>
            ) : (
                <>
                    <>
                        <div className="page-header">
                            <Search
                                placeholder="Enter Search Value"
                                allowClear
                                value={query}
                                style={{ width: 300, marginTop: '10px', marginBottom: '20px' }}
                                onChange={onSearch}
                            />

                            <div className="top-actions">
                                {/* Export Data */}
                                {/* {columns.length ? <ExportReactCSV csvData={exportData} /> : null} */}
                                {exportData.exportDatas ? <ExportReactCSV headers={exportData.exportDatas.exportDataHeaders} csvData={exportData.exportDatas.exportDataColumns} /> : null}
                                {/* Export Data Ends */}

                                <Link to={'/new-registration/mode'}>
                                    <Button type="primary" size="small">
                                        {t('New Appointment')}
                                    </Button>
                                </Link>

                                <Switch
                                    defaultChecked={view}
                                    onChange={changeView}
                                    checked={view}
                                    checkedChildren={<OrderedListOutlined />}
                                    unCheckedChildren={<PicCenterOutlined />}
                                />
                            </div>
                        </div>

                        <div>
                            {view ? (
                                <>
                                    <CardList dataSource={filtered} columns={columns} />
                                </>
                            ) : (
                                <>
                                    <Card className="table-card">
                                        <Table
                                            scroll={{ x: true }}
                                            rowKey={(record) => record.da_id}
                                            dataSource={filtered}
                                            columns={columns}
                                            pagination={{
                                                current: page,
                                                onChange(current) {
                                                    setPage(current);
                                                },
                                            }}
                                        />
                                    </Card>
                                </>
                            )}
                        </div>

                        <div>{contextHolder}</div>
                    </>
                </>
            )}

            <Divider />
        </section>
    );
}


//Mobile view card Section
function CardList({ dataSource, columns, url }) {
    const { user = {}, isMobile, dispatch } = useContext(GlobalContext);

    function onClick(item) {
        Location.navigate({
            url: `/appointment/${item.appointment.id}`,
        });

        dispatch({ type: 'index', payload: item.rowIndex });
    }

    return dataSource.map((item, index) => {
        // to={`/lab-detail/${item.BillID}`}
        return (
            <div
                key={index}
                className="report-item"
                onClick={() => {
                    onClick(item);
                }}
            >
                <Appointments.Card record={item} />
            </div>
        );
    });
}
