/**
 * General List Model
 * @authour Sameena
 *
 */

import { BaseAPI } from 'soxo-bootstrap-core';
import { ApiUtils } from 'soxo-bootstrap-core';

class GeneralList extends BaseAPI {
    constructor() {
        super();
    }

    get id() {
        return 'genl_code';
    }

    get getEndpoint() {
        return 'generallist';
    }

    get path() {
        return `generallist`;
    }

    get getName() {
        return this.modelName;
    }

    get modelName() {
        return `generallist`;
    }

    generalAdd = (values) => {
        var formBody = {
            ...values,
        };
        return ApiUtils.post({ url: 'generallist/create-general-list', formBody });
    };

    /**
 * Edit referrer details
 * @param {*} code
 * @returns
 */
    editReason = (code, values) => {
        var formBody = {
            ...values,
        };
        return ApiUtils.put({ url: `Generallist/${code}`, formBody });
    };

    checkCode = (code,mode) => {
        return ApiUtils.get({
            // url: `settingslist/table-data?table_name=generallist&primary_key=genl_code&primary_key_value=${code}`,
            url: `settingslist/table-data?table_name=generallist&primary_key=genl_code&composite_key=genl_mode&primary_key_value=${code}&composite_key_value=${mode}`,
        });
    };

    getReasonWithId = (code) => {
        return ApiUtils.get({
            url: `Generallist/${code}`,
        });
    };
    getReason = (genl_mode) => {
        return ApiUtils.get({
            url: `Generallist?genl_mode=${genl_mode}`,
        });
    };


}

export default GeneralList;
