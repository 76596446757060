/***
 *
 * Creating a new componet is used to Upload file by selecting  . In this componet opens a modal to upload documents
 *
 * @description
 * @author Hasna
 */
import React, { useState, useEffect } from 'react';

import { Button, Form, Input, Modal, Table, message, Typography, Alert, Space, Select } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import { FileUpload, PdfViewer } from 'soxo-bootstrap-core';

import moment from 'moment-timezone';

import { DocDetails, DocFileTypes } from '../../../../../models';

const { TextArea } = Input;

const { Option } = Select;

const { Title } = Typography;

var layout = {
    layout: 'vertical',
};

export function DocumentUpload({ op_no, labVisit, histories }) {
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [documents, setDocuments] = useState([]);

    const [fileDetails, setFileDEtails] = useState([])

    useEffect(() => {
        loadDocuments(op_no);

        getFileDetails()
    }, []);

    // Load Documents by op no
    async function loadDocuments(op_no) {
        var result = await DocDetails.getDocuments(op_no);
        if (result.result.length) {
            setDocuments(result.result);
        } else {
            setDocuments([]);
        }
    }

    function getFileDetails() {

        DocFileTypes.get().then((result) => {

            setFileDEtails(result.result)

        });
    }
    // This constant is used to open the modal
    const showModal = () => {
        setIsModalVisible(true);
    };

    return (
        <div className="action">
            <div className="page-header">
                <div>
                    <Title level={3}>Documents</Title>

                    <Alert message="Have any additional document that you want to record for the customer, Add it here" type="info" />
                </div>

                {/** Button For Document upload  */}
                {/** If the histories returns true then hide the button (Show the emr screen contains patient history tab) else show the button.*/}
                {!histories ? (
                    <Button size="small" onClick={showModal}>
                        <PlusOutlined />
                        Add Document
                    </Button>
                ) : (null)}
            </div>

            <div className="page-content">
                <Table
                    scroll={{ x: true }}
                    pagination={false}
                    dataSource={documents}
                    columns={[
                        {
                            title: '#',
                            dataIndex: 'index',
                            render: (value, item, index) => index + 1,
                        },

                        {
                            title: 'Document Name',
                            dataIndex: 'docdet_filename',
                            key: 'docdet_filename',
                        },

                        {
                            title: 'Document Type',
                            dataIndex: 'docdet_nativefiletype',
                            key: 'docdet_nativefiletype',
                        },

                        {
                            title: 'Document Time',
                            key: 'uploaded_time',
                            render: (ele) => {
                                // return DateUtils.formatDate(ele.docdet_uploaded_time);

                                return moment.tz(ele.docdet_uploaded_time, '').format('DD/MM/YYYY HH:mm a')
                            },
                        },
                
                        {
                            title: 'Remarks',
                            dataIndex: 'docdet_remarks',
                            key: 'docdet_remarks',
                        },

                        {
                            title: 'Document',
                            key: 'action',
                            render: (record) => {
                                return (
                                    <Space size="middle">
                                        <ViewModal record={record} />
                                    </Space>
                                );
                            },
                        },
                    ]}
                />
            </div>

            {/** Document modal section start  */}

            <Modal
                title="Add Document"
                visible={isModalVisible}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
                onOk={() => setIsModalVisible(false)}
                onCancel={() => setIsModalVisible(false)}
                destroyOnClose={true}
            >
                <UploadFile
                    opNo={op_no}
                    fileDetails={fileDetails}
                    labVisit={labVisit}
                    callback={() => {
                        setIsModalVisible(false);
                        loadDocuments(op_no);
                    }}
                />
            </Modal>

            {/** Document modal section End  */}
        </div>
    );
}

/**
 *  This Function accepts attachment files.
 * @returns
 */
function UploadFile({ opNo, callback, labVisit, fileDetails }) {
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);



    /**
     *  Upload file
     */
    function onUpload(attachments) {
        var files = [];

        attachments.map((attachment) => {
            files.push(attachment);
        });
        form.setFieldsValue({ files: files });
    }

    // setting initialvalues accoding to the user
    let defaultfileName = !labVisit ? fileDetails[0].docft_deffilename : fileDetails[1].docft_deffilename;

    // On form submit
    function onFinish(values) {

        setLoading(true);
        var formData = new FormData();

        //Each file is appended
        values.files.map((file) => {
            formData.append('files', file);
        });

        // Filetype , remarks, and op_no  passed to backend
        // If filename is present it is recorded as the filename by default
        formData.append('docdet_filename', values.filename ? values.filename : values.fileType);
        formData.append('docdet_remarks', values.remarks);
        formData.append('docdet_refptr', opNo);
        formData.append('docdet_refmode', 'OPREG');
        formData.append('docdet_refdetails', 'OPREG Details');

        // File is uploaded and recorded in docdetails
        DocDetails.uploadFiles(formData).then((result) => {
            if (result.message === 'success') {
                message.success('File Uploaded SuccessFully');
                setLoading(false);
                callback();
            }
        });
    }

    // setting deafault filetype according to the user
    let defaultfileTypes = !labVisit ? fileDetails[0].docft_desc : fileDetails[1].docft_desc

    return (
        <Form {...layout} form={form} onFinish={onFinish} initialValues={{ filename: defaultfileName, fileType: defaultfileTypes }}>
            <Form.Item label="Select File Type" name="fileType" rules={[{ required: true, message: 'Please select file type' }]}>

                <Select>
                    {/* {fileDetails.map((item, key) => (

                        <Option key={key} value={item.docft_id}>
                            {item.docft_desc}
                        </Option>
                    ))} */}

                    {!labVisit ? (
                        // setting options according to role
                        <Option value="Lab Reports">{fileDetails[0].docft_desc}</Option>
                    ) : (
                        <Option value="Medical Reports">{fileDetails[1].docft_desc}</Option>
                    )}
                </Select>


            </Form.Item>

            <br />
            <Form.Item label="File Name" name="filename">
                <TextArea rows={1} />
            </Form.Item>
            <Form.Item label="Upload File" name="files" rules={[{ required: true, message: 'Please select file' }]}>
                <FileUpload callback={(attachment) => onUpload(attachment)} autoUpload={false} />
            </Form.Item>

            <Form.Item
                label="Remarks"
                name="remarks"
                rules={[
                    { required: true, message: 'Please enter remarks' },
                    {
                        max: 250,
                        message: 'Length cannot exceed 250 characters !',
                    },
                ]}
            >
                <TextArea rows={4} />
            </Form.Item>

            <Form.Item>
                <Button loading={loading} htmlType="submit" type="primary">
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
}

/**
 * To view files uploaded
 * @param { } param0
 * @returns
 */
function ViewModal({ record }) {
    const [IsViewVisible, setIsViewVisible] = useState(false);

    // Variable to convert BytesArray
    var [byteArray, setByteArray] = useState([]);

    const [fileURL, setFileURL] = useState();

    const loadFile = (record) => {
        DocDetails.loadFile(record.docdet_location).then((result) => {
            if (result && result.data) {
                //                setBtnLoading(false);
                var res = result.data;
                var bytearray = Object.keys(res);
                var arrayelement = Object.values(res);

                var uint8Array = new Uint8Array(bytearray.length);

                for (var i = 0; i < uint8Array.length; i++) {
                    uint8Array[i] = arrayelement[i];
                }

                for (var i = 0; i < bytearray; i++) {
                    var ascii = arrayelement.charCodeAt(i);
                    uint8Array[i] = ascii;
                }

                var report = new Blob([uint8Array], {
                    type: 'image/jpeg',
                });

                // To view image
                setFileURL(URL.createObjectURL(report));

                // To view pdf
                setByteArray({ data: uint8Array });

                return uint8Array;
            } else {
                message.warning(result.message);
            }
        });
    };

    const viewOk = () => {
        setIsViewVisible(false);
    };

    const viewCancel = () => {
        setIsViewVisible(false);
    };

    const viewModal = () => {
        setIsViewVisible(true);
        loadFile(record);
    };

    return (
        <>
            <a onClick={viewModal}>View</a>

            <Modal
                width={690}
                title="Document"
                visible={IsViewVisible}
                onOk={viewOk}
                onCancel={viewCancel}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <Space>
                    {record.docdet_nativefiletype === 'application/pdf' ? (
                        <div>
                            <PdfViewer url={byteArray} />
                        </div>
                    ) : (
                        <img width={650} src={fileURL} />
                    )}
                </Space>
            </Modal>
        </>
    );
}
