/**
 *@description
 * @author jinshakappil
 * This component is used in guest information page. This card used to show reasons and name of doctor
 */

import React, { useState, useEffect } from 'react';

import { Button, Typography } from 'antd';

import { BulbOutlined } from '@ant-design/icons';

import { Location } from 'soxo-bootstrap-core';

import './guest-preferences.scss';

import { Opreg, Doctors } from './../../../../models';

const { Title } = Typography;

export function GuestPreferences() {

    var urlParams = Location.search();

    const [reason, setReason] = useState([]);

    useEffect(() => {

        getReasonDetails();

        return () => { }

    }, [])


    /**
   * Get reason Details
   */
    function getReasonDetails() {
        Doctors.getReasonDetails(urlParams.reason).then((result) => {

            if (result.result && result.result[0])
                setReason(result.result[0].genl_desc);
        });
    }

    return (
        <div className="guest-preferences card">
            <div className="right-card">
                <div className="title">
                    <Title level={5}> GUEST PREFERENCES </Title>
                </div>

                {/* Doctor Selection */}
                {/* {props.doctorSelectionDisable ? null : ( */}
                {/* <div className="detail-card" style={{ backgroundColor: '#DCF9FF' }}>
                    <>
                        <p className="header">DOCTOR</p>
                        <p className="data"> Dr. Kiran Kumar</p>
                    </>
                </div> */}
                {/* )} */}
                {/* Doctor Selection Ends */}

                {/* Reason Section */}
                <div className="detail-card">
                    {/* {doctorSelectionDisable ? null : ( */}
                    <>
                        <p className="header">REASON </p>
                        <p className="data"> {reason}</p>
                    </>
                    {/* )} */}
                </div>
                {/* Reason Section Ends */}

                <div className="detail-card">
                    <>
                        <p className="header">REMARKS</p>
                        <p className="data" style={{ fontSize: '16px' }}>
                            {' '}
                            {urlParams.remarks}
                        </p>
                    </>
                </div>
            </div>

            <div className="right-card">
                <div className="preferences-details">
                    <div className="button">
                        <Button type="primary" className="tip-to-remeber">
                            <BulbOutlined />
                            TIP TO REMEMBER
                        </Button>
                    </div>
                </div>

                <div className="title">
                    <div className="detail-card">
                        <>
                            <p className="data"> Register a guest easily by selecting reason and providing any remarks.</p>
                        </>
                    </div>
                </div>
            </div>
        </div>
    );
}
