/**
 *
 * This should ideally be satisfied by a generic listing page
 *
 *
 */

import React, { useState, useEffect, useContext } from 'react';

//
import { Card, Space, DatePicker, Switch, Form, Button, Typography, Table, Skeleton, Pagination, Checkbox, Tag, Popover, Radio } from 'antd';

import { Route, Link } from 'react-router-dom';

import { ModalityGroups } from './../../../../models';

import Select from 'react-select';

// import { Location } from '../../../../utils/location.utils';

import { Location } from 'soxo-bootstrap-core';

//  import ApiUtils from '../../../../utils/api.utils';

// import { GlobalContext } from '../../../../Store';

import { GlobalContext } from 'soxo-bootstrap-core';

import moment from 'moment-timezone';

import './modality-dashboard.scss';

import { ReloadOutlined, OrderedListOutlined, PicCenterOutlined, CaretLeftOutlined, CaretRightOutlined, DeleteOutlined } from '@ant-design/icons';

import PatientDetails from '../../../imaging/components/patient-details/patient-details';

const { Title, Text } = Typography;

const { RangePicker } = DatePicker;

let defaultValue = {
    value: { mod_code: 'all' },
    label: 'All',
};

/**
 * Patient List
 *
 * @param {*} match
 * @returns
 */
export default function ModalityDashboard({ match, mode, ...props }) {
    // location params
    var param = Location.search();

    const [range, setRange] = useState([
        moment.tz(param.start_time, process.env.REACT_APP_TIMEZONE).startOf('day'),
        moment.tz(param.end_time, process.env.REACT_APP_TIMEZONE).endOf('day'),
    ]);

    const [selected, setSelected] = useState(false);

    const [tech, setTech] = useState([]);

    const [groups, setGroups] = useState([]);

    const { user = {}, isMobile } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);

    const [page, setPage] = useState(1); // Pagination number

    const [limit, setLimit] = useState(20);

    const [patients, setPatients] = useState([]); //Patients list array

    const [modality, setModality] = useState(defaultValue);

    const [subModality, setSubModality] = useState('');

    const [suboptions, setSuboptions] = useState([]);

    const [list, setList] = useState('ALL');

    useEffect(() => {
        // On url update load the data
        loadData();

        // Load data with the updated params
        // getPatientDetails(params);
    }, []);

    /**
     * Load the data
     */
    function loadData() {
        let urlParams = Location.search();

        // Match params
        let params = {
            start_time: range[0].format('MM/DD/YYYY'),
            end_time: range[1].format('MM/DD/YYYY'),
            limit: 10,

            ...urlParams,
        };

        loadGuestRecords(params);
    }

    useEffect(() => {
        getDropdown();

        getGroups();
    }, [user]);

    /**
     * load the data
     *
     * @param {*} params
     */
    function loadGuestRecords(params) {
        //
        setLoading(true);

        // Load the data
        ModalityGroups.loadGuests({ ...params, user }).then((result) => {
            if (result[0].length) {
                // Guests
                setPatients(result[0]);

                var first = result[0][0];

                if (first && first.opno) {
                    setSelected(first);

                    Location.search({
                        opno: first.opno,
                        accessionno: first.AccessionNo,
                    });
                }
            } else {

                setPatients([]);

                setSelected(false);

            }

            setLoading(false);
        });
    }

    /**
     * On Page Change
     *
     * @param {*} data
     */
    function onPagination(page, limit) {
        setPage(page);

        setLimit(limit);

        Location.search({ page, limit });

        loadData();
    }

    function getDropdown() {
        ModalityGroups.getTechMaster().then((result) => {
            setTech(result);
        });
    }

    /**
     * Get all the groups
     */
    function getGroups() {
        // get groups
        ModalityGroups.getModality().then((result) => {
            setGroups([
                defaultValue,

                ...result
                    .filter((item) => item.mod_active === 'Y')
                    .map((record) => {
                        return {
                            value: record,
                            label: record.mod_desc,
                        };
                    }),
            ]);
        });
    }

    /**
     * On change of datetime
     *
     * @param {*} dt
     */
    function updateTime(dt) {
        setPage(1);
        setRange(dt);
        setSubModality('');

        Location.search({
            page: 1,
            limit: 20,
            start_time: moment(dt[0]).format('MM/DD/YYYY'),
            end_time: moment(dt[1]).format('MM/DD/YYYY'),
        });

        loadData();
    }

    /**
     * Refresh the
     */
    function refresh() {
        setSubModality('');

        loadData();
    }

    /**
     * Filter by modality
     *
     * @param {*} value
     * @param {*} content
     */
    function filterModality(value, content) {
        setPage(1);

        setModality(value);

        setSubModality('');

        Location.search({ modality: value.value.mod_code, page: 1 });

        if (value.label !== 'All') {
            ModalityDashboard.getModalityGroupItemGroupMap(value.value.mod_groupptr).then((result) => {
                setSuboptions(
                    result.map((record) => {
                        return {
                            value: record,
                            label: record.itm_desc,
                        };
                    })
                );
            });
        }
    }

    /**
     * Subfilter
     *
     * @param {*} value
     */
    function subFilterModality(value) {
        setSubModality(value);

        Location.search({ submodality: value.value.itm_code });
    }

    const onTabChange = (event) => {
        var list = event.target.value;

        setList(event.target.value);

        var filtered = patients.filter((record) => {
            if (list === 'ALL') {
                return true;
            } else if (list === 'TECH') {
                return !record.TechResult;
            } else if (list === 'RAD') {
                return record.TechResult && !record.DrResult;
            }
        });

        // setSelected({});

        // setTimeout(() => {
        setSelected(filtered[0]);

        Location.search({
            accessionno: filtered[0].AccessionNo,
            opno: filtered[0].opno,
            mode: 'TECH',
        });
        // }, 100);
    };

    function onSelect(selected) {
        setSelected(selected);
    }

    function onComplete() {
        loadData();
    }

    return (
        <section className="patient-list">
            {/* Page Header */}
            <div className="page-header">
                <div className="left">
                    <Radio.Group value={list} onChange={onTabChange}>
                        <Radio.Button value="ALL">All</Radio.Button>
                        <Radio.Button value="TECH">Technician</Radio.Button>
                        <Radio.Button value="RAD">Radiologist</Radio.Button>
                        {/* <Radio.Button value="small">Completed</Radio.Button> */}
                    </Radio.Group>

                    {/* <Title style={{ marginBottom: '0px' }} level={3}>
                         Guest List
                     </Title> */}
                </div>

                <div className="right">
                    <div style={{ marginRight: '10px' }} className="modality-name">
                        <div className="action-header">Modality</div>
                        <Select
                            options={groups}
                            // labelInValue={'mod_desc'}
                            value={modality}
                            onChange={(value) => {
                                filterModality(value);
                            }}
                            // defaultValue="all"
                            style={{ width: 120 }}
                        ></Select>
                    </div>

                    {/* Filter for Sub Modality */}
                    {modality.label !== 'All' ? (
                        <div style={{ marginRight: '10px' }} className="sub-modality-name">
                            <div className="action-header">Sub Modality</div>
                            <Select options={suboptions} value={subModality} onChange={subFilterModality} style={{ width: 120 }}></Select>
                        </div>
                    ) : null}
                    {/* Filter for Sub Modality Ends */}

                    {/* Filtering data */}

                    <div className="date-and-fltr">
                        <div className="action-header">Date</div>
                        <RangePicker
                            allowClear={false}
                            inputReadOnly
                            format={'DD/MM/YYYY'}
                            value={range}
                            onChange={(time) => {
                                updateTime(time, range);
                            }}
                            ranges={{
                                Today: [moment(), moment()],

                                Yesterday: [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],

                                'This Week': [moment().startOf('week'), moment().endOf('week')],

                                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],

                                'This Month': [moment().startOf('month'), moment().endOf('month')],

                                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            }}
                        />

                        <Button onClick={refresh} type="secondary" size={'small'}>
                            <ReloadOutlined />
                        </Button>
                    </div>
                </div>
            </div>

            {/* Page Header Ends */}

            {/* Content Below */}
            {loading ? (
                <>
                    <Skeleton active />
                </>
            ) : (
                <>
                    {/* Below should evolve to a generic detail view with good api support */}
                    <div className="detail-view">
                        <div className="left">
                            <CardList list={list} selected={selected} dataSource={patients} callback={onSelect} />
                        </div>

                        <div className="right">
                            {selected ? <PatientDetails onSubmit={onComplete} mode={list} dashboard={true} match={match} {...props} /> : null}
                        </div>
                    </div>
                </>
            )}

            <div className="phycnListpgntn">
                {!loading ? <Pagination pageSize={limit} showSizeChanger={true} onChange={onPagination} defaultCurrent={page} total={100} /> : null}
            </div>
        </section>
    );
}

//Mobile view card Section
function CardList({ selected, dataSource, callback, list }) {
    //
    const onSelect = (item) => {
        Location.search({
            accessionno: item.AccessionNo,
            opno: item.opno,
            mode: 'TECH',
        });

        callback(item);
    };

    let filtered = dataSource.filter((record) => {
        if (list === 'ALL') {
            return true;
        } else if (list === 'TECH') {
            return !record.TechResult;
        } else if (list === 'RAD') {
            return record.TechResult && !record.DrResult;
        }
    });

    return (
        <div>
            <p className="size-hint">{filtered.length} records.</p>

            {filtered.map((item, index) => {
                return (
                    <div
                        key={index}
                        onClick={() => {
                            onSelect(item);
                        }}
                        className="guest-card"
                    >
                        <ModalityGroups.GuestCard record={item} selected={selected} />
                    </div>
                );
            })}
        </div>
    );
}
