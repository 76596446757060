import React, { useState, useEffect, useContext } from 'react';

import { Button, Typography, Skeleton, Popconfirm, message, Modal, Form, Input, Tabs, Tag, Row } from 'antd';

import { GlobalContext, Location, ConfirmModal, ApiUtils, Camera } from 'soxo-bootstrap-core';

import { Appointments, DocDetails } from './../../../../models/';

import moment from 'moment-timezone';

import './appointment-detail.scss';

import { ReloadOutlined } from '@ant-design/icons';

import OutboxList from '../../../outbox-list/outbox-list';

import GuestInfo from '../../../common/components/guest-info/guest-info';

import DocumentPreview from './../../../../components/document-preview/document-preview';

import StatusChange from '../status-change/status-change';


// import AppointmentConsent from './../../../appointment-consent/appointment-consent';

const { Title,Text } = Typography;

const { TabPane } = Tabs;

const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
};

const { TextArea } = Input;

/**
 * Appointment Detail Screen
 *
 * @param {*} props
 * @returns
 */
export default function AppointmentDetail(props) {

    // Url Params
    const { user = {}, isMobile } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);

    const { id } = props.match.params; //Get pagination number

    const [guest, setGuest] = useState({});

    const [guestopreg, setGuestOpReg] = useState({});

    const [guestitem, setGuestItem] = useState({});

    const [guestprereg, setGuestPreReg] = useState({});

    const [guestopbill, setGuestOpBill] = useState();

    const [status, setStatus] = useState({}) // To record process status

    const [form] = Form.useForm();

    const [visible, setVisible] = useState(false);

    //PatiantData fetching function
    async function getAppointments(id) {
        setLoading(true);

        Appointments.getAppointmentRecord(id).then((res) => {
            var result = res.drAppntmnt;

            setGuest(result);

            setStatus(res.data);

            setGuestOpReg(res.opreg);

            setGuestItem(result.item);

            setGuestPreReg(result.preReg);

            if (result.opbill) setGuestOpBill(result.opbill);

            setLoading(false);
        });
    }

    useEffect(() => {
        if (user) {
            getAppointments(id);
        }
    }, [user]);

    function refresh() {
        getAppointments(guest.da_id);
    }

    //Cancellation of Appointment
    function onCancelAppointment() {
        form.validateFields()
            .then((values) => {
                let updateParams = {
                    cancel_flag: true,
                    da_canuser: user.email,
                    da_canuserid: user.id,
                    da_canremarks: values.remarks,
                };

                // cancel the appointment
                Appointments.cancelAppointment(id, updateParams).then(() => {
                    setVisible(false);
                    Location.navigate({ url: `/appointments` });

                    message.success('Appointment is Cancelled');
                });
            })
            .catch((info) => {
                // console.log('Validate Failed:', info);
                setVisible(false);
            });
    }

    /**
     *
     */
    async function sendGoogleReviewMail() {

        const result = await ApiUtils.post({ url: `opbill/google-review-mail?billId=${guest.da_billid}` });

        message.success('Mail send');
    }


    return (
        <section className="appointment-detail">
            {/* Page Header */}

            <div className="page-header">
                <div className="left">
                    {/* <Title style={{ marginBottom: '0px' }} level={3}>
                        Appointment
                    </Title> */}
                </div>

                <div className="right">
                    <Button onClick={refresh} type="secondary" size={'small'}>
                        <ReloadOutlined />
                    </Button>
                </div>
            </div>
            <div className="page-header">
                <div className="left">
                    <Title style={{ marginBottom: '0px' }} level={2}>
                        Appointment
                    </Title>
                </div>

                <div className="actions">

                    {guest.da_billid ? (
                        <div>
                            <Popconfirm
                                title="Are you sure you want to trigger mail for this guest ? "
                                onConfirm={() => sendGoogleReviewMail()}
                                onCancel={() => { }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button
                                    className="billing"
                                // type="primary"
                                // color="green"
                                >
                                    Send Google Review Mail
                                </Button>
                            </Popconfirm>
                        </div>
                    ) : null}
                </div>
            </div>

            {/* Page Header Ends */}

            <p className="size-hint">{/* {loading ? 'Loading' : patients.length} records. */}</p>

            {/* Content Below */}
            {loading ? (
                <>
                    <Skeleton active />
                </>
            ) : (
                <div>
                        <div className="timeline-guest">
                        <div className="guest-detail card-shadow card">
                            {/*Guest Information */}
                            <GuestInfo
                                guest={guest}
                                data={status}
                                guestopreg={guestopreg}
                                guestitem={guestitem}
                                guestprereg={guestprereg}
                                guestopbill={guestopbill}
                            />

                            <GuestRecord
                                record={guest}
                                cancelApp={() => {
                                    setVisible(true);
                                }}

                                filteredMail={props.filterMail}
                            />
                            <div className='status'>
                                <div className="appointment-consent">
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                        <div className="section-label">
                                            <Text type="secondary">PAYMENT STATUS</Text>
                                        </div>
                                        <div className="section-value">
                                            {/* payment status model start */}

                                            <StatusChange
                                                description={status.payment_status}
                                                callback={refresh}
                                                id={guest.da_id}
                                                statusValue={status.payment_status_code}
                                                // status mode
                                                mode="PAY"
                                                // mode of status for api call
                                                apiMode="PAY"
                                                name="payment status"
                                                updateBtnDisabled={status.paid_status && guest.da_sourcetype=='NWEB'}
                                            />

                                            {/* payment status model end */}
                                        </div>
                                    </div>
                                </div>
                            {/* Consent Status */}
                            <AppointmentConsent callback={refresh} mode="appointment" value={guest.da_consentscannedstatus} id={guest.da_id} />
                            {/* Consent Status Done*/}
                            </div>

                            {/* <div> */}
                            {/* <Tabs defaultActiveKey="0">
                                <TabPane tab="Outbox" key="0">
                                    <OutboxList record={guest} />
                                </TabPane>
                            </Tabs> */}
                            {/* </div> */}
                        </div>
                        {/*Appointment Timeline */}
                        {/* <AppointmentTimeline guest={guest} guestopreg={guestopreg} guestitem={guestitem} /> */}
                    </div>
                </div>
            )}
            {/* Modal for Cancel Appointment */}
            <Modal
                destroyOnClose={true}
                title="Cancel Appointment"
                visible={visible}
                okText="Okay"
                onOk={() => {
                    setVisible(false);
                    onCancelAppointment();
                }}
                onCancel={() => {
                    setVisible(false);
                }}
            >
                <Form {...layout} form={form} name="new-record" layout="vertical">
                    <Form.Item
                        name={'remarks'}
                        label="Remarks"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter Remarks',
                            },
                        ]}
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                </Form>
            </Modal>
        </section>
    );
}

function GuestRecord({ record, cancelApp, filteredMail = [] }) {

    const [visible, setVisible] = useState(false);

    /**
     *
     */
    async function resendEmails() {
        const result = await ApiUtils.get({ url: `mail/resend-appointment-details?id=${record.da_id}` });

        // console.log(result);

        message.success('Mail/SMS Confirmation send');
    }

    // function proceedRegistration() {
    //     var selected_time = moment(record.da_date).format('DD/MM/YYYY') + record.da_aptime;

    //     let url = `/new-registration/guest?da_id=${record.da_id}&prereg_id=${record.da_referenceid}&da_packagetypevalue=${record.da_packagetypevalue}&selected_time=${selected_time}&token_no=${record.da_tokenno}`;

    //     Location.navigate({ url: url });
    // }

    return (
        <>
            {/* Cancel Appointment, Billing, Registration */}

            {/*Billing */}

            <div className="billing">
                {record.da_newopno && !record.da_billid ? (
                    <>
                        <div>
                            {/* <Button
                                className="billing"
                                onClick={() => {
                                    proceedBilling();
                                }}
                                type="secondary"
                                shape="round"
                                style={{ background: "#FAC141" }}
                                // size="small"
                                color="green"
                            >
                                Proceed for Billing
                            </Button> */}
                        </div>
                    </>
                ) : null}

                {/*Registration  */}

                {!record.da_newopno ? (
                    <>
                        {/* <div>
                            <Button
                                className="billing"
                                onClick={() => {
                                    proceedRegistration();
                                }}
                                type="secondary"
                                shape="round"
                                style={{ background: '#FAC141' }}
                                // size="small"
                                color="green"
                            >
                                Proceed for Registration
                            </Button>
                        </div> */}
                    </>
                ) : null}

                {/* Cancel and Reschedule Appointment */}

                <ConfirmModal
                    caption={'Resend Appointment Confirmation Mails'}
                    title="This action will send the Appointment Confirmation , Questionnaire and Access Credentials to the Customers Email and Phone , Are you sure ? "
                    callback={(values) => resendEmails()}
                >
                    <Button
                        className="billing"
                        type="primary"
                        // // size="small"
                        // shape="round"
                        // style={{ background: "#FAC141" }}
                        color="green"
                    >
                        Resend Appointment Confirmation Mails
                    </Button>
                </ConfirmModal>
                <div>
                    <span>
                        {' '}
                        <h5 style={{ paddingTop: '10px' }}>
                            Last Updated By - {record.da_user} for {moment(record.da_date).format('DD/MM/YYYY')},{record.da_aptime} .

                            {/* on{' '} */}
                            Updated Time - {moment.tz(record.da_time, 'DD MM YYYY hh:mm a').format('DD/MM/YYYY, hh:mm a')}.
                        </h5>
                    </span>
                </div>
                {/* <Popconfirm
                    title="This action will send the Appointment Confirmation , Questionnaire and Access Credentials to the Customers Email and Phone , Are you sure ? "
                    onConfirm={() => resendEmails()}
                    onCancel={() => { }}
                    okText="Yes"
                    cancelText="No"
                >

                </Popconfirm> */}

                {!record.da_billid ? (
                    <div>
                        {/* Cancel Appointment */}
                        <Popconfirm
                            title="Are you sure you want to cancel this appointment ? "
                            onConfirm={() => cancelApp()}
                            onCancel={() => { }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                className="billing"
                                type="primary"
                                // // size="small"
                                // shape="round"
                                style={{ paddingBottom: '1rem' }}
                                color="green"
                            >
                                Cancel Appointment
                            </Button>
                        </Popconfirm>

                        {/* Cancel Appointment Ends */}
                    </div>
                ) : null}

                <div>
                    <Button className="outbox"
                        onClick={() => {
                            setVisible(true);
                        }}
                        type="primary"
                        color="green">View Outbox</Button>
                    {/*  Outbox modal starts  */}
                    <Modal
                        destroyOnClose={true}
                        visible={visible}
                        // title='Outbox'
                        footer={null}
                        className=""
                        onCancel={() => {
                            setVisible(false);
                        }}
                        width={'80%'}
                        maskClosable={false}
                    >

                        <Tabs defaultActiveKey="0">
                            <TabPane tab="Outbox" key="0">
                                <OutboxList filteredMail={filteredMail} record={record} />
                            </TabPane>
                        </Tabs>

                    </Modal>

                    {/*  Outbox modal ends  */}
                </div>
            </div>

        </>
    );
}


/**
 * Component would show the appointment consent current status , 
 * If the value does not have value , the user can upload new consent  
 * 
 * @param {*} param0 
 * @returns 
 */
function AppointmentConsent({ id, value, callback }) {

    const [openPreview, setOpenPreview] = useState(false);

    const [openIframe, setOpenIframe] = useState(false);

    /**
     * Function to upload consent form
     * 
     * @param {*} dataUri 
     */
    const onConsentUpload = (selectedImages, baseIndex = 0) => {

        message.loading('Uploading Consent Document');

        // var formData = new FormData();
        Promise.all(
            selectedImages.map((ele, index) => {

                var formData = new FormData();

                // setting body
                formData.append('files', ele);
                formData.append('appointment_id', id);
                formData.append('index', baseIndex + (index + 1));

                return DocDetails.uploadConsentForm(formData).then((result) => {
                    if (result.message === 'success') {
                        // return;
                    }
                });
            })
        ).then(() => {
            // refreshing page after consent upload
            // setTimeout(() => {
            message.success('File Uploaded SuccessFully');

            callback();
            // }, 3000);
        });
    }

    return (
        <div className="detail-element-card appointment-consent">
            {/* <Button
                size="middle"
                type="primary"
                onClick={() => {
                    setOpenIframe(true)
                }}
            >
                Take Picture
            </Button> */}
            <Row span={12}>
                <span>Consent Document Status</span>
            </Row>
            <Row span={12}>
                <span>{
                    value ?
                        <div>
                            <Tag color="green">Submitted</Tag>

                            <Button
                                size="middle"
                                type="primary"
                                onClick={() => {
                                    setOpenPreview(true)
                                }}
                            >
                                Preview
                            </Button>
                            {/* <DocumentPreview id={id} /> */}

                        </div>
                        :
                        <>
                            <Tag color="orange">Pending</Tag>

                            {/* Camera Component with Advanced View */}
                            <Camera
                                multiple={true}
                                onUpload={onConsentUpload}
                                mode={'rear'}
                            />
                            {/* Camera Component with Advanced View Ends */}

                        </>
                }
                </span>
            </Row>
            <Modal
                width={'100%'}
                destroyOnClose={true}
                title="Consent File"
                visible={openPreview}
                okText="Okay"
                onOk={() => {
                    setOpenPreview(false);
                }}
                onCancel={() => {
                    setOpenPreview(false);
                }}
            >
                <DocumentPreview id={id} onDocumentUpload={onConsentUpload} />
                {/* <iframe allow="camera; microphone" align="center" src={`http://localhost:3000/document-preview/${id}?token=${localStorage.access_token}`} width="100%" height="400px"></iframe> */}
            </Modal>
            {/* <Modal
                wrapClassName="advanced-camera"
                bodyStyle={{ height: "80vh" }}
                width={'100%'}
                destroyOnClose={true}
                // title="Nura desk"
                visible={openIframe}
                okText="Okay"
                onOk={() => {
                    setOpenIframe(false);
                }}
                onCancel={() => {
                    setOpenIframe(false);
                }}
            >
                <iframe align="center" src={`http://localhost:3001/nura-desk/appointment/60271?token=${localStorage.access_token}`} width="100%" height="400px"></iframe>
                <iframe allow="camera; microphone" align="center" src={`http://localhost:3000/advanced-camera/${id}?token=${localStorage.access_token}`} width="100%" height="100%"></iframe>
            </Modal> */}
        </div>
    )
}


