/**
 * LabgensettList
 *
 *
 *
 *
 */

import { ApiUtils, BaseAPI } from 'soxo-bootstrap-core';

class LabGenSettList extends BaseAPI {
    get id() {
        return 'lgsl_id';
    }

    get getEndpoint() {
        return 'labgensettlist';
    }

    get path() {
        return `labgensettlist`;
    }

    get getName() {
        return this.modelName;
    }

    get modelName() {
        return `labgensettlist`;
    }
}

export default LabGenSettList;
