import Model from './models';

import { ApiUtils } from 'soxo-bootstrap-core';

//  import moment from 'moment-timezone';

class Clinicals extends Model {
    constructor() {
        super();

        this.fields = [
            {
                field: 'name',
                caption: 'Name',
            },
        ];

        // this.columns = ;
    }

    get id() {
        return 'cold_id';
    }

    get getEndpoint() {
        return 'opreg';
    }

    get modelName() {
        return `collection`;
    }

    /**
     * Get Vital Information
     *
     * @returns
     */
    getVitalInformation = (visit_id) => {
        return ApiUtils.get({ url: `servicebaseclinicaldettran?srvbcd_refid=${visit_id}` });
    };

    /**
     * Get Edit Information
     *
     * @returns
     */

    updateVitalInformation = (id, formBody) => {
        return ApiUtils.patch({ url: `servicebaseclinicaldettran/${id}`, formBody });
    };

    /**
     * Enter the Vital
     *
     * @returns
     */
    enterVitalInformation = (opvisit_id, values) => {
        var formBody = {
            srvbcd_refid: parseInt(opvisit_id),

            ...values,

            // "srvbcd_modmodeptr: "string",
            // "srvbcd_module": "string",
            // srvbcd_refid : srvbcd_id,
            // "srvbcd_refno": "string",
            // "srvbcd_detrefid": 0,
            // "srvbcd_accessionno": "string",
            // "srvbcd_modalityptr": "string",
            // "srvbcd_itemptr": "string",
            // "srvbcd_doctorptr": "string",
            // "srvbcd_dt": "2022-04-30T06:18:07.390Z",
            // "srvbcd_tm": "2022-04-30T06:18:07.390Z",
            // srvbcd_bmi: 0,
            // srvbcd_height: 0,
            // srvbcd_weight: 0,
            // srvbcd_bmi: 0,
            // srvbcd_bpsys: 0,
            // srvbcd_bpdia: 0,
            // srvbcd_pulse: 0,
            // srvbcd_height: values.srvbcd_height,
            // srvbcd_weight: 0,
            // srvbcd_bmi: 0,
            // srvbcd_bpsys: 0,
            // srvbcd_bpdia: 0,
            // srvbcd_pulse: 0,

            // "srvbcd_abdominalcircumference": 0,
            // "srvbcd_remarks": "string",
            // "srvbcd_extradet1": "string",
            // "srvbcd_extradet2": "string",
            // "srvbcd_extradet3": "string",
            // "srvbcd_firmid": 0,
            // "srvbcd_finyearid": 0,
            // "srvbcd_user": "string",
            // "updttm": "2022-04-30T06:18:07.390Z",
            // "srvbcd_visceralfatobesity": 0,
            // "srvbcd_doctornotes": "string",
            // "srvbcd_doctorverified": "string",
            // "srvbcd_verifieddttm": "2022-04-30T06:18:07.390Z"
        };

        return ApiUtils.post({ url: 'servicebaseclinicaldettran', formBody });
    };
}

export default Clinicals;
