import React, { useState, useEffect } from 'react';

// import bgimage from "./../../../../assets/images/background_img.png";

import logoimg from './../../../../assets/images/logo_1.png';

import healthimg from './../../../../assets/images/health_1.png';

import { Button, Input, Form, message } from 'antd';

import Newstate from './wrist_band';

import './kiosk-screen.scss';

import { Opreg } from '../../../../models';

import { ArrowRightOutlined } from '@ant-design/icons';

import ArrowIcon from './../../../../assets/icons/Icons.png';

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const states = {
    input: 'input',
    result: 'result',
    continue: 'continue',
};

export default function KioskScreen() {

    // Variable to save the guest data
    const [guest, setGuest] = useState({});


    const [ID, setID] = useState('');
    const [patientname, setPatientname] = useState('');
    const [currentState, setCurrentState] = useState(states.input);
    const [isKioskMode, setIsKioskMode] = useState(false);
    const [startDragPoint, setStartDragPoint] = useState({ x: 50, y: 50 });
    const [endDragPoint, setEndDragPoint] = useState({ x: 30, y: 30 });
    const [inputValue, setInputValue] = useState('');
    const [isClicked, setIsClicked] = useState(false);
    // const [opno, setOpNo] = useState();
    /**
     *
     *
     *
     * @param {*} e
     */

    const handleInputChange = e => {
        setInputValue(e.target.value);
    };

    const handleCancelClick = () => {
        setCurrentState(states.input);
    };
    /**
     * @
     */
    async function handleSubmit(formBody) {
        let queries = [
            {
                field: 'op_no',
                value: formBody.opno,
            },
        ];

        let result = await Opreg.get({ url: 'opreg/search', queries });
        
        if (result.length === 0) {
            message.error('Invalid op no');
        } else {
            setPatientname(result[0].op_fname + ' ' + result[0].op_lname);

            setGuest(result[0])

            setCurrentState(states.result);
        }

        // const patientname = ("ROBERT"); // Replace this with your logic to fetch the name based on inputValue
        // setPatientname(patientname);
    }

    /**
     * 
     */
    const handleContinueClick = () => {

        // Make the POST request to the server to trigger the print
        fetch('http://localhost:3000/trigger-print', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                // Pass any data you want to send to the server here
                ID,
                patientname,
                opno: guest.op_no
            }),
        })
            .then(response => {
                // Check if the response status is 200 (success)
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json(); // Parse the JSON data from the response
            })
            .then(data => {
                console.log('Server response:', data); // Log the response data
                setCurrentState(states.continue); // Move to the 'continue' state after receiving the response
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    const enterKioskMode = () => {
        setCurrentState(states.input);
        setIsKioskMode(true);
    };

    const handleDragStart = event => {
        // Store the starting point when dragging starts
        setStartDragPoint({ x: event.clientX, y: event.clientY });
    };

    const handleDragEnd = event => {
        // Store the ending point when dragging ends
        setEndDragPoint({ x: event.clientX, y: event.clientY });

        // Calculate the distance dragged (optional)
        const distanceDraggedX = endDragPoint.x - startDragPoint.x;
        const distanceDraggedY = endDragPoint.y - startDragPoint.y;
        console.log('Distance dragged (X, Y):', distanceDraggedX, distanceDraggedY);

        // Now, you can use the startDragPoint and endDragPoint for further processing
        console.log('Starting point:', startDragPoint);
        console.log('Ending point:', endDragPoint);
    };

    const handleDrag = event => {
        console.log({ event });
        event.preventDefault();
        const dragThreshold = 20;
        const distanceX = event.clientX - startDragPoint.x;
        const distanceY = event.clientY - startDragPoint.y;

        // Determine if the drag is primarily horizontal or vertical based on the distance comparison
        if (Math.abs(distanceX) < dragThreshold || Math.abs(distanceY) < dragThreshold) {
            // Only consider it a valid drag if the movement is significant enough
            console.log('Valid Drag');
            if (isKioskMode && currentState === states.input) {
                setIsKioskMode(!isKioskMode);
            }
        } else {
            // Ignore the drag event if it's not significant enough
            console.log('Ignored Drag');
        }
    };

    // const isInputEmpty = ID.trim() === "";

    // useEffect(() => {
    //   if (currentState === states.continue) {
    //     const timeoutId = setTimeout(() => {
    //       setCurrentState(states.input);
    //     }, 5000);

    //     return () => clearTimeout(timeoutId);
    //   }
    // }, [currentState]);

    const renderContent = () => {
        switch (currentState) {
            case states.input:
                return (
                    <div
                        className={`drag-container ${isKioskMode ? 'drag-container-kiosk-mode' : ''}`}
                        onDragStart={handleDragStart}
                        onDragEnd={handleDragEnd}
                        onDrag={handleDrag}
                        draggable="false"
                    >
                        <div
                            className="main_container"
                            style={{
                                margin: 0,
                            }}
                        >
                            <div className="container_1">
                                <Form className="form" onFinish={handleSubmit}>
                                    <h4 className="title">ENTER YOUR NURA ID OR BOOKING ID</h4>
                                    <Form.Item name="opno">
                                        <Input name="opno" className="input" value={inputValue} onChange={handleInputChange}></Input>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            block
                                            type="primary"
                                            htmlType="submit"
                                            // disabled={isInputEmpty}
                                            className="btn btn-primary"
                                        >
                                            SEARCH
                                            <img src={ArrowIcon} className="arrow-icon"></img>
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                            <div className="container_2">
                                <div className="logo_container">
                                    <img src={logoimg} className="logo_img" alt="logo_pic" />
                                </div>
                                <div className="health_container">
                                    <img src={healthimg} className="health_img" alt="health_pic" />
                                </div>
                                <div className="btn_kiosk">
                                    <Button
                                        type="primary"
                                        className="btn"
                                        htmlType="submit"
                                        style={{
                                            width: '180px',
                                            height: '45px',
                                            bottom: 0,
                                            right: 0,
                                            borderRadius: '4px',
                                        }}
                                        onClick={enterKioskMode}
                                        disabled={isKioskMode}
                                    >
                                        Enter Kiosk Mode
                                        <ArrowRightOutlined />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            case states.result:
                return (
                    <div
                        className="main_container"
                        style={{
                            margin: 0,
                        }}
                    >
                        <div className="container_1">
                            <Form className="form_result" action="http://localhost:3000/trigger-print" method="post">
                                <h4 className="title">ENTER YOUR NURA ID OR BOOKING ID</h4>
                                <Form.Item>
                                    <Input
                                        className="input"
                                        value={inputValue}
                                        readOnly
                                        style={{
                                            fontSize: '19px',
                                            fontWeight: '600',
                                            color: '#333',
                                            backgroundColor: '#f0f0f0',
                                            border: '1px solid #ccc',
                                            padding: '8px',
                                            borderRadius: '4px',
                                            /* Add more styles as needed */
                                        }}
                                    />
                                    <h4 className="title">NAME</h4>
                                    <p className="patient-name">{patientname}</p>
                                    <Button block type="primary" htmlType="submit" onClick={handleContinueClick} className="btn btn-primary">
                                        CONTINUE
                                        <img src={ArrowIcon} className="arrow-icon"></img>
                                    </Button>
                                    <Button block type="primary" htmlType="submit" onClick={handleCancelClick} className=" btn btn-secondary cancel-btn">
                                        CANCEL
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                        <div className="container_2">
                            <div className="logo_container">
                                <img src={logoimg} className="img" alt="logo_pic" />
                            </div>
                            <div className="health_container">
                                <img src={healthimg} className="health_img" alt="health_pic" />
                            </div>
                        </div>
                    </div>
                );
            case states.continue:
                return <Newstate inputValue={inputValue} />;
            default:
                return null;
        }
    };

    return <div className="kiosk-screen">{renderContent()}</div>;
}
