/***
 *
 *
 * @description
 * @author jinshakappil
 */
import React, { useEffect, useState, useRef } from 'react';

import { Opvisits, ModalityGroups, DocFileTypes, DocDetails } from '../../../../models';

import { Typography, Card, Button, message, Form, Skeleton, Input, Modal, Tabs, Select, Upload, DatePicker } from 'antd';

// import { UploadOutlined } from '@ant-design/icons';

import { Editor } from '@tinymce/tinymce-react';

import dayjs from 'dayjs';

import moment from 'moment-timezone';

import { Location, PdfViewer } from 'soxo-bootstrap-core';

import './emr-screen.scss';

import { InvestigationCard } from './../investigation/investigation';

import MedicineCard from '../medicine/medicine';

import VisitInfo from './../visit-info/visit-info';

import PatientHistory from './../patient-visits/patient-visits';

import ClinicalInformation from './../clinical-information/clinical-information';

import LabSummary from './../../../lab/components/lab-summary/lab-summary';

import ResultDetail from '../../../lab/components/result-detail/result-detail';

import RadiologySummary from './../../../imaging/components/radiology-summary/radiology-summary';

import { ReloadOutlined } from '@ant-design/icons';

import { DocumentUpload } from './documents/documents';

const { Title } = Typography;

const { TextArea } = Input;

const { TabPane } = Tabs;

const videoImage = require('./../../../../assets/images/video.png');

export default function EMRscreen({
    match,
    presentIllnessDisable,
    diagnosisDisable,
    radiology,
    includeStock,
    template,
    pageTitle,
    printCaptions,
    mode,
    consultType,
    consultationWidget,
    itemMode,
    labVisit,
    doctorFilter,
    histories,
    // The template should be loaded accoding to department
    deptPointer,
    // The template with which on submission the report has to be printed
    templateCode,
    disable_expectedDeliveryDate
}) {


    let editorRef = useRef();

    const [cartItems, setcartItems] = useState({});

    var [selectedItems, setSelectedItems] = useState([]);

    //Medicine section
    const [reportModalVisible, setReportModalVisible] = useState(false);

    var [selectedMedicine, setSelectedMedicine] = useState([]);

    const [cartMedicine, setcartMedicine] = useState([]);

    const [btnloading, setBtnloading] = useState(false);

    const [btnloader, setBtnloader] = useState(false);
    // Editor section
    const [content, setContent] = useState('');

    const [initialContent, setInitialContent] = useState('');

    const [templateLoading, setTemplateLoading] = useState(true);

    const [templates, setTemplates] = useState('');

    const [patient, setPatient] = useState({});

    const [loading, setLoading] = useState(false);

    var [byteArray, setByteArray] = useState([]);

    const [visitInfo, setVisitInfo] = useState({});

    const [billid, setBillid] = useState();

    const [labModelVisible, setLabModelVisible] = useState(false);

    const [historyModelVisible, setHistoryModelVisible] = useState(false);

    const [radiologyModelVisible, setRadiologyModelVisible] = useState(false);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const [form] = Form.useForm();

    const [fileURL, setFileURL] = useState();

    let urlParams = Location.search();

    const { params } = match;

    // var [templatecode, setTemplateCode] = useState([]);


    /**
     * Get call
     */
    useEffect(() => {
        loadData();

    }, []);

    function loadData() {
        getBillID(urlParams.op_no);

        getConsultDetails(params.id);

        getPatient(params.id);
    }

    async function getBillID(id) {
        const result = await Opvisits.getBillID(id);

        if (result && result.result.length) {
            setBillid(result.result[0].opb_id);
        }
    }

    /**
     *  GET Opreg for visitinfo
     */

    async function getPatient() {
        let opreg;

        const result = await Opvisits.getPatient(params.id);

        if (result.result.length) {
            opreg = result.result[0].op_reg;

            setVisitInfo(opreg);
        }

        getTemplateMaster(template, deptPointer, opreg);
    }

    /**
     *
     * Loading consulted data . include patient medicine and packages/items
     */

    function getConsultDetails(visit_id) {
        setLoading(true);
        setcartItems([]);
        setSelectedItems([]);
        setcartMedicine([]);
        setSelectedMedicine([]);
        Opvisits.getConsultDetails(visit_id).then((result) => {
            // privious consult details of items .
            if (result.result[0]) {
                var a = [];

                let investigation = result.result[0].patient_investigation_details;

                investigation.map((ele) => {
                    a.push({
                        name: ele.pid_desc,
                        date: moment(ele.pid_invdonedt),
                        remarks: ele.pid_remarks,
                        itm_code: ele.pid_code,
                        pid_isbilled: ele.pid_isbilled,
                    });
                });
                setcartItems(a);
                setSelectedItems(a);
                // privious consult details of medicine
                var b = [];
                let medicine = result.result[0].patient_medicine_details;
                medicine.map((ele) => {
                    b.push({
                        name: ele.pmd_desc,
                        pm_cod: ele.pmd_code,
                        direction: ele.pmd_direction,
                        days: ele.pmd_days,
                        remarks: ele.pmd_remarks,
                        type: ele.pmd_catdesc,
                    });
                });
                setcartMedicine(b);

                setSelectedMedicine(b);
                setLoading(false);

                let editor = result.result[0].pcd_htmlreportdata1;
                setContent(editor);

                if (result.result.length)
                    form.setFieldsValue({ pcd_preillness: result.result[0].pcd_preillness, pcd_finaldiag: result.result[0].pcd_finaldiag });

                // Set fields value
            } else {
                setLoading(false);
            }

            //function call get templates
            // getTemplateMaster('CTLAAS001', visitInfo);
            if (result.result.length) {
                form.setFieldsValue({ pcd_preillness: result.result[0].pcd_preillness, pcd_finaldiag: result.result[0].pcd_finaldiag });
            }

            //  getReport(visit_id);
        });
    }

    /**
     * onsubmit
     */

    function onSubmit(value) {
        // formbody for investigation

        setBtnloading(true);

        // Enable the hidden elements
        editorRef.current.editor.contentStyles = `.replaceable-content{
            display:inline-block;
        }`;

        selectedItems = selectedItems.map((item) => {
            return {
                pid_code: item.itm_code,

                pid_mode: mode,

                pid_consulttype: consultType,

                pid_desc: item.name,

                pid_result: null,

                pid_reference: null,

                pid_invdonedt: moment(item.date),

                pid_remarks: item.remarks || null,

                pid_filebatchid: null,

                pid_isbilled: null,

                pid_billid: null,
            };
        });
        // formbody for medicine
        selectedMedicine = selectedMedicine.map((record) => {
            return {
                pmd_catcode: record.value,

                pmd_catdesc: null,

                pmd_code: record.pm_cod,

                pmd_desc: record.name,

                pmd_medtype: record.category,

                pmd_medtypedesc: record.medicinetype,

                pmd_direction: record.direction,

                pmd_days: record.days,

                pmd_catdesc: record.type,

                pmd_medstop: 'N',

                pmd_remarks: record.remarks,
            };
        });

        let formbody = {
            opno: urlParams.op_no,

            emode: mode,

            consulttype: consultType,

            consultid: params.id,

            pcd_preillness: value.pcd_preillness,

            pcd_finaldiag: value.pcd_finaldiag,

            code: '',

            desc: '',

            // To update opv_edd we pass opv_id also to api
            opv_edd: value.opv_edd,

            opv_id: params.id,

            pcd_otherdet: null,

            // pcd_htmlreportdata1: content,
            pcd_htmlreportdata1: editorRef.current.editor.getDoc().documentElement.innerHTML,

            template_code: templateCode,

            pcd_otherphydetxml: [
                {
                    height: '160',
                    weight: '60',
                },
            ],

            // template_code: '83',

            investigation: selectedItems,

            medicine: selectedMedicine,
        };

        Opvisits.patientInvestigation(formbody)
            .then((result) => {
                /**
                 * This condition used to preview report After submiting data
                 */
                if (result.result) {
                    message.success('Success');

                    var res = result.result.pdf.data;

                    var bytearray = Object.keys(res);
                    var arrayelement = Object.values(res);

                    var uint8Array = new Uint8Array(bytearray.length);

                    for (var i = 0; i < uint8Array.length; i++) {
                        uint8Array[i] = arrayelement[i];
                    }

                    for (var i = 0; i < bytearray; i++) {
                        var ascii = arrayelement.charCodeAt(i);
                        uint8Array[i] = ascii;
                    }

                    setReportModalVisible(true);

                    var report = new Blob([uint8Array], {
                        type: 'application/pdf',
                    });

                    setFileURL(URL.createObjectURL(report));

                    setByteArray({ data: uint8Array, fileName: patient.op_reg.op_fname });

                    setBtnloading(false);

                    return uint8Array;
                } else {
                    setBtnloading(false);
                    Location.navigate({ url: `/candidate` });
                    message.success('Success');
                }
            })
            .catch(() => {
                setBtnloading(false);
            });
    }

    /**
     * Editor
     * @param {} evt
     * @param {*} editor
     */
    const handleInit = (evt, editor) => {
        const editorContent = editor.getContent();

        setContent(editorContent);

        // setContent(editor.getDoc().documentElement.innerHTML);
    };

    const handleUpdate = (value, editor, ...props) => {
        const editorContent = editor.getContent();

        setContent(editorContent);
        // const editorContent = editor.getDoc().documentElement.innerHTML
        // setContent(editorContent);
    };

    /**
     * Function triggered when insertTemplate dialog box is opened
     */
    const handleBeforeAddUndo = async (evt, editor) => {
        var collection = document.getElementsByClassName('tox-button');

        // To change Button name in Editor to Edit (when selecting template)
        setTimeout(async () => {
            if (collection.length) {
                collection[2].innerText = 'Edit';
            }
        }, 0);

        const length = editor.getContent();
        // note that this is the opposite test as in handleUpdate
        // because we are determining when to deny adding an undo level
    };

    /**
     * Get the templates
     *
     * @param {*} item
     * @param {*} patient
     */

    function getTemplateMaster(item, deptPointer, visitInfo) {
        // Get the templates
        ModalityGroups.getEMRTemplates(item, deptPointer).then((result) => {
            // templateCode = result[0].templatemas[0].tmpl_code

            var arr = result.result;

            // result.forEach((record) => {
            //     if (record.templatemas) {

            //         arr = [].concat(arr, record.templatemas);
            //     }
            // });

            if (visitInfo) {
                arr = arr.map((item, index) => {
                    // setTemplateCode(item.tmpl_code);

                    return {
                        title: `${index + 1} ${item.tmpl_desc}`,
                        description: '',
                        // content: `${item.tmpl_template}`,
                        content: `${item.tmpl_template
                            .replaceAll('{{name}}', visitInfo.op_fname)
                            .replaceAll('{{opno}}', visitInfo.op_no)
                            .replaceAll('{{age}}', visitInfo.op_age)
                            .replaceAll('{{gender}}', visitInfo.op_gender)
                            .replaceAll('{{date}}', moment.tz(visitInfo.op_time, '').format('DD-MM-YYYY hh:mm a'))}`,
                    };
                });
            }

            setTemplates([...arr]);

            setTemplateLoading(false);
        });
    }

    /**
     *
     * Get Byte Array for the Report
     */

    function getReport(visit_id) {
        var formBody = {
            visit_id: visit_id,
        };
        Opvisits.getReport(formBody).then(async (result) => {
            if (result && result.result) {
                var res = result.result.data;
                var bytearray = Object.keys(res);
                var arrayelement = Object.values(res);

                var uint8Array = new Uint8Array(bytearray.length);

                for (var i = 0; i < uint8Array.length; i++) {
                    uint8Array[i] = arrayelement[i];
                }

                for (var i = 0; i < bytearray; i++) {
                    var ascii = arrayelement.charCodeAt(i);
                    uint8Array[i] = ascii;
                }

                setByteArray({ data: uint8Array });

                return uint8Array;
            } else {
                setByteArray([]);
            }
        });
    }

    /**
     * View the Report
     */

    function viewReport() {
        setReportModalVisible(true);
    }


    const showhistoryModal = () => {
        setHistoryModelVisible(true);
    };

    const disabledDate = (current) => {
        // Can not select days before today 
        return moment().add(-1, 'days') >= current;
    };

    // const handleDocument = (e) => {
    //     let files = e.target.files[0];
    //     setDocument(files);
    // };

    return (
        <div className="emr-screen">
            <div className="card">
                <div className="page-header">
                    {/** If the histories returns true then hide the headings part(Show the emr screen contains patient history tab) else show headings part.*/}
                    {!histories ? (
                        <div>
                            {pageTitle ? (
                                // For Matria the title of EMR screen should be Consultation
                                <Title level={3}>Consultation</Title>
                            ) : (
                                <Title level={3}>Electronic Medical Record</Title>
                            )}
                            <p>Please find the below details of the patient</p>
                        </div>
                    ) : (null)}
                    <div className="page-actions">
                        {byteArray && byteArray.length === 0 ? null : (
                            <Button className="preview-report-trigger" loading={btnloader} type="dashed" onClick={viewReport}>
                                Preview Report{' '}
                            </Button>
                        )}

                        <Button className="preview-report-trigger" loading={btnloader} onClick={loadData}>
                            Refresh
                        </Button>
                    </div>
                </div>
                <div className="emr-sections">
                    {/* Left Section */}
                    <div className="section-1">
                        {/* Patient Visit Information */}

                        {/** If the histories returns true then hide the visit info(Show the emr screen contains patient history tab) else show visit info.*/}
                        {!histories ? (
                            <VisitInfo visit_id={params.id} patient={patient} setPatient={setPatient} />
                        ) : (null)}
                        {/* Patient Visit Information Ends */}
                        {consultationWidget === true ? (
                            <>
                                <a
                                    className="card consult-online"
                                    target="_blank"
                                    href={
                                        'https://medicant-tele.web.app/waiting-room/24QwKt5M1Ke6ts9aobJo?process_transaction_id=DzZYyzZijzMGvAydMirQ'
                                    }
                                    style={{ width: '100%' }}
                                >
                                    <img src={videoImage} />

                                    <div>
                                        <Title level={5}>Consult Online</Title>
                                        <p>Get on a call with the patient to follow up with him</p>
                                    </div>
                                </a>
                            </>
                        ) : null}

                        {/** Patient privious history section start  */}
                        {!labVisit ? (
                            <PatientHistory
                                histories={histories}
                                op_number={urlParams.op_no}
                                doctorFilter={doctorFilter}
                                callback={(values) => {

                                    getConsultDetails(values)
                                }}
                                visit_id={params.id}
                            />
                        ) : null}
                        {/** Patient privious history section ends  */}
                        {/** If the histories returns true then show the button (Show the emr screen contains patient history tab) else hide the button.*/}
                        {!histories && !labVisit ? (
                            <div className='history-class'>
                                <Button className='history-button' type="primary" onClick={showhistoryModal}>Patient History</Button>
                            </div>
                        ) : (null)}
                        {/** Clinical information section start */}

                        {!labVisit ? <ClinicalInformation histories={histories} visit_id={params.id} /> : null}
                        {/** Clinical information section Ends */}
                    </div>

                    {/** Investigation section start */}
                    <div className="section-2">
                        {!labVisit ? (
                            <Tabs type="card" defaultActiveKey="0">
                                <TabPane tab="EMR" key="0">
                                    <div>
                                        {/** editor section start  */}

                                        <div className="card">
                                            {templateLoading ? (
                                                <Card className="editor">
                                                    <Skeleton active />
                                                </Card>
                                            ) : (
                                                <Editor
                                                    disabled={histories}
                                                    ref={editorRef}
                                                    // apiKey="y7bj7rt02vqi84dggp8vydsl2tu4e21rzomlej8at6ynx9ja"
                                                    tinymceScriptSrc={process.env.PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                                    initialValue={initialContent.pmr_report}
                                                    value={content}
                                                    onInit={handleInit}
                                                    onEditorChange={handleUpdate}
                                                    onBeforeAddUndo={handleBeforeAddUndo}
                                                    init={{
                                                        readonly: 1,
                                                        // selector: 'textarea',
                                                        height: 500,
                                                        promotion: false,
                                                        branding: false,
                                                        // importcss_append: true,
                                                        // content_css: './template.css',
                                                        // paste_block_drop: false,
                                                        plugins: [
                                                            'template',
                                                            'searchreplace',
                                                            'visualblocks',
                                                            'code',
                                                            'fullscreen',
                                                            'insertdatetime',
                                                            'media',
                                                            'table',
                                                            'paste',
                                                            'code',
                                                            'help',
                                                            'wordcount',
                                                            'print',
                                                        ],
                                                        toolbar:
                                                            ' undo redo | formatselect | ' +
                                                            'bold italic backcolor | alignleft aligncenter ' +
                                                            'alignright alignjustify | template fullscreen | bullist numlist outdent indent  ' +
                                                            'removeformat | help | ' +
                                                            'print',

                                                        // skin: false,
                                                        content_css: false,
                                                        content_style: ` @page {
                                            size: A4;
                                            margin: 0;
                                        }
                                        @media print {
                                            html,
                                            body {
                                                width: 210mm;
                                                height: 297mm;
                                            }
                                            /* ... the rest of the rules ... */
                                        }
                                        * {
                                            box-sizing: border-box;
                                        }
                                
                                        body {
                                            background: #eee;
                                            font-family: Arial, Helvetica, sans-serif;
                                        }
                                        .container {
                                            background-color: white;
                                            padding: 40px;
                                        }
                                        .report {
                                            width: 970px !important;
                                            margin: 50px auto;
                                        }
                                
                                        .logo {
                                            float: left;
                                            padding-right: 10px;
                                            margin: 10px auto;
                                        }
                                
                                        .customercard {
                                            border: 1px solid black;
                                            padding: 10px;
                                        }
                                
                                        .itemscard {
                                            min-width: 98.5%;
                                            margin-left: 0.5%;
                                        }
                                
                                        .logo {
                                            max-width: 5rem;
                                            margin-top: -0.25rem;
                                        }
                                
                                        .invDetails {
                                            margin-top: 0rem;
                                        }
                                
                                        .pageTitle {
                                            margin-bottom: -1rem;
                                        }
                                        .table {
                                            width: 100%;
                                            vertical-align: bottom;
                                        }
                                
                                        .table td {
                                            padding: 5px 5px;
                                            font-size: 16px;
                                        }
                                
                                        .table.table-sm td {
                                            padding: 2px 5px;
                                            font-size: 12px;
                                        }
                                        .table.border-0 tr td:first-child {
                                            padding-left: 0;
                                        }
                                        .table.border-0 tr td:last-child {
                                            padding-right: 0;
                                        }
                                        .table-bordered,
                                        .table-bordered td,
                                        .table-bordered th {
                                            border: 1px solid black;
                                            border-collapse: collapse;
                                            padding: 5px;
                                        }
                                        .d-flex {
                                            display: flex;
                                        }
                                        .justify-content-between {
                                            justify-content: space-between !important;
                                        }
                                        .flex-grow-1 {
                                            flex-grow: 1 !important;
                                        }
                                        .flex-column {
                                            flex-direction: column !important;
                                        }
                                        .align-items-end {
                                            align-items: flex-end !important;
                                        }
                                        .val-underline {
                                            width: 100%;
                                            min-height: 15px;
                                        }
                                        .mr-10 {
                                            margin-right: 10px;
                                        }
                                        .mr-15 {
                                            margin-right: 15px;
                                        }
                                        .mr-20 {
                                            margin-right: 20px;
                                        }
                                        .mt-10 {
                                            margin-top: 10px;
                                        }
                                        .mb-10 {
                                            margin-bottom: 10px;
                                        }
                                        .mb-20 {
                                            margin-bottom: 20px;
                                        }
                                        .mb-30 {
                                            margin-bottom: 30px;
                                        }
                                        .pl-10 {
                                            padding-left: 30px;
                                        }
                                        .pr-30 {
                                            padding-right: 30px;
                                        }
                                        .px-100 {
                                            padding-left: 100px;
                                            padding-right: 100px;
                                        }
                                        h1 {
                                            font-size: 32px;
                                        }
                                        .text-center {
                                            text-align: center;
                                        }
                                        .row {
                                            display: flex;
                                        }
                                        .col {
                                            flex-grow: 1;
                                            flex-shrink: 0;
                                            flex-basis: 0%;
                                        }
                                        .min-h-50 {
                                            min-height: 50px;
                                        }
                                        .val-description {
                                            font-size: 14px;
                                        }
                                        .footer p {
                                            font-size: 13px;
                                            color: rgb(29, 29, 29);
                                            margin: 0 0 5px 0;
                                        }
                                        .check-box {
                                            display: inline-block;
                                        }
                                        .check-box .box {
                                            position: relative;
                                            display: inline-block;
                                            border: 1px solid black;
                                            border-radius: 2px;
                                            width: 18px;
                                            height: 18px;
                                            margin: 0 3px;
                                            vertical-align: bottom;
                                        }
                                        .check-box .box.ticked:before {
                                            content: '?';
                                            position: absolute;
                                            left: 2px;
                                            right: 0;
                                            top: -3px;
                                            bottom: 0;
                                            margin: auto;
                                        }
                                        .check-box .val {
                                            font-size: 14px;
                                            color: rgb(29, 29, 29);
                                        }
                                        .card-plan {
                                            width: 350px;
                                            height: 300px;
                                            border: 1px solid black;
                                        }
                                        .card-header {
                                            padding: 5px;
                                            width: 100%;
                                            background-color: grey;
                                            text-align: center;
                                        }
                                        .float-left {
                                            float: left;
                                        }
                                        .float-right {
                                            float: right;
                                        }
                                        .card-body {
                                            padding: 15px;
                                        }

                                            .replaceable-content{
                                                display:none
                                            }
                                        
                                        
                                        `,
                                                        // template_cdate_classes: 'cdate creationdate',
                                                        // template_mdate_classes: 'mdate modifieddate',
                                                        // template_selected_content_classes: 'selcontent',
                                                        template_cdate_format: '%m/%d/%Y : %H:%M:%S',
                                                        template_mdate_format: '%m/%d/%Y : %H:%M:%S',
                                                        // template_replace_values: {
                                                        //     fullName: 'Jack Black',
                                                        //     opno: '991234',
                                                        // },
                                                        // content_css: 'editor',
                                                        templates: templates,
                                                    }}
                                                />
                                            )}
                                        </div>

                                        {/* <Title level={3}>Results</Title> */}

                                        <Form
                                            name="basic"
                                            layout="vertical"
                                            initialValues={{
                                                remember: true,
                                            }}
                                            form={form}
                                            onFinish={onSubmit}
                                            onFieldsChange={(fields) => { }}
                                        >
                                            {/* Investigation Section */}
                                            <InvestigationCard
                                                histories={histories}
                                                cartItems={cartItems}
                                                setcartItems={setcartItems}
                                                selectedItems={selectedItems}
                                                setSelectedItems={setSelectedItems}
                                                loading={loading}
                                                getConsultDetails={getConsultDetails}
                                                itemMode={itemMode}
                                            />
                                            {/* Investigation Section Ends */}

                                            {/* Medicine Section Starts */}
                                            <MedicineCard
                                                histories={histories}
                                                selectedMedicine={selectedMedicine}
                                                setSelectedMedicine={setSelectedMedicine}
                                                setcartMedicine={setcartMedicine}
                                                cartMedicine={cartMedicine}
                                                loading={loading}
                                                includeStock={includeStock}
                                            />                                
                                            {/**Expected delivery date starts */}
                                            {/** If the disable_expectedDeliveryDate returns true then disable expected delivery date(Show the emr screen contains patient history tab) else enable expected delivery date.*/}
                                            {disable_expectedDeliveryDate ? null :
                                                <div className="card">
                                                    <Form.Item label={'Expected Delivery Date'} name="opv_edd">
                                                        <DatePicker disabled={histories} format={'DD/MM/YYYY'} />
                                                    </Form.Item>
                                                </div>
                                            }

                                            {/**Expected delivery date starts */}

                                            {/** If the histories returns true then disable expected delivery date(Show the emr screen contains patient history tab) else enable expected delivery date.*/}
                                            {!histories ? (
                                            <div className="card">
                                                <Form.Item label={'Expected Delivery Date'} name="opv_edd">
                                                    <DatePicker
                                                        disabledDate={disabledDate}
                                                        // disabled={histories}
                                                        format={'DD/MM/YYYY'} />
                                                </Form.Item>
                                            </div>
                                            ):null}
                                            {/**Expected delivery date ends */}

                                            {presentIllnessDisable ? null : (
                                                <div className="card">
                                                    <>
                                                        <Form.Item
                                                            name="pcd_preillness"
                                                            label={'Present illness'}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'Please Enter Present illness',
                                                                },
                                                                {
                                                                    max: 1000,
                                                                    message: 'Length cannot exceed 1000 characters !',
                                                                },
                                                                {
                                                                    pattern: /^[a-zA-Z\., ]*$/g,
                                                                    message: 'Please Enter Valid Present illness !',
                                                                },
                                                                // {
                                                                //     max: 1000,
                                                                //     message: 'Please Enter 1000 Present illness !',
                                                                // },
                                                            ]}
                                                        >
                                                            {/** If the histories returns true then edit is disabled (Show the emr screen contains patient history tab) else edit enabled.*/}
                                                            <TextArea disabled={histories} rules={[{ required: true, message: 'Please enter your Present illness' }]} />
                                                        </Form.Item>
                                                    </>
                                                </div>
                                            )}

                                            {radiology ? (
                                                <>
                                                    {/* <div className="card report-card">
                                                        <div className="car-item">
                                                            <img
                                                                className="card-icon"
                                                                src={require(`../../../../assets/icons/external-lab-back-to-school-flat-02-chattapat-.png`)}
                                                            />
                                                        </div>
                                                    </div> */}

                                                    {radiology ? (
                                                        <>
                                                            <div className="card report-card">
                                                                <div className="car-item">
                                                                    <img
                                                                        className="card-icon"
                                                                        src={require(`../../../../assets/icons/external-lab-back-to-school-flat-02-chattapat-.png`)}
                                                                    />

                                                                    <h3>Laboratory Details</h3>
                                                                </div>

                                                                <Button type="primary" ghost onClick={() => setLabModelVisible(true)}>
                                                                    View Details
                                                                </Button>
                                                            </div>
                                                            <div className="card report-card">
                                                                <div className="car-item">
                                                                    <img
                                                                        className="card-icon"
                                                                        src={require(`../../../../assets/icons/radiology1.png`)}
                                                                    />

                                                                    <h3>Radiology Details</h3>
                                                                </div>

                                                                <Button type="primary" ghost onClick={() => setRadiologyModelVisible(true)}>
                                                                    View Details
                                                                </Button>
                                                            </div>
                                                        </>
                                                    ) : null}
                                                    {/* {billid && <LabSummary bill_id={billid} />} */}

                                                    {/* {billid && <RadiologySummary opno={urlParams.op_no} accessionno={5999}/>} */}

                                                    {diagnosisDisable ? null : (
                                                        <div className="card">
                                                            <Form.Item
                                                                name="pcd_finaldiag"
                                                                label="Diagnosis"
                                                                rules={[
                                                                    {
                                                                        required: true,
                                                                        message: 'Please Enter Diagnosis',
                                                                    },
                                                                    {
                                                                        max: 1000,
                                                                        message: 'Length cannot exceed 1000 characters !',
                                                                    },
                                                                    {
                                                                        pattern: /^[a-zA-Z\., ]*$/g,
                                                                        message: 'Please Enter Valid Diagnosis !',
                                                                    },
                                                                ]}
                                                            >
                                                                <TextArea rules={[{ required: true, message: 'Please enter your diagnosis' }]} />
                                                            </Form.Item>
                                                        </div>
                                                    )}

                                                    {/* Medicine Section Ends */}

                                                    {/** editor section end */}
                                                </>
                                            ) : null}

                                            {/** If the histories returns true then show the button (Show the emr screen contains patient history tab) else hide the button.*/}
                                            {!histories ? (
                                                <Form.Item>
                                                    <Button loading={btnloading} type="primary" htmlType="submit">
                                                        Save
                                                    </Button>
                                                </Form.Item>
                                            ) : null}
                                        </Form>
                                    </div>
                                </TabPane>

                                <TabPane tab="Others" key="1">
                                    <DocumentUpload histories={histories} op_no={urlParams.op_no} />
                                </TabPane>
                            </Tabs>
                        ) : (
                            <Tabs type="card" defaultActiveKey="0">
                                <TabPane tab="Others" key="0">
                                    <DocumentUpload histories={histories} labVisit={labVisit} op_no={urlParams.op_no} />
                                </TabPane>
                            </Tabs>
                        )}
                        {/* Modal to add document starts */}
                        <Modal
                            title="Add Document"
                            visible={isModalVisible}
                            onOk={() => setIsModalVisible(false)}
                            onCancel={() => setIsModalVisible(false)}
                        >
                            {/* <UploadFile /> */}
                        </Modal>

                        {/* Modal to show patient history */}
                        <Modal
                            width={'100%'}
                            destroyOnClose={true}
                            title="Patient History"
                            visible={historyModelVisible}
                            cancelButtonProps={{ style: { display: 'none' } }}
                            okText="Close"
                            onOk={() => {
                                setHistoryModelVisible(false);
                            }}
                            onCancel={() => {
                                setHistoryModelVisible(false);
                            }}
                        >

                            <Histories
                                match={match}
                                pageTitle={pageTitle}
                                presentIllnessDisable={presentIllnessDisable}
                                diagnosisDisable={diagnosisDisable}
                                radiology={radiology}
                                includeStock={includeStock}
                                template={template}
                                printCaptions={printCaptions}
                                mode={mode}
                                consultType={consultType}
                                consultationWidget={consultationWidget}
                                itemMode={itemMode}
                                labVisit={labVisit}

                            />
                        </Modal>
                        {/* Modal to show patient history ends*/}

                        {/* Modal to add document starts */}

                        <Modal
                            title=""
                            style={{ top: 30 }}
                            visible={labModelVisible}
                            okText="Close"
                            cancelButtonProps={{ style: { display: 'none' } }}
                            onOk={() => setLabModelVisible(false)}
                            onCancel={() => setLabModelVisible(false)}
                            width="85%"
                        >
                            {billid && <ResultDetail mode="visit" bill_id={match.params.id} />}
                        </Modal>

                        <Modal
                            destroyOnClose={true}
                            title=""
                            style={{ top: 30 }}
                            visible={radiologyModelVisible}
                            okText="Close"
                            cancelButtonProps={{ style: { display: 'none' } }}
                            onOk={() => setRadiologyModelVisible(false)}
                            onCancel={() => setRadiologyModelVisible(false)}
                            width={900}
                        >
                            {billid && <Summary match={match} />}
                        </Modal>

                        {/* Modal for Viewing Report */}
                        <Modal
                            bodyStyle={{ height: '500px' }}
                            width={'55%'}
                            destroyOnClose={true}
                            title="Preview Report"
                            visible={reportModalVisible}
                            cancelButtonProps={{ style: { display: 'none' } }}
                            okText="Close"
                            onOk={() => {
                                setReportModalVisible(false);
                            }}
                            onCancel={() => {
                                setReportModalVisible(false);
                            }}
                        >
                            {/* <PdfViewer printCaption={printCaptions} url={byteArray} /> */}

                            <iframe align="center" src={fileURL} width="100%" height="100%"></iframe>
                        </Modal>

                        {/* Modal for Viewing Report */}
                    </div>
                </div>
            </div>
        </div>
    );
}

function Summary({ match }) {
    const [loading, setLoading] = useState(false);

    const [radiologyData, setRadiologyData] = useState([]);

    useEffect(() => {
        //Patiant list get function

        getModalityDetails();
    }, []);

    function getModalityDetails() {
        // Load the details

        setLoading(true);

        ModalityGroups.getVisitBills({
            visit_id: match.params.id,

            mode: `'TECH',
             'RAD'`,
        }).then((result) => {
            setRadiologyData(result.data);

            setLoading(false);
        });
    }

    return (
        <>
            <div className="page-header">
                <div>
                    <Title level={3}>Radiology Summary</Title>

                    <p className="size-hint">{loading ? 'Loading data' : 'Please find below results'}</p>
                </div>

                <Button
                    onClick={() => {
                        getModalityDetails();
                    }}
                    type="secondary"
                    size={'small'}
                >
                    <ReloadOutlined />
                </Button>
            </div>
            <div className="">
                {loading ? (
                    <Skeleton />
                ) : (
                    <>
                        {radiologyData.map((record) => {
                            return record.modalityPatientStatusTran.map((data) => {
                                var result = data.serviceResultTran;

                                return (
                                    <RadiologySummary
                                        serviceResultMas={result && result.serviceResultMas ? result.serviceResultMas : null}
                                        opno={record.opb_opno}
                                        accessionno={data.mpst_accessionno}
                                    />
                                );
                            });
                        })}
                    </>
                )}
            </div>
        </>
    );
}


/**
 * Show patient histort tab
 * @returns
 */
function Histories({ match, pageTitle, presentIllnessDisable, diagnosisDisable, radiology, includeStock, template, printCaptions, mode, consultType, consultationWidget, itemMode, labVisit }) {

    return (

        <div>
            <EMRscreen
                pageTitle={pageTitle}
                presentIllnessDisable={presentIllnessDisable}
                diagnosisDisable={diagnosisDisable}
                radiology={radiology}
                includeStock={includeStock}
                template={template}
                printCaptions={printCaptions}
                mode={mode}
                consultType={consultType}
                consultationWidget={consultationWidget}
                itemMode={itemMode}
                labVisit={labVisit}
                histories={true} // Variabe that maintains to show emr screen which contains only patient history
                match={match} />
        </div>
    )
}
