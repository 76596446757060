/**
 * All Models in the application
 */

import Collection from './collections';

import CollectionDetail from './collection-details';

import Appointment from './appointments';

import Doctor from './doctor';

import RegistrationModel from './registrations';

import Bill from './bill';

import BillDetail from './bill-details';

import Sample from './samples';

import Report from './reports';

import ResultEntryModel from './result-entry';

import SCRFilesLinks from './scrfileslinks'

import ModalityGroup from './modality-group';

import MedicalReport from './medical-reports';

import ServiceResultMasterModel from './service-result-master';

import OutBox from './outbox';

import Clinical from './clinical';

import Item from './items';

import Referrer from './referrers';

import Category from './categories';

import Customer from './customers';

import PreregModel from './prereg';

import OpregModel from './opreg';

import EventTransaction from './event-transactions';

import GeneralList from './general-list';

import RewardTransaction from './reward-transactions';

import TemplateMaster from './template-masters';

import RewardMaster from './reward-masters';

import Opvisit from './opvisit';

import SettingsList from './settings-list';

import ItemRateType from './item-ratetype';

import Department from './department';
import DocFileType from './doc-file-type';

import DocDetail from './doc-details';
import Branches from './branches';

import LabGenSettList from './labgen-settlist';
// import Doctor from './doctor';

import Communication from './communication';

import Preference from './preferences';

import ListProfession from './list-profession';

const Collections = new Collection();

const CollectionDetails = new CollectionDetail();

const Appointments = new Appointment();

const Doctors = new Doctor();

const Registrations = new RegistrationModel();

const Samples = new Sample();

const Reports = new Report();

const GeneralLists = new GeneralList();

const ResultEntry = new ResultEntryModel();

const Bills = new Bill();

const BillDetails = new BillDetail();

const SCRFilesLink = new SCRFilesLinks();

const ModalityGroups = new ModalityGroup();

const MedicalReports = new MedicalReport();

const ServiceResultMaster = new ServiceResultMasterModel();

const OutBoxs = new OutBox();

const Clinicals = new Clinical();

const Items = new Item();

const Referrers = new Referrer();

const ItemRateTypes = new ItemRateType();

const Categories = new Category();

const Customers = new Customer();

const Prereg = new PreregModel();

const Opreg = new OpregModel();

const EventTransactions = new EventTransaction();

const RewardTransactions = new RewardTransaction();

const RewardMasters = new RewardMaster();

const TemplateMasters = new TemplateMaster();

const Opvisits = new Opvisit();

const Settings = new SettingsList();

const Departments = new Department();

// Communcation Model

const Communications = new Communication();
const DocFileTypes = new DocFileType();

const DocDetails = new DocDetail();

const LabGenSettLists = new LabGenSettList();

const Preferences = new Preference();
const Branch = new Branches();

const ListProfessions = new ListProfession();


export {
    Opvisits,
    Collections,
    CollectionDetails,
    Appointments,
    Doctors,
    Registrations,
    Samples,
    ResultEntry,
    Bills,
    BillDetails,
    Reports,
    SCRFilesLink,
    ModalityGroups,
    MedicalReports,
    ServiceResultMaster,
    OutBoxs,
    Clinicals,
    Items,
    Referrers,
    Categories,
    GeneralLists,
    Customers,
    Prereg,
    Opreg,
    EventTransactions,
    RewardTransactions,
    RewardMasters,
    TemplateMasters,
    Settings,
    ItemRateTypes,
    Departments,

    Communications,
    Preferences,
    DocFileTypes,
    DocDetails,
    Branch,
    LabGenSettLists,
    ListProfessions,
};
