/**
 * Bill Model
 *
 *
 *
 *
 */
import React, { useState, useEffect, useContext } from 'react';

import Model from './models';

import { Tag, Card, Typography, Button, message } from 'antd';

import { Items } from '.';

import BillDetailView from './../modules/common/components/bill-detail/bill-detail';

import { ApiUtils } from 'soxo-bootstrap-core';

const moment = require('moment-timezone');

const { Text } = Typography;

class Bill extends Model {
    constructor() {
        super();

        this.fields = [
            {
                field: 'opb_name',
                caption: 'Name',
            },
            {
                field: 'opb_opno',
                caption: 'num',
            },
            // {
            //     field: 'cold_tm',
            //     caption: 'Date'
            // },
            // {
            //     field: 'Collected By',
            //     caption: 'cold_user'
            // }
        ];

        // this.columns = ;
    }

    get id() {
        return 'opb_id';
    }

    get getEndpoint() {
        return 'opbill';
    }

    get path() {
        return `bills`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `bill`;
    }

    disableAdd = () => {
        return true;
    };

    get columns() {
        return [
            {
                field: 'opb_name',
                render: (record) => {
                    return record.opb_name;
                },
                caption: 'Name',
            },
            {
                field: 'opb_gender',
                render: (record) => {
                    return <Tag color={record.opb_gender === 'Male' ? 'green' : 'orange'}>{record.opb_gender}</Tag>;
                },
                caption: 'Gender',
            },
            {
                field: 'opb_age',
                render: (record) => {
                    return record.opb_age;
                },
                caption: 'Age',
            },
            {
                field: 'opb_mobile',
                render: (record) => {
                    return record.opb_mobile;
                },
                caption: 'Mobile',
            },
            {
                field: 'opb_opno',
                render: (record) => {
                    return record.opb_opno;
                },
                caption: 'OP No.',
            },
            {
                // field: 'cold_amt',
                render: (record) => {
                    return record.opb_amt;
                },
                caption: 'Amount',
            },

            {
                // field: 'cold_amt',
                render: (record) => {
                    return record.opb_bdisamt;
                },
                caption: 'Discount',
            },

            {
                // field: 'cold_amt',
                render: (record) => {
                    return record.opb_netamt;
                },
                caption: 'Net Amount',
            },

            {
                // field: 'cold_amt',
                render: (record) => {
                    return record.opb_paidamt;
                },
                caption: 'Paid',
            },
            // {
            //     field: 'cold_dt',
            //     render: (record) => {
            //         return record.colldetails ? record.colldetails.cold_dt : null;
            //     },
            //     caption: 'Date'
            // },
            // {
            //     field: 'Collected By',
            //     render: (record) => {
            //         return record.colldetails ? record.colldetails.cold_user : null;
            //     },
            //     caption: 'cold_user'
            // },
            {
                field: 'opb_remarks',
                render: (record) => {
                    return record.opb_remarks;
                },
                caption: 'Remarks',
            },
        ];
    }

    getBillTypes = () => {
        return ApiUtils.get({
            url: `billingtypes?page=1&limit=10&btyp_billnocode=OP`,
        });
    };

    getCustomers = () => {
        return ApiUtils.get({
            url: `customer?page=1&limit=10`,
        });
    };

    applyCoupon = (coupon) => {
        return ApiUtils.get({
            url: `campgndiscountcoupons/${coupon}`,
        });
    };

    validatecoupon = (coupon) => {
        return ApiUtils.get({
            url: `campgndiscountcoupons/validate?coupon_no=${coupon}`,
        });
    };

    /**
     * Create a Bill with Items
     */
    createFinalBill = (formBody) => {
        return ApiUtils.post({
            url: `opbill/final_bill`,
            formBody,
        });
    };

    bill = async (formBody) => {
        // var items = [];
        formBody = {
            ...formBody,
        };
        return this.createFinalBill(formBody)
            .then((result) => {
                return result;
            })
            .catch((result) => {
                return result;
            });
    };

    DirectBill = (formBody) => {
        console.log(formBody);
        formBody = {
            ...formBody,
        };
        return this.createFinalBill(formBody)

            .then((result) => {
                return result;
            })
            .catch((result) => {
                return result;
            });
    };


    /**
     *
     *  create rewards
     *
     */

    createRewards = (formBody) => {
        return ApiUtils.post({
            url: `reward-transactions/create-rewards`,
            formBody,
        });
    };

    rewardList = (id, op_id, opb_opno) => {
        return ApiUtils.get({
            url: `reward-transactions/rewards-lists?reward_master_id=${id}&account_id_type=${op_id}&account_code=${opb_opno}`,
        });
    };

    /**
     * to check if lab billing is done
     */

    getLabBilled = (visit_id, item) => {
        return ApiUtils.get({
            url: `opbill/view-details?visit_id=${visit_id}&item=${item}`,
        });
    };

    /**
     *
     *  Active list
     *
     */

    getActiveList = () => {
        return ApiUtils.get({
            url: `reward-transactions/active-list`,
        });
    };

    getAuthList = () => {
        return ApiUtils.get({
            url: `reward-transactions/authorization-list`,
        });
    };


    /**
 *
 * Get Bill list table
 * @param {*} range
 * @returns
 */
    getbillList = (range) => {

        const start_time = moment(range[0]).format('MM/DD/YYYY');

        const end_time = moment(range[1]).format('MM/DD/YYYY');

        return ApiUtils.get({
            url: `opbill/bill-list?start_date=${start_time}&end_date=${end_time}`,
        });
    };

    /**
     *
     * Get Bill list table
     * @param {*} range
     * @returns
     */
    getRewardList = (range) => {
        const start_time = moment(range[0]).format('MM/DD/YYYY');

        const end_time = moment(range[1]).format('MM/DD/YYYY');

        return ApiUtils.get({
            url: `opbill/reward-list?start_date=${start_time}&end_date=${end_time}`,
        });
    };

    getCollections = (bill_id) => {
        return ApiUtils.get({
            url: `colldetails/collection-load?cold_tranno=${bill_id}`,
        });
    };

    getOplist = (query) => {
        return ApiUtils.get({
            url: `opreg/visit-search?name=${query}`,
        });

        // return ApiUtils.get({
        //     url: `opbill/search/${query}`,
        // });
    };

    // getOperg = (op_no, da_id) => {
    //     return ApiUtils.get({
    //         url: `opreg/with-appointment?op_no=${op_no}&da_id=${da_id}`,
    //     });
    // };

    getOpRegData = (op_no) => {
        return ApiUtils.get({
            url: `opreg?op_no=${op_no}`,
        });
    };

    getScreeningTrend = (range) => {
        const start_date = moment(range[0]).format('MM/DD/YYYY');

        const end_date = moment(range[1]).format('MM/DD/YYYY');

        return ApiUtils.get({
            url: `opbill/screening-trend?start_date=${start_date}&end_date=${end_date}`,
        });
    };

    /**
     * Get Byte Array for the Report
     */
    getReport = (formBody) => {
        return ApiUtils.post({
            url: `opbill/patient-lab-report`,
            formBody,
        });
    };

    /**
     * Publish Reoprt
     */

    publishReport = (formBody) => {
        return ApiUtils.post({
            url: `opbill/patient-lab-report`,
            formBody,
        });
    };

    /**
     * Branch wise data
     *
     * @param {*} range
     * @returns
     */
    getScreeningTrendBranchWise = (range) => {
        const start_date = moment(range[0]).format('MM/DD/YYYY');

        const end_date = moment(range[1]).format('MM/DD/YYYY');

        return ApiUtils.get({
            url: `opbill/screening-trend-branchwise?start_date=${start_date}&end_date=${end_date}`,
        });
    };

    Card = ({ ...props }) => {
        return <GuestCard record={props} {...props}></GuestCard>;
    };

    DetailComponent = (routeParams) => <BillDetailView {...routeParams} model={this} />;
    //  AddComponent = () => <CollectionAdd model={this} />;

    /**
     *
     * @param {*} values
     * @returns
     */
    generateBill = async (values) => {
        // setModalVisible(true)

        var item = await Items.getItems('CXRAY');

        item = item.map((ele) => {
            return {
                ...ele,
                ir_rate: ele.itemrate[0].ir_rate,
                ir_itemptr: ele.itemrate[0].ir_itemptr,
            };
        });

        var formBody = {
            opb_opno: values.op_no,
            visit_id: values.visit_id,

            rate_type: '3', // Not sure if required
            opd_remarks: 'Chest Xray',
            category: 'GEN',

            billtype: 0,

            cmgdcpn_couponnumber: null,

            doctorptr: null,

            // refererptr: patient.opbill.opb_referrerptr,
        };

        return this.createBill(formBody, item)
            .then((result) => {
                if (result.OpBillD) message.success('Bill has been Successfully Created');
                return result;
            })
            .catch((result) => {
                // setBtnLoading(false);

                message.error(JSON.stringify(result.message));

                return result;
            });
    };

    /**
     * Api call to get final report details
     * @param {*} id 
     * @returns 
     */
    getFinalReport = async (id) => {
        return ApiUtils.get({
            url: `opbill/get-report/${id}`,
            headers: {
                Authorization: 'Bearer' + " " + localStorage.access_token,
            },
        });
    }

    /**
     * Create Bill function
     * @param {*} formBody
     * @param {*} selected
     * @param {*} guest
     * @param {*} corporateptr
     * @param {*} discount
     * @param {*} coupon
     * @returns
     */
    createBill = async (formBody, selected, guest, corporateptr, discount, coupon) => {
        var items = [];

        formBody = {
            ...formBody,
            items: selected.map((record) => {
                // According to discount add item to items
                // if (record.discount) {
                //     if (record.discount.type === '%') {

                //         var item = {

                //             opbd_qty: 1,
                //             opbd_disper: record.discount.value,
                //         };

                //         items.push(item);

                //         formBody = {
                //             ...formBody,
                //             items,
                //         };
                //     } else if (record.discount.type === '₹') {
                //         // Add the bill discount value
                //         var item = {
                //             // opbd_itemptr: params.da_packagetypevalue,
                //             opbd_qty: 1,
                //             opbd_disamt: record.discount.value,
                //             //  opb_bdisamt:itmdiscount.opb_bdisamt,
                //         };
                //         items.push(item);

                //         formBody = {
                //             ...formBody,
                //             items,
                //         };
                //     }
                // } else {

                //     formBody = {
                //         ...formBody,

                //         // items,
                //     };
                // }

                // if (guest && guest.drAppointment && guest.drAppointment.da_couponno) {
                if (guest && guest.drAppointment && guest.drAppointment.da_couponno) {
                    let difference = record.ir_rate - guest.drAppointment.da_couponvalue;

                    return {
                        opbd_itemptr: record.ir_itemptr,
                        opbd_qty: 1,
                        //  opbd_disamt: record.opb_bdisamt,
                        opbd_rate: difference,
                    };
                } else if (record.discount) {
                    if (record.discount.type === '%') {
                        var item = {
                            // ...record,
                            opbd_itemptr: record.ir_itemptr,
                            opbd_qty: 1,
                            opbd_disper: record.discount.value,
                            opbd_rate: record.ir_rate,
                        };
                    } else if (record.discount.type === '₹') {
                        // Add the bill discount value
                        var item = {
                            // ...record,
                            opbd_itemptr: record.ir_itemptr,
                            opbd_qty: 1,
                            opbd_disamt: record.discount.value,
                            opbd_rate: record.ir_rate,
                        };
                    }

                    // let difference = record.ir_rate - record.discount.value;

                    return item;

                    // return {
                    //     opbd_itemptr: record.ir_itemptr,
                    //     opbd_qty: 1,
                    //     //  opbd_disamt: record.opb_bdisamt,
                    //     opbd_rate: difference,
                    // };
                } else {
                    return {
                        // opbd_itemptr: record.ir_itemptr,
                        opbd_itemptr: record.ir_itemptr,
                        opbd_qty: 1,
                        //  opbd_disamt: record.opb_bdisamt,
                        opbd_rate: record.ir_rate,
                    };
                }
            }),
        };

        // If its a corporate customer

        if (corporateptr) {
            formBody.opd_corporateptr = corporateptr;
        }

        // If its a campaign

        // Bill level discount

        // Condition for adding bill level discount
        if (discount) {
            if (discount.type === '%') {
                // Add the bill discount value
                formBody = {
                    ...formBody,
                    Bdiscount_per: discount.value,
                };
            } else if (discount.type === '₹') {
                // Add the bill discount value
                formBody = {
                    ...formBody,
                    Bdiscount_amt: discount.value,
                };
            }
        }

        // According to discount add item to items

        // Condition for adding bill level discount
        if (coupon && coupon.coupon_no && coupon.coupon_value) {
            formBody = {
                ...formBody,
                // cmgdcpn_couponnumber: coupon.dataValues.cmgdcpn_couponnumber,
                cmgdcpn_couponvalue: coupon.coupon_value,
            };
        } else {
        }

        if (guest && guest.drAppointment && guest.drAppointment.da_couponno && guest.drAppointment.da_couponvalue) {
            formBody = {
                ...formBody,
                cmgdcpn_couponnumber: guest.drAppointment.da_couponno,
                cmgdcpn_couponvalue: guest.drAppointment.da_couponvalue,

                // coupon_data: {
                //     coupon_no: guest.drAppointment.da_couponno,
                //     coupon_value: guest.drAppointment.da_couponvalue
                // }
            };
        }

        return this.createFinalBill(formBody)
            .then((result) => {
                return result;
            })
            .catch((result) => {
                return result;
            });
    };
}

function GuestCard({ record }) {
    return (
        <Card className="card vehicle-card">
            <div className="card">
                <h2 className="title">{record.opb_name}</h2>

                {/* {record.preReg.opb_age} */}

                {/* <h3 className="values">{record.da_aptime}</h3> */}

                {/* <Tag color={record.da_questfilled === 'F' ? 'orange' : 'green'}>{record.da_questfilled === 'F' ? 'Filled' : 'Pending'}</Tag> */}

                <Tag color="green">{record.opb_gender}</Tag>

                <Text type="secondary">{record.opb_mobile}</Text>

                <h4 className="values">{record.opb_opno}</h4>
            </div>
        </Card>
    );
}

export default Bill;
