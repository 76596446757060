/**
 * Outbox Model
 *
 *
 *
 *
 */
import React from 'react';

import Model from './models';

import { ApiUtils } from 'soxo-bootstrap-core';

class OutBox extends Model {

    constructor() {

        super();

        this.fields = [

            {
                field: 'name',

                caption: 'Name',
            },

        ];

    }

    get id() {

        return 'msgoutbox';

    }

    get getEndpoint() {

        return 'msgoutbox';

    }

    get path() {

        return `msgoutbox`;

    }

    get getName() {

        return 'msgoutbox';

    }

    get modelName() {

        return `msgoutbox`;

    }

    getOutboxList = ({ formBody }) => {

        return ApiUtils.post({ url: `msgoutbox/load-outbox`, formBody });

    };
    
}

export default OutBox;
