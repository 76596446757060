/**
 * Sample Collection Page
 */

import React, { useState, useEffect, useContext, useRef, Fragment } from 'react';

import { Tooltip, Col, Card, Input, Button, DatePicker, Form, Row, message, Alert, Skeleton, Typography, Checkbox, Timeline, Tag, Modal } from 'antd';

import { CheckCircleOutlined } from '@ant-design/icons';

import moment from 'moment-timezone';

import { ReloadOutlined } from '@ant-design/icons';

import RegistrationInfo from './../../../common/components/registration-info/registration-info';

import { ConfirmModal, InputComponent } from 'soxo-bootstrap-core';

import { useReactToPrint } from 'react-to-print';

import './sample-detail.scss';

import { GlobalContext, Location, Barcode, DateUtils } from 'soxo-bootstrap-core';

import { SmileTwoTone, BarcodeOutlined, CheckCircleTwoTone } from '@ant-design/icons';

import { Samples } from './../../../../models/';

const { Title, Text } = Typography;

const { TextArea } = Input;

let dateTimeFormat = 'DD/MM/YYYY HH:mm:ss';

export default function SampleDetail({ match, model, sampleRecieved }) {
    // Loading
    const [loading, setLoading] = useState(true);

    // List of specimens
    const [specimens, setSpecimens] = useState([]);

    const [testloading, setTestloading] = useState(false);

    // List of all the tests that can be done with the speciment selected
    const [order, setOrder] = useState({ opbilld: [] });

    const [resultloading, setResultloading] = useState(false);

    const [test, setTest] = useState({});

    const [selected, setSelected] = useState({});

    var urlParams = Location.search();

    const [selectedTest, setSelectedTest] = useState([]);

    const [patient, setPatient] = useState({}); //Patiants list array

    const { billno } = match.params;

    useEffect(() => {
        getPatientDetails(urlParams);
    }, []);

    /**
     *
     * Patient Data
     *
     * @param {*} urlParms
     */
    async function getPatientDetails(urlParms) {
        //Loadings
        setLoading(true);
        setResultloading(false);
        setTestloading(true);

        //Initial API

        // To get list of specimens
        Samples.getPatientSpecimenSampleDetail(billno, 'Blood').then(async (result) => {

            let { opbilld, opbill } = result;

            setPatient(result);

            setSpecimens(result.specimen_list);
            setSelected(result.specimen_list[0]);

            //If SampleRecieved is true filter pending and rejected elements as the sample has not been collected
            if (sampleRecieved) {
                result['opbilld'] = result.opbilld.filter((ele) => {
                    if (ele.status !== 'Pending' && ele.status !== 'Rejected') {
                        return ele;
                    }
                });
            }

            //Filtering order by selected specimen
            result.opbilld = result.opbilld.filter((item) => item.labTest && item.labTest.lbt_specimen === result.specimen_list[0]);

            //setting collected or rejected status
            //setting deafault selection
            //If SampleRecieved is true make status Lab recieved
            if (sampleRecieved) {
                result.opbilld.map((item) => {
                    if (item.hasOwnProperty('labsamplestatus')) {
                        if (item.labsamplestatus.lbss_samtranmode === 'LR') {
                            item.status = 'Lab Recieved';
                            item.selected = false;
                        } else if (item.labsamplestatus.lbss_samtranmode === 'SR') {
                            item.status = 'Rejected';
                            item.selected = true;
                        }
                    } else {
                        item.selected = true;
                    }
                });
            } else {
                result.opbilld.map((item) => {
                    if (item.hasOwnProperty('labsamplestatus')) {
                        if (item.labsamplestatus.lbss_samtranmode === 'SC') {
                            item.status = 'Collected';
                            item.selected = false;
                        } else if (item.labsamplestatus.lbss_samtranmode === 'SR') {
                            item.status = 'Rejected';
                            item.selected = true;
                        }
                    } else {
                        item.selected = true;
                    }
                });
            }

            // To sort result according to slno in labtest
            result.opbilld.sort((a, b) => (a.labTest.lbt_slno > b.labTest.lbt_slno ? 1 : -1));

            var testSelected = result.opbilld.filter((item) => item.selected);

            //If no item is selected the zero th item is selected in default

            if (testSelected && testSelected.length === 0) {
                if (result && result.opbilld && result.opbilld[0]) {
                    // setSelectedTest(order.opbilld[0])

                    result.opbilld[0].selected = true;
                }

                setOrder({ ...order });
            }

            setOrder(result);
            loadTestData(opbill, opbilld[0]);

            setSelectedTest(result.opbilld.filter((item) => item.selected));
            setTestloading(true);
            setTestloading(false);
            setLoading(false);
        });
    }

    /**
     * Load the specimen detail
     *
     * @param {*} param0
     * @returns
     */
    function loadSpecimenDetail(selected, opbill) {
        setResultloading(true);
        setTestloading(true);
        Samples.getSpecimenDetail(selected, opbill).then((result) => {
            //If SampleRecieved is true filter pending and rejected elements as the sample has not been collected
            if (sampleRecieved) {
                result['opbilld'] = result.opbilld.filter((ele) => {
                    if (ele.status !== 'Pending' && ele.status !== 'Rejected') {
                        return ele;
                    }
                });
            }
            result.opbilld.map((item) => {
                if (sampleRecieved) {
                    if (item.hasOwnProperty('labsamplestatus')) {
                        if (item.labsamplestatus.lbss_samtranmode === 'LR') {
                            item.status = 'Lab Recieved';
                            item.selected = false;
                        } else if (item.labsamplestatus.lbss_samtranmode === 'SR') {
                            item.status = 'Rejected';
                            item.selected = true;
                        }
                    } else {
                        item.selected = true;
                    }
                } else {
                    if (item.hasOwnProperty('labsamplestatus')) {
                        if (item.labsamplestatus.lbss_samtranmode === 'SC') {
                            item.status = 'Collected';
                            item.selected = false;
                        } else if (item.labsamplestatus.lbss_samtranmode === 'SR') {
                            item.status = 'Rejected';
                            item.selected = true;
                        }
                    } else {
                        item.selected = true;
                    }
                }
            });

            var testSelected = result.opbilld.filter((item) => item.selected);

            if (testSelected && testSelected.length === 0) {
                //If SampleRecieved is true filter pending and rejected elements as the sample has not been collected
                if (sampleRecieved) {
                    order['opbilld'] = order.opbilld.filter((ele) => {
                        if (ele.status !== 'Pending' && ele.status !== 'Rejected') {
                            return ele;
                        }
                    });
                }

                if (result && result.opbilld && result.opbilld[0]) {
                    result.opbilld[0].selected = true;
                }

                setOrder({ ...order });
            }

            setSelectedTest(result.opbilld.filter((item) => item.selected));

            loadTestData(result.opbill, result.opbilld[0]);

            result.opbilld.sort((a, b) => (a.labTest.lbt_slno > b.labTest.lbt_slno ? 1 : -1));

            setOrder({ ...result });

            setTestloading(false);
        });
    }

    /**
     * Get the Lab Test Detail
     *
     * @param {*} param0
     */
    function getLabTest({ labTest }) {
        return Samples.getLabTest(labTest.lbt_itemptr).then((result) => {
            if (result.length) {
                setTest(result[0]);
            }
        });
    }

    /**
     *
     */
    function loadTestData(opbill, opbilld) {
        // setResultloading(true);
        if (opbilld && opbilld.labTest) {
            // Get the lab test
            getLabTest(opbilld, opbill).then(() => {
                // getSampleList(opbill, opbilld);

                setResultloading(false);
            });
        } else {
            setResultloading(false);

            if (!sampleRecieved) message.error('Lab Test is missing');
        }
    }

    function refresh() {
        var urlParams = Location.search();

        getPatientDetails(urlParams);
    }

    const { opbill, opbilld } = patient;

    const onChangeAll = (e) => {
        if (e.target.checked) {
            setSelected('All');
            loadSpecimenDetail('All', opbill);
        } else {
            setSelected('Blood');
            loadSpecimenDetail('Blood', opbill);
        }
    };

    const testChangeHandler = (key) => {
        //If SampleRecieved is true filter pending and rejected elements as the sample has not been collected
        if (sampleRecieved) {
            order['opbilld'] = order.opbilld.filter((ele) => {
                if (ele.status !== 'Pending' && ele.status !== 'Rejected') {
                    return ele;
                }
            });
        }

        if (selectedTest.length === 1) {
            // To select test according to key
            order.opbilld[key].selected = true;

            order.opbilld.map((item) => {
                if (item.opbd_itemptr != order.opbilld[key].opbd_itemptr) {
                    // To unselect all other tests
                    item.selected = false;
                }
            });
        } else {
            order.opbilld[key].selected = !order.opbilld[key].selected;
        }

        // To sort tests according to sl no
        order.opbilld.sort((a, b) => (a.labTest.lbt_slno > b.labTest.lbt_slno ? 1 : -1));

        setOrder({ ...order });

        setSelectedTest(order.opbilld.filter((item) => item.selected));
    };

    var arr = [];

    var collectedTime = null;

    var recievedTime = null;

    var collectedRemarks = null;

    var recievedRemarks = null;

    if (selectedTest[0] && selectedTest[0].labsamplestatus && selectedTest[0].labsamplestatus[0]) {
        var sampleCollected = selectedTest[0].labsamplestatus.filter((ele) => ele.lbss_samtranmode === 'SC');

        var sampleRecievedList = selectedTest[0].labsamplestatus.filter((ele) => ele.lbss_samtranmode === 'LR');

        if (sampleCollected && sampleCollected.length && (selectedTest[0].status === 'Collected' || selectedTest[0].status === 'Lab Recieved')) {
            // Sample collected remarks
            collectedRemarks = sampleCollected[0].lbss_remarks;

            // Sample collected time
            collectedTime = sampleCollected[0].lbss_samtrandttm;

            sampleCollected.map((sample) => {
                if (sample.lbss_samtrandttm > collectedTime) collectedTime = sample.lbss_samtrandttm;
            });
        }

        if (sampleRecievedList && sampleRecievedList.length && selectedTest[0].status === 'Lab Recieved') {
            recievedRemarks = sampleRecievedList[0].lbss_remarks;

            recievedTime = sampleRecievedList[0].lbss_samtrandttm;

            sampleRecievedList.map((sample) => {
                if (sample.lbss_samtrandttm > recievedTime) recievedTime = sample.lbss_samtrandttm;
            });
        }
    }

    return (
        <section className="sample-detail card">
            <div className="page-header">
                <div>
                    <Title level={3}>{sampleRecieved ? 'Sample Received' : 'Sample Collection'}</Title>

                    <p className="size-hint">{loading ? 'Loading data' : 'Refer below information and update feedback'}</p>
                </div>

                <Button onClick={refresh} type="secondary" size={'small'}>
                    <ReloadOutlined />
                </Button>
            </div>

            {/* Loading Indicator */}
            {loading ? (
                <Card className="">
                    <Skeleton active />
                </Card>
            ) : (
                <>
                    <div className="top-bar">
                        <RegistrationInfo className="registration-info" guest={opbill} />

                        {/* <ClinicalInfomation bill={opbill} /> */}
                    </div>

                    {/**If sample collected page then show window, else if sample recieved page then check condition and if condition true only then show window */}
                    {!sampleRecieved || (selectedTest.length > 0 && ['Pending', 'Rejected'].indexOf(selectedTest[0].status) === -1) ? (
                        /* Detail Section */
                        <div className="card detail-content">
                            <div className="left">
                                <Row>
                                    <Col span={20}>
                                        <Title level={5}>Specimens ({specimens.length})</Title>
                                    </Col>
                                    {/* <Col span={8} style={{ textAlign: 'right' }}> */}
                                    {/* <Button onClick={() => {
                                         console.log('');
                                         setSelected('All')
                                         loadSpecimenDetail('All', opbill);
 
                                     }
                                     } icon={<CheckOutlined />}>All</Button> */}

                                    {/* <Checkbox onChange={onChangeAll}>All</Checkbox> */}
                                    {/* </Col> */}
                                </Row>

                                <div className="specimen-records">
                                    {specimens.map((entry, key) => {
                                        let specimenImage = require(`./../../../../assets/icons/None.png`);

                                        try {
                                            specimenImage = require(`./../../../../assets/icons/${entry}.png`);
                                        } catch (error) {
                                            console.log('Could not load specimen image');
                                        }

                                        return (
                                            <div
                                                onClick={() => {
                                                    setSelected(entry);

                                                    loadSpecimenDetail(entry, opbill);

                                                    // loadTestData(detail.opbill, entry);
                                                }}
                                                className={`lab-test card card-shadow ${selected === entry || selected === 'All' ? 'active' : ''}`}
                                                key={key}
                                            >
                                                <img className="specimen-icon" src={specimenImage} />

                                                {entry}

                                                <div className="specimen-status"></div>

                                                {/* {entry.status === 'Collected' ? (
                                                 <CheckCircleTwoTone twoToneColor="#52c41a" />
 
                                                 
                                             ) : (
                                                 <ExclamationCircleOutlined />
                                             )} */}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>

                            {/* List of Tests that will be done */}
                            <div className="test-order">
                                <Title level={5}>Tests to Order</Title>

                                {testloading ? (
                                    <>
                                        <Skeleton active style={{ width: '100%' }} />
                                    </>
                                ) : (
                                    <div className="billd-records">
                                        {/**
                                         * If sampleRecieved is true Lab Recieved status are shown
                                         */}
                                        {sampleRecieved
                                            ? order.opbilld.map((entry, key) => {
                                                  return (
                                                      <div
                                                          onClick={() => testChangeHandler(key)}
                                                          className={`test-name card card-shadow ${entry.selected ? 'active' : ''}`}
                                                          key={key}
                                                      >
                                                          <Row>
                                                              <Col span={18}>
                                                                  {key + 1}. {entry.opbd_itemdesc}
                                                              </Col>
                                                              <Col span={6} style={{ textAlign: 'right' }}>
                                                                  {entry.status === 'Lab Recieved' ? (
                                                                      <CheckCircleTwoTone twoToneColor="#52c41a" />
                                                                  ) : (
                                                                      <CheckCircleOutlined />
                                                                  )}
                                                              </Col>
                                                          </Row>
                                                      </div>
                                                  );
                                              })
                                            : order.opbilld.map((entry, key) => {
                                                  return (
                                                      <div
                                                          onClick={() => testChangeHandler(key)}
                                                          className={`test-name card card-shadow ${entry.selected ? 'active' : ''}`}
                                                          key={key}
                                                      >
                                                          <Row>
                                                              <Col span={18}>
                                                                  {key + 1}. {entry.opbd_itemdesc}
                                                              </Col>
                                                              <Col span={6} style={{ textAlign: 'right' }}>
                                                                  {entry.status === 'Collected' || entry.status === 'Lab Recieved' ? (
                                                                      <CheckCircleTwoTone twoToneColor="#52c41a" />
                                                                  ) : (
                                                                      <CheckCircleOutlined />
                                                                  )}
                                                              </Col>
                                                          </Row>
                                                      </div>
                                                  );
                                              })}
                                    </div>
                                )}
                            </div>

                            {/* List of Tests Ends */}

                            <div className="right ">
                                <Title level={5}>{sampleRecieved ? 'Received Details' : 'Collection Details'}</Title>

                                {/* Loading Indicator */}
                                {resultloading ? (
                                    <Card className="card-shadow card" style={{ width: '100%' }}>
                                        <Skeleton active style={{ width: '100%' }} />
                                    </Card>
                                ) : (
                                    <>
                                        {/*  */}

                                        <div className="card card-shadow lab-result">
                                            <div className="timeline-wrapper">
                                                <Timeline>
                                                    <Timeline.Item color="green">
                                                        Ordered
                                                        <p>
                                                            <small>
                                                                {opbill && opbill.opb_tm
                                                                    ? moment.tz(opbill.opb_tm, '').format('DD/MM/YYYY HH:mm a')
                                                                    : null}
                                                            </small>
                                                        </p>
                                                    </Timeline.Item>

                                                    <Timeline.Item
                                                        color={
                                                            selectedTest.length > 0 && ['Pending', 'Rejected'].indexOf(selectedTest[0].status) === -1
                                                                ? 'green'
                                                                : 'gray'
                                                        }
                                                    >
                                                        Sample Collected
                                                        <p>
                                                            <small>
                                                                {collectedTime === null
                                                                    ? null
                                                                    : moment.tz(collectedTime, '').format('DD/MM/YYYY HH:mm a')}
                                                            </small>
                                                        </p>
                                                        <p>
                                                            <small>{collectedRemarks === null ? null : collectedRemarks}</small>
                                                        </p>
                                                        <p>
                                                            <small>
                                                                {selectedTest.length > 0 &&
                                                                (selectedTest[0].status === 'Pending' || selectedTest[0].status === 'Rejected') ? (
                                                                    <>Pending</>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </small>
                                                        </p>
                                                    </Timeline.Item>

                                                    <Timeline.Item
                                                        color={
                                                            selectedTest.length > 0 && selectedTest[0].status === 'Lab Recieved' ? 'green' : 'gray'
                                                        }
                                                    >
                                                        Lab In
                                                        <p>
                                                            <small>
                                                                {recievedTime === null
                                                                    ? null
                                                                    : moment.tz(recievedTime, '').format('DD/MM/YYYY HH:mm a')}
                                                            </small>
                                                        </p>
                                                        <p>
                                                            <small>{recievedRemarks === null ? null : recievedRemarks}</small>
                                                        </p>
                                                        <p>
                                                            <small>
                                                                {selectedTest.length > 0 && selectedTest[0].status === 'Collected' ? (
                                                                    <>Pending</>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </small>
                                                        </p>
                                                    </Timeline.Item>

                                                    <Timeline.Item
                                                        color={
                                                            selectedTest.length > 0 &&
                                                            selectedTest[0].lab_result &&
                                                            selectedTest[0].lab_result.lbtrs_testdonedttm
                                                                ? 'green'
                                                                : 'gray'
                                                        }
                                                    >
                                                        Test Done
                                                        <p>
                                                            <small>
                                                                {selectedTest.length &&
                                                                selectedTest[0].lab_result &&
                                                                selectedTest[0].lab_result.lbtrs_testdonedttm
                                                                    ? moment
                                                                          .tz(selectedTest[0].lab_result.lbtrs_testdonedttm, '')
                                                                          .format('DD/MM/YYYY HH:mm a')
                                                                    : null}
                                                            </small>
                                                        </p>
                                                        <p>
                                                            <small>
                                                                {selectedTest.length &&
                                                                selectedTest[0].lab_result &&
                                                                selectedTest[0].lab_result.lbtrs_remarks
                                                                    ? selectedTest[0].lab_result.lbtrs_remarks
                                                                    : null}
                                                            </small>
                                                        </p>
                                                    </Timeline.Item>

                                                    <Timeline.Item
                                                        color={
                                                            selectedTest.length > 0 &&
                                                            selectedTest[0].lab_result &&
                                                            selectedTest[0].lab_result.lbtrs_isclosed === 'Y'
                                                                ? 'green'
                                                                : 'gray'
                                                        }
                                                    >
                                                        Closed{' '}
                                                        <p>
                                                            <small>
                                                                {selectedTest.length &&
                                                                selectedTest[0].lab_result &&
                                                                selectedTest[0].lab_result.lbtrs_closeddttm
                                                                    ? moment
                                                                          .tz(selectedTest[0].lab_result.lbtrs_closeddttm, '')
                                                                          .format('DD/MM/YYYY HH:mm a')
                                                                    : null}
                                                            </small>
                                                        </p>
                                                    </Timeline.Item>
                                                </Timeline>
                                            </div>

                                            {/* <div className="result-content">
                                                <div gutter={0} className="detail-wrapper">
                                                    <div className="detail-element">
                                                        <Row span={12}>
                                                            <span>Notes</span>
                                                        </Row>
                                                        <Row span={12}>
                                                            <h3>
                                                                (Out - {test.lbt_outsource}) {test.lbt_specimen} (Method - {test.lbt_method})
                                                            </h3>
                                                        </Row>
                                                    </div> */}

                                            {/* <div className="detail-element">
                                                        <Row span={12}>
                                                            <span>Chief Technician</span>
                                                        </Row>
                                                        <Row span={12}>
                                                            <h3>{test.lbtrs_chieftechnician || ''}</h3>
                                                        </Row>
                                                    </div>

                                                    <div className="detail-element">
                                                        <Row span={12}>
                                                            <span>Technician</span>
                                                        </Row>
                                                        <Row span={12}>
                                                            <h3>{test.lbtrs_technician || ''}</h3>
                                                        </Row>
                                                    </div>

                                                    <div className="detail-element">
                                                        <Row span={12}>
                                                            <span>Remarks</span>
                                                        </Row>
                                                        <Row span={12}>
                                                            <h3>{result.lbtrs_remarks || ''}</h3>
                                                        </Row>
                                                    </div> */}
                                            {/* </div>
                                            </div> */}

                                            {/* Sample Collected Status */}

                                            <SampleCollectionStatus
                                                model={model}
                                                selected={selected}
                                                billno={billno}
                                                opbill={opbill}
                                                opbilld={opbilld[0]}
                                                order={order}
                                                loadSpecimenDetail={loadSpecimenDetail}
                                                selectedTest={selectedTest}
                                                sampleRecieved={sampleRecieved}
                                                collectedTime={collectedTime}
                                                recievedTime={recievedTime}
                                                test={test}
                                            />

                                            {/* Sample Collected Status Ends */}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    ) : (
                        <>
                            <Alert description={`Please collect the sample to proceed !`} type="error" showIcon style={{ margin: '10px 0px' }} />
                        </>
                    )}
                </>
            )}
        </section>
    );
}

class WrapperClass extends React.Component {
    render() {
        return <div style={{ width: '100%' }}>{this.props.children}</div>;
    }
}

/**
 * Sample Collection Status
 *
 * @returns
 */
function SampleCollectionStatus({
    model,
    opbilld,
    opbill,
    billno,
    selected,
    order,
    loadSpecimenDetail,
    selectedTest,
    sampleRecieved,
    collectedTime,
    recievedTime,
    test,
}) {
    const [btnloading, setBtnloading] = useState(false);

    const { user } = useContext(GlobalContext);

    const [form] = Form.useForm();

    // Loading Variable
    const [loading, setLoading] = useState(false);

    // Variable for saving the collected list
    const [collected, setCollected] = useState({});

    const [checked, setChecked] = useState(false);

    useEffect(() => {
        // Get the data
        // console.log("test", test)
    }, []);
    /**
     * Get the samples
     */
    function getSampleList(opbill, opbilld) {
        setLoading(true);

        return Samples.getSampleList(opbill, opbilld).then((result) => {
            // console.log("Sample list", result);

            if (result.length) {
                setCollected(result);
            } else {
                // setCollected(result[0]);
            }

            setLoading(false);
        });
    }

    /**
     * On submit of the form
     */
    async function onSubmit(values) {
        setBtnloading(true);

        //Set selected orders to array
        let selectedOrder = order.opbilld.filter((item) => item.selected);
        let rows = selectedOrder.map((item) => item.opbd_itemptr);

        values = {
            ...values,
            bill_id: billno,
            lbss_remarks: values.lbss_remarks,
            specimen: selected,
            // is_test_order: values.is_test_order,
            lbss_samtrandttm: DateUtils.getFormattedTimeDate(values.lbss_samtrandttm),
            //moment( values.lbss_samtrandttm._d, 'DD/MM/YYYY HH/MM/SS').format('YYYY-MM-DD HH:MM:SS'),
            rows: rows,
        };

        //If SampleRecieved is true update mode as LR
        if (sampleRecieved) {
            return Samples.recieveSample(values, user).then((result, lbss_samtrandttm) => {
                message.success('Updates are saved');

                if (checked) {
                    handlePrint();
                }
                console.log(result);

                setBtnloading(false);

                loadSpecimenDetail(selected, opbill);

                getSampleList(opbill, opbilld);

                setBtnloading(false);
            });
        } else {
            // Collect Sample
            return Samples.collectSample(values, user).then((result, lbss_samtrandttm) => {
                message.success('Updates are saved');

                if (checked) {
                    handlePrint();
                }

                setBtnloading(false);

                loadSpecimenDetail(selected, opbill);

                getSampleList(opbill, opbilld);

                setBtnloading(false);

                handlePrint();
            });
        }
    }

    /**
     * Reject an entry
     */
    function rejectCollection(values) {
        let selectedOrder = order.opbilld.filter((item) => item.selected);
        let rows = selectedOrder.map((item) => item.opbd_itemptr);
        var params = {
            // ...values,
            bill_id: billno,
            lbss_itemptr: selected.opbd_itemptr,
            // lbss_samtrandttm: values.lbss_samtrandttm._d,
            lbss_remarks: values.remarks,
            specimen: selected,
            rows: rows,
        };

        return Samples.rejectSample(params, user).then((result) => {
            message.success('Updates are saved');

            // Reload page

            // setBtnloading(false);

            loadSpecimenDetail(selected, opbill);
            getSampleList(opbill, opbilld);
        });
    }

    // Initial values
    var initialValues = {
        lbss_samtrandttm: moment(),
    };

    var print = useRef();

    const handlePrint = useReactToPrint({
        content: () => print.current,
        bodyClass: 'hello',
    });

    let foundCollected = false;
    let collectedArray = [];
    let foundRejected = false;
    let rejectedArray = [];
    let foundUnCollected = false;

    if (sampleRecieved) {
        for (var i = 0; i < selectedTest.length; i++) {
            if (selectedTest[i].status === 'Lab Recieved') {
                foundCollected = true;
                collectedArray.push(selectedTest[i]);
            } else if (selectedTest[i].status === 'Rejected') {
                foundRejected = true;
                rejectedArray.push(selectedTest[i]);
            } else {
                foundUnCollected = true;
            }
        }
    } else {
        for (var i = 0; i < selectedTest.length; i++) {
            if (selectedTest[i].status === 'Collected' || selectedTest[i].status === 'Lab Recieved') {
                foundCollected = true;
                collectedArray.push(selectedTest[i]);
            } else if (selectedTest[i].status === 'Rejected') {
                foundRejected = true;
                rejectedArray.push(selectedTest[i]);
            } else {
                foundUnCollected = true;
            }
        }
    }

    var disable = false;

    if (selectedTest && selectedTest.length) {
        disable = false;
    } else {
        disable = true;
    }

    function onChange(e) {
        setChecked(e.target.checked);
    }

    return (
        <>
            {loading ? (
                <Skeleton />
            ) : (
                <>
                    {foundCollected ? (
                        <>
                            <div className="collected-status">
                                <Tag color={'green'}>
                                    <CheckCircleTwoTone twoToneColor="#52c41a" />{' '}
                                    {sampleRecieved ? <span>Sample Received</span> : <span>Sample Collected </span>}
                                </Tag>

                                <div gutter={0} className="detail-wrapper">
                                    <div className="detail-element">
                                        {collectedArray.map((item) => {
                                            console.log(item);
                                            return (
                                                <Fragment>
                                                    {/* <Row span={12}>
                                                        <span>{item.opbd_itemdesc}</span>
                                                    </Row> */}
                                                    <Row span={12}>
                                                        <span>Collected Time</span>
                                                    </Row>
                                                    <Row span={12}>
                                                        {/* <h3>{moment(item.labsamplestatus.lbss_samtrandttm).format('DD/MM/YYYY HH:mm')}</h3> */}
                                                        {!sampleRecieved ? (
                                                            <h3>{moment.tz(collectedTime, '').format('DD/MM/YYYY HH:mm a')}</h3>
                                                        ) : (
                                                            <h3>{moment.tz(recievedTime, '').format('DD/MM/YYYY HH:mm a')}</h3>
                                                        )}
                                                    </Row>
                                                </Fragment>
                                            );
                                        })}
                                    </div>

                                    <div className="detail-element">
                                        {/* <Row span={12}>
                                                 <span>Remarks</span>
                                             </Row> */}
                                        <Row span={12}>
                                            <h3>{collected.lbss_remarks}</h3>
                                        </Row>
                                    </div>
                                </div>
                                {console.log(selectedTest[0], foundUnCollected, foundRejected)}

                                <div className="collection-actions">
                                    {!sampleRecieved ||
                                    (selectedTest.length > 0 &&
                                        selectedTest[0].result_status &&
                                        selectedTest[0].result_status === 'Test Done') ? null : foundRejected || foundUnCollected ? ( //Only select collected
                                        ''
                                    ) : (
                                        <ConfirmModal
                                            caption={'Reject'}
                                            title="Are you sure you want to reject this collection? "
                                            callback={(values) => rejectCollection(values)}
                                        >
                                            <Button size={'small'} type="secondary">
                                                Reject
                                            </Button>
                                        </ConfirmModal>
                                    )}
                                    <Button Button size={'small'} type="dashed" className="print-button" onClick={handlePrint}>
                                        Print Barcode
                                    </Button>
                                </div>

                                {/* <Popconfirm
                                                         title="Are you sure you want to reject this collection? "
                                                         onConfirm={() => rejectCollection()}
                                                         onCancel={() => {}}
                                                         okText="Yes"
                                                         cancelText="No"
                                                     >
                                                         <Button type="secondary">Reject</Button> */}
                                {/* </Popconfirm>  */}
                            </div>
                        </>
                    ) : (
                        <div className="collection-form">
                            {foundRejected ? (
                                <>
                                    {/* Rejected Before */}
                                    {/* {rejectedArray.map((item)=>{
                                              return (
                                                  <Alert 
                             message={`${item.opbd_itemdesc}`}
                             description={`rejected : ${moment(item.labsamplestatus.lbss_samtrandttm).format('dddd, MMMM Do YYYY, h:mm:ss a')} `}
                             type={'warning'}
                         />
                                              )       
                         })} */}

                                    {/* Rejected Before Ends */}
                                </>
                            ) : (
                                <></>
                            )}

                            <div className="notes">
                                <p className="notes-head">Notes</p>

                                <h3>
                                    (Out - {test.lbt_outsource}) {test.lbt_specimen} (Method - {test.lbt_method})
                                </h3>
                            </div>

                            <Form initialValues={initialValues} form={form} layout="vertical" onFinish={onSubmit}>
                                {/**If sample is rejected show details  */}
                                <div>
                                    {selectedTest[0].labsamplestatus[0] && selectedTest[0].labsamplestatus[0].lbss_samtranmode === 'SR' ? (
                                        <div className="status">
                                            <b>
                                                {' '}
                                                Rejected on{' '}
                                                {moment.tz(selectedTest[0].labsamplestatus[0].lbss_samtrandttm, '').format('DD/MM/YYYY HH:mm a')}
                                            </b>
                                            {selectedTest[0].labsamplestatus[0].lbss_remarks ? (
                                                <p>
                                                    Rejected Remark: <b>{selectedTest[0].labsamplestatus[0].lbss_remarks}</b>
                                                </p>
                                            ) : null}
                                        </div>
                                    ) : null}
                                </div>
                                {/* <Form.Item label="Notes"> */}
                                <p>Notes</p>
                                <h3>
                                    (Out - {test.lbt_outsource}) {test.lbt_specimen} (Method - {test.lbt_method})
                                </h3>
                                {/* </Form.Item> */}
                                {sampleRecieved ? <p>Record the status on sample recieved</p> : <p>Record the status on sample collection</p>}

                                {/* Sample Collected Time */}
                                <Form.Item
                                    rules={[
                                        ({ getFieldValue }) => ({
                                            validator(entry, value) {
                                                let data = !collectedTime ? 'billed' : 'collected';

                                                // Ordered datetime format
                                                let orderedTime = moment.tz(opbill.opb_tm, '').format('YYYY-MM-DD HH:mm:ss');

                                                // Sample collected datetime format
                                                let sampleCollectedTime = moment.tz(collectedTime, '').format('YYYY-MM-DD HH:mm:ss');

                                                if (
                                                    !collectedTime &&
                                                    moment(orderedTime).isSameOrBefore(value) &&
                                                    value.isSameOrBefore(moment.tz())
                                                ) {
                                                    return Promise.resolve();
                                                } else if (
                                                    collectedTime &&
                                                    moment(sampleCollectedTime).isSameOrBefore(value) &&
                                                    value.isSameOrBefore(moment.tz())
                                                ) {
                                                    return Promise.resolve();
                                                } else {
                                                    return Promise.reject(
                                                        new Error(
                                                            `Selected date & time has to be after the ${data} date & time and before the current date & time`
                                                        )
                                                    );
                                                }
                                            },
                                        }),
                                    ]}
                                    name="lbss_samtrandttm"
                                    label={sampleRecieved ? 'Received Time' : 'Collected Time'}
                                    required
                                    initialValues={{
                                        isPrintBarcode: true,
                                    }}
                                >
                                    <DatePicker
                                        format={dateTimeFormat}
                                        // disabledDate={(current) => {
                                        //     return current.diff(moment.tz(opbill.opb_tm, '')) < 0;
                                        // }}
                                        // disabledDate={(current) => {
                                        //     let startDate = moment(opbill.opb_dt)
                                        //     return current.isBefore(startDate)||current.isAfter()
                                        //         }}
                                        showTime
                                    />
                                </Form.Item>
                                {/* Sample Collected Time Ends */}

                                {/* On enabled this would create test entry */}
                                {/* <Form.Item
                                     name={'isTestOrder'}
                                     initialValue={false}
                                     valuePropName="checked"
                                     label="Test Entry"
                                     tooltip={{ title: 'Tooltip with customize icon' }}
                                 >
                                     <Checkbox>Create Test Entry</Checkbox>
                                 </Form.Item> */}
                                {/* On enabled this would create test entry ends */}

                                <Form.Item
                                    rules={[
                                        {
                                            max: 100,
                                            message: 'Length cannot exceed 100 characters !',
                                        },
                                    ]}
                                    name={'lbss_remarks'}
                                    label="Remarks"
                                >
                                    <TextArea />
                                </Form.Item>

                                <Form.Item>
                                    <Button loading={btnloading} disabled={disable} htmlType="submit" type="primary">
                                        Save
                                    </Button>

                                    {sampleRecieved ? (
                                        selectedTest && selectedTest[0] && selectedTest[0].status === 'Collected' ? (
                                            <ConfirmModal
                                                caption={'Reject'}
                                                title="Are you sure you want to reject this collection? "
                                                callback={(values) => rejectCollection(values)}
                                                type="primary"
                                                size="default"
                                                // required={true}
                                            >
                                                <Button size={'default'} type="primary" disabled={true}>
                                                    Reject
                                                </Button>
                                            </ConfirmModal>
                                        ) : null
                                    ) : null}

                                    <Form.Item name={'isPrintBarcode'} valuePropName={true}>
                                        <Checkbox checked={checked} onChange={onChange}>
                                            Print <BarcodeOutlined />
                                        </Checkbox>
                                    </Form.Item>
                                </Form.Item>
                            </Form>
                        </div>
                    )}
                    {/* Print Block */}
                    <div className="print-block">
                        <WrapperClass ref={print}>
                            {/* Generated QR and Customer Name */}
                            <Barcode value={`${opbill.opb_name} ${opbill.opb_age} ${opbill.opb_gender} ${opbill.opb_bno}`} />
                            <div className="barcode-content">
                                {/* <p className="label-title">Onedesk | Medical Application</p> */}
                                {/* 
                                <p className="label-description">
                                    Collected at {moment(collected.lbss_samtrandttm).format('dddd, MMMM Do YYYY, h:mm:ss a')}
                                </p> */}
                            </div>
                            {/* Generated QR and Customer Name Ends */}
                        </WrapperClass>
                    </div>
                    {/* Print Block ends */}
                </>
            )}
        </>
    );
}

/**/
function ClinicalInfomation({ bill }) {
    const [info, setInfo] = useState({});

    const [loading, setLoading] = useState(false);

    const [visible, setVisible] = useState(false);

    const { user } = useContext(GlobalContext);

    useEffect(() => {
        // Get the data
        getData();
    }, []);

    function getData() {
        setLoading(false);

        Samples.getClinicalInfomation(bill).then((result) => {
            if (result && result.srvbcd_id) {
                setInfo(result);
            } else {
                setInfo({});
            }
            setLoading(false);
        });
    }

    /**
     * On submit of form
     *
     * @param {*} event
     */
    function onSubmit(values) {
        setVisible(false);
        setLoading(false);
        if (info.srvbcd_id) {
            Samples.updateClinical(bill.opb_id, values, bill, user).then((result) => {
                message.success('Success');

                setInfo(result);
            });
        } else {
            values = {
                ...values,
                bill_id: bill.opb_id,
            };

            Samples.enterClinical(values, user).then((result) => {
                message.success('Success');

                setInfo(result);
            });
        }
    }
    // calculation of BMI
    if (info && info.srvbcd_height && info.srvbcd_weight) {
        var bmi = (info.srvbcd_weight / (info.srvbcd_height * info.srvbcd_height)) * 10000;
        info.srvbcd_bmi = Math.round(bmi * 100) / 100;
    } else info.srvbcd_bmi = null;

    return (
        <div className="clinical-information">
            <Card>
                <Title level={5}>Clinical Information</Title>

                <p className="size-hint">{loading ? 'Loading data' : 'Refer below information'}</p>

                {loading ? (
                    <>
                        <Skeleton />
                    </>
                ) : (
                    <div gutter={0} className="detail-wrapper">
                        <Row>
                            <Col span={12}>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>Height</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{info.srvbcd_height}</h3>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>Weight</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{info.srvbcd_weight}</h3>
                                    </Row>
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>BMI</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{info.srvbcd_bmi}</h3>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>Blood Pressure</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>
                                            {info.srvbcd_bpsys} - {info.srvbcd_bpdia}
                                        </h3>
                                    </Row>
                                </div>

                                <div className="detail-element">
                                    <Row span={12}>
                                        <span>Pulse</span>
                                    </Row>
                                    <Row span={12}>
                                        <h3>{info.srvbcd_pulse}</h3>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}

                <Button onClick={() => setVisible(true)} size={'small'} type="secondary">
                    {info.srvbcd_height ? 'Edit' : 'Add'}
                </Button>
            </Card>

            <Modal visible={visible} footer={null} className="" onCancel={() => setVisible(false)}>
                <Form
                    initialValues={{
                        srvbcd_height: info.srvbcd_height,
                        srvbcd_weight: info.srvbcd_weight,
                        srvbcd_bmi: info.srvbcd_bmi,
                        srvbcd_bpsys: info.srvbcd_bpsys,
                        srvbcd_pulse: info.srvbcd_pulse,
                    }}
                    layout="vertical"
                    onFinish={onSubmit}
                >
                    <p>Enter below details</p>

                    <Form.Item name={'srvbcd_height'} label="Height">
                        <InputComponent />
                    </Form.Item>

                    <Form.Item name={'srvbcd_weight'} label="Weight">
                        <InputComponent />
                    </Form.Item>

                    {/* <Form.Item name={'srvbcd_bmi'} label="BMI">
                         <Input />
                     </Form.Item> */}

                    <Form.Item name={'srvbcd_bpsys'} label="Blood Pressure">
                        <InputComponent />
                    </Form.Item>

                    <Form.Item name={'srvbcd_pulse'} label="Pulse">
                        <InputComponent />
                    </Form.Item>

                    <Form.Item>
                        <Button htmlType="submit" type="primary">
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}
