/**
 * Appointments Model
 *
 *
 * Appointments is a record created on
 *
 */
import React, { useState, useEffect, useContext } from 'react';

import Model from './models';

import { Link } from 'react-router-dom';

import { Tag, Card, Typography, Button } from 'antd';

import { ApiUtils, GetData } from 'soxo-bootstrap-core';

import moment from 'moment';

const { Text } = Typography;

class Appointments extends Model {
    constructor() {
        super();

        this.fields = [
            {
                field: 'name',
                caption: 'Name',
            },
            {
                field: 'amount',
                caption: 'Amount',
            },
        ];

        // this.columns = ;
    }

    get id() {
        return 'da_id';
    }

    get getEndpoint() {
        return 'doctor-appointment';
    }

    get path() {
        return `doctorappointment`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `doctorappointment`;
    }

    get columns() {
        return [
            {
                caption: 'Staff',
                field: 'staff.name',
                key: 'staff',
            },
            {
                caption: 'Weight',
                field: 'weight',
                key: 'weight',
            },
            {
                caption: 'Date',
                field: 'created_at',
                key: 'created_at',
            },
        ];
    }



    /** 
     * Search Config used for appointment Search .
     * 
     */
    searchConfig = {
        url: 'opreg/opreg-search',
        columns: (callback) => {
            return [
                {
                    field: 'op_number',
                    title: 'Op No',
                    dataIndex: 'op_number',
                },
                {
                    field: 'first_name',
                    title: 'First Name',
                    dataIndex: 'first_name',
                },
                {
                    field: 'last_name',
                    title: 'Last  Name',
                    dataIndex: 'last_name',
                }, {
                    field: 'email',
                    title: 'Email',
                    dataIndex: 'email',

                }, {
                    field: 'branch_name',
                    title: 'Branch',
                    dataIndex: 'branch_name',

                },


                {
                    // field: '',
                    title: 'Actions',
                    render: (record) => {
                        return (
                            <Button
                                onClick={(event) => {
                                    callback(record);
                                }}
                                type="link"
                            >
                                Select
                            </Button>
                        );
                    },
                },


            ];
        },
        searchBy: [
            {
                caption: 'First Name',
                field: 'first_name',
            },
            {
                caption: 'Last  Name',
                field: 'last_name',
            },
            {
                caption: 'Email',
                field: 'email',
            },
            {
                caption: 'Op no',
                field: 'op_number',
            }, {
                caption: 'User Name',
                field: 'user_name',
            },
            // {
            //     caption: 'Gender',
            //     field: 'gender',
            // },
            {

                caption: 'Address1',
                field: 'address1',
            },
            // {
            //     caption:'dob',
            //     field:'dob'

            // },
            {
                caption: 'Address2',
                field: 'address2'
            }, {
                caption: 'Mobile1',
                field: 'mobile1',
            }, {
                caption: 'mobile2',
                field: 'mobile2'

            },
        ],
        criteria: [
            {
                caption: 'Start with',
                field: 'start_with',

            }, {
                caption: 'End with',
                field: 'end_with',

            }, {

                caption: 'Any Where',
                field: 'anywhere',

            },

        ],
    };

    /**
     * Get appointment record details
     * @param {*} id 
     * @returns 
     */
    getAppointmentRecord = (id) => {
        return ApiUtils.get({
            url: `doctor-appointment/drappointment/${id}`,
        });
    }

    /**
     *
     * @returns
     */
    getAppointments = (pageNo = 1, range, limit = 10, userFilter) => {
        const start_time = moment(range[0]).format('MM/DD/YYYY');

        const end_time = moment(range[1]).format('MM/DD/YYYY');

        if (userFilter) {
            return ApiUtils.get({
                url: `doctor-appointment/appointments?da_date_gte=${start_time}&da_date_lte=${end_time}&filter_user=${userFilter}`,
            });
        } else {
            return ApiUtils.get({
                url: `doctor-appointment/appointments?da_date_gte=${start_time}&da_date_lte=${end_time}`,
            });
        }
    };

    // Time Slots
    getSlots = (selected_date, selected_item) => {
        return ApiUtils.get({
            url: `appntmnt-settings/getScheduledList?as_doctorptr=${selected_item.itm_slotdoctorptr}&date=${selected_date}&limit=200`,
        });
    };
    proceedForregistration = (formBody) => {
        return ApiUtils.post({ url: `appointment/proceed-payment`, formBody });
    }




    /**
     *
     * @returns
     */
    getUpcoming = () => {
        const start_time = moment().format('MM/DD/YYYY');

        const end_time = moment().endOf('year').format('MM/DD/YYYY');

        return GetData({
            url: `doctor-appointment?page=1&da_date_gte=${start_time}&da_date_lte=${end_time}&limit=100`,
        });
    };

    /**
     *
     *
     * @returns
     */
    getCountries = () => {
        return ApiUtils.get({
            url: `list-country`,
        });
    };

    getDoctorDeatils = (id) => {
        return ApiUtils.get({
            url: `doctor/${id}`,
        });
    };

    getBillNo = () => {
        return ApiUtils.get({
            url: `billnos/blno/OPR`,
        });
    };

    /**
     * Get consolidated search data, from prereg and opreg
     * allBranch is maintained to determine whether the search is to be done in all branches or the specific branch
     * @param {*} name 
     * @param {*} allBranch 
     * @returns 
     */
    consolidatedSearch = (formBody) => {
        return ApiUtils.post({
            // baseUrl: 'https://dev.nura.in/dev/',
            url: `opreg/opreg-consolidated-search`,
            formBody,
            headers: {
                db_ptr: 'nuramho'
            }

        })
    }

    /* Pincode Validation Api
    * @param {*} id 
    * @returns 
    */
    getPincodeDetails = (id) => {

        return ApiUtils.get({
            url: `list-country/pincode-details?id=${id}`,
        });

    }




    /**
     * Api is used to load prereg and opreg of exisiting guest
     * @param { } firm
     * @returns
     */
    loadPreregWithOpreg = (username, index) => {

        return ApiUtils.get({

            url: `prereg/load-prereg-with-opreg?username=${username}`,
            headers: {
                db_ptr: index,
            },
        });
    };
    /**
     *
     *  Api is used to create new prereg and opreg for new loaction
     */
    preregValidation = (formBody) => {

        return ApiUtils.post({

            url: `prereg/registration-validation`,
            formBody,
        });
    };

    /**
     * Create prereg or opreg for existing customer, in case of repeated flow
     * @param {*} opreg 
     * @returns 
     */
    createExistingCustomer = async (opreg) => {

        const result = await this.loadPreregWithOpreg(opreg.user_name, opreg.db_ptr)
        
        if (result.data) {

            /* Api is used to create new prereg and opreg for new loaction */
            const opReg = await this.preregValidation(result.data);
            return opReg
        }
    }



    /**
     * API Call searches for registration
     *
     * @param {*} name
     * @returns
     */
    searchSameBranchRegistration = (name) => {
        return ApiUtils.get({
            // url: `opreg/opreg-details?name=${name}`,
            url: `opreg/opreg-query?name=${name}`,
        });
    };

    applyCoupon = (coupon, itm_code) => {
        return ApiUtils.get({
            url: `campgndiscountcoupons/validate?coupon_no=${coupon}&item=${itm_code}`,

            // url: `campgndiscountcoupons/couponnumber/${coupon}`,
        });
    };

    getCommunication = () => {
        return ApiUtils.get({
            url: `communication`,
        });
    };

    getCityList = (country) => {
        return ApiUtils.get({
            url: `list-country/city/${country}`,
        });
    };

    cancelAppointment = (id, formBody) => {
        return ApiUtils.put({
            url: `doctor-appointment/cancelAppointment/${id}`,
            formBody,
        });
    };


    /**
     * API Call updates data in doctorappointment table
     * 
     * @param {*} id 
     * @param {*} formBody 
     * @returns 
     */
    updateAppointment = (id, formBody) => {
        return ApiUtils.put({
            url: `doctor-appointment/update-drappointment/${id}`,
            formBody,
        });
    };



    /**
     * API Call updates fitkit status in doctorappointment table and send mail
     * 
     * @param {*} id 
     * @param {*} formBody 
     * @returns 
     */

    updateFitkit = (id, formBody) => {
        return ApiUtils.post({
            url: `doctor-appointment/fitkit-status-update/${id}`,
            formBody,
        });
    };


    /**
     * API Call updates report status in doctorappointment table and send mail
     * 
     * @param {*} id 
     * @param {*} formBody 
     * @returns 
     */

    updateReport = (id, formBody) => {
        return ApiUtils.post({
            url: `doctor-appointment/report-status-update/${id}`,
            formBody,
        });
    };

    rescheduleAppointment = (id, formBody) => {
        return ApiUtils.put({
            url: `doctor-appointment/reschedule/${id}`,
            formBody,
        });
    };

    createOtherBranchAppointment = (formBody) => {
        return ApiUtils.post({
            url: `opreg/create-other-branch-registration`,
            formBody,
            headers: {
                db_ptr: 'nuramho',
            },
        });
    };

    createAppointment = (formBody) => {
        return ApiUtils.post({
            url: `doctor-appointment/create-drappointment`,
            formBody,
        });
    };
    /**
     *  This Api is used to find Doctor Appointment
     * @param {*} id 
     * @returns 
     */
    getRecord = (id) => {
        return ApiUtils.get({
            url: `doctor-appointment/drappointment/${id}`,
        });
    };
    createOrder = (formBody) => {
        return ApiUtils.post({
            url: `razorpay/pay`,
            formBody,
        });
    };

    /**
     * Get the data from the table
     */
    // get = (queries, model) => {

    //     return ApiUtils.get().then(()=>{

    //     })

    //     return FirebaseUtils.getRecords(queries, model).then((result) => {

    //         return Promise.all(result.collections.map(this.loadStaff)).then((result) => {

    //             return { collections: result };

    //         })
    //     })
    // }

    // Attach the related staff with the object
    loadStaff = (item) => {
        // return Staffs.getRecord(item.staff, Staffs).then((result) => {
        //     return {
        //         ...item,
        //         staff: result
        //     }
        // })
    };

    // Attach the related staff with the object
    getDetail = (url) => {
        return fetch(url).then((res) => res.json());
    };

    // Self Check in Api

    checkIn = (opno) => {
        return GetData({ url: `doctor-appointment/check-in/${opno}` });
    };

    /**
     * Card Component for Staff
     *
     * @param {*} record
     * @returns
     */
    // Card = (record) => {
    //     return (
    //         <Link className="booking-card card card-shadow" to={`/collections/${record['cold_id']}`}>
    //             <div className="card">
    //                 <h2 className="">{record.cold_user ? record.cold_user : null}</h2>

    //                 <h3 className="title">{record.cold_amt}</h3>

    //                 <p className="address">Collected on {record.cold_tm}</p>

    //                 <Tag color="magenta">{record['cold_mode']}</Tag>
    //             </div>
    //         </Link>
    //     );
    // };

    Card = ({ ...props }) => {
        return <GuestCard {...props}></GuestCard>;
    };

    SmallCard = ({ record }) => {
        return (
            <Link to={`/appointment/${record.da_id}`} className="card small-card">
                <div className="card">
                    <div>
                        <h4 className="title">
                            {record.da_fname} {record.da_lname}
                        </h4>

                        <p className="values">
                            {moment(record.da_date).format('dddd, MMM Do')} {record.da_aptime}
                        </p>
                    </div>

                    <Button size="small" className="">
                        View
                    </Button>

                    {/* {record.da_newopno ? <Tag color="green">Booked</Tag> : <Tag color="orange">Pending</Tag>} */}
                </div>
            </Link>
        );
    };

    //  AddComponent = () => <CollectionAdd model={this} />
}

function GuestCard({ record = {} }) {
    return (
        <Card className="">
            <div className="card">
                <div className="title">
                    <h1>
                        Name:{' '}
                        <strong>
                            {record.appointment.first_name ? record.appointment.first_name.toUpperCase() : null} {record.appointment.last_name ? record.appointment.last_name.toUpperCase() : null}
                        </strong>
                    </h1>
                </div>

                <div className="values">
                    {record.appointment.appointment_time ? (
                        <p>
                            <strong>Appointment Time: {record.appointment.appointment_time}</strong>
                        </p>
                    ) : null}
                </div>

                <div className="values">
                    {record.item ? (
                        <p>
                            <strong>Package: {record.item.description}</strong>
                        </p>
                    ) : null}
                </div>

                <div className="values">{record.registration && record.registration.yesr ? <p> Age: {record.registration.year}</p> : null}</div>

                <div className="values">{record.appointment.token ? <p>Token No.: {record.appointment.token}</p> : null}</div>

                <div>
                    <strong>Bill Status</strong> <Tag color={record.appointment.bill_id ? 'green' : 'orange'}>{record.appointment.bill_id ? 'Billed' : 'Pending'}</Tag>
                    <strong>Questionnaire Status</strong>{' '}
                    <Tag color={record.appointment.questionnaire_filled === 'F' ? 'green' : 'orange'}>{record.appointment.questionnaire_filled === 'F' ? 'Filled' : 'Pending'}</Tag>
                    <strong> Appointment Status</strong> {record.appointment.op_number ? <Tag color="green">Booked</Tag> : <Tag color="orange">Pending</Tag>}
                </div>

                {/* <h3 className="values">
                Primary Result : {record.PrimaryResult || 'Pending'}

            </h3> */}

                <Text type="secondary">{record.place}</Text>

                <div className="values" style={{ marginTop: '10px' }}>
                    {record.appointment.date ? <p>Date: {moment(record.appointment.date).format('DD/MM/YYYY')}</p> : null}
                </div>
            </div>
        </Card>
    );
}

export default Appointments;

/**
 * Make payment with Razorpay
 */
//    function makePayment(record) {
//     if (record.preReg) {
//         // Create Order
//         Appointments.createOrder({ amount: record.da_billvalue, currency: 'INR' })
//             .then((result) => {
//                 var options = {
//                     key: process.env.REACT_APP_RAZORPAY_KEY,
//                     amount: result.amount,
//                     name: 'Nurapay',
//                     order_id: result.id,
//                     description: 'Description',
//                     // image: environment.url + 'assets/img/logo.png',
//                     prefill: {
//                         name: '',
//                         email: record.preReg.prereg_email,
//                         contact: record.preReg.prereg_mobile1,
//                         method: '',
//                     },
//                     // modal: {},
//                     theme: {
//                         color: '#FAC141',
//                     },
//                     // 'handler': this.razorPaySuccess.bind(this),
//                     handler: (success) => { },
//                     modal: {
//                         ondismiss: () => { },
//                     },
//                 };

//                 let razorpay = new window.Razorpay(options);

//                 razorpay.open();
//             })
//             .catch((error) => {
//                 message.error(JSON.stringify(error));
//             });
//     } else {
//         message.error('Phone Number/Email is missing');
//     }
// }
