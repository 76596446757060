import React, { useState, useEffect, useContext } from 'react';
import { Card, Space, DatePicker, Switch, Select, Tag, Button, Typography, Table, Skeleton, Descriptions, Checkbox, Popover } from 'antd';

import { Route, Link } from 'react-router-dom';

import { Location, GlobalContext } from 'soxo-bootstrap-core';

import GuestInfo from '../../../common/components/guest-info/guest-info';

// import { GlobalContext } from "../../../../Store";

import { Tabs } from 'antd';

import moment from 'moment-timezone';

import { Registrations } from '../../../../models/';

// import './appointments.scss';

import { ReloadOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;

const { RangePicker } = DatePicker;

const { TabPane } = Tabs;

export default function RegistrationDetail(props) {
    var param = Location.search();

    const [range, setRange] = useState([
        moment.tz(param.start_time, 'Asia/Calcutta').startOf('day'),
        moment.tz(param.end_time, 'Asia/Calcutta').endOf('day'),
    ]);

    const { user = {}, isMobile } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);

    const { pageno, id } = props.match.params; //Get pagination number

    const [pageNo, setPageNo] = useState(pageno); // Pagination number

    const [guest, setGuest] = useState({});

    const [guestdr, setGuestDoctorAppointment] = useState({});

    const [guestitm, setGuestItem] = useState({});

    const [guestpreReg, setGuestPreReg] = useState({});

    const [guestevent, setGuestEvent] = useState({});

    var urlParams = Location.search();

    console.log(props.match.params);

    //PatiantData fetching function
    async function getRegistrations(id) {
        setLoading(true);

        Registrations.getRegistrationRecord(id).then((result) => {
            setGuest(result.result);
            if (result.result.drAppointment) setGuestDoctorAppointment(result.result.drAppointment); //Doctor Appoointment
            if (result.result.drAppointment && result.result.drAppointment.item) setGuestItem(result.result.drAppointment.item); //Item and Package
            if (result.result.drAppointment && result.result.drAppointment.preReg) setGuestPreReg(result.result.drAppointment.preReg); //Pre Registration
            if (
                result.result.drAppointment &&
                result.result.drAppointment.opbill &&
                result.result.drAppointment.opbill.Eventtran &&
                result.result.drAppointment.opbill.Eventtran.eventsubmas
            )
                setGuestEvent(result.result.drAppointment.opbill.Eventtran.eventsubmas); //Eventtran

            console.log(result.result);

            setLoading(false);
        });
    }

    useEffect(() => {
        if (user) {
            getRegistrations(id);

            // getPatientDetails(pageNo, range); //Patiant list get function
        }
    }, [user]);

    function refresh() {
        getRegistrations(id);
    }

    function callback(key) {
        console.log(key);
    }

    return (
        <section className="appointments">
            {/* Page Header */}

            <div className="page-header">
                <div className="left">
                    <Title style={{ marginBottom: '0px' }} level={3}>
                        Registration
                    </Title>
                </div>
                <div className="right">
                    {/* <Button
                        onClick={() => {
                            Location.navigate({ url: `/new-registration/guest?da_id=${guest.drAppointment.da_id}&da_packagetypevalue=${guest.drAppointment.da_packagetypevalue}&prereg_id=${guest.drAppointment.da_referenceid}&prereg_opno=${id}&edit=true` });

                        }}
                        type="secondary" size={'small'}
                    >
                        Edit Registration
                    </Button> */}
                    <Button onClick={refresh} type="secondary" size={'small'}>
                        <ReloadOutlined />
                    </Button>
                </div>
            </div>
            {/* Page Header Ends */}

            <p className="size-hint">{/* {loading ? 'Loading' : patients.length} records. */}</p>

            {/* Content Below */}
            {loading ? (
                <>
                    <Skeleton active />
                </>
            ) : (
                <>
                    {/* <Tabs onChange={callback} type="card"> */}
                    {/* <GuestRecord record={guest} /> */}
                    {/* <GuestInfo guestreg={guest} guestdr={guestdr} guestitm={guestitm} guestevent={guestevent} guestprereg={guestpreReg} /> */}

                    {/* </Tabs> */}
                    <div>
                        <div className="registration-timeline-guest">
                            <div className="guest-detail card-shadow card">
                                {/*Guest Information */}
                                <GuestInfo
                                    guestreg={guest}
                                    guestdr={guestdr}
                                    guestitm={guestitm}
                                    guestevent={guestevent}
                                    guestprereg={guestpreReg}
                                    params={props.match}
                                    urlParams={urlParams}
                                    // Temporary flag to disable questionnaire
                                    disableQuestionnaire={true}
                                />
                            </div>
                            {/*Appointment Timeline */}
                            {/* <AppointmentTimeline guest={guestdr} guestopreg={guest} guestitem={guestitm} /> */}
                        </div>
                    </div>
                </>
            )}
        </section>
    );
}

function GuestRecord({ record }) {
    function goQues(id, edit) {
        let payload = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization:
                    'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImRlbW8iLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3VzZXJkYXRhIjoiZmY6MToxOmRlczpzdWIiLCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL2V4cGlyYXRpb24iOiJTdW5kYXksIEF1Z3VzdCAxLCAyMDIxIiwibmJmIjoxNjI1MjMzNjA0LCJleHAiOjE2Mjc4MjU2MDQsImlhdCI6MTYyNTIzMzYwNH0.mY0Mlv9eBNUDupmvRlIX6roqLzLafkPjIQE8b23JdCs:demo',
            },
        };

        return fetch(`${process.env.REACT_APP_OLD_BACKEND_URL}/doctorappointment/Getkey?id=${id}`, payload)
            .then((res) => res.json())
            .then((res) => {
                if (edit) {
                    window.open(`${process.env.REACT_APP_QUES_KEY}/staff/questionnaire?id=${id}&key=${res.Result}&edit=true`, '_blank');
                } else {
                    window.open(`${process.env.REACT_APP_QUES_KEY}/staff/questionnaire?id=${id}&key=${res.Result}`, '_blank');
                }
            })
            .catch((error) => {
                return;
            });
    }

    return (
        <>
            {record.op_id ? (
                <Card className="card-shadow card">
                    <Descriptions>
                        <Descriptions.Item label="Name">
                            <strong>
                                {record.op_fname} {record.op_lname} ({record.op_gender}) - {record.op_age}
                            </strong>
                        </Descriptions.Item>
                        <Descriptions.Item label="Mobile">{record.op_phone}</Descriptions.Item>
                        <Descriptions.Item label="Date">{moment(record.op_dt).format('DD/MM/YYYY')}</Descriptions.Item>
                        <Descriptions.Item label="Package Name">{record.drAppointment && record.drAppointment.da_packagetypevalue}</Descriptions.Item>
                        <Descriptions.Item label="Questionnaire Status">
                            {record.drAppointment && record.drAppointment.da_questfilled === 'F' ? (
                                <div>
                                    {record.drAppointment.da_questfilled}
                                    <br />
                                    <Button
                                        size="small"
                                        type="primary"
                                        onClick={() => {
                                            goQues(record.drAppointment.da_id, true);
                                        }}
                                    >
                                        Edit Questionnaire
                                    </Button>
                                </div>
                            ) : (
                                <Button
                                    size="small"
                                    type="primary"
                                    onClick={() => {
                                        goQues(record.drAppointment.da_id);
                                    }}
                                >
                                    Start Questionnaire
                                </Button>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Payment Status">
                            {record.drAppointment && record.drAppointment.da_billid ? 'Billed' : 'Pending'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Billing Status">
                            {/* <Button
                                className="billing"
                                onClick={() => {
                                    // proceedBilling();
                                }}
                                type="secondary"
                                shape="round"
                                style={{ background: "#FAC141" }}
                                // size="small"
                                color="green"
                            >
                                Proceed for Billing
                            </Button> */}
                        </Descriptions.Item>

                        <Descriptions.Item label="Current Status">{record.da_status}</Descriptions.Item>
                        <Descriptions.Item label="Current Status Remarks">{record.drAppointment.da_remarks}</Descriptions.Item>
                    </Descriptions>
                </Card>
            ) : null}
        </>
    );
}
