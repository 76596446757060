import React from 'react';

import { Tag, Timeline, Card, Row } from 'antd';

import './registration-info.scss';

import moment from 'moment';

import { Avatar } from 'antd';

import { UserOutlined } from '@ant-design/icons';

export default function GuestInfo({ guest = {} }) {
    let gender = null;
    if (guest.opb_gender === 'Male') {
        gender = 'Male';
    } else if (guest.opb_gender === 'M') {
        gender = 'Male';
    }

    return (
        <>
            <div className="registration-info card">
                <Avatar shape="square" size={64} icon={<UserOutlined />} />

                <div gutter={0} className="detail-wrapper">
                    <div className="detail-element">
                        <Row span={12}>
                            <span>Customer</span>
                        </Row>
                        <Row span={12}>
                            <h3>{guest.opb_name}</h3>
                        </Row>
                    </div>

                    <div className="detail-element">
                        <Row span={12}>
                            <span>Age</span>
                        </Row>
                        <Row span={12}>
                            <h3>{guest.opb_age}</h3>
                        </Row>
                    </div>

                    <div className="detail-element">
                        <Row span={12}>
                            <span>Gender</span>
                        </Row>
                        <Row span={12}>
                            <h3>{gender === 'Male' ? 'Male' : 'Female'}</h3>
                        </Row>
                    </div>

                    <div className="detail-element">
                        <Row span={12}>
                            <span>Appointment Date</span>
                        </Row>
                        <Row span={12}>
                            <h3>{moment(guest.opb_tm).format('dddd, MMM Do')}</h3>
                        </Row>
                    </div>

                    <div className="detail-element">
                        <Row span={12}>
                            <span>Mobile</span>
                        </Row>
                        <Row span={12}>
                            <h3>{guest.opb_mobile}</h3>
                        </Row>
                    </div>

                    <div className="detail-element">
                        <Row span={12}>
                            <span>OP No</span>
                        </Row>
                        <Row span={12}>
                            <h3>{guest.opb_opno}</h3>
                        </Row>
                    </div>

                    <div className="detail-element">
                        <Row span={12}>
                            <span>Order No</span>
                        </Row>
                        <Row span={12}>
                            <h3>{guest.opb_bno}</h3>
                        </Row>
                    </div>
                </div>
            </div>
        </>
    );
}
