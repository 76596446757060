/**
 * Bill Model
 *
 *
 *
 *
 */
import React, { useState, useEffect, useContext } from 'react';

import { ApiUtils, BaseAPI } from 'soxo-bootstrap-core';

import { Tag, Card, Typography, Button } from 'antd';
// import { Referrers } from '.';

// import BillDetailView from './../modules/common/components/bill-detail/bill-detail';

// import BillDetail from './../modules/common/components/bill-detail/bill-detail';

const { Text } = Typography;

const moment = require('moment-timezone');

class ItemRateType extends BaseAPI {
    constructor() {
        super();
    }

    get id() {
        return 'itmrt_code';
    }

    get getEndpoint() {
        return 'item-rate-types';
    }

    get path() {
        return `item-rate-types`;
    }

    get getName() {
        return this.modelName;
    }

    get modelName() {
        return `item-rate-types`;
    }
}

export default ItemRateType;
