/**
 * Component to search by name and opno used inside opreg search component
 *
 * @author Jinisha
 */

import React, { useState, useCallback } from 'react';

import { Input, Modal, Button, Table, Select, Form, Row, Col, Typography, Skeleton, Checkbox } from 'antd';

import { Appointments, Opreg, Opvisits, Registrations } from '../../models';

import { DateUtils, SearchDebounce } from 'soxo-bootstrap-core';

import _debounce from 'lodash/debounce';

const { Search } = Input;

const { Title } = Typography;

const { Option } = Select;

export default function AdvanceSearch({ model, enableVisit, callback, title, setVisible }) {
    let config = model.searchConfig;

    let [searchby, setSearchBy] = useState(model.searchConfig.searchBy[0].field);

    let [criteria, setCriteria] = useState(model.searchConfig.criteria[0].field);

    const [loading, setLoading] = useState(false);

    let [opvisitView, setOpvisitView] = useState(false);

    const [visit, setVisit] = useState([]);

    let [result, setResult] = useState([]);

    const [allbranch, setAllbranch] = useState(true);
    // Selected matching result from the result
    const [selected, setSelected] = useState({});

    /**
     * Columns used for Visit Information
     *
     */

    const columns = [
        // {
        //     title: '#',
        //     dataIndex: 'index',
        // },
        {
            title: 'Op No',
            key: 'name',
            render: (ele) => {
                return ele.opv_opno;
            },
        },
        {
            title: 'Name',
            key: 'name',
            render: (ele) => {
                // var Opreg = result.filter((item) => {
                //     return ele.opv_opno == item.op_no;
                // });

                return selected.op_fname;
            },
        },
        {
            title: ' Visit Date',
            key: 'date',
            render: (ele) => {
                return DateUtils.formatDate(ele.opv_dt);
            },
        },
        {
            title: 'Contact',
            key: 'contact',
            render: (ele) => {
                return selected.op_mobile;
            },
        },
        {
            // field: '',
            title: 'Actions',
            render: (ele) => {
                return (
                    <Button
                        onClick={(event) => {
                            callback(ele.visit_id, ele.op_no);
                        }}
                        type="link"
                    >
                        {' '}
                        View
                    </Button>
                );
            },
        },
    ];

    // /**
    // * Handle search using debounce
    // *
    // * @param {*} event
    // */
    // function handleSearch(event) {

    //     setOpvisitView(false);

    //     setResult([]);

    //     debounceFunction(event.target.value);
    // }

    // /**
    //  * Function debounces callback passed
    //  */
    //    const debounceFunction = useCallback(_debounce(loadSearch, 1000), []);

    /**
     * Is used to Load search heads based on the query .
     *
     * @param {*} event
     * @returns
     */
    async function loadSearch(searchQuery) {
        /**
         * Form Body
         *
         */
        let formBody = {
            search_by: searchby,
            criteria: criteria,
            search_text: searchQuery,
        };

        /**Check condition for  Search ALL Branches .
         * If All branches selected the index is passing ALL .
         *  Otherwise index is corresponding branch db_ptr
         * **/
        if (allbranch) {
            formBody = {
                ...formBody,
                index: 'ALL',
            };
        } else {
            formBody = {
                ...formBody,
                index: localStorage.db_ptr?localStorage.db_ptr:process.env.REACT_APP_DB_PTR,
            };
        }

        if (model.searchConfig.url) {
            setLoading(true);

            let queries = [
                {
                    field: searchby,
                    value: searchQuery,
                },
            ];

            var result;
            if (enableVisit) {
                // opreg serach For stoptb
                result = await Opreg.get({ url: 'opreg/search', queries });

                setResult(result);
            } else {
                // consolidatedSearch For nura only
                let value = await Appointments.consolidatedSearch(formBody);
                setResult(value.result);
            }

            setLoading(false);
        }
    }

    /**
     * Function triggered when an opreg is selected
     *
     * @param {*} record
     */
    const onSelectRecord = (record) => {
        if (enableVisit) {
            // If Enablevisit get visit details with op_no
            getPatientHistory(record.op_no);

            setOpvisitView(true);

            setSelected(record);
        } else {
            setSelected(record);

            callback(record);
            setVisible(false);
        }
    };

    /**
     * Get the opvisit based on corresponding opno
     *
     * @param {*} op_number
     */
    async function getPatientHistory(val) {
        await Opvisits.getPatientHistory(val).then((result) => {
            if (result.result) {
                setVisit([...result.result]);
            }
        });
    }

    /**
     * Update all the branch
     *
     * @param {*} value
     */
    function updateAllBranch(event) {
        setAllbranch(event.target.checked);
    }

    return (
        <div>
            <div>
                <Title level={4}>{title}</Title>
            </div>
            <div className="card">
                <Form>
                    <Row gutter={12}>
                        {/** Search Section Start */}

                        {/** Search  By Section Start  */}
                        <Col xs={24} md={12} xl={7}>
                            <h4>SEARCH BY</h4>
                            <Form.Item>
                                <Select
                                    defaultValue="First Name"
                                    //   style={{ width: "99%" }}
                                    onChange={(i) => {
                                        setSearchBy(i);
                                    }}
                                >
                                    {model.searchConfig.searchBy.map((option, key) => (
                                        <Option value={option.field}>{option.caption}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        {/** Search B Section end  */}

                        <Col xs={24} md={12} xl={8}>
                            <h4>SEARCH</h4>

                            <SearchDebounce
                                value={''}
                                onChange={(value) => {
                                    if (value.length) {
                                        loadSearch(value);
                                    }
                                }}
                            />
                            {/* <Form.Item>
                                <Search
                                    placeholder="Enter Search Value"
                                    // allowClear
                                    onChange={handleSearch}
                                />
                            </Form.Item> */}
                        </Col>
                        {/** Search section end  */}

                        {/** Criteria Section  starts */}
                        <Col xs={3} md={6} xl={6}>
                            <h4>CRITERIA</h4>

                            <Form.Item>
                                <Select
                                    defaultValue="Start With"
                                    //   style={{ width: "99%" }}
                                    onChange={(i) => {
                                        setCriteria(i);
                                    }}
                                >
                                    {model.searchConfig.criteria.map((option, key) => (
                                        <Option value={option.field}>{option.caption}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        {/** Criteria section End */}
                    </Row>
                    <div className="checkbox-input" style={{ width: '350px' }}>
                        <Checkbox defaultChecked={allbranch} value={allbranch} onChange={updateAllBranch} />

                        <span className="caption">Search All Branches</span>
                    </div>
                </Form>
            </div>

            {/* Block for showing Opreg search */}
            {!opvisitView ? (
                <>
                    {/* Table Hint Section */}
                    <p className="size-hint">{result ? result.length : 0} Matches Found For Your Search .</p>
                    {/* Table Hint Section Ends */}

                    {/* Listing Matching Opreg */}

                    {loading ? (
                        <Skeleton />
                    ) : (
                        <Table
                            columns={config.columns((record) => {
                                onSelectRecord(record);
                            })}
                            dataSource={result}
                        ></Table>
                    )}
                    {/* Listing Matching Opreg Ends */}
                </>
            ) : null}
            {/* Block for showing Opreg search Ends */}

            {/* Block for showing Opvisit list */}
            {opvisitView ? (
                <>
                    {/* Table Hint Section */}
                    <h2 className="size-hint">{visit ? visit.length : 0} Visits Found under this OP number .</h2>
                    {/* Table Hint Section Ends */}

                    <Table columns={columns} dataSource={visit}></Table>
                </>
            ) : null}
        </div>
    );
}
