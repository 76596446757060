/**
 * Sample Collection model to group all apis related to it
 *
 *
 * To seperate all logic
 *
 */

import Model from './models';

import { ApiUtils } from 'soxo-bootstrap-core';

import moment from 'moment-timezone';

class Reports extends Model {
    constructor() {
        super();

        this.title = 'Reporting List';

        this.fields = [
            {
                field: 'name',
                caption: 'Name',
            },
            {
                field: 'amount',
                caption: 'Amount',
            },
        ];

        // this.columns = ;
    }

    /**
     * Get list data
     */
    getListing = (range, pageNo, user) => {
        const start_date = moment(range[0]).format('MM/DD/YYYY');

        const end_date = moment(range[1]).format('MM/DD/YYYY');

        return ApiUtils.get({ url: `opbill?start_date=${start_date}&end_date=${end_date}` });
    };

    /**
     * Record Sample Collection
     *
     * @param {*} formBody
     * @returns
     */
    collectSample = (params, user) => {
        var formBody = {
            lbss_refmode: 'B',
            lbss_hid: 0,
            lbss_refid: params.bill_id,
            lbss_tranid: 0,
            lbss_itemptr: params.lbss_itemptr,
            lbss_samtranmode: 'SC',
            lbss_samtranstatus: '',
            lbss_samtrandttm: params.lbss_samtrandttm.format('YYYY-MM-DD hh:mm:ss'),
            lbss_notes: '',
            lbss_remarks: params.lbss_remarks,
            lbss_slno: 0,
            lbss_user: user.email,
            lbss_canedit: 'Y',
            // lbss_canflg: 'string',
            // isTestOrder: params.is_test_order,
        };

        return ApiUtils.post({ url: 'labsamplestatus', formBody });
    };

    /**
     * Reject a sample
     *
     * @param {*} opbill
     * @returns
     */
    rejectSample = () => {
        return ApiUtils.get({ url: 'labsamplestatus' });
    };

    /**
     * Get all the specimens to be collected for the bill
     *
     * @param {*} opbill
     * @returns
     */
    getSpecimens = (opbill) => {
        return ApiUtils.get({ url: 'labtest/specimen?bill_id=' + opbill.opb_id });
    };

    /**
     * Get the content for the report for review
     *
     * @param {*} opbill
     * @returns
     */
    getReportContent = (opbill) => {
        console.log('opbill', opbill);
        // op_no=NURA156&bill_no=170
        return ApiUtils.get({ url: 'testreport/GuestVitals?bill_no=' + opbill.opb_bno + '&op_no=' + opbill.opb_opno });
        // return ApiUtils.get({ url: 'testreport/final-report?bill_no=' + opbill.opb_bno + '&op_no=' + opbill.opb_opno });
    };

    physicianNote = (data, id) => {
        var formBody = {
            srvbcd_doctornotes: data,
        };

        return ApiUtils.put({ url: `servicebaseclinicaldettran/${id}`, formBody });
    };

    /**
     * Get the content for the report for review
     *
     * @param {*} opbill
     * @returns
     */
    generateReport = (opbill) => {
        return ApiUtils.get({ url: 'testreport/fill-pdf?bill_no=' + opbill.opb_bno + '&op_no=' + opbill.opb_opno });
    };

    /**
     * Get all
     *
     * @param {*} selected
     * @param {*} opbill
     * @returns
     */
    getSpecimenDetail = (selected, opbill) => {
        return ApiUtils.get({ url: 'opbill/specimen-detail?bill_id=' + opbill.opb_id + '&specimen=' + selected });
    };

    getSampleList = (opbill, opbilld) => {
        return ApiUtils.get({ url: 'labsamplestatus/sample-list?billid=' + opbill.opb_id + '&itemid=' + opbilld.labTest.lbt_itemptr });
    };

    get id() {
        return 'cold_id';
    }

    get getEndpoint() {
        return 'colldetails';
    }

    get path() {
        return `collections`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `collection`;
    }

    get columns() {
        return [
            {
                caption: 'Staff',
                field: 'staff.name',
                key: 'staff',
            },
            {
                caption: 'Weight',
                field: 'weight',
                key: 'weight',
            },
            {
                caption: 'Date',
                field: 'created_at',
                key: 'created_at',
            },
        ];
    }

    /**
     *
     */
    createNew = (id) => {
        return ApiUtils.post({ url: 'opbill/kiosk/' + id });
    };
}

export default Reports;
