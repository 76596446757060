/**
 * Bill Model
 *
 *
 *
 *
 */
import React, { useState, useEffect, useContext } from 'react';

import { Tag, Card, Typography, Button, message } from 'antd';


import { ApiUtils, BaseAPI, Location } from 'soxo-bootstrap-core';

import { Registrations } from '.';
// import { Referrers } from '.';

// import BillDetailView from './../modules/common/components/bill-detail/bill-detail';

// import BillDetail from './../modules/common/components/bill-detail/bill-detail';

const { Text } = Typography;

const moment = require('moment-timezone');

class Opreg extends BaseAPI {

    constructor() {

        super();

        this.fields = [
            {
                field: 'opb_name',
                caption: 'Name',
            },
            {
                field: 'opb_opno',
                caption: 'num',
            },
            // {
            //     field: 'cold_tm',
            //     caption: 'Date'
            // },
            // {
            //     field: 'Collected By',
            //     caption: 'cold_user'
            // }
        ];

        // this.columns = ;
    }

    get id() {
        return 'opreg';
    }

    get getEndpoint() {
        return 'opreg';
    }

    get path() {
        return `opreg`;
    }

    get getName() {
        return this.modelName;
    }

    get modelName() {
        return `opreg`;
    }

    disableAdd = () => {
        return true;
    };


    createRegistration = (formBody) => {
        return ApiUtils.post({
            url: `opreg/op-create`,
            formBody,
        });
    };

    /**
     * Save registration for StopTB
     * 
     * @param {*} values 
     * @returns 
     */
    saveNewRegistration = (formBody) => {

        return ApiUtils.post({ url: `opreg/registration-create`, formBody });
    }

    /**
     * edit registration
     */
    editRegistration = (formBody, id) => {

        return ApiUtils.put({ url: `opreg/update-registration/${id}`, formBody });
    };

    /**
     * On Change of values in the form
     * We have to trigger all the configuration
     *
     * @param {*} field
     * @param {*} values
     */
    onValuesChange = (keys, values, form, gender, fields, add_on_fields, onChange, initialValues) => {
        // Key
        let field = Object.keys(keys)[0];

        // Load the configuration
        let fieldConfiguration = fields[field];

        if (!fieldConfiguration) {
            fieldConfiguration = add_on_fields[field];
        }

        // We have predefined handlers that needs to work for all forms
        if (field === 'gender') {

            this.onGenderChange(values.gender, gender, form);
        }

        if (field === 'title') {
            this.onTitleChange(values.title, form ,initialValues);
        }

        if (field === 'pincode') {

            // This condition is check field is op_zip  , This Condition is decide to trigger onChangePincode function
            let address2 = form.getFieldValue('address2');

            form.setFieldsValue({ country: null, place: null, state: null });

            if (values.pincode >= 6) {
                if (address2) {
                    this.onChangePincode(form, values.pincode, address2);
                } else {
                    this.onChangePincode(form, values.pincode);
                }
            }
        }

        // Call the onChange listner of the hook
        // to trigger any listner that is specified in the config
        onChange({
            field,
            fieldConfiguration,
            values,
        });
    };

    /**
     *  The function for  Tittle is selected the gender should pre populate
     * @param {*} value
     */
    onTitleChange = (value, form,initialValues) => {
        var genderSelected;
        const values = form.getFieldValue();

        if (value === 'Mr.') {
            genderSelected = 'Male';
        } else if (value === 'Ms.' || value === 'Mrs.') {
            genderSelected = 'Female';
        } else if (value === 'Dr.') {
            genderSelected = initialValues && initialValues.gender ? initialValues.gender : values.gender
        }
        else {
            genderSelected = initialValues && initialValues.gender ? initialValues.gender : values.gender
        }
        form.setFieldsValue({ gender: genderSelected });
    }

    /**
   *  When gender is selected the titile should pre populate
   * Gneder is also validated in this function
   * 
   * @param {*} value
   */
    onGenderChange = async (value, gender, form) => {

        var titleSelected;
        var validGender;

        // Get form values, if selected gender is other then the title value shold be kept as before
        const values = form.getFieldValue()

        if (values.title === 'Dr.') {

            validGender = true

        } else {

            // Validate the selected gender, If the selected gender is same to the gender for the selcted item
            validGender = await this.validateGender(value, gender)

        }
        if (!validGender) {
            message.warning('You have Selected the Wrong Gender');
        } else {
            if (value === 'Male') {
                titleSelected = values.title ? values.title : 'Mr.';
            } else if (value === 'Female') {
                titleSelected = values.title ? values.title : 'Ms.';
            } else {
                titleSelected = values.title
            }
            form.setFieldsValue({ title: titleSelected });
        }

    }

    /**
   * Function to validate if the package selected is for the same gender selected
   * If gender is not Both(returned for DR title in onTitleChange) gender is validated according to the gender of item
   * @param {*} gender 
   * @returns 
   */
    validateGender = (gender, genderItem) => {
        const params = Location.search()
        var itemGender
        if (gender !== 'Both') {
            if (params.item_gender === 'M' || genderItem === 'M') {
                itemGender = 'Male';
            } else if (params.item_gender === 'F' || genderItem === 'F') {
                itemGender = 'Female';
            } else if (params.item_gender === 'PF' || genderItem === 'PF') {
                itemGender = 'Female'
            } else
                itemGender = null

            if (itemGender != null) {
                if (gender !== itemGender)
                    return false;
                else return true
            } else
                return true
        } else {
            return true
        }
    }

    /**
 *  This function is used for prepopulate  country ,state and city  Based on pincode.
 * if we enter any pincode by using the Api response it prepopulate city And country ,state
 * @param {*} value
 */
    onChangePincode = (form, value, address2) => {
        Registrations.getPincodeDetails(value).then((result) => {
            if (result.data) {
                form.setFieldsValue({
                    country: result.data.country,
                    place: result.data.city,
                    state: result.data.state,
                    address2: address2 ? address2 : result.data.post_office,
                });
            }
        });
    }



    get columns() {
        return [
            {
                field: 'opb_name',
                render: (record) => {
                    return record.opb_name;
                },
                caption: 'Name',
            },
            {
                field: 'opb_gender',
                render: (record) => {
                    return <Tag color={record.opb_gender === 'Male' ? 'green' : 'orange'}>{record.opb_gender}</Tag>;
                },
                caption: 'Gender',
            },
            {
                field: 'opb_age',
                render: (record) => {
                    return record.opb_age;
                },
                caption: 'Age',
            },
            {
                field: 'opb_mobile',
                render: (record) => {
                    return record.opb_mobile;
                },
                caption: 'Mobile',
            },
            {
                field: 'opb_opno',
                render: (record) => {
                    return record.opb_opno;
                },
                caption: 'OP No.',
            },
            {
                // field: 'cold_amt',
                render: (record) => {
                    return record.opb_amt;
                },
                caption: 'Amount',
            },

            {
                // field: 'cold_amt',
                render: (record) => {
                    return record.opb_bdisamt;
                },
                caption: 'Discount',
            },

            {
                // field: 'cold_amt',
                render: (record) => {
                    return record.opb_netamt;
                },
                caption: 'Net Amount',
            },

            {
                // field: 'cold_amt',
                render: (record) => {
                    return record.opb_paidamt;
                },
                caption: 'Paid',
            },
            // {
            //     field: 'cold_dt',
            //     render: (record) => {
            //         return record.colldetails ? record.colldetails.cold_dt : null;
            //     },
            //     caption: 'Date'
            // },
            // {
            //     field: 'Collected By',
            //     render: (record) => {
            //         return record.colldetails ? record.colldetails.cold_user : null;
            //     },
            //     caption: 'cold_user'
            // },
            {
                field: 'opb_remarks',
                render: (record) => {
                    return record.opb_remarks;
                },
                caption: 'Remarks',
            },
        ];
    }

    getCustomers = () => {
        return ApiUtils.get({
            url: `customer?page=1&limit=10`,
        });
    };


    /**
     * Get Doctor 
     * @param {*} value 
     * @returns 
     */
    getDoctor = (value) => {
        return ApiUtils.get({
            url: `doctor?do_code=${value}`,
        });
    };

   


    /**
     * Function to get guest details
     * If mode is opreg, data is taken from opreg
     * Else if mode is prereg, data is taken from prereg
     * @param {*} id 
     * @param {*} mode 
     * @returns 
     */
    loadGuestInformation = (id, mode, da_id) => {
        if (da_id)
            return ApiUtils.get({
                url: `opreg/guest-details/${id}?mode=${mode}&doctorappointment_id=${da_id}`
            })
        else
            return ApiUtils.get({
                url: `opreg/guest-details/${id}?mode=${mode}`
            })
    }

    /**
     *   This Config is used for user search componet
     */

    searchConfig = {
        url: 'opreg/opreg-search',
        columns: (callback) => {
            return [
                {
                    field: 'op_no',
                    title: 'Op No',
                    dataIndex: 'op_no',
                },
                {
                    field: 'op_fname',
                    title: 'First Name',
                    dataIndex: 'op_fname',
                },
                {
                    field: 'op_lname',
                    title: 'Last  Name',
                    dataIndex: 'op_lname',
                },

                {
                    // field: '',
                    title: 'Actions',
                    render: (record) => {
                        return (
                            <Button
                                onClick={(event) => {
                                    callback(record);
                                    // getPatientHistory(record.op_no)
                                    // setVisibleView(true);
                                }}
                                type="link"
                            >
                                {' '}
                                {record.action}
                            </Button>
                        );
                    },
                },

                // {
                //     // field: '',
                //     title: 'Actions',
                //     render: (record) => {
                //         return (
                //             <Button
                //             // onClick={onClick(record)}

                //                 onClick={(event) => {
                //                     onClick(event,record);
                //                 }}
                //                 type="link"
                //             >
                //                 {' '}
                //                 View
                //             </Button>
                //         );

                //     },
                // },
            ];
        },
        searchBy: [
            {
                caption: 'First Name',
                field: 'name',
            },
            {
                caption: 'OP No',
                field: 'op_no',
            },
        ],
        criteria: [
            {
                caption: 'Start with',
            },
        ],
    };

    /**
     * Registration List
     * @param {*} range
     * @returns
     */

    getRegistration = (range, page) => {
        const start_time = moment(range[0]).format('MM/DD/YYYY');

        const end_time = moment(range[1]).format('MM/DD/YYYY');

        return ApiUtils.get({
            // <<<<<<< HEAD
            //             url: `opreg?page=${page}&da_date_gte=${start_time}&da_date_lte=${end_time}&limit=20`,
            // =======
            url: `opreg/get-opreg?page=${page}&date_gte=${start_time}&date_lt=${end_time}&limit=20`,
            // >>>>>>> medicant-main
        });
    };


    
    /**
     *
     * @param {*} formBody
     * @returns
     */
    verifyemail = (formBody) => {
        return ApiUtils.post({
            url: `opreg/username-validation`,
            formBody,
        });
    };

    /**
     *Addition key generating Function in opreg 
     * @param {*} formBody
     * @returns
     */
    additionalKey = (id) => {
        return ApiUtils.get({
            url: `opreg/addtionalkey?key=${id}`,
        });
    };


    /**
     *
     * @param {*} da_id
     * @param {*} formBody
     * @returns
     */
    updateRegistration = (da_id, formBody) => {
        return ApiUtils.put({
            url: `opreg/update-registartion/${da_id}`,
            formBody,
        });
    };

    updateOpregDetail = (opno, params) => {
        var formBody = {
            // op_modeofcommptr: "1",
            op_remarks: 'Patient ID :   ' + params.remarks,
            ...params,
        };

        return ApiUtils.put({
            url: `opreg/${opno}`,
            formBody,
        });
    };

    Card = ({ ...props }) => {
        return <GuestCard record={props} {...props}></GuestCard>;
    };

    // DetailComponent = (routeParams) => <BillDetailView {...routeParams} model={this} />;
    //  AddComponent = () => <CollectionAdd model={this} />;
}

// function onClick(event,record) {
//     console.log("record",record)

//     return (
//         <OpregSearch  callback={record}  ></OpregSearch>

//     )
// }
function GuestCard({ record }) {
    return (
        <Card className="card vehicle-card">
            <div className="card">
                <h2 className="title">{record.opb_name}</h2>

                {/* {record.opreg.opb_age} */}

                {/* <h3 className="values">{record.da_aptime}</h3> */}

                {/* <Tag color={record.da_questfilled === 'F' ? 'orange' : 'green'}>{record.da_questfilled === 'F' ? 'Filled' : 'Pending'}</Tag> */}

                <Tag color="green">{record.opb_gender}</Tag>

                <Text type="secondary">{record.opb_mobile}</Text>

                <h4 className="values">{record.opb_opno}</h4>
            </div>
        </Card>
    );
}

export default Opreg;
