/**
 * Component to Show Collection details
 *
 *
 * @author Masuma
 */

import React, { useEffect, useState, Fragment, useRef, useContext } from 'react';

import { Skeleton, Input, Typography, Select, Table, Button } from 'antd';

import { useReactToPrint } from 'react-to-print';

import { GlobalContext } from 'soxo-bootstrap-core';

import './collection-detail.scss';

import { Collections, CollectionDetails } from '../../../../models';

import Collection from '../../../../models/collections';

import { Rupee } from 'soxo-bootstrap-core';

const { Title, Text } = Typography;

export default function CollectionDetailView({ match, ...props }) {
    // var param = Location.search();

    const { user, defaultBranch } = useContext(GlobalContext);

    const [record, setRecord] = useState({});

    const [items, setItems] = useState([]);

    const print = useRef();

    const [loader, setLoader] = useState(false);

    const { id } = match.params;

    const [receipt, setReceipt] = useState(0);

    useEffect(() => {
        loadData();
    }, []);

    const handlePrint = useReactToPrint({
        content: () => print.current,
        bodyClass: 'hello',
    });

    function loadData() {
        setLoader(true);

        Collections.loadReceipt(id).then((result) => {
            setRecord(result);

            setItems(result.colldetails);

            setLoader(false);

            if (result.colldetails && result.colldetails.length) {
                let no = result.colldetails[0].cold_rctno;

                setReceipt(no);
            }

            // CollectionDetails.getReceiptItems(result.cold_tranno).then((result) => {

            //     console.log(result);

            //     setRecord(result);
            //     setItems(result.colldetails)
            // });
        });
    }
    return (
        <div className="page-content">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end' }}>
                <div>
                    <Title level={3}>Receipt</Title>
                    <p>Receipt with Collection Details</p>
                </div>
                <Button type="dashed" className="print-button" onClick={handlePrint}>
                    Print Invoice
                </Button>
            </div>
            <Fragment>
                <div className="card card-shadow receipt-preview">
                    {loader ? (
                        <Skeleton />
                    ) : (
                        <div className="card">
                            <div className="bill-header">
                                {/* <div>
        <img className="brand-logo" src={logo} alt="Logo" />
        <br />
        <h2>Receipt No: {record.cold_rctno}</h2>
    </div>
    <div>
        <p>376, 100 Feet Rd,</p>
        <p>Indiranagar, HAL 2nd Stage,</p>
        <p>Bengaluru, Karnataka 560038</p>
    </div> */}
                                <Title level={4}>Credit Bill Collection Receipt</Title>
                            </div>
                            <hr />
                            <div className="bill-details">
                                <table>
                                    <tr>
                                        <td>
                                            <strong>Receipt No.</strong>
                                        </td>
                                        <td>: {receipt}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>OP No.</strong>
                                        </td>
                                        <td>: {record.opb_opno}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Name</strong>
                                        </td>
                                        <td>: {record.opb_name}</td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <strong>Order No.</strong>
                                        </td>
                                        <td>: {record.opb_bno}</td>
                                    </tr>
                                </table>
                                <table>
                                    <tr>
                                        <td>
                                            <strong>Date</strong>
                                        </td>
                                        <td>: {record.opb_dt}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Ip No.</strong>
                                        </td>
                                        <td>: {record.opb_ipno}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Department</strong>
                                        </td>
                                        <td>: {record.opb_deptmode}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong>Date</strong>
                                        </td>
                                        <td>: {record.opb_dt}</td>
                                    </tr>
                                </table>
                            </div>
                            <Table
                                className="bill-summary"
                                scroll={{ x: true }}
                                // rowKey={(record) => record.da_id}
                                dataSource={items}
                                columns={recieptColumns}
                                pagination={false}
                                summary={(pageData) => {
                                    let Total = 0;
                                    pageData.forEach((entry) => {
                                        Total += entry.cold_amt;
                                    });
                                    return (
                                        <>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell>
                                                    <strong>Total</strong>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>

                                                <Table.Summary.Cell>
                                                    <Text>
                                                        <Rupee value={record.opb_amt + record.opb_disamt}></Rupee>
                                                    </Text>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell>
                                                    <strong>Discount</strong>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>

                                                <Table.Summary.Cell>
                                                    <Text>
                                                        <Rupee value={record.opb_disamt}></Rupee>
                                                    </Text>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell>
                                                    <strong>Net Amount</strong>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>

                                                <Table.Summary.Cell>
                                                    <Text>
                                                        <Rupee value={record.opb_netamt}></Rupee>
                                                    </Text>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell>
                                                    <strong>Paid Amount</strong>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>

                                                <Table.Summary.Cell>
                                                    <Text>
                                                        <Rupee value={record.opb_paidamt}></Rupee>
                                                    </Text>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                            <Table.Summary.Row>
                                                <Table.Summary.Cell>
                                                    <strong>Balance to Pay</strong>
                                                </Table.Summary.Cell>
                                                <Table.Summary.Cell></Table.Summary.Cell>

                                                <Table.Summary.Cell>
                                                    <Text>
                                                        <Rupee value={record.opb_netamt - record.opb_paidamt}></Rupee>
                                                    </Text>
                                                </Table.Summary.Cell>
                                            </Table.Summary.Row>
                                        </>
                                    );
                                }}
                            />
                            <div className="receipt-footer">
                                <div>
                                    <p>Created On:</p>
                                    <p>Printed On:</p>
                                </div>
                                <p>Signature</p>
                            </div>
                            {/* Print Block */}
                            <div className="print-block">
                                <WrapperClass ref={print}>
                                    <div className="card card-shadow bill-preview">
                                        <Fragment>
                                            <div className="page-content">
                                                <div className="card">
                                                    <Title level={4}>Credit Bill Collection Receipt</Title>
                                                    <hr />
                                                    <div className="bill-details">
                                                        <table>
                                                            <tr>
                                                                <td>
                                                                    <strong>Receipt No.</strong>
                                                                </td>
                                                                <td>: {record.opb_gender}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>OP No.</strong>
                                                                </td>
                                                                <td>: {record.opb_opno}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Name</strong>
                                                                </td>
                                                                <td>: {record.opb_name}</td>
                                                            </tr>

                                                            <tr>
                                                                <td>
                                                                    <strong>Order No.</strong>
                                                                </td>
                                                                <td>: {record.opb_bno}</td>
                                                            </tr>
                                                        </table>
                                                        <table>
                                                            <tr>
                                                                <td>
                                                                    <strong>Date</strong>
                                                                </td>
                                                                <td>: {record.opb_opno}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Ip No.</strong>
                                                                </td>
                                                                <td>: {record.opb_ipno}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Department</strong>
                                                                </td>
                                                                <td>: {record.opb_deptmode}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <strong>Date</strong>
                                                                </td>
                                                                <td>: {record.opb_dt}</td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                    <Table
                                                        className="bill-summary"
                                                        scroll={{ x: true }}
                                                        // rowKey={(record) => record.da_id}
                                                        dataSource={items}
                                                        columns={recieptColumns}
                                                        pagination={false}
                                                        summary={(pageData) => {
                                                            let Total = 0;
                                                            pageData.forEach((entry) => {
                                                                Total += entry.cold_amt;
                                                            });
                                                            return (
                                                                <>
                                                                    <Table.Summary.Row>
                                                                        <Table.Summary.Cell>
                                                                            <strong>Total</strong>
                                                                        </Table.Summary.Cell>
                                                                        <Table.Summary.Cell></Table.Summary.Cell>
                                                                        <Table.Summary.Cell>
                                                                            <Text>
                                                                                <Rupee value={record.opb_amt + record.opb_disamt}></Rupee>
                                                                            </Text>
                                                                        </Table.Summary.Cell>
                                                                    </Table.Summary.Row>
                                                                    <Table.Summary.Row>
                                                                        <Table.Summary.Cell>
                                                                            <strong>Discount</strong>
                                                                        </Table.Summary.Cell>
                                                                        <Table.Summary.Cell></Table.Summary.Cell>
                                                                        <Table.Summary.Cell>
                                                                            <Text>
                                                                                <Rupee value={record.opb_disamt}></Rupee>
                                                                            </Text>
                                                                        </Table.Summary.Cell>
                                                                    </Table.Summary.Row>
                                                                    <Table.Summary.Row>
                                                                        <Table.Summary.Cell>
                                                                            <strong>Net Amount</strong>
                                                                        </Table.Summary.Cell>
                                                                        <Table.Summary.Cell></Table.Summary.Cell>
                                                                        <Table.Summary.Cell>
                                                                            <Text>
                                                                                <Rupee value={record.opb_netamt}></Rupee>
                                                                            </Text>
                                                                        </Table.Summary.Cell>
                                                                    </Table.Summary.Row>
                                                                    <Table.Summary.Row>
                                                                        <Table.Summary.Cell>
                                                                            <strong>Paid Amount</strong>
                                                                        </Table.Summary.Cell>
                                                                        <Table.Summary.Cell></Table.Summary.Cell>
                                                                        <Table.Summary.Cell>
                                                                            <Text>
                                                                                <Rupee value={Total}></Rupee>
                                                                            </Text>
                                                                        </Table.Summary.Cell>
                                                                    </Table.Summary.Row>
                                                                    <Table.Summary.Row>
                                                                        <Table.Summary.Cell>
                                                                            <strong>Balance to Pay</strong>
                                                                        </Table.Summary.Cell>
                                                                        <Table.Summary.Cell></Table.Summary.Cell>
                                                                        <Table.Summary.Cell>
                                                                            <Text>
                                                                                <Rupee value={record.opb_netamt - Total}></Rupee>
                                                                            </Text>
                                                                        </Table.Summary.Cell>
                                                                    </Table.Summary.Row>
                                                                </>
                                                            );
                                                        }}
                                                    />
                                                    <div className="receipt-footer">
                                                        <div>
                                                            <p>Created On:</p>
                                                            <p>Printed On:</p>
                                                        </div>
                                                        <p>Signature</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fragment>
                                    </div>
                                </WrapperClass>
                            </div>
                            {/* Print Block ends */}
                        </div>
                    )}
                </div>
            </Fragment>
        </div>
    );
}

class WrapperClass extends React.Component {
    render() {
        return <div style={{ width: '100%' }}>{this.props.children}</div>;
    }
}

const recieptColumns = [
    {
        title: '#',
        width: '10',
        dataIndex: 'index',
        render: (value, item, index) => index + 1,
    },
    {
        title: 'Particulars',

        render: (items) => {
            return <div>{items.cold_paymentmode}</div>;
        },
        key: 'Particulars',
    },

    {
        title: 'Amount',
        width: '30',
        align: 'Right',
        render: (items) => {
            return <div>{items.cold_amt}</div>;
        },
        key: 'Amount',
    },
];
